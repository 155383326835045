import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { StarIcon } from '../../../shared/assets/icons'
import cls from './menuHeader.module.scss'
import PhotoBlock from '../../ProfileForm/PhotoBlock'

const MenuHeader = memo(({ userData }) => {
  return (
    <div className={cls.MenuHeaderWrap}>
      <div className={cls.imageUser}>
        <PhotoBlock photo={userData.photo} />
        <div className={cls.rate}>
          <div className={cls.rateInfo}>
            <StarIcon className={cls.starIcon} width={21} height={18} />
            <span>{userData?.rating}</span>
          </div>
        </div>
      </div>

      <h2>{!userData?.firstName ? t('unknown_name') : userData?.firstName}</h2>
      <Link className={cls.link} to="/profile">
        {t('my_profile')}
      </Link>
    </div>
  )
})

export default MenuHeader
