export const GET_GEOLOCATION_MAP_MARKET = 'GET_GEOLOCATION_MAP_MARKET'
export const getGeolocationMapMarket = (modal) => ({
  type: GET_GEOLOCATION_MAP_MARKET,
  payload: modal
})

export const EDIT_ID_MAP_MARKET = 'EDIT_ID_MAP_MARKET'
export const editIdMapMarket = (modal) => ({
  type: EDIT_ID_MAP_MARKET,
  payload: modal
})

export const EDIT_FIRST_MARKET_MARKET = 'EDIT_FIRST_MARKET_MARKET'
export const editFirstMarket = (market) => ({
  type: EDIT_FIRST_MARKET_MARKET,
  payload: market
})
export const SAVE_NEW_MARKER = 'SAVE_NEW_MARKER'
export const saveNewMarker = (markets) => ({
  type: SAVE_NEW_MARKER,
  payload: markets
})

export const GET_ME_MARKER = 'GET_ME_MARKER'
export const getMeMarker = (marker) => ({
  type: GET_ME_MARKER,
  payload: marker
})

export const REMOVE_ONE_MARKER = 'REMOVE_ONE_MARKER'
export const removeOneMarker = (markers) => ({
  type: REMOVE_ONE_MARKER,
  payload: markers
})

