import { axiosInstance } from 'src/axios.config'
import { apiData } from '../../../config'
import { errorFunction } from '../../../components/ErrorComponent/error.action'
import { errorTokenFunction } from '../../../components/ErrorToken/error.action'

export const SEND_MESSAGE_PENDING = 'SEND_MESSAGE_PENDING'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR'
export const sendMessage = (header, message) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: SEND_MESSAGE_PENDING,
    })
    axiosInstance
      .post(
        apiData + '/profile/support/chat/',
        {
          topic: header,
          content: message,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log('Action* - частые вопросы УСПЕХ: ', response?.data)
        dispatch({
          type: SEND_MESSAGE_SUCCESS,
          payload: true,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        console.log('Action* - частые вопросы ОШИБКА: ', error)
        dispatch({
          type: SEND_MESSAGE_ERROR,
          payload: false,
        })
      })
  }
}

export const GET_ZENDESK_TOKEN_LOADING = 'GET_ZENDESK_TOKEN_LOADING'
export const GET_ZENDESK_TOKEN = 'GET_ZENDESK_TOKEN'
export const getZendeskToken = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ZENDESK_TOKEN_LOADING,
        payload: true,
      })

      const accessToken = localStorage.getItem('accessToken')

      const zendeskToken = await axiosInstance.get(apiData + '/profile/zendesktoken', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })

      dispatch({
        type: GET_ZENDESK_TOKEN,
        payload: zendeskToken.data.token,
      })
    } catch (error) {
      dispatch({
        type: GET_ZENDESK_TOKEN,
        payload: '',
      })
      console.log('Error getZendeskToken:', error)
    } finally {
      dispatch({
        type: GET_ZENDESK_TOKEN_LOADING,
        payload: false,
      })
    }
  }
}