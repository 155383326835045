import { axiosInstance } from "src/axios.config";
import { errorFunction } from "src/components/ErrorSocket/error.action";
import { errorTokenFunction } from "src/components/ErrorToken/error.action";
import { apiCards, paymentGateV2 } from "src/config";

export const BUTTON_ACTIVE = "BUTTON_ACTIVE";
export const BUTTON_DISABLED = "BUTTON_DISABLED";

export const addCardData = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: BUTTON_DISABLED,
			});

			const accessToken = localStorage.getItem("accessToken");
			const response = await axiosInstance.post(paymentGateV2 + "/client/cards", data, {
				headers: { Authorization: `Bearer ${accessToken}` },
			});

			dispatch({
				type: BUTTON_ACTIVE,
				payload: response.data,
			});
		} catch (error) {
			dispatch(errorFunction(error));

			dispatch({
				type: BUTTON_DISABLED,
			});
		}
	};
};

export const GET_CARDS_SUCCESS = "GET_CARD_SUCCESS";
export const GET_CARDS_ERROR = "GET_CARD_ERROR";
export const GET_CARDS_LOADING = "GET_CARDS_LOADING";

export const getUserCards = () => {
	return async (dispatch) => {
		dispatch({
			type: GET_CARDS_LOADING,
			payload: true,
		});

		try {
			const accessToken = localStorage.getItem("accessToken");

			const response = await axiosInstance.get(paymentGateV2 + "/client/cards", {
				headers: { Authorization: `Bearer ${accessToken}` },
			});

			dispatch({
				type: GET_CARDS_LOADING,
				payload: false,
			});

			dispatch({
				type: GET_CARDS_SUCCESS,
				payload: response.data,
			});
		} catch (error) {
			if (error.response && error.response.statusText === "Unauthorized") {
				dispatch(errorTokenFunction(error));
			}

			dispatch({
				type: GET_CARDS_ERROR,
				payload: false,
			});

			dispatch({
				type: GET_CARDS_LOADING,
				payload: false,
			});
		}
	};
};

export const GET_CARD_BY_ID_ERROR = "GET_CARD_BY_ID_ERROR";
export const GET_CARD_BY_ID_LOADING = "GET_CARD_BY_ID_LOADING";

export const getUserCardById = (cardId) => {
	return async (dispatch) => {
		try {
			const accessToken = localStorage.getItem("accessToken");

			const response = await axiosInstance.get(paymentGateV2 + `/cards/${cardId}`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			});

			const isLoading = response.data.status === "Pending";

			if (!isLoading) {
				localStorage.removeItem("cardId");
			}

			dispatch({
				type: GET_CARD_BY_ID_LOADING,
				payload: isLoading,
			});

			return response.data.status;
		} catch (error) {
			if (error.response && error.response.statusText === "Unauthorized") {
				dispatch(errorTokenFunction(error));
			}

			dispatch({
				type: GET_CARD_BY_ID_ERROR,
				payload: false,
			});
		}
	};
};

export const deleteCard = (id) => {
	return async (dispatch) => {
		try {
			const accessToken = localStorage.getItem("accessToken");

			await axiosInstance.delete(apiCards + `/client/cards/${id}`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			});

			dispatch(getUserCards());
		} catch (error) {
			dispatch(errorFunction(error.response));
		}
	};
};
