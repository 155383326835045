import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../../shared/assets/icons";
import cls from "./PageHeader.module.scss";
import { classNames } from "../../shared/lib/classNames/classNames";

function PageHeader({ title, className, onClick, backPage, isBackIconVisible = true }) {
	const navigate = useNavigate();

	const handleGoBack = useCallback(() => {
		if (onClick) {
			onClick();
		} else {
			backPage ? navigate(backPage) : navigate(-1);
		}
	}, [backPage, navigate, onClick]);

	return (
		<div className={classNames(cls.wrapper, {}, [className])}>
			{title && (
				<div className={cls.content}>
					{isBackIconVisible && <BackIcon className={cls.icon} onClick={handleGoBack} />}
					<p className={`${cls.title} ${isBackIconVisible === false ? cls.title_without : ''}`}>{title}</p>
				</div>
			)}
		</div>
	);
}

export default PageHeader;
