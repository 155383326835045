import React, { memo } from 'react'
import Auth from '../../components/Auth'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'
import FormChangePas from '../../components/ProfileForm/FormChangePas'

const EditPassword = memo(() => {
  return (
    <>
      <PageHeader title={t('change_password')} />
      <PageLayout>
        <FormChangePas />
      </PageLayout>
    </>
  )
})

export default EditPassword
