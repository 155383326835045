import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { t } from "i18next";
import cls from "./AuthPassword.module.scss";
import { Input } from "../../shared/ui/Input/Input";
import { Button, ButtonVariant } from "../../shared/ui/Button/button";
import { getPhoneLogin, resetLoginData } from "../Auth/auth.action";
import Loader from "../../shared/ui/Loader/Loader";

const AuthPassword = memo((props) => {
	const { getPhoneLogin, loginError, loginStatus, loginLoaded, resetLoginData } = props;
	const history = useNavigate();
	const [password, setPassword] = useState("");
	const [otpCodeFromSmsCalled, setOtpCodeFromSmsCalled] = useState(false);
	const continueButtonRef = useRef(null);

	const onChangePassword = useCallback((event) => {
		const passwordValue = event.target.value;
		setPassword(passwordValue);
	}, []);

	const submitCheck = useCallback(async () => {
		const phone = localStorage.getItem("userPhone");
		await getPhoneLogin({ phone, password });
	}, [getPhoneLogin, password]);

	// Define the event listener function which call on android
	const setOtpCodeFromSms = (event) => {
		const smsPassword = event.detail.otp;
		setPassword(smsPassword);
		// Set the flag to true when setOtpCodeFromSms is called
		setOtpCodeFromSmsCalled(true);
	};

	useEffect(() => {
		return () => {
			return resetLoginData();
		};
	}, [history, loginStatus, resetLoginData]);

	useEffect(() => {
		// sms listener for android
		window.addEventListener("newOtp", setOtpCodeFromSms);

		return () => {
			window.removeEventListener("newOtp", setOtpCodeFromSms);
		};
	}, []);

	useEffect(() => {
		// Check if setOtpCodeFromSms was called
		if (otpCodeFromSmsCalled) {
			continueButtonRef.current.click();
			// Reset the flag after the side effect is executed
			setOtpCodeFromSmsCalled(false);
		}
	}, [otpCodeFromSmsCalled]);

	if (loginStatus) {
		return <Navigate to="/order" />;
	}

	if (loginLoaded) {
		return <Loader />;
	}

	return (
		<div className={cls.AuthPasswordWrapper}>
			<h1 className={cls.title}>{t("enter_your_password")}</h1>
			<div className={cls.inputPassword}>
				<Input
					className={cls.password}
					isPassword
					type="password"
					autocomplete="one-time-code"
					value={password}
					onChange={onChangePassword}
					placeholder={t("enter_password")}
					isError={loginError}
					valueError={t("incorrect_password")}
				/>
			</div>
			<Link className={cls.forgot} to="/forgot">
				{t("forgot_password")}
			</Link>
			<div className={cls.continueBtn}>
				<Button
					variant={ButtonVariant.background}
					ref={continueButtonRef}
					fullWidth
					onClick={submitCheck}
				>
					{t("continue")}
				</Button>
			</div>
		</div>
	);
});

const mapStateToProps = (state) => {
	return {
		loginLoaded: state.auth.loginLoaded,
		loginPhoneCheck: state.auth.loginPhoneCheck,
		loginStatus: state.auth.loginStatus,
		loginError: state.auth.loginError,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPhoneLogin: (loginInfo) => dispatch(getPhoneLogin(loginInfo)),
		resetLoginData: () => dispatch(resetLoginData()),
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AuthPassword));
