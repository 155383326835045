import moment from 'moment-timezone'

export function convertToUserTimezone(createdAt) {
  // Создаем момент из строки даты с указанием часового пояса GMT+0
  const timezone = localStorage.getItem('timezone')
  const date = moment.utc(createdAt).tz('Etc/GMT+0')

  // Конвертируем дату в часовой пояс пользователя
  const dateInUserTimezone = date.tz(timezone)

  return dateInUserTimezone.format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
}
