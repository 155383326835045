import {
  GET_PASS_PENDING,
  GET_PASS_SUCCESS,
  GET_PASS_ERROR,
  RESET_PASS_INFO,
} from './forgot.action'

const initialState = {
  forgotStatus: false,
  forgotError: false,
  forgotLoaded: false,
}

const forgotReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PASS_PENDING: {
      return {
        ...state,
        forgotLoaded: true,
      }
    }
    case GET_PASS_SUCCESS: {
      return {
        ...state,
        forgotLoaded: false,
        forgotStatus: true,
        forgotError: false,
      }
    }
    case GET_PASS_ERROR: {
      return {
        ...state,
        forgotStatus: false,
        forgotLoaded: false,
        forgotError: true,
      }
    }
    case RESET_PASS_INFO: {
      return {
        ...state,
        forgotStatus: false,
        forgotError: false,
        forgotLoaded: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default forgotReducer
