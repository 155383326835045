/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import cls from 'src/components/MapJs/orders.module.scss'
import { ReactComponent as DownLine } from 'src/images/DownLine.svg'
import { ReactComponent as Headphones } from 'src/images/Headphones.svg'
import { ReactComponent as ChevronRight } from 'src/images/ChevronRight.svg'
import { ReactComponent as LocationPin } from 'src/images/LocationPin.svg'
import { ReactComponent as Info } from 'src/images/Info.svg'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import IconStrip from '../../../IconStrip'
import ModalAlert from '../../../ModalAlert/ModalAlert'
import { getValuteSymbol } from '../../../CurrencyDefinition/getValuteSymbol'
import PaidWait from '../PaidWait'
import OrderTimer from '../OrderTimer'

const CarClass1 = () => <div>{t('78235900-0ce1-4aca-99ca-b89e776f0910')}</div>
const CarClass2 = () => <div>{t('b4c9b252-8d90-400c-bab8-c21f4fd9c7a9')}</div>
const CarClass3 = () => <div>{t('bdf73206-ed08-4aec-b603-40ee948c53b2')}</div>

export const OrderProgress = ({ routes, driverData, tariffCar, carClassName, symbol, socketData }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  let carClass

  if (carClassName === '78235900-0ce1-4aca-99ca-b89e776f0910') {
    carClass = <CarClass1 />
  } else if (carClassName === 'b4c9b252-8d90-400c-bab8-c21f4fd9c7a9') {
    carClass = <CarClass2 />
  } else if (carClassName === 'bdf73206-ed08-4aec-b603-40ee948c53b2') {
    carClass = <CarClass3 />
  } else {
    carClass = <div className={cls.default_icon} />
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const [isDetails, setIsDetails] = useState(false)

  const [startY, setStartY] = useState(null)
  const [endY, setEndY] = useState(null)
  const touchRef = useRef(null)

  const handleTouchStart = (e) => {
    // Проверка, что событие не произошло на элементах button и a (ссылках)
    if (!isButtonOrLink(e.target)) {
      const touch = e.touches[0]
      setStartY(touch.clientY)
      touchRef.current = touch
    }
  }

  const handleTouchEnd = (e) => {
    if (!touchRef.current) return

    // Проверка, что событие не произошло на элементах button и a (ссылках)
    if (!isButtonOrLink(e.target)) {
      const touch = e.changedTouches[0]
      setEndY(touch.clientY)

      const deltaY = endY - startY

      if (deltaY > 50) {
        setIsDetails(false)
      } else if (deltaY < -50) {
        setIsDetails(true)
      }

      touchRef.current = null
    }
  }

  // Функция, которая проверяет, является ли элемент кнопкой или ссылкой или находится внутри них
  const isButtonOrLink = (element) => {
    if (!element) return false
    if (element.tagName.toLowerCase() === 'button' || element.tagName.toLowerCase() === 'a') {
      return true
    }
    // Рекурсивная проверка для вложенных элементов
    if (element.parentElement) {
      return isButtonOrLink(element.parentElement)
    }
    return false
  }

  const textFormator = (text) => {
    const arrAdress = text.split(', ')
    let minusCountElement = 1
    let mainText = arrAdress[0] + ' '

    if (parseInt(arrAdress[1])) {
      mainText += arrAdress[1]
      minusCountElement++
    }

    return {
      mainText,
      secondaryText: arrAdress.slice(minusCountElement, arrAdress.length - 2).join(', '),
    }
  }

  const address = (point) => point.adress?.formatted_address || point.name
  let hasSecondaryText = true
  routes.forEach((point) => {
    const formattedAddress = textFormator(address(point) || '')
    if (!formattedAddress.secondaryText) {
      hasSecondaryText = false
    }
  })

  const firstRoute = routes[0]
  let secondaryTextLength = 0

  if (firstRoute) {
    secondaryTextLength = textFormator(address(firstRoute)).secondaryText.length
  }

  const pendingTime = JSON.parse(localStorage.getItem('pendingTime')) ?? 0 //!TODO: need to check

  return (
    <>
      <ModalAlert isOpen={isModalOpen} onClose={closeModal} />
      <div className={cls.OrderExpectWrapper}>
        {/* Need a condition to show the timer -- PaidWait */}
        {/* <PaidWait pendingTime={pendingTime} isCountdown={false} /> */}

        <div
          className={cls.search_car_form}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className={cls.down_line}
            onClick={() => setIsDetails(!isDetails)}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <DownLine className={cls.down_line_icon} />
          </div>
          <div className={cls.header_order_block}>
            <div className={cls.header_main_text}>
              <p>{t('on_road')}</p>
            </div>
            <div className={cls.current_address_block}>
              <LocationPin />
              <p>{textFormator(address(routes[routes.length - 1])).mainText}</p>
            </div>
          </div>
          <div className={cls.car_class_info_block} style={{ marginBottom: '16px' }}>
            <div className={cls.car_name_with_class}>
              <p>
                {t(driverData.carColorName)} {driverData.carName}
              </p>
              <div className={cls.car_circle} />
              <p>{carClass}</p>
            </div>
            <div className={cls.car_number}>{driverData.carNumber}</div>
          </div>
          {isDetails && (
            <div className={cls.details_search}>
              <div className={cls.details_items}>
                <div className={cls.order_timer}>
                  <div className={cls.timer_single_item}>
                    <p className={cls.timer_light_text}>{t('order_completed_time')}</p>
                    <OrderTimer />
                  </div>
                  <div className={cls.timer_single_item} style={{ marginLeft: '8px' }}>
                    <div className={cls.add_cost_text} onClick={openModal}>
                      <p className={cls.timer_light_text}>{t('add_cost')}</p>
                      <Info />
                    </div>
                    <div className={cls.add_cost_timer}>
                      <p className={cls.timerBoldText}>{socketData?.priceWaiting ?? 0}</p>
                      <p className={cls.timerBoldText}>{symbol}</p>
                    </div>
                  </div>
                </div>
                <div className={cls.order_path} style={{ marginTop: '24px' }}>
                  <p>{t('route')}</p>
                </div>
                <div className={cls.order_path_details}>
                  <div className={cls.path_icons}>
                    <IconStrip
                      iconCount={routes.length}
                      secondaryTextLength={secondaryTextLength}
                    />
                  </div>
                  <div className={cls.path_route}>
                    {routes.map((point) => {
                      const formattedAddress = textFormator(address(point))
                      return (
                        <div className={cls.path_single_item} key={point.id}>
                          <p className={cls.main_text}>{formattedAddress.mainText}</p>
                          {hasSecondaryText && (
                            <p className={cls.secondary_text}>{formattedAddress.secondaryText}</p>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className={cls.order_main_safe}>
                  <div className={cls.order_path} style={{ marginTop: '5px' }}>
                    <p>{t('safe')}</p>
                  </div>
                  <Link to="/chats" className={cls.link_btn}>
                    <div className={cls.link_name}>
                      <Headphones />
                      <p>{t('support_service')}</p>
                    </div>
                    <ChevronRight />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    routes: state.odrerRoute.routes,
    tariffCar: state.odrerRoute.tariffCar,
    driverData: state.socket.driverData,
    carClassName: state.odrerRoute.car.carClassId,
    symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
    socketData: state.socket.currentOrderData
  }
}
export default connect(mapStateToProps, {})(OrderProgress)
