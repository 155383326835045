const colors = {
  lightTheme: {
    routeColor: 'black',
  },
  darkTheme: {
    routeColor: 'white',
  },
}

export default colors
