import React, { memo } from 'react'
import { t } from 'i18next'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import PaymentInfo from '../../components/PaymentInfo'

const PaymentInfoPage = memo(() => {
  return (
    <>
      <PageHeader title={t('payment_and_refunds')} />
      <PageLayout>
        <PaymentInfo />
      </PageLayout>
    </>
  )
})

export default PaymentInfoPage
