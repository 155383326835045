import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { HistoriesAddress } from 'src/components/HistoriesAddress'
import { getLastRide } from '../../../History/store/history.action'
import { ReactComponent as ArrowRight } from '../../../../images/ArrowRight.svg';
import { ReactComponent as DownLine } from "../../../../images/DownLine.svg";
import { textFormator } from 'src/components/OrderRoute/AutocompleteOrderHandler';
import { useSwipeable } from 'react-swipeable'
import { HomeIcon, WorkIcon } from 'src/shared/assets/icons'
import styles from './styles.module.scss';
import { t } from 'i18next'

let isFirstGet = true

const FirstPlace = ({ lastPoints, getLastRide, openFirstHistory, closeMap, homeAddress, workAddress, openAnotherComponentBottomMenu, nameComponents }) => {

    const [isDetails, setIsDetails] = useState(false)

    useEffect(() => {
        if (lastPoints.length === 0 && isFirstGet) { // если нет  то делаем запрос для их получения 
            isFirstGet = false;
            getLastRide()
        }
    }, [])

    const handlers = useSwipeable({
        onSwipedUp: () => setIsDetails(true),
        onSwipedDown: () => setIsDetails(false),
    })

    const addAdress = (place) => {
        localStorage.removeItem("orderStage");
        openFirstHistory(place, 1)
    }
    return (
        <div className={styles.windowWrapper} {...handlers}>
            <div className={styles.downLine} onClick={() => setIsDetails(!isDetails)}>
                <DownLine className={styles.downLineIcon} />
            </div>
            <button onClick={() => { openAnotherComponentBottomMenu(nameComponents.ORDER_ADDRESSES); closeMap(); localStorage.removeItem("orderStage"); }}>
                <ArrowRight />
                <p>{t('where_shall_we_go')}</p>
            </button>
            {isDetails &&
                <>
                    {homeAddress?.name &&
                        <div className={styles.singleAddressText} onClick={() => addAdress(homeAddress)}>
                            <HomeIcon />
                            <div className={styles.textBlock}>
                                <p className={styles.mainText}>{t('home')}</p>
                                <p className={styles.secondaryText}>{textFormator(homeAddress?.name).mainText}</p>
                            </div>
                        </div>
                    }
                    {workAddress?.name &&
                        < div className={styles.singleAddressText} onClick={() => addAdress(workAddress)}>
                            <WorkIcon />
                            <div className={styles.textBlock}>
                                <p className={styles.mainText}>{t('work')}</p>
                                <p className={styles.secondaryText}>{textFormator(workAddress?.name).mainText}</p>
                            </div>
                        </div>
                    }
                </>
            }
            <HistoriesAddress click={openFirstHistory} lastPoints={lastPoints} inputId={1} />
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        lastPoints: state.history?.lastPoints,
        homeAddress: state.profile?.userData.homeAddress,
        workAddress: state.profile?.userData.workAddress
    };
};

export default connect(mapStateToProps, { getLastRide })(FirstPlace)