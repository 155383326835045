import { axiosInstance } from 'src/axios.config'
import { apiData } from '../../config'
import { errorFunction } from '../ErrorComponent/error.action'
import { errorTokenFunction } from '../ErrorToken/error.action'

export const GET_FAVORITE_PENDING = 'GTE_FAVORITE_PENDING'
export const GET_FAVORITE_SUCCESS = 'GTE_FAVORITE_SUCCESS'
export const GET_FAVORITE_ERROR = 'GTE_FAVORITE_ERROR'

export const getFavoriteAddress = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_FAVORITE_PENDING,
    })
    axiosInstance
      .get(apiData + '/profile/fav-addr', { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        dispatch({
          type: GET_FAVORITE_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_FAVORITE_ERROR,
          payload: error,
        })
      })
  }
}

export const POST_FAVORITE_PENDING = 'POST_FAVORITE_PENDING'
export const POST_FAVORITE_SUCCESS = 'POST_FAVORITE_SUCCESS'
export const POST_FAVORITE_ERROR = 'POST_FAVORITE_ERROR'

export const postFavoriteAddress = (data) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: POST_FAVORITE_PENDING,
    })
    axiosInstance
      .post(apiData + '/profile/fav-addr', data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        dispatch(getFavoriteAddress())
        dispatch({
          type: POST_FAVORITE_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: POST_FAVORITE_ERROR,
          payload: error,
        })
      })
  }
}

export const DELETE_FAVORITE_PENDING = 'DELETE_FAVORITE_PENDING'
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS'
export const DELETE_FAVORITE_ERROR = 'DELETE_FAVORITE_ERROR'

export const deleteFavoriteAddress = (id) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: DELETE_FAVORITE_PENDING,
    })
    axiosInstance
      .delete(apiData + `/profile/fav-addr/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        dispatch(getFavoriteAddress())
        dispatch({
          type: DELETE_FAVORITE_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: DELETE_FAVORITE_ERROR,
          payload: error,
        })
      })
  }
}
