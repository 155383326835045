import React from 'react'

export const SortUpIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#fff"
        d="M12.75 12.25a.75.75 0 0 1 .75-.75h6.75a.75.75 0 1 1 0 1.5H13.5a.75.75 0 0 1-.75-.75Zm7.5 5.25H7.5a.75.75 0 1 0 0 1.5h12.75a.75.75 0 1 0 0-1.5ZM15 7h5.25a.75.75 0 1 0 0-1.5H15A.75.75 0 1 0 15 7ZM3.22 9.031a.75.75 0 0 0 1.061 0l2.47-2.47v7.19a.75.75 0 1 0 1.5 0V6.56l2.469 2.47A.75.75 0 1 0 11.78 7.97l-3.75-3.75a.75.75 0 0 0-1.061 0L3.22 7.97a.75.75 0 0 0 0 1.061Z"
      />
    </svg>
  )
}
