import { memo, useCallback } from "react";
import { ReactComponent as PlusCircle } from "../../../../../../images/PlusCircle.svg";
import { t } from "i18next";

const PaymentForm = (props) => {
	const { formInputs = [], action, cardId, method, className } = props;

	const handleClick = useCallback(() => {
		localStorage.setItem("cardId", cardId);
	}, [cardId]);

	return (
		<form action={action} method={method} id="makepayment-form">
			{formInputs &&
				formInputs.map((input, index) => (
					<input key={`${input.name}_${index}`} type="hidden" name={input.name} value={input.value} />
				))}

			<button
				type="submit"
				name="btnsubmit"
				onClick={handleClick}
				value={t("add_card")}
				className={className}
			>
				<PlusCircle />
				<p>{t("add_card")}</p>
			</button>
		</form>
	);
};

export default memo(PaymentForm);
