import React, { memo } from 'react'
import { t } from 'i18next'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import Questions from '../../components/Questions'

const QuestionsPage = memo(() => {
  return (
    <>
      <PageHeader title={t('common_questions')} />
      <PageLayout>
        <Questions />
      </PageLayout>
    </>
  )
})

export default QuestionsPage
