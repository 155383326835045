import React, { memo } from 'react'
import { HeadphonesIcon } from '../../../shared/assets/icons'
import MenuItemLink from '../../Menu/MenuItemLink'
import cls from './historyDetailItem.module.scss'
import HistoryDetailHeader from './HistoryDetailHeader'
import HistoryDetailRoutes from './HistoryDetailRoutes'
import HistoryDetailPaid from './HistoryDetailPaid'
import { t } from 'i18next'

const HistoryDetailItem = memo((props) => {
  const { destinationPoints } = props

  return (
    <div className={cls.HistoryDetailItem}>
      <HistoryDetailHeader {...props} />
      <HistoryDetailRoutes routePoints={destinationPoints} />
      <HistoryDetailPaid {...props} />
      <div className={cls.help}>
        <h1>{t('help')}</h1>
        <MenuItemLink
          className={cls.link}
          icon={HeadphonesIcon}
          text={t('support_service')}
          path="/chats"
        />
      </div>
    </div>
  )
})

export default HistoryDetailItem
