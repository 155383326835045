/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import mapStart from '../../images/mapStart.svg'
import mapEnd from '../../images/mapEnd.svg'
import { t } from 'i18next'

export let polylinesArr = []

let orderAddressInfo = {}
let markers = [
  {
    id: 'origin',
    marker: false,
  },
  {
    id: 'destination',
    marker: false,
  },
]

class AutocompleteDirectionsHandler {
  constructor(map) {
    // Инициализация структуры
    this.map = map
    this.directionsService = new window.google.maps.DirectionsService()
    this.directionsRenderer = new window.google.maps.DirectionsRenderer()
    this.directionsRenderer.setMap(map)
    this.directionsRenderer.setOptions({
      polylineOptions: {
        strokeWeight: 4,
        strokeOpacity: 0.8,
        strokeColor: '#3BB525',
      },
      suppressMarkers: true,
      zoom: 10,
    })
    this.travelMode = 'DRIVING'
    this.route = function (param) {
      // Проверка на заполненость полей и что бы не совпадали адреса
      if (!this.originPlaceId || !this.destinationPlaceId) {
        return
      }
      const originInput = document.getElementById('origin-input')
      const destinationInput = document.getElementById('destination-input')

      if (!originInput?.value) {
        markers[0].marker.setMap(null)
        this.directionsRenderer.setMap(null)
        this.originPlaceId = null
      }

      if (!destinationInput?.value) {
        markers[1].marker.setMap(null)
        this.directionsRenderer.setMap(null)
        this.destinationPlaceId = null
      }

      if (this.originPlaceId === this.destinationPlaceId) {
        document.getElementById('check-address').value = -1
        return
      }

      const me = this
      // me.directionsRenderer.setMap(null)
      this.directionsService.route(
        {
          origin: { placeId: this.originPlaceId },
          destination: { placeId: this.destinationPlaceId },
          travelMode: this.travelMode,
        },
        function (response, status) {
          me.directionsRenderer.setMap(map)
          if (status === 'OK') {
            if (response.routes['0'] && response.routes['0'].legs) {
              response.routes['0'].legs.forEach((leg) => {
                const { steps } = leg

                if (polylinesArr.length > 0) {
                  polylinesArr = []
                }

                for (let i = 0; i < steps.length; i++) {
                  const polylines = steps[i].polyline
                  if (polylines && polylines.points) {
                    polylinesArr.push(polylines.points)
                  }
                }
              })
            }

            if (param) {
              localStorage.setItem('orderInfo', JSON.stringify(orderAddressInfo)) //! remove
              localStorage.setItem(
                'orderInfoResponse',
                JSON.stringify({
                  geocoded_waypoints: response.geocoded_waypoints,
                  routes: response.routes,
                })
              )
            }

            // Рисуем маршрут
            me.directionsRenderer.setDirections(response)
            const zoom = me.map.getZoom()
            me.map.setZoom(zoom - 2)

            if (param && response.routes['0'].legs['0'].distance.value < 2000000) {
              // setTimeout(() =>  document.getElementById('check-address').value = 1, 300)
              document.getElementById('check-address').value = 1
            } else if (param && response.routes['0'].legs['0'].distance.value > 1999999) {
              setTimeout(() => (document.getElementById('check-address').value = -3), 300)
            }
          } else {
            window.alert(t('impossible_create_route') + status)
          }
        }
      )
    }

    const originInput = document.getElementById('origin-input')
    const originAutocomplete = new window.google.maps.places.Autocomplete(originInput)
    originAutocomplete.setFields(['place_id']) // Specify just the place data fields that you need.

    const destinationInput = document.getElementById('destination-input')
    const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationInput)
    destinationAutocomplete.setFields(['place_id']) // Specify just the place data fields that you need.

    this.setupPlaceChangedListener(originAutocomplete, 'ORIG')
    this.setupPlaceChangedListener(destinationAutocomplete, 'DEST')
  }

  setupPlaceChangedListener = function (autocomplete, mode) {
    autocomplete.bindTo('bounds', this.map)
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (!place.place_id) {
        document.getElementById('check-address').value = -2
        return
      }
      if (mode === 'ORIG') {
        this.originPlaceId = place.place_id
        this.initMarker(place.place_id, 'origin')
      } else {
        this.destinationPlaceId = place.place_id
        this.initMarker(place.place_id, 'destination')
      }
      this.route(true)
    })
  }

  setupPlaceToMap = function (placeId, name) {
    if (name === 'origin') {
      this.originPlaceId = placeId
    }
    if (name === 'destination') {
      this.destinationPlaceId = placeId
    }
    this.initMarker(placeId, name)
    this.route(true)
  }

  initMarker = function (placeId, name) {
    const geoData = new window.google.maps.Geocoder()
    geoData.geocode({ placeId: placeId }, (results, status) => {
      const marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        }),
        map: this.map,
        icon: name === 'origin' ? mapStart : mapEnd,
      })

      if (name === 'origin') {
        localStorage.setItem('startPlace', JSON.stringify(results[0]))
        if (markers[0].marker !== false) {
          markers[0].marker.setMap(null)
        }
        markers[0].marker = marker
        marker.setMap(this.map)
      }
      if (name === 'destination') {
        if (markers[1].marker !== false) {
          markers[1].marker.setMap(null)
        }
        markers[1].marker = marker
        marker.setMap(this.map)
      }
    })
  }
}

export default AutocompleteDirectionsHandler
