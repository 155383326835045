import React from 'react'
import cls from './photoBlock.module.scss'
import { classNames } from '../../../shared//lib/classNames/classNames'
import { UserIcon } from '../../../shared/assets/icons'

const PhotoBlock = ({ children, className, photo }) => {
  const renderPhoto = () => {
    if (photo) {
      return (
        <div className={classNames(cls.circleAvatar, {}, [className])}>
          <img src={photo} alt="User Avatar" />
        </div>
      )
    } else {
      return <UserIcon className={cls.icon} />
    }
  }

  const mods = {
    [cls.noImg]: !Boolean(photo),
  }

  return (
    <div className={cls.imageUser}>
      <div className={classNames(cls.imageBlock, mods, [className])}>
        {renderPhoto()}
        {children}
      </div>
    </div>
  )
}

export default PhotoBlock
