import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { t } from "i18next";
import cls from "./registration.module.scss";
import {
	registration,
	getUserCoords,
	getUserLocation,
	resetUserData,
} from "./registration.action";
import useLanguageManager from "../../shared/hooks/useLanguageManager";
import { CustomSwitch } from "../../shared/ui/Switch/switch";
import { Input } from "../../shared/ui/Input/Input";
import Loader from "../../shared/ui/Loader/Loader";
import { Trans } from "react-i18next";
import Select from "../../shared/ui/Select/Select";
import { Button, ButtonSize, ButtonVariant } from "../../shared/ui/Button/button";
import { languagesNames } from "../../mock/languages";
import { GlobeIcon } from "../../shared/assets/icons";
import CountrySelector from "../CountrySelector";
import { setCountryPhoneCode } from "../Auth/auth.action";

const chkPhoneNumber = new RegExp(/^[0-9]{0,9}$/);
const checkName = new RegExp("^[а-яёєїіґ'‘-]{0,20}$", "i");
const internationalNameRegex = new RegExp(/^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u0500-\u052F'-]+$/u);
const chkEmail = new RegExp(/([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.]+[A-Za-z]{2,}$)/);

const Registration = memo((props) => {
	const {
		getUserCoords,
		registration,
		registrationCountryInfo,
		resetUserData,
		registrationStatus,
		registrationLoaded,
		rpcLoaded,
		registrationError,
		registrationRpcError,
		registrationCoordsError,
		getUserLocation,
		countryCode,
		changeLanguageTitle,
		setCountryPhoneCode,
	} = props;

	const navigate = useNavigate();
	const [phone, setPhone] = useState("");
	const [firstName, setFirstName] = useState({ name: "", isError: false });
	const [lastName, setLastName] = useState({ name: "", isError: false });
	const [email, setEmail] = useState({ name: "", isError: false });
	const [password, setPassword] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [switchState, setSwitchState] = useState(false);
	const { language, changeLanguage, getCurrentLanguage } = useLanguageManager();

	// Get current location client
	useEffect(() => {
		const fetchUserCoords = async () => {
			await getUserLocation(); //TODO
			await getUserCoords();
		};

		fetchUserCoords();
		getCurrentLanguage();

		return () => resetUserData();
	}, [getCurrentLanguage, getUserCoords, getUserLocation, resetUserData]);

	useEffect(() => {
		if (registrationStatus && switchState) {
			navigate("/code");
		}
	}, [navigate, registrationStatus, switchState]);

	const handleLanguageChange = useCallback(
		(newLanguage) => {
			changeLanguage(newLanguage);
			changeLanguageTitle(t("register"));
		},
		[changeLanguage, changeLanguageTitle],
	);

	const onChangePhone = useCallback((event) => {
		const inputValue = event.target.value.slice(0, 9);

		if (chkPhoneNumber.test(inputValue)) {
			setPhone(inputValue);
		}
	}, []);

	const onChangePassword = useCallback((event) => {
		const passwordValue = event.target.value;
		if (passwordValue.length <= 30) {
			setPassword(passwordValue);
		}
	}, []);

	const isNameValid = useCallback((name) => {
		return name.length >= 2 && internationalNameRegex.test(name);
	}, []);

	const onChangeName = useCallback(
		(event) => {
			const name = event.target.value.replace(/\s+/g, "").trim();
			const typeField = event.target.name;

			const newName = {
				name,
				isError: !isNameValid(name),
			};

			typeField === "firstName" ? setFirstName(newName) : setLastName(newName);
		},
		[isNameValid],
	);

	const onChangeEmail = useCallback(({ target }) => {
		const email = target.value;
		const isEmailValid = (email) => chkEmail.test(email) || email.length === 0;

		const newEmailName = {
			name: email,
			isError: !isEmailValid(email),
		};

		setEmail(newEmailName);
	}, []);

	const handleSwitchChange = useCallback(() => {
		setSwitchState(!switchState);
	}, [switchState]);

	const submitCheck = async () => {
		const correctPhone = countryCode + phone;
		const languageName = localStorage.getItem("language_name");

		localStorage.setItem("userPhone", correctPhone);

		const regInfo = {
			phone: correctPhone,
			password: password,
			email: email.name || null,
			name: firstName.name || null,
			surname: lastName.name || null,
			country: registrationCountryInfo?.country_code,
			currency: registrationCountryInfo?.currency_code,
			language: languageName,
		};

		await registration(regInfo);
	};

	//!TODO
	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onOpen = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const isValidDataReg = () => {
		if (
			phone.length === 9 &&
			password.length >= 4 &&
			firstName.name &&
			lastName.name &&
			!firstName.isError &&
			!lastName.isError &&
			!email.isError &&
			switchState
		) {
			return false;
		}

		return true;
	};

	if (registrationLoaded || rpcLoaded) {
		return <Loader />;
	}

	if (registrationCoordsError || registrationRpcError) {
		return <Navigate to="/" />;
	}

	return (
		<div className={cls.RegistrationWrapper}>
			<h1 className={cls.title}>{t("enter_phone")}</h1>
			<div className={cls.inputPhone}>
				<CountrySelector
					countryInfo={registrationCountryInfo}
					setCountryPhoneCode={setCountryPhoneCode}
					countryCode={countryCode}
					placeholder={t("enter_phone")}
					type="number"
					maxLength="9"
					value={phone}
					onChange={onChangePhone}
					isRequired
				/>
			</div>
			<Input
				className={cls.field}
				type="password"
				isPassword
				value={password}
				onChange={onChangePassword}
				placeholder={t("password")}
				isError={registrationError}
				valueError={t("wrong_login_or_password")}
				isRequired
			/>
			<Input
				className={cls.field}
				type="text"
				name="firstName"
				value={firstName.name}
				onChange={onChangeName}
				placeholder={t("name")}
				isError={firstName.isError}
				valueError={t("incorrect_name")}
				isRequired
			/>
			<Input
				className={cls.field}
				type="text"
				name="lastName"
				value={lastName.name}
				onChange={onChangeName}
				placeholder={t("last_name")}
				isError={lastName.isError}
				valueError={t("incorrect_last_name")}
				isRequired
			/>
			<Input
				className={cls.field}
				type="text"
				value={email.name}
				onChange={onChangeEmail}
				placeholder="Email"
				isError={email.isError}
				valueError={t("incorrect_email")}
			/>
			<div className={cls.requiredInputs}>
				<p>* - {t("required_fields")}</p>
			</div>
			<div className={cls.terms}>
				<p>
					<Trans
						i18nKey="agree_terms"
						components={{
							Link1: (
								<Link className={cls.link} to="/rules">
									link
								</Link>
							),
							Link2: (
								<Link className={cls.link} to="/policy">
									link
								</Link>
							),
						}}
					/>
				</p>
				<FormControlLabel
					checked={switchState}
					sx={{ mr: 0 }}
					onChange={handleSwitchChange}
					control={<CustomSwitch />}
				/>
			</div>

			<div className={cls.continueBtn}>
				<Button
					variant={ButtonVariant.background}
					fullWidth
					onClick={submitCheck}
					disabled={isValidDataReg()}
				>
					{t("continue")}
				</Button>
			</div>

			<div className={cls.languages}>
				<Button
					variant={ButtonVariant.select}
					size={ButtonSize.M}
					className={cls.btnLanguages}
					onClick={onOpen}
				>
					<GlobeIcon className={cls.icon} width={17} height={16} />
					<span className={cls.value}>{t(language ? language.name : "language")}</span>
				</Button>
			</div>
			{isOpen && (
				<Select
					title={t("language")}
					onClose={onClose}
					isOpen={isOpen}
					options={languagesNames}
					selectedOption={language}
					handleOptionClick={handleLanguageChange}
				/>
			)}
		</div>
	);
});

const mapStateToProps = (state) => {
	return {
		registrationCountryInfo: state.registration.registrationCountryInfo,
		loginPhone: state.auth.loginPhone,
		registrationLoaded: state.registration.registrationLoaded,
		rpcLoaded: state.registration.rpcLoaded,
		countryCode: state.auth.countryCode,
		registrationStatus: state.registration.registrationStatus,
		registrationError: state.registration.registrationError,
		registrationMessage: state.registration.registrationMessage,
		registrationCoordsError: state.registration.registrationCoordsError,
		registrationRpcError: state.registration.registrationRpcError,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		registration: (userData) => dispatch(registration(userData)),
		getUserCoords: () => dispatch(getUserCoords()),
		getUserLocation: () => dispatch(getUserLocation()),
		resetUserData: () => dispatch(resetUserData()),
		setCountryPhoneCode: (countryCode) => dispatch(setCountryPhoneCode(countryCode)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
