/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import mapStart from '../../images/mapStart.svg'
import mapEnd from '../../images/mapEnd.svg'
import { t } from 'i18next'
import { useEffect } from 'react'
import LocationPin from '../../images/MyGeolocation.svg'
import LocationPinGreen from '../../images/MyGeolocationGreen.svg'

export let polylinesArr = []

let orderAddressInfo = {}
let markers = [
  {
    id: 'origin',
    marker: false,
  },
  {
    id: 'destination',
    marker: false,
  },
]

const options = {
  types: ['geocode'],
  strictBounds: true,
}

export const initMarker = (placeId, input_id, choiceAdress) => {
  const geoData = new window.google.maps.Geocoder()
  geoData.geocode({ placeId: placeId }, (results, status) => {
    const data = results[0]
    choiceAdress(input_id, data)
  })
}

const setupPlaceChangedListener = (autocomplete, input_id, choiceAdress) => {
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    initMarker(place.place_id, input_id, choiceAdress)
  })
}

const AutocompleteOrderHandler = (inputs, choiceAdress) => {
  inputs.forEach((data) => {
    const input = data.ref.current
    const autocomplete = new window.google.maps.places.Autocomplete(input, options)
    autocomplete.setFields(['place_id'])
    setupPlaceChangedListener(autocomplete, data._id, choiceAdress)
  })
}

const getWaypoints = (arr) => {
  return arr.map((item) => {
    return {
      stopover: true,
      location: { placeId: item.adress.place_id },
    }
  })
}

export const createTravelForMap = (routes, map, changeTravelRotesOrderRoute) => {
  const appTheme = localStorage.getItem('appTheme')
  const commonConfig = JSON.parse(localStorage.getItem('commonConfig'))

  if (commonConfig?.mapProvider === 'osm-carma') return

  let strokeColor = '#212121'
  if (appTheme === 'dark') {
    strokeColor = '#FFFFFF'
  }

  const directionsService = new window.google.maps.DirectionsService()
  const directionsRenderer = new window.google.maps.DirectionsRenderer()
  directionsRenderer.setMap(map)
  directionsRenderer.setOptions({
    polylineOptions: {
      strokeWeight: 4,
      strokeOpacity: 0.8,
      strokeColor: strokeColor,
    },
    suppressMarkers: true,
    zoom: 10,
  })

  const first = routes[0].adress
  const last = routes[routes.length - 1].adress

  const waypoints = getWaypoints(routes.slice(1, -1))

  const request = {
    origin: { placeId: first.place_id },
    destination: { placeId: last.place_id },
    travelMode: window.google.maps.TravelMode.DRIVING,
    waypoints: waypoints,
  }

  directionsService.route(request, function (result, status) {
    if (status === google.maps.DirectionsStatus.OK) {
      directionsRenderer.setDirections(result) //! build map
      routes.forEach((market, i) => {
        const img = i === 0 ? LocationPinGreen : LocationPin
        new window.google.maps.Marker({
          position: market.adress.geometry.location,
          map: map,
          icon: {
            url: img,
            scaledSize: new window.google.maps.Size(32, 32),
          },
          title: market._id,
        })
      })
      changeTravelRotesOrderRoute(result)
    }
  })
}

export const textFormator = (text) => {
  if (!text) {
    return {};
  }

  const arrAddress = text.split(", ");
  let mainText, secondaryText;

  if (!parseInt(arrAddress[1])) {
    mainText = arrAddress.slice(0, 2).join(", ");
    secondaryText = arrAddress.slice(2, arrAddress.length - 1).join(", ");
  } else {
    mainText = arrAddress.slice(0, 2).join(", ") + ", " + arrAddress[3];
    secondaryText = arrAddress.slice(2, arrAddress.length - 2).join(", ");
  }

  return {
    mainText,
    secondaryText,
  };
};

export default AutocompleteOrderHandler
