import React, { memo, useEffect } from "react";
import { t } from "i18next";
import { List } from "../../common/List";
import MenuItemLink from "../Menu/MenuItemLink";
import { HeadphonesIcon } from "../../shared/assets/icons";
import { ReactComponent as DeleteAccountIcon } from "src/images/DeleteAccountIcon.svg";
import MenuItem from "src/shared/ui/MenuItem/MenuItem";
import cls from "./helpList.module.scss";
import ZendeskWidget, { ZendeskAPI } from "../ZendeskWidget";
import { zendeskKey } from "src/config";
import useZendeskStyles from "src/components/ZendeskWidget/hooks/useZendeskStyles";

const HelpList = (props) => {
	const { userData, zendeskToken, getZendeskToken, getUserData } = props;

	const { adjustIframePadding } = useZendeskStyles();

	const addConversationFields = () => {
		const { phone, firstName, lastName } = userData;

		const fields = [
			{ id: "12351325214620", value: String(phone) },
			{ id: "12335717889052", value: String(firstName ?? "") },
			{ id: "12335694744348", value: String(lastName ?? "") },
		];

		ZendeskAPI("messenger:set", "conversationFields", fields);
	};

	const handleCloseZendesk = () => {
		ZendeskAPI("messenger", "close");
	};

	const handleOpenZendesk = () => {
		const languageName = localStorage.getItem("language_name")?.toLowerCase();

		ZendeskAPI("messenger", "open");
		ZendeskAPI("messenger:set", "locale", languageName);

		adjustIframePadding();

		window.addEventListener("popstate", handleCloseZendesk);
	};

	const handleLoadedZendesk = () => {
		addConversationFields();
	};

	useEffect(() => {
		const fetchData = async () => {
			const countryCode = localStorage.getItem("country_code");
			await getZendeskToken();
			await getUserData(countryCode);
		};

		fetchData();

		return () => {
			window.removeEventListener("popstate", handleCloseZendesk);
		};
	}, [getUserData, getZendeskToken]);

	useEffect(() => {
		if (zendeskToken) {
			ZendeskAPI("messenger", "loginUser", function (cb) {
				cb(zendeskToken);
			});
		}
	}, [zendeskToken]);

	return (
		<List>
			<ZendeskWidget defer onLoaded={handleLoadedZendesk} zendeskKey={zendeskKey} />
			<MenuItem
				className={cls.menuButton}
				showRightIcon={false}
				icon={<HeadphonesIcon className={cls.icon} />}
				title={t("support_service")}
				onClick={handleOpenZendesk}
			/>
			<MenuItemLink
				className={cls.menuHelpLink}
				icon={DeleteAccountIcon}
				text={t("delete_account")}
				path="/delete-account"
			/>
		</List>
	);
};

export default memo(HelpList);
