export const SHOW_MODAL_ALERT = 'SHOW_MODAL_ALERT'
export const SHOW_MODAL_ACTION = 'SHOW_MODAL_ACTION'
export const SHOW_MODAL = 'SHOW_MODAL'

export const showModalAlert = (status) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_ALERT,
      payload: status,
    })
  }
}

export const showModalAction = (status) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_ACTION,
      payload: status,
    })
  }
}

export const showModal = (status) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      payload: status,
    })
  }
}
