import { t } from 'i18next';
import { axiosInstance } from 'src/axios.config';
import { svcApi } from 'src/config';
import { ErrorTypes } from 'src/shared/variables/errors';

export const ACTION_ERROR = 'ACTION_ERROR';

const initialState = {
  error: false,
  errorData: '',
	isServerError: false,
};

const handleBlacklistedUser = (payload) => ({
  error: true,
  errorData: payload,
	isServerError: false
});

const handleUserBlocked = () => ({
  error: true,
  errorData: `${t('error_user_blocked')} ${t('error_refused_create_order')}`,
	isServerError: false
});

const handleStatusCode406 = (payload, currentLanguage) => ({
  error: true,
  errorData: payload?.data?.error[currentLanguage] || '',
	isServerError: false
});

const handleErrorServer = (payload) => ({
  error: true,
  errorData: `${t('error_server')} ${payload?.data?.Data} ${payload?.data?.Message}`,
});

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_ERROR: {
      switch (true) {
        case t(action.payload) === t('error_blacklisted_user'):
          return { ...state, ...handleBlacklistedUser(action.payload) };

        case t(action.payload) === t('error_user_blocked'):
          return { ...state, ...handleUserBlocked() };

        case action.payload?.status === 406:
          const currentLanguage = localStorage.getItem('language_name');
          return { ...state, ...handleStatusCode406(action.payload, currentLanguage) };

        case action.payload?.data?.Data === 'user_not_found':
          return { ...state, error: false };

        default:
          return { ...state, error: true, isServerError: true };
      }
    }
    default:
      return state;
  }
};

export default errorReducer;
