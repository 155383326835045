import { t } from 'i18next'
import { ReactComponent as Wallet } from "../../images/Wallet.svg"
import { ReactComponent as MasterCardIcon } from "../../images/MasterCardIcon.svg"

export const SET_ORDER_CURRENT_PRICE = 'SET_ORDER_CURRENT_PRICE'
export const setOrderCurrentPrice = (price) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_CURRENT_PRICE,
      payload : price
    })
}}

export const GET_GOOGLE_MAP_COORDINATES = 'GET_GOOGLE_MAP_COORDINATES'
export const getGoogleMapCoordinates = (coordinates) => {
  return (dispatch) => {
    dispatch({
      type: GET_GOOGLE_MAP_COORDINATES,
      payload : coordinates
    })
}}

export const COUNT_INPUTS = 'COUNT_INPUTS';
export const countInputs = (count) => ({
  type: COUNT_INPUTS,
  payload: count,
});

export const UPDATE_INPUT_COUNT = 'UPDATE_INPUT_COUNT';
export const updateInputCount = (count) => ({
  type: UPDATE_INPUT_COUNT,
  payload: count,
});

export const KEYBOARD_OPEN = 'KEYBOARD_OPEN';
export const keyboardOpen = (value) => ({
  type: KEYBOARD_OPEN,
  payload: value,
});

export const KEYBOARD_OPEN_TWO = 'KEYBOARD_OPEN_TWO';
export const keyboardOpenTwo = (valueTwo) => ({
  type: KEYBOARD_OPEN_TWO,
  payload: valueTwo,
});

export const CHANGE_START_PLACE_ORDER_ROUTE = 'CHANGE_START_PLACE_ORDER_ROUTE'
export const changeStartPlaceOrderRoute = (adress) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_START_PLACE_ORDER_ROUTE,
      payload : adress
    })
}}
export const CHANGE_COUNTRY_PLACE_ORDER_ROUTE = 'CHANGE_COUNTRY_PLACE_ORDER_ROUTE'
export const changeCountryPlaceOrderRoute = (data) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_COUNTRY_PLACE_ORDER_ROUTE,
      payload : data
    })
}}

export const ADD_ROUTES_ORDER_ROUTE = 'ADD_ROUTES_ORDER_ROUTE'
export const addRoutesOrderRoute = (orders) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ROUTES_ORDER_ROUTE,
      payload : orders
    })
}}
export const CONFIRMED_ADRESS_ORDER_ROUTE = 'CONFIRMED_ADRESS_ORDER_ROUTE'
export const confirmedAdressOrderRoute = (confirmed) => {
  return (dispatch) => {
    dispatch({
      type: CONFIRMED_ADRESS_ORDER_ROUTE,
      payload : confirmed
    })
}}

export const CHANGE_DOP_SERVISES_ORDER_ROUTE = 'CHANGE_DOP_SERVISES_ORDER_ROUTE'
export const changeDopServicesOrderRoute = (service) => ({
  type: CHANGE_DOP_SERVISES_ORDER_ROUTE,
  payload : service
})

export const GET_CAR_ORDER_ROUTE = 'GET_CAR_ORDER_ROUTE'
export const getCarOrderRoute = (car) => ({
  type: GET_CAR_ORDER_ROUTE,
  payload : car
})

// export const SAVE_ORDER_ID_ORDER_ROUTE = 'SAVE_ORDER_ID_ORDER_ROUTE'
// export const saveOrderIdOrderRoute = (id) => ({
//   type: SAVE_ORDER_ID_ORDER_ROUTE,
//   payload : id
// })

export const CHANGE_COMMENT_ORDER_ROUTE = 'CHANGE_COMMENT_ORDER_ROUTE'
export const changeCommentOrderRoute = (comment) => ({
  type: CHANGE_COMMENT_ORDER_ROUTE,
  payload : comment
})
export const SELECED_COMMENT_ID__ORDER_ROUTE = 'SELECED_COMMENT_ID__ORDER_ROUTE'
export const selectedCommentIdOrderRoute = (id) => ({
  type: SELECED_COMMENT_ID__ORDER_ROUTE,
  payload : id
})

export const SELECED_TARIFF_CAR_ORDER_ROUTE = 'SELECED_TARIFF_CAR_ORDER_ROUTE'
export const selectedTariffCarOrderRoute = (tariff) => ({
  type: SELECED_TARIFF_CAR_ORDER_ROUTE,
  payload : tariff
})

export const paymentTypes = {
  CASH : 'CASH',
  CARD : 'CARD',
  CASH_BONUSES: 'CASH_BONUSES',
  CARD_BONUSES: 'CARD_BONUSES'
}

export const paymentsCart = {
  type: paymentTypes.CASH,
  text: 'cash',
  Img: Wallet
}

export const getCurrentImg = (type) => {

  switch(type){
    case paymentTypes.CARD : return MasterCardIcon;
    default :  return Wallet;
  }
}

export const CHANGE_PAYMENT_TYPE_ORDER_ROUTE = 'CHANGE_PAYMENT_TYPE_ORDER_ROUTE'
export const changePaymentTypeOrderRoute = (type) => ({
  type: CHANGE_PAYMENT_TYPE_ORDER_ROUTE,
  payload : type
})

export const CHANGE_CHANGE_TRAVEL_ORDER_ROUTE = 'CHANGE_CHANGE_TRAVEL_ORDER_ROUTE'
export const changeTravelRotesOrderRoute = (travelRoutes) => ({
  type: CHANGE_CHANGE_TRAVEL_ORDER_ROUTE,
  payload : travelRoutes
})
export const CHANGE_PRICE_ORDER_ROUTE = 'CHANGE_PRICE_ORDER_ROUTE'
export const changePriceOrderRoute = (price) => ({
  type: CHANGE_PRICE_ORDER_ROUTE,
  payload : price
})

export const GET_OLD_ORDER_ROUTE = 'GET_OLD_ORDER_ROUTE'
export const getOldOrderRoute = (data) => ({
  type: GET_OLD_ORDER_ROUTE,
  payload : data
})

export const CLEAR_ORDER_ROUTE = 'CLEAR_ORDER_ROUTE'
export const clearOrderRoute = () => ({
  type: CLEAR_ORDER_ROUTE,
})

export const SELECTED_TRAVEL_ORDER_ROUTE = 'SELECTED_TRAVEL_ORDER_ROUTE'
export const selectedTravelOrderRoute = (data = {}) => ({
  type: SELECTED_TRAVEL_ORDER_ROUTE,
  payload : data
})


export const ADD_NEW_ROUTES_ORDER_ROUTE = 'ADD_NEW_ROUTES_ORDER_ROUTE'
export const addNewlOrderRoute = (data = {}) => ({
  type: ADD_NEW_ROUTES_ORDER_ROUTE,
  payload : data
})

export const ADD_ORDER_DISTANCE = 'ADD_ORDER_DISTANCE'
export const addOrderDistance = (distance) => ({
  type: ADD_ORDER_DISTANCE,
  payload : distance
})

export const SELECED_TARIFF_CAR_UPDATE_ORDER_ROUTE = 'SELECED_TARIFF_CAR_UPDATE_ORDER_ROUTE';
export const updateTariffCar = (data = {}) => ({
  type: SELECED_TARIFF_CAR_UPDATE_ORDER_ROUTE,
  payload : data
})