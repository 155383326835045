export const ACTIONS_ERROR = 'ACTIONS_ERROR'

export const errorFunction = data => {
  return dispatch => {
    dispatch({
      type: ACTIONS_ERROR,
      payload: data,
    })
  }
}

export const ACTIONS_CLOSE_ERROR = 'ACTIONS_CLOSE_ERROR'

export const errorFunctionClose = () => {
  return dispatch => {
    dispatch({
      type: ACTIONS_CLOSE_ERROR,
      payload: false,
    })
  }
}
