// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl'
import { useEffect, useRef } from 'react'
import createMarker from '../helpers/createMarker'
import calculateBearing from '../../../services/calculateBearing'
import { calculatePointOnLine } from '../helpers/calculatePointOnLine'

const useDrawMarker = (props) => {
  const { map, carMarkerRef, polylinePositions, bearingAccuracyMeters, coordsDriver, mapLoaded } =
    props
  const prevDriverCoords = useRef(null)
  const prevAnimationCoords = useRef(null)
  const bearingPrevCoords = useRef(null)

  useEffect(() => {
    if (mapLoaded && coordsDriver) {
      // Create custom marker element
      const markerElement = document.createElement('div')
      markerElement.className = 'car-marker'
      let rotation = null

      if (!prevDriverCoords.current) {
        prevDriverCoords.current = coordsDriver
      }

      // bearing rotation
      if (!bearingPrevCoords.current) {
        bearingPrevCoords.current = coordsDriver
      }

      // create coords for marker
      let markerCoords = coordsDriver
      // stick car on polyline
      if (coordsDriver && polylinePositions) {
        const result = calculatePointOnLine(
          map,
          [coordsDriver[1], coordsDriver[0]],
          polylinePositions
        )

        if (result && result.coords) {
          // markerCoords = [coords[1], coords[0]]
          markerCoords = [coordsDriver[0], coordsDriver[1]]
        }
      }

      // bearing
      let convertedPrevDriverCoords = new maplibregl.LngLat(
        bearingPrevCoords.current[0],
        bearingPrevCoords.current[1]
      )
      let convertedCoordsDriver = new maplibregl.LngLat(markerCoords[0], markerCoords[1])

      const isBearingCoordsChange =
        convertedPrevDriverCoords.distanceTo(convertedCoordsDriver) > bearingAccuracyMeters

      if (isBearingCoordsChange && bearingPrevCoords.current) {
        const bearing = calculateBearing(
          bearingPrevCoords.current[1],
          bearingPrevCoords.current[0],
          markerCoords[1],
          markerCoords[0]
        )

        if (bearing !== 0) {
          rotation = bearing
          bearingPrevCoords.current = coordsDriver
        }
      }
      prevDriverCoords.current = coordsDriver

      // Function to animate the marker
      const animateMarker = (newLngLat) => {
        // Calculate the animation steps here
        const steps = 60 // Number of animation frames

        const startLngLat = prevAnimationCoords.current || coordsDriver

        if (startLngLat) {
          const stepLng = (newLngLat[0] - startLngLat[0]) / steps
          const stepLat = (newLngLat[1] - startLngLat[1]) / steps

          let frame = 0

          const animate = () => {
            if (frame < steps) {
              const lng = startLngLat[0] + stepLng * frame
              const lat = startLngLat[1] + stepLat * frame
              carMarkerRef.current.setLngLat([lng, lat])
              frame++
              requestAnimationFrame(animate)
            }
          }

          animate()
          if (rotation) {
            carMarkerRef.current.setRotation(rotation)
          }
          prevAnimationCoords.current = newLngLat // Store the newLngLat for the next animation
        }
      }

      if (!carMarkerRef.current) {
        createMarker({
          map,
          markerElement,
          position: markerCoords,
          markerRef: carMarkerRef,
          rotation,
        })
        animateMarker(markerCoords)
      } else {
        // Example: Animate the marker to a new position when coordsDriver changes
        animateMarker(markerCoords)
      }
    }
  }, [bearingAccuracyMeters, carMarkerRef, coordsDriver, map, mapLoaded, polylinePositions])

  return null // You may return a React component here if needed
}

export default useDrawMarker
