import { axiosInstance } from "src/axios.config";
import { clientLocation } from "src/config";

const accessToken = localStorage.getItem("accessToken");
const headers = accessToken
  ? {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  : null;

export const updateCoords = async ({ lat, lon }) => {
  try {
    await axiosInstance.put(clientLocation, { lat, lon }, headers);
  } catch (error) {
    console.log("Error in updateCoords: ", error);
  }
};

