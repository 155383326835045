const createPolyline = ({ map, sourceId, routeGeoJSON, layoutStyles }) => {
  if (map.current.getSource(sourceId)) {
    // If the source already exists, update its data
    map.current.getSource(sourceId).setData(routeGeoJSON);
    
    // Update the layer styles (assuming it's an existing layer)
    map.current.setPaintProperty(sourceId, 'line-color', layoutStyles.paint['line-color']);
    // You can update other layer properties as needed
    
    return; // No need to add a new layer or source
  }

  // Create polyline and add it if it doesn't exist
  map.current.addSource(sourceId, {
    type: 'geojson',
    data: routeGeoJSON,
  });
  map.current.addLayer(layoutStyles);
};


export default createPolyline
