import React from 'react'
import OrderDone from '../../components/MapJs/OrderDone'

/**
 * Страница заказа
 *
 * @returns {*}
 * @constructor
 */
export default function OrderDonePage() {
  return (
    <div className="container-fluid">
      <OrderDone />
    </div>
  )
}
