import { axiosInstance } from 'src/axios.config'
import { apiData } from '../../config'
import { errorFunction } from '../../components/ErrorComponent/error.action'
import { errorTokenFunction } from '../../components/ErrorToken/error.action'

export const GET_USERNEWS_PENDING = 'GTE_USERNEWS_PENDING'
export const GET_USERNEWS_SUCCESS = 'GTE_USERNEWS_SUCCESS'
export const GET_USERNEWS_ERROR = 'GTE_USERNEWS_ERROR'

export const getUserNews = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_USERNEWS_PENDING,
    })
    axiosInstance
      .get(apiData + '/profile/news', { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        dispatch({
          type: GET_USERNEWS_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        console.log('Action* - список новостей ОШИБКА: ', error)
        dispatch({
          type: GET_USERNEWS_ERROR,
          payload: error,
        })
      })
  }
}

export const GET_USERNEW_PENDING = 'GTE_USERNEW_PENDING'
export const GET_USERNEW_SUCCESS = 'GTE_USERNEW_SUCCESS'
export const GET_USERNEW_ERROR = 'GTE_USERNEW_ERROR'

export const getUserNew = (newsId) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_USERNEW_PENDING,
    })
    axiosInstance
      .get(apiData + '/profile/news/' + newsId, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log('Action* - данные новостей УСПЕХ: ', response)
        dispatch({
          type: GET_USERNEW_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        console.log('Action* - данные новостей ОШИБКА: ', error)
        dispatch({
          type: GET_USERNEW_ERROR,
          payload: error,
        })
      })
  }
}

export const updNewsItem = (newsId) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    axiosInstance
      .post(apiData + '/profile/news/' + newsId, null, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        dispatch(getUserNews())
      })
      .catch((error) => {
        console.log(error)
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        console.log('Action* - данные новостей ОШИБКА: ', error)
        dispatch({
          type: GET_USERNEW_ERROR,
          payload: error,
        })
      })
  }
}
