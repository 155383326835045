import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { ReactComponent as FirstAdressPoint } from "../../shared/assets/icons/FirstAdressPoint.svg";
import { ReactComponent as FirstAdressPointDark } from "../../shared/assets/icons/FirstAdressPointDark.svg";
import cls from './styles.module.scss'

function MapComment({
    routes,
    commentMessage,
    changeCommentOrderRoute,
    nameComponents,
    openAnotherComponentBottomMenu
}) {

    const [text, setText] = useState(commentMessage)

    const handleChange = (event) => {
        const inputValue = event.target.value
        setText(inputValue)
    }

    const back = () => {
        openAnotherComponentBottomMenu(nameComponents.ORDER_PREFERENCES);
    }

    const addComment = () => {
        changeCommentOrderRoute(text)
        openAnotherComponentBottomMenu(nameComponents.ORDER_PREFERENCES);
    }

    useEffect(() => {
        return () => {
            changeCommentOrderRoute(text)
        }
    }, [text, changeCommentOrderRoute])

    const isDarkTheme = localStorage.getItem('appTheme') === 'dark';

    const addButtonClassNameLight = text ? cls.addButton : `${cls.addButton} ${cls.addButtonDisabled}`;

    const addButtonClassNameDark = text ? cls.addButtonDark : `${cls.addButtonDark} ${cls.addButtonDisabledDark}`;

    const addButtonClassName = isDarkTheme ? addButtonClassNameDark : addButtonClassNameLight

    const commentArea = isDarkTheme ? cls.commentAreaDark : cls.commentArea

    return (
        <>
            <div className={cls.background_wrapper} />
            <div className={cls.MapCommentWrapper}>
                <div className={cls.commentBlock}>
                    <div className={cls.headerBlock}>
                        <div className={cls.firstRoute}>
                            {isDarkTheme
                                ?
                                <FirstAdressPointDark />
                                :
                                <FirstAdressPoint />
                            }
                            <p>{routes.length > 0 && routes[0].name}</p>
                        </div>
                    </div>
                    <div className={cls.addCommentBlock}>
                        <div className={commentArea}>
                            <textarea
                                placeholder={t('write_comment')}
                                maxLength={195}
                                value={text}
                                onChange={handleChange}
                            />
                            <p className={cls.textCounter}>{text.length}/195</p>
                        </div>
                    </div>
                    <div className={cls.confirmCommentBtns}>
                        <button className={cls.rejectButton} onClick={back}>
                            {t('cancel')}
                        </button>
                        <button className={addButtonClassName} onClick={addComment}>
                            {t('add')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MapComment;
