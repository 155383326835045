import { useState, useEffect, useCallback, useRef } from 'react'

const useCountdownTimer = (initialSeconds = 0, timestampKey = null) => {
  const [seconds, setSeconds] = useState(initialSeconds)

  const intervalIdRef = useRef(null)

  const clearLocalStorage = useCallback(() => {
    localStorage.removeItem(timestampKey)
  }, [timestampKey])

  const updateTimer = useCallback(
    (timestamp) => {
      const elapsedSeconds = Math.floor((Date.now() - timestamp) / 1000)
      const remainingSeconds = Math.max(initialSeconds - elapsedSeconds, 0)

      setSeconds(remainingSeconds)

      if (remainingSeconds === 0) {
        clearInterval(intervalIdRef.current)
        clearLocalStorage()
      }
    },
    [clearLocalStorage, initialSeconds],
  )

  const startTimer = useCallback(
    (timestamp) => {
      updateTimer(timestamp)

      intervalIdRef.current = setInterval(() => updateTimer(timestamp), 1000)
    },
    [updateTimer],
  )

  const initializeTimer = useCallback(() => {
    const timestamp = Number(localStorage.getItem(timestampKey))

    if (timestamp) {
      startTimer(timestamp)
    }
  }, [timestampKey, startTimer])

  useEffect(() => {
    const storedTimestamp = Number(localStorage.getItem(timestampKey))

    if (!storedTimestamp) {
      const currentTimestamp = Date.now()
      localStorage.setItem(timestampKey, currentTimestamp)
      initializeTimer(currentTimestamp)
    } else {
      initializeTimer()
    }

    return () => clearInterval(intervalIdRef.current)
  }, [timestampKey, initializeTimer])

  return { seconds }
}

export default useCountdownTimer
