import React, { memo } from 'react'
import EditProfile from '../../components/ProfileForm/EditProfile'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'

const EditProfilePage = memo(() => {
  return (
    <>
      <PageHeader title={t('profile')} />
      <PageLayout>
        <EditProfile />
      </PageLayout>
    </>
  )
})

export default EditProfilePage
