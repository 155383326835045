import { availablePaymentMethods } from "src/shared/variables";

export const getTextPaymentById = (paymentId) => {
	switch (paymentId) {
		case 0:
			return availablePaymentMethods.CASH;
		case 1:
			return availablePaymentMethods.CARD;
		case 2:
			return availablePaymentMethods.CASH_AND_BONUSES;
		case 4:
			return availablePaymentMethods.CARD_AND_BONUSES;
		default:
			return availablePaymentMethods.CASH;
	}
};
