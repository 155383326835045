/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import {
  POST_ORDER_PENDING,
  POST_ORDER_SUCCESS,
  POST_ORDER_ERROR,
  PUT_RATING_PENDING,
  PUT_RATING_SUCCESS,
  GET_ORDER_COST_PENDING,
  GET_ORDER_COST_SUCCESS,
  GET_ORDER_COST_ERROR,
  UPDATE_POS,
  GET_ORDER_IDENTIFIER,
  GET_CAR_CLASS_TIME_INFO_SUCCESS,
	POST_BONUS_SUCCESS,
	CLEAR_USED_BONUSES,
} from './order.action'
import { GET_CURRENT_ORDER_SUCCESS } from '../SocketConnection/socket.action'

const initialState = {
  pos: {
    lat: null,
    lon: null,
  },
  costOrders: [],
  carArrivalTimes: [],
  costOrdersLoaded: false,
  orderId: 0,
  orderLoaded: false,
  orderError: false,
  finishOrder: false,
  finishOrderLoaded: false,
  identifier: null,
	usedBonuses: 0
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_POS: {
      return {
        ...state,
        pos: { ...action.payload },
      }
    }
    case GET_ORDER_IDENTIFIER: {
      return {
        ...state,
        identifier: action.payload,
      }
    }
		case POST_BONUS_SUCCESS: {
      return {
        ...state,
        usedBonuses: action.payload.data.data,
      }
    }

		case CLEAR_USED_BONUSES: {
			return {
        ...state,
        usedBonuses: 0,
      }
		}

    case GET_CAR_CLASS_TIME_INFO_SUCCESS: {
      return {
        ...state,
        carArrivalTimes: action.payload || [],
      }
    }

    case GET_ORDER_COST_PENDING: {
      return {
        ...state,
        costOrders: [],
        orderError: false,
        costOrdersLoaded: true,
      }
    }
    case GET_ORDER_COST_SUCCESS: {
      return {
        ...state,
        costOrders: action.payload,
        orderError: false,
        costOrdersLoaded: false,
      }
    }
    case GET_ORDER_COST_ERROR: {
      return {
        ...state,
        orderError: action.payload,
        costOrdersLoaded: false,
      }
    }

    case POST_ORDER_PENDING: {
      return {
        ...state,
        orderLoaded: action.payload,
        orderError: false,
      }
    }
    case POST_ORDER_SUCCESS: {
      return {
        ...state,
        orderId: action.payload.id,
        orderLoaded: false,
        orderError: false,
      }
    }
    case POST_ORDER_ERROR: {
      return {
        ...state,
        orderError: action.payload,
      }
    }

    case GET_CURRENT_ORDER_SUCCESS: {
      return {
        ...state,
        orderId: action.payload.id,
        orderLoaded: false,
        orderError: false,
      }
    }

    case PUT_RATING_PENDING: {
      return {
        ...state,
        finishOrderLoaded: true,
      }
    }
    case PUT_RATING_SUCCESS: {
      return {
        ...state,
        finishOrder: true,
        finishOrderLoaded: false,
      }
    }

    default:
      return {
        ...state,
      }
  }
}

export default orderReducer
