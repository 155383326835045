import { axiosInstance } from 'src/axios.config'
import { api, apiData, apiImages } from '../../config'
import { errorFunction } from '../ErrorComponent/error.action'
import { errorTokenFunction } from '../ErrorToken/error.action'
import { GET_LOGOUT } from '../Auth/auth.action'

export const GET_USERDATA_PENDING = 'GET_USERDATA_PENDING'
export const GET_USERDATA_SUCCESS = 'GET_USERDATA_SUCCESS'
export const GET_USERDATA_ERROR = 'GET_USERDATA_ERROR'
export const RESET_USERDATA = 'RESET_USERDATA'
export const IS_MENU_OPEN = 'IS_MENU_OPEN'
export const SET_PROFILE_LANGUAGE = 'SET_PROFILE_LANGUAGE'

export const resetUserData = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USERDATA,
    })
  }
}

export const getOpenFromMenu = (isOpen) => {
  return (dispatch) => {
    dispatch({
      type: IS_MENU_OPEN,
      payload: isOpen,
    })
  }
}

export const updateLanguage = (newLanguage) => {
  const accessToken = localStorage.getItem('accessToken')
  return async (dispatch) => {
    await axiosInstance
      .put(apiData + '/profile/updatelanguage', newLanguage, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(() => {
        dispatch({
          type: SET_PROFILE_LANGUAGE,
          payload: newLanguage.language,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else if (error.response.data.Data === 'client_not_found') {
          localStorage.removeItem('username')
          localStorage.removeItem('userHash')
          dispatch({
            type: GET_LOGOUT,
          })
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_USERDATA_ERROR,
          payload: error,
        })
      })
  }
}

export const getUserData = (country) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      dispatch({
        type: GET_USERDATA_PENDING,
      });

      const response = await axiosInstance.get(apiData + '/profile', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          country,
        },
      });

      dispatch({
        type: GET_USERDATA_SUCCESS,
        payload: response.data.data,
      });

      localStorage.setItem('isBlocked', response.data.data.isBlocked);
    } catch (error) {
      console.log('Action* - профиль пользователя ОШИБКА: ', error);

      if (error.response && error.response.statusText === 'Unauthorized') {
        dispatch(errorTokenFunction(error));
      } else if (error.response && error.response.data.Data === 'client_not_found') {
        localStorage.removeItem('username');
        localStorage.removeItem('userHash');
        dispatch({
          type: GET_LOGOUT,
        });
      } else {
        dispatch(errorFunction(error.response));
      }

      dispatch({
        type: GET_USERDATA_ERROR,
        payload: error,
      });
    }
  };
};


export const POST_USERDATA_PENDING = 'POST_USERDATA_PENDING'
export const POST_USERDATA_SUCCESS = 'POST_USERDATA_SUCCESS'
export const POST_USERDATA_ERROR = 'POST_USERDATA_ERROR'

export const postUserData = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const countryCode = localStorage.getItem('country_code');

      dispatch({
        type: POST_USERDATA_PENDING,
      });

      const response = await axiosInstance.put(apiData + '/profile', data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      console.log('Action* - изменение профиля пользователя УСПЕХ: ', response);

      dispatch({
        type: POST_USERDATA_SUCCESS,
        payload: true,
      });

      await dispatch(getUserData(countryCode));
      dispatch(resetUserData());
    } catch (error) {
      console.log('Action* - изменение профиля пользователя ОШИБКА: ', error);

      if (error.response && error.response.statusText === 'Unauthorized') {
        dispatch(errorTokenFunction(error));
      } else {
        dispatch(errorFunction(error.response));
      }

      dispatch({
        type: POST_USERDATA_ERROR,
        payload: error,
      });
    }
  };
};


export const POST_CHANGEPASSWORD_PENDING = 'POST_CHANGEPASSWORD_PENDING'
export const POST_CHANGEPASSWORD_SUCCESS = 'POST_CHANGEPASSWORD_SUCCESS'
export const POST_CHANGEPASSWORD_ERROR = 'POST_CHANGEPASSWORD_ERROR'

export const postChangePassword = (data) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: POST_CHANGEPASSWORD_PENDING,
    })
    axiosInstance
      .post(api + '/rider/change-password', data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log('Action* - смена пароля УСПЕХ: ', response)
        dispatch({
          type: POST_CHANGEPASSWORD_SUCCESS,
          payload: true,
        })
      })
      .catch((error) => {
        console.log('Action* - смена пароля ОШИБКА: ', error)
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: POST_CHANGEPASSWORD_ERROR,
          payload: error,
        })
      })
  }
}

export const POST_IMAGE_CODE_PENDING = 'POST_IMAGE_CODE_PENDING'
export const POST_IMAGE_CODE_SUCCESS = 'POST_IMAGE_CODE_SUCCESS'
export const POST_IMAGE_CODE_ERROR = 'POST_IMAGE_CODE_ERROR'

export const postImage = (imgInfo, retardation = false) => {
  const accessToken = localStorage.getItem('accessToken')
  const countryCode = localStorage.getItem('country_сode')

  return (dispatch) => {
    dispatch({
      type: POST_IMAGE_CODE_PENDING,
    })
    axiosInstance
      .post(apiImages + '/files', imgInfo, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log('Action* - загрузка фото УСПЕХ: ', response)
        dispatch({
          type: POST_IMAGE_CODE_SUCCESS,
          payload: response.data.data.id,
        })
        if (retardation) {
          dispatch(
            postUserData({
              id: retardation.id,
              firstName: retardation.firstName,
              lastName: retardation.lastName,
              photo: `${apiImages}/download/${response.data.data.id}`,
              email: retardation.email,
              workAdress: retardation.workAdress,
              homeAdress: retardation.homeAdress,
            })
          )
          dispatch(getUserData(countryCode))
        }
      })
      .catch((error) => {
        console.log('Action* - загрузка фото ОШИБКА: ', error)
        if (error?.response?.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error?.response))
        }
        dispatch({
          type: POST_IMAGE_CODE_ERROR,
          payload: error,
        })
      })
  }
}

export const removeAccount = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    return axiosInstance
      .put(
        apiData + '/profile/deleterequest',
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log('Action* - удаление аккаунта УСПЕХ: ', response)
        return response
      })
      .catch((error) => {
        console.log('Action* - удаление аккаунта ОШИБКА: ', error)
      })
  }
}
