import React from 'react'

export const ImageIcon = (props) => {
  const { width = 24, height = 25, fill = 'none', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#212121"
        stroke="#212121"
        strokeWidth={0.2}
        d="M19.5 3.5h-15A1.5 1.5 0 0 0 3 5v15a1.5 1.5 0 0 0 1.5 1.5h15A1.5 1.5 0 0 0 21 20V5a1.5 1.5 0 0 0-1.5-1.5ZM4.5 5h15v7.254L17.185 9.94a1.5 1.5 0 0 0-2.12 0L5.003 20H4.5V5Zm15 15H7.125l9-9 3.375 3.375V20ZM9 11.75a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Zm0-3a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Z"
      />
    </svg>
  )
}
