import { createContext, useContext, useMemo, useState } from "react";

export const MapContext = createContext({});

export const MapProvider = ({ children }) => {
	const [isInputActive, setIsInputActive] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isPriceCalculated, setIsPriceCalculated] = useState(false);

	const defaultProps = useMemo(
		() => ({
			isInputActive,
			setIsInputActive,
			isLoading,
			setIsLoading,
			isPriceCalculated,
			setIsPriceCalculated,
		}),
		[isInputActive, isLoading, isPriceCalculated],
	);

	return <MapContext.Provider value={defaultProps}>{children}</MapContext.Provider>;
};

export const useMap = () => {
	const context = useContext(MapContext);
	return context;
};
