import React, { memo, useCallback, useMemo } from "react";
import AutocompletePoint from "../AutocompletePoint";

import { ReactComponent as PlusCircle } from "../../../../images/PlusCircle.svg";
import { ReactComponent as CloseIcon } from "../../../../images/Close.svg";
import { ReactComponent as FirstAdressPoint } from "../../../../shared/assets/icons/FirstAdressPoint.svg";
import { ReactComponent as FirstAdressPointDark } from "../../../../shared/assets/icons/FirstAdressPointDark.svg";
import { ReactComponent as LastAddressPoint } from "../../../../shared/assets/icons/LastAddressPoint.svg";
import { ReactComponent as MapPin } from "../../../../images/MapPin.svg"; // кружочек для доп. точек

import { classNames } from "../../../../shared/lib/classNames/classNames";
import { Button } from "../../../../shared/ui/Button/button";
import { t } from "i18next";
import { getNonEmptyPoints } from "../../helpers/getNonEmptyPoints";
import cls from "./styles.module.scss";
import { useMap } from "src/entities/MapWrapper/context/MapContext";
import { maxOrderPoints } from "../../constants";

const AddressPoint = (props) => {
	const {
		roadPoints,
		setPoint,
		placeholder,
		addInput,
		removeInput,
		pointId,
		removePoint,
		name,
		passengerCoords,
		checkAndCalculateRoutePrice,
		setActiveInput,
		removeActiveInput,
		isActive,
		setIsAutocompleteSelection,
		isCurrentLocation,
		isInputHighlighted,
		setSuggestionPlaces,
		suggestionPlaces,
		setIsMapMove,
		setCanCreateRoute,
		mapLoaded,
		setManualPoint,
		registrationCountryInfo,
	} = props;

	const { isInputActive, isLoading } = useMap();

	const isFirstPoint = pointId === 0;
	const isLastPoint = pointId === roadPoints[roadPoints.length - 1]?.id;
	const isFistSelectedPoint = roadPoints[0]?.name;
	const isLastSelectedPoint = roadPoints[roadPoints.length - 1]?.name;
	const canAddNewPoint = pointId === roadPoints[roadPoints.length - 1]?.id && roadPoints.length < maxOrderPoints;
	const countOfNamedPoints = roadPoints.slice(1).filter((point) => point.name).length;
	const currentPointElement = roadPoints.findIndex((point) => point.id === pointId);

	const isDarkTheme = localStorage.getItem('appTheme') === 'dark';

	// inputs icons

	const roadIcon = useMemo(() => {
		switch (true) {
			case (isFirstPoint && !isFistSelectedPoint && !isDarkTheme):
				return <FirstAdressPoint className={classNames(cls.iconPoint, {}, [cls.firstPoint])} />;
			case (isFirstPoint && isFistSelectedPoint && isCurrentLocation && !isDarkTheme):
				return <FirstAdressPoint className={classNames(cls.iconPoint, {}, [cls.firstPoint])} />;
			case (isFirstPoint && !isFistSelectedPoint && isDarkTheme):
				return <FirstAdressPointDark className={classNames(cls.iconPoint, {}, [cls.firstPoint])} />;
			case (isFirstPoint && isFistSelectedPoint && isCurrentLocation && isDarkTheme):
				return <FirstAdressPointDark className={classNames(cls.iconPoint, {}, [cls.firstPoint])} />;
			case (isFirstPoint && isFistSelectedPoint && !isCurrentLocation):
				return <LastAddressPoint className={classNames(cls.iconPoint, {}, [cls.selectedPointIcon])} />;
			case (isLastPoint && !isLastSelectedPoint):
				return <LastAddressPoint className={classNames(cls.iconPoint, {}, [cls.selectedPointIcon])} />;
			case (isLastPoint):
				return <LastAddressPoint className={classNames(cls.iconPoint, {}, [cls.selectedPointIcon])} />;
			default:
				return <MapPin className={classNames(cls.iconPoint, {}, [cls.additionStyle])} />;
		}
	}, [isFirstPoint, isFistSelectedPoint, isLastPoint, isLastSelectedPoint, isCurrentLocation]);


	const handlePointRemoval = useCallback(
		(e) => {
			e.stopPropagation();
			const idxToCheckActive = roadPoints.findIndex((point) => point.id === pointId);

			if (name) {
				removePoint();
				setSuggestionPlaces([]);
				setCanCreateRoute(false);
				return;
			}

			removeInput();
			if (roadPoints[idxToCheckActive]?.active) {
				removeActiveInput();
			}

			setSuggestionPlaces([]);
		},
		[
			name,
			pointId,
			removeActiveInput,
			removeInput,
			removePoint,
			roadPoints,
			setCanCreateRoute,
			setSuggestionPlaces,
		],
	);

	const handleApplyPoint = useCallback(async () => {
		if (name && isInputActive) {
			const result = await setManualPoint();

			if (!result) {
				return;
			}
		}

		removeActiveInput();
		setCanCreateRoute(false);
	}, [isInputActive, name, removeActiveInput, setCanCreateRoute, setManualPoint]);

	return (
		<>
			<div
				className={classNames(cls.AddressPointWrapper, {}, [])}
				onClick={() => setCanCreateRoute(true)}
			>
				<div
					className={classNames(
						cls.points,
						{
							[cls.selectedPoint]: roadPoints[currentPointElement]?.name,
							[cls.highlightedInput]: pointId === isInputHighlighted,
						},
						[],
					)}
				>
					<div className={cls.iconLeft}>{roadIcon}</div>
					<AutocompletePoint
						className={cls.autocompleteInput}
						placeholder={placeholder}
						setPoint={setPoint}
						value={name}
						suggestionPlaces={suggestionPlaces}
						setSuggestionPlaces={setSuggestionPlaces}
						passengerCoords={passengerCoords}
						setActiveInput={setActiveInput}
						mapLoaded={mapLoaded}
						removeActiveInput={removeActiveInput}
						setIsAutocompleteSelection={setIsAutocompleteSelection}
						isActive={isActive}
						setIsMapMove={setIsMapMove}
						registrationCountryInfo={registrationCountryInfo}
					/>
					<div
						className={classNames(
							cls.iconRight,
							{
								[cls.hideClose]: roadPoints.length > 2 && isFirstPoint && countOfNamedPoints > 0,
							},
							[],
						)}
					>
						<CloseIcon onClick={handlePointRemoval} />
					</div>
				</div>

				{canAddNewPoint && (
					<div className={cls.add_point} onClick={addInput}>
						<PlusCircle />
					</div>
				)}
			</div>

			{isLastPoint && (
				<Button
					className={cls.bottom_btn}
					onClick={checkAndCalculateRoutePrice}
					isLoading={isLoading}
					loaderSize={18}
					disabled={getNonEmptyPoints(roadPoints)?.length < 2 || isLoading}
				>
					{t("complet")}
				</Button>
			)}
		</>
	);
};

export default memo(AddressPoint);
