import React, { Component, memo, useEffect, useState } from "react";
import { reconnectIntervalMs } from "../../config-in-git";
import { connect } from "react-redux";
import { apiSignalR } from "../../config";
import {
	setDriverLocation,
	setOrderStatus,
	orderInfoDriver,
	setAcceptedDriverCoordinates,
	getCurrentOrder,
	updateOrderStatus,
} from "./socket.action";
import { errorFunction } from "../ErrorSocket/error.action";

import {
	changeModalBottomMenu,
	nameComponents,
	openAnotherComponentBottomMenu,
} from "../BottomMenu/bottom.navigate.action";
import { clearOrderRoute, getCarOrderRoute } from "../OrderRoute/order.route.action";
import useSignalR from "./hooks/useSignalR";

const SignalRComponent = (props) => {
	const {
		coords,
		setDriverLocation,
		setOrderStatus,
		changeModalBottomMenu,
		openAnotherComponentBottomMenu,
		getCarOrderRoute,
		odrerRoute,
		setAcceptedDriverCoordinates,
		getCurrentOrder,
		orderInfoDriver,
		orderStatus,
		updateOrderStatus,
	} = props;
	const connection = useSignalR(apiSignalR, coords);

	useEffect(() => {
		try {
			if (connection) {
				connection.on("confirmation_code_generated", async (code) => {
					try {
						console.log("Socket -> confirmation_code_generated", code);
						if (code.messageId) {
							await connection
								.invoke(
									"sendMessage",
									"message_received",
									'{ "messageId": "' + code.messageId + '" }',
								)
								.then();
						}
					} catch (error) {
						console.log("error in confirmation_code_generated: ", error);
					}
				});

				// Получаем статусы заказа
				connection.on("order_status", async (code) => {
					try {
						console.log("Socket -> order_status", code);
						updateOrderStatus(code);

						if (code.messageId) {
							await connection
								.invoke(
									"sendMessage",
									"message_received",
									'{ "messageId": "' + code.messageId + '" }',
								)
								.then();
						}
					} catch (error) {
						console.log("error in order_status: ", error);
					}
				});

				// Статусы подтверждения заказа
				connection.on("driver_assigned", async (code) => {
					try {
						console.log("Socket -> driver_assigned", code);
						if (code.data?.destinationTime) {
							localStorage.setItem("destinationTime", code.data.destinationTime);
						}
						//  !place
						/**
						 * Смена состояний страницы по прийденым статусам с сокета
						 *
						 * Заказ создан status: 0
						 * Заказ принял водила status: 1
						 * Поездка отменен status: 2
						 * Водила на месте status: 4
						 * Поездка в пути status: 8
						 * Поездка завершена status: 16
						 * Машин не найдено status: 32
						 * Поездка оплачена status: 128 */
						if (code.data.status === 1) {
							localStorage.setItem("orderStage", code.data.status);
							openAnotherComponentBottomMenu(nameComponents.ORDER_ACCEPTED);
							getCarOrderRoute(code.data);
							localStorage.setItem("odrerRoute", JSON.stringify({ ...odrerRoute, car: code.data }));
							///TODO asdasd
							// localStorage.setItem('odrerRoute',JSON.stringify(odrerRoute))
						}
						orderInfoDriver(code.data);
						if (code.messageId) {
							await connection
								.invoke(
									"sendMessage",
									"message_received",
									'{ "messageId": "' + code.messageId + '" }',
								)
								.then();
						}
					} catch (error) {
						console.log("error in driver_assigned: ", error);
					}
				});

				connection.on("current_driver_position", async (data) => {
					try {
						console.log("current_driver_position");

						const driverCoords = {
							lon: data.driverLon,
							lat: data.driverLat,
						};

						setAcceptedDriverCoordinates(driverCoords);
					} catch (error) {
						console.log("error in current_driver_position: ", error);
					}
				});

				// Вылетает сообщение, если уже есть существующий заказ
				connection.on("current_order_info", async (code) => {
					try {
						console.log("Socket -> current_order_info", code);
						if (!localStorage.getItem("orderId")) {
							localStorage.setItem("orderId", code.data.orderId);
						}

						getCurrentOrder(code.data.orderId);

						orderInfoDriver(code.data); // Получаем информацию о драйвере
						if (orderStatus?.status) {
							setOrderStatus({
								status: orderStatus.status,
								orderId: code.data.orderId,
							});
						}

						if (code.messageId) {
							await connection
								.invoke(
									"sendMessage",
									"message_received",
									'{ "messageId": "' + code.messageId + '" }',
								)
								.then();
						}
					} catch (error) {
						console.log("Error in current_order_info:", error);
					}
				});

				return () => {
					connection.off("confirmation_code_generated");
					connection.off("order_status");
					connection.off("driver_assigned");
					connection.off("current_driver_position");
					connection.off("current_order_info");
				};
			}
		} catch (error) {
			console.log("Error in SignalRComponent: ", error);
		}
	}, [
		changeModalBottomMenu,
		connection,
		getCarOrderRoute,
		getCurrentOrder,
		odrerRoute,
		openAnotherComponentBottomMenu,
		orderInfoDriver,
		orderStatus.status,
		setAcceptedDriverCoordinates,
		setDriverLocation,
		setOrderStatus,
		updateOrderStatus,
	]);

	return null;
};

const mapStateToProps = (state) => {
	return {
		killOrder: state.socket.killOrder,
		acceptedDriverCoordinates: state.socket.acceptedDriverCoordinates,
		orderStatus: state.socket.orderStatus,
		userData: state.profile.userData,
		odrerRoute: state.odrerRoute,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		orderInfoDriver: (code) => dispatch(orderInfoDriver(code)),
		setOrderStatus: (code) => dispatch(setOrderStatus(code)),
		setAcceptedDriverCoordinates: (code) => dispatch(setAcceptedDriverCoordinates(code)),
		setDriverLocation: (code) => dispatch(setDriverLocation(code)),
		errorFunction: (data) => dispatch(errorFunction(data)),
		getCarOrderRoute: (data) => dispatch(getCarOrderRoute(data)),
		clearOrderRoute: () => dispatch(clearOrderRoute()),
		changeModalBottomMenu: (modal) => dispatch(changeModalBottomMenu(modal)),
		openAnotherComponentBottomMenu: (data) => dispatch(openAnotherComponentBottomMenu(data)),
		getCurrentOrder: (orderId) => dispatch(getCurrentOrder(orderId)),
		updateOrderStatus: (code) => dispatch(updateOrderStatus(code)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(SignalRComponent));
