import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getQuestion, getQuestionText } from './question.action'

import TextBlock from '../../components/TextBlock'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '../../common/Expansion'
import Typography from '../../common/Typography'
import ExpandMoreIcon from '../../common/material-ui-icons/ExpandMoreIcon'
import CircularProgress from '../../common/Preloader'
import { t } from 'i18next'

class Questions extends Component {
  state = {
    ques: false,
    expanded: false,
  }

  componentDidMount() {
    this.props.getQuestion()
  }

  handleChange = panel => (event, isExpanded) => {
    this.setState(state=> ({
      expanded: isExpanded ? panel : false,
    }))
    if(isExpanded) {
      this.props.getQuestionText(panel)
    }
  };

  render() {
    const contentQuestions = !this.props.userQuestions ? null : this.props.userQuestions.map(item => (
      <ExpansionPanel expanded={this.state.expanded === `${item.id}`} onChange={this.handleChange(`${item.id}`)} key={item.id}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{item.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>{!this.props.userQuest.content ? t('downloading') : this.props.userQuest.content}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ))

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 questions">
            <TextBlock text={!this.props.loading ? contentQuestions : ''} />
          </div>
        </div>
        {this.props.loading === true ? (<CircularProgress className="preloader" />) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userQuestions: state.questions.userQuestions,
    userQuest: state.questions.userQuest,
    loading: state.questions.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getQuestion: () => dispatch(getQuestion()),
    getQuestionText: id => dispatch(getQuestionText(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions)
