import React from 'react'
import { t } from 'i18next'
import { ReactComponent as RepairIcon } from "src/images/error-icons/repair-icon.svg"
import { ReactComponent as WarningIcon } from "src/images/error-icons/warning-icon.svg"
import { ReactComponent as Headphones } from "src/images/Headphones.svg";
import { useNavigate } from "react-router-dom";
import { Button } from 'src/shared/ui/Button/button'
import Modal from 'src/shared/ui/Modal/Modal'

import styles from './styles.module.scss'
import { ErrorTypes } from 'src/shared/variables/errors';

export default function DialogError(props) {
  const { open, content, close, onClick, typeServerError } = props

  const navigate = useNavigate();

	const navigateToSupportPage = () => {
    navigate("/help");
		onClick()
  };

	return (
    <Modal contentStyles={styles.contentStyles} overlayClassname={styles.overlayClassname} isOpen={open} onClose={close} >
      <div className={styles.modalError}>
				{typeServerError === ErrorTypes.MAINTENANCE_ERROR ? <RepairIcon/> : <WarningIcon/>}

        {typeServerError !== ErrorTypes.MAINTENANCE_ERROR && (
					<p className={styles.unexpectedError}>{t("unexpected_error_occurred")}</p>
        )}

				<p className={styles.contentError}>{content}</p>

        <div className={styles.btns}>
          <Button className={styles.acceptBtn} fullWidth onClick={onClick}>
						OK
          </Button>

          {typeServerError !== ErrorTypes.MAINTENANCE_ERROR && (
            <Button className={styles.supportBtn} fullWidth onClick={navigateToSupportPage} variant="outline">
							<Headphones className={styles.icon} />
							{t('customer_service')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
