/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import cls from './ModalLargeRequest.module.scss';
import { Modal } from '@material-ui/core';
import { t } from 'i18next';

const ModalLargeRequest = ({ isOpen, onClose }) => {
  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  return (
    <Modal open={isOpen} className={cls.modal}>
      <div className={cls.content_block}>
        <p className={cls.modal_text}>{t('large_request_error')}</p>
        <button className={cls.modal_main_btn} onClick={handleClose}>
          {t('close')}
        </button>
      </div>
    </Modal>
  );
};

export default ModalLargeRequest;

