import React, { memo } from "react";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Trans } from "react-i18next";
import styles from "./styles.module.scss";

const OrderDiscount = (props) => {
	const { discount, discountType, symbol } = props;

	return (
		<div
			className={classNames(
				styles.OrderDiscountWrapper,
				{ [styles.active]: Boolean(discount) },
				[],
			)}
		>
			<div className={styles.discount}>
				<p>
					{discountType === 1 ? (
						<Trans i18nKey="you_have_been_discount" values={{ discount, currency: symbol }} />
					) : (
						<Trans i18nKey="you_have_been_percent_discount" values={{ discount }} />
					)}
				</p>
			</div>
		</div>
	);
};

export default memo(OrderDiscount);
