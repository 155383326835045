import React, { useState, useEffect } from "react";
import DialogError from "src/common/DialogError";

const ErrorSocket = ({ error, errorMessage, errorFunctionClose }) => {
	const [show, setShow] = useState(true);

	useEffect(() => {
		if (error) {
			setShow(true);
		} else {
			setShow(false);
		}
	}, [error]);

	const toggleFunction = () => {
		setShow(!show);
	};

	const handleClose = () => {
		toggleFunction();
		errorFunctionClose();
	};

	return (
		error && (
			<DialogError
				open={show}
				close={toggleFunction}
				content={`Сообщение сокета: ${errorMessage}`}
				onClick={handleClose}
			/>
		)
	);
};



export default ErrorSocket
