import { axiosInstance } from 'src/axios.config'
import { api } from '../../config'
import { getPreferences, getCities } from '../Dictionarys/dictionary.action'
import { getUserData } from '../ProfileForm/profile.action'
import { errorFunction } from '../ErrorComponent/error.action'

export const GET_LOGIN_PENDING = 'GET_LOGIN_PENDING'
export const GET_LOGIN_CHECK = 'GET_LOGIN_CHECK'
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS'
export const GET_LOGIN_ERROR = 'GET_LOGIN_ERROR'
export const RESET_LOGIN_DATA = 'RESET_LOGIN_DATA'
export const SET_COUNTRY_PHONE_CODE = 'SET_COUNTRY_PHONE_CODE'

export const setCountryPhoneCode = (countryCode) => {
  return (dispatch) => {
    dispatch({
      type: SET_COUNTRY_PHONE_CODE,
      payload: countryCode,
    })
  }
}

export const resetLoginData = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_LOGIN_DATA,
    })
  }
}

export const getPhoneLogin = (loginInfo) => {
  localStorage.removeItem('registrationData')
  return (dispatch) => {
    dispatch({
      type: GET_LOGIN_PENDING,
    })
    if (loginInfo.password === undefined) {
      axiosInstance
        .post(api + '/rider/check-lt', {
          login: loginInfo.phone,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            localStorage.setItem('userPhone', loginInfo.phone)
            dispatch({
              type: GET_LOGIN_CHECK,
              payload: {
                check: true,
                phone: loginInfo.phone,
              },
            })
          }
        })
        .catch((error) => {
          if (error.response.data.Data === 'user_in_blacklist') {
            dispatch(errorFunction('Пользователь в черном списке'))
          } else {
            dispatch({
              type: GET_LOGIN_ERROR,
              payload: error,
            })
          }
        })
    } else {
      axiosInstance
        .post(api + '/rider/sign-in-lt', {
          branchId: 2,
          login: loginInfo.phone,
          password: loginInfo.password,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            const countryCode = localStorage.getItem('country_code')

            localStorage.setItem('accessToken', response.data.accessToken)
            localStorage.setItem('refreshToken', response.data.refreshToken)
            localStorage.setItem('userId', response.data.id)
            try {
              // eslint-disable-next-line no-undef
              Native.onLoggedIn()
            } catch (e) {
              console.log(
                'AUTHORIZATION WAS SUCCESSFUL, BUT THIS DEVICE IS NOT AN ANDROID OR IPHONE'
              )
            }

            dispatch(getCities())
            dispatch(getUserData(countryCode || 'UA'))
            dispatch(getPreferences(countryCode || 'UA'))

            dispatch({
              type: GET_LOGIN_SUCCESS,
              payload: {
                loginStatus: true,
                phone: loginInfo.phone,
              },
            })
          } else {
            dispatch({
              type: GET_LOGIN_SUCCESS,
              payload: {
                loginStatus: false,
                message: 'Неверный логин или пароль',
                phone: loginInfo.phone,
              },
            })
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_LOGIN_ERROR,
            payload: error,
          })
        })
    }
  }
}

export const GET_LOGOUT = 'GET_LOGOUT'
/**
 *
 * @returns {Function} */
export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem('username')
    localStorage.removeItem('userHash')
    dispatch({
      type: GET_LOGOUT,
    })
  }
}
