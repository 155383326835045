import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { forgot, resetForgotPass } from './forgot.action'
import { showModalAlert } from '../Modals/store/modal.action'
import { Input } from '../../shared/ui/Input/Input'
import Modal from '../../shared/ui/Modal/Modal'
import { Loader } from '../../shared/ui/Loader/Loader'
import AlertModal from '../Modals/ui/AlertModal/AlertModal'
import { Button, ButtonVariant } from '../../shared/ui/Button/button'
import cls from './forgot.module.scss'

const chkPhoneNumber = new RegExp(/^\+380[0-9]{0,9}$/)

const Forgot = ({
  loginPhone,
  forgotStatus,
  forgot,
  forgotError,
  forgotLoaded,
  resetForgotPass,
}) => {
  const [phone, setPhone] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (loginPhone !== '') {
      setPhone(loginPhone)
    }

    return () => resetForgotPass()
  }, [loginPhone, resetForgotPass])

  const submitCheck = useCallback(() => {
    forgot({ phone })
  }, [forgot, phone])

  const onChangePhone = useCallback((event) => {
    const inputValue = event.target.value

    if (chkPhoneNumber.test(inputValue)) {
      setPhone(inputValue)
    }
  }, [])

  const goBackPage = useCallback(() => {
    navigate('/auth-password')
  }, [navigate])

  if (forgotLoaded) {
    return <Loader />
  }

  return (
    <div className={cls.ForgotWrapper}>
      <Modal onClose={goBackPage} isOpen={forgotStatus} isCloseAction>
        <AlertModal title={t('message_with_new_password_sent')} />
      </Modal>
      <h1 className={cls.title}>{t('enter_number_that_used_sign_up')}</h1>
      <Input
        className={cls.phone}
        type="text"
        value={phone.length === 0 ? '+380' : phone}
        onChange={onChangePhone}
        placeholder={t('phone_number')}
        isError={forgotError}
        valueError={t('acc_not_found')}
      />
      <div className={cls.continueBtn}>
        <Button variant={ButtonVariant.background} fullWidth onClick={submitCheck}>
          {t('restore')}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loginPhone: state.auth.loginPhone,
    forgotStatus: state.forgot.forgotStatus,
    forgotLoaded: state.forgot.forgotLoaded,
    forgotError: state.forgot.forgotError,
    isOpenModalAlert: state.modal.isOpenModalAlert,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgot: (loginInfo) => dispatch(forgot(loginInfo)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    resetForgotPass: () => dispatch(resetForgotPass()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot)
