import {
  GET_USERCHATS_PENDING,
  GET_USERCHATS_SUCCESS,
  // GET_USERCHATS_ERROR,
  GET_USERCHAT_PENDING,
  GET_IS_VIEW,
  GET_USERCHAT_SUCCESS,
  // GET_USERCHAT_ERROR,
} from './chat.action'

const initialState = {
  userChats: [],
  userChat: {},
  loading: false,
  error: false,
  isViewMessage: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERCHATS_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_USERCHATS_SUCCESS: {
      const myDiv = document.getElementById('bottom')
      if (myDiv) {
        myDiv.scrollIntoView()
      }
      return {
        ...state,
        userChats: action.payload.reverse(),
        loading: false,
        error: false,
      }
    }
    case GET_IS_VIEW: {
      return {
        ...state,
        isViewMessage: action.payload?.isNew,
      }
    }
    case GET_USERCHAT_SUCCESS: {
      return {
        ...state,
        userChat: action.payload,
      }
    }
    case GET_USERCHAT_PENDING: {
      return {
        ...state,
        error: true,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
