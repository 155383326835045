import { memo } from 'react'
import { Trans } from 'react-i18next'
import { classNames } from 'src/shared/lib/classNames/classNames'
import { formatSecondsToTime } from 'src/shared/helpers/formatSecondsToTime'
import useWaitingTimer from 'src/components/BottomMenu/hooks/useWaitingTimer'
import cls from './styles.module.scss'

const PaidWait = (props) => {
  const { pendingTime, isCountdown } = props

  const { seconds, isCountingDown } = useWaitingTimer(pendingTime, isCountdown)

  const currentText = isCountingDown ? 'free_waiting' : 'paid_waiting'
  const mods = {
    [cls.paidWait]: !isCountingDown,
  }

  return (
    <div className={classNames(cls.PaidWaitWrapper, mods, [])}>
      <div className={cls.containerTime}>
        <p>
          <Trans i18nKey={currentText} values={{ time: formatSecondsToTime(seconds) }} />
        </p>
      </div>
    </div>
  )
}

export default memo(PaidWait)
