import { useEffect } from "react";

const launcherBottomPosition = {
	default: "10px",
};

const useZendeskLauncherPosition = () => {
	useEffect(() => {
		const adjustZendeskLauncherPosition = () => {
			const isWebWidgetPresent = document.body.querySelectorAll(
				':scope > [data-product="web_widget"]',
			);
			if (!isWebWidgetPresent.length) {
				return;
			}

			const zendeskLauncher = document.body.querySelector("#launcher");
			if (!zendeskLauncher) {
				return;
			}

			zendeskLauncher.style.bottom = launcherBottomPosition.default;
		};

		adjustZendeskLauncherPosition();
	}, []);
};

export default useZendeskLauncherPosition;
