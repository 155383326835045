import React, { memo, useEffect, useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import 'moment/locale/uk'
import 'moment/locale/ru'
import 'moment/locale/sk'
import 'moment/locale/de'

import PhotoBlock from '../../../ProfileForm/PhotoBlock'
import { StarIcon } from '../../../../shared/assets/icons'
import { Button, ButtonSize, ButtonVariant } from '../../../../shared/ui/Button/button'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { convertToUserTimezone } from '../../../../shared/helpers/convertToUserTimezone'
import cls from './historyDetailHeader.module.scss'

const HistoryDetailHeader = memo((props) => {
  const {
    createdAt,
    driverPhoto,
    driverName,
    carName,
    carColorName,
    carClassName,
    carNumber,
    driverRating,
    statusId,
  } = props

  const [formattedDate, setFormattedDate] = useState('')

  useEffect(() => {
    const convertedDate = convertToUserTimezone(createdAt)
    const formatDateToTime = moment(convertedDate).format('D MMMM YYYY, HH:mm')

    setFormattedDate(formatDateToTime)
  }, [createdAt])

  useEffect(() => {
    const languageName = localStorage.getItem('language_name')
    if (languageName === 'UK') {
      moment.locale('ua')
    } else {
      moment.locale(languageName)
    }
  }, [])

  const nameDriver = driverName?.split(' ')[0]
  const numberCar = `${carNumber?.slice(0, 3)}**${carNumber?.charAt(6)}**`
  const isFailedTrip = statusId === 2 || statusId === 32

  const mods = {
    [cls.failureTrip]: isFailedTrip,
  }

  return (
    <div className={cls.HistoryDetailHeader}>
      <div className={cls.dateTrip}>
        <h3>{formattedDate}</h3>
        <Button
          variant={ButtonVariant.success}
          className={classNames(cls.orderBtn, mods, [])}
          size={ButtonSize.M}
        >
          {isFailedTrip ? t('canceled') : t('done')}
        </Button>
      </div>
      <div className={cls.infoDriverBlock}>
        <div>
          <h1>{nameDriver ? `${t('travel_with')} ${nameDriver}` : t('travel')}</h1>
          {Boolean(carColorName) && <h2>{`${t(carColorName)} ${carName}`}</h2>}

          <div className={cls.infoCar}>
            <p className={cls.tariffCar}>{t(carClassName)}</p>
            {Boolean(carNumber) && <p className={cls.numberCar}>{numberCar}</p>}
          </div>
        </div>
        <div className={cls.photoDriver}>
          <PhotoBlock photo={driverPhoto} />
          <div className={cls.rate}>
            <div className={cls.rateInfo}>
              <StarIcon className={cls.starIcon} width={19} height={18} />
              <span>{driverRating}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default HistoryDetailHeader
