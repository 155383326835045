import React, { memo } from 'react'
import Auth from '../../components/Auth'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'
import { Navigate } from 'react-router-dom'

const AuthPage = memo(() => {
  const accesToken = localStorage.getItem('accessToken')

  if (accesToken) {
    return <Navigate to="/order" />
  }

  return (
    <>
      <PageHeader title={t('authorization')} />
      <PageLayout>
        <Auth />
      </PageLayout>
    </>
  )
})

export default AuthPage
