import React, { memo, useCallback, useMemo } from 'react'
import { CheckIcon, SearchIcon } from '../../../shared/assets/icons'
import { t } from 'i18next'
import SimpleDialog from '../../../common/Dialog'
import { classNames } from '../../lib/classNames/classNames'
import { Input } from '../Input/Input'
import { Button } from '../Button/button'
import cls from './Select.module.scss'

const Select = memo((props) => {
  const {
    hasTitle = true,
    handleOptionClick,
    selectedOption,
    options,
    placeholder,
    confirmOptionClick,
    title,
    search = false,
    onClose,
    isOpen,
  } = props

  const closeHandler = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const onContentClick = useCallback((e) => {
    e.stopPropagation()
  }, [])

  // Если нужно кнопка [Подтвердить]
  // const confirmChange = useCallback(() => {
  //   if (selectedOption) {
  //     confirmOptionClick(selectedOption)
  //   }

  //   closeHandler()
  // }, [closeHandler, confirmOptionClick, selectedOption])

  const mods = {
    [cls.small_select]: !hasTitle,
  }

  const optionList = useMemo(
    () =>
      options.map((option) => (
        <div
          key={option.id}
          className={classNames(
            cls.selectOption,
            { [cls.activeOption]: option.id === selectedOption?.id },
            []
          )}
          onClick={() => handleOptionClick(option)}
        >
          <div className={classNames(cls.content, {}, [])}>
            <span className={classNames(cls.title, {}, [])}>{t(option.name)}</span>
            {option.id === selectedOption?.id && <CheckIcon />}
          </div>
        </div>
      )),
    [handleOptionClick, options, selectedOption?.id]
  )

  const selectWrapper = (
    <div className={cls.selectContainer}>
      {isOpen && (
        <div className={cls.selectOverlay} onClick={closeHandler}>
          <div className={classNames(cls.selectDropdown, mods, [])} onClick={onContentClick}>
            <div className={cls.search}>
              {search && (
                <>
                  <Input
                    className={classNames(cls.searchInput, {}, [])}
                    placeholder={placeholder}
                  />
                  <SearchIcon className={cls.searchIcon} />
                </>
              )}
            </div>
            {optionList}
            {!hasTitle && (
              <div className={cls.btnBlock}>
                <Button className={cls.saveBtn}>{t('confirm')}</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )

  return (
    <>
      <SimpleDialog fullScreen title={title} open={isOpen} content={selectWrapper} />
    </>
  )
})

export default Select
