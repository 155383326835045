import React from 'react'
import { connect } from 'react-redux'
import { t } from 'i18next'
import HelpList from '../../components/HelpList'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { getZendeskToken } from 'src/pages/HelpListPage/store/feed.action'
import { getUserData } from 'src/components/ProfileForm/profile.action'

const HelpListPage = (props) => {
  return (
    <>
      <PageHeader title={t('help')} />
      <PageLayout>
        <HelpList {...props}/>
      </PageLayout>
    </>
  )
}

const mapStateToProps = (state) => {
	return {
		userData: state.profile.userData,
		zendeskToken: state.feed.zendeskToken,
    zendeskTokenLoading: state.feed.zendeskTokenLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getZendeskToken: () => dispatch(getZendeskToken()),
    getUserData: (data) => dispatch(getUserData(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpListPage);