import React from "react";
import { t } from "i18next";
import { Button, ButtonSize, ButtonVariant } from "../../shared/ui/Button/button";
import { formatDateToDayMonthYear } from "../../shared/helpers/formatDateToDayMonthYear";
import styles from "./styles.module.scss";

const News = (props) => {
	const { setDetailNews, news } = props;

	return (
		<div className={styles.NewsWrapper}>
			{news.map((news) => (
				<div className={styles.contentNews} key={news.id} onClick={() => setDetailNews(news)}>
					<div className={styles.dateInfo}>
						<p>{formatDateToDayMonthYear(news.createdAt)}</p>
						{!news.isRead && (
							<Button variant={ButtonVariant.clear} size={ButtonSize.S} className={styles.infoBtn}>
								{t("new")}
							</Button>
						)}
					</div>
					<h2 className={styles.title}>{news.title}</h2>
					<p className={styles.subTitle}>{!news.content ? t("read_more") : news.content}</p>
				</div>
			))}
		</div>
	);
};

export default News;
