/* eslint-disable import/no-webpack-loader-syntax */
import { useEffect, useRef } from "react";
import maplibregl from "!maplibre-gl";
import { calculateBearingByMapParams } from "../helpers/calculateBearingByMapParams";
import { animateMovingMarker } from "../helpers/animateMovingMarker";

/**
 *
 * @param {{arrayIcons :Map<string, { id: number, coords: [lon, lat], markerElement: HTMLElement}>, disabledDrawArrayMarkers:boolean}} props
 */

export const useDrawArrayMarkers = ({ map, arrayIcons, disabledDrawArrayMarkers }) => {
	const previousMarkersRef = useRef(new Map());

	useEffect(() => {
		if (!map.current || disabledDrawArrayMarkers || !arrayIcons) {
			// clear all markers
			for (const key of previousMarkersRef.current.keys()) {
				previousMarkersRef.current.get(key).mapMarker.remove();
				previousMarkersRef.current.delete(key);
			}
			return;
		}

		arrayIcons.forEach((marker) => {
			const existedMarker = previousMarkersRef.current.get(marker.id);

			if (existedMarker) {
				existedMarker.mapMarker.setLngLat(marker.coords);
				const prevBearingCoords = existedMarker?.prevBearingCoords || marker.coords;
				const prevAnimationCoords = existedMarker?.prevAnimationCoords || marker.coords;

				// bearing(rotation marker)
				const rotation = calculateBearingByMapParams({
					markerCoords: marker.coords,
					bearingAccuracyMeters: 1,
					prevBearingCoords,
				});

				const onUpdateAnimationCoords = ({ coords }) => {
					existedMarker.mapMarker.setLngLat(coords);
				};

				animateMovingMarker({
					prevAnimationCoords,
					newCoords: marker.coords,
					onUpdateAnimationCoords,
				});

				if (rotation) {
					existedMarker.mapMarker.setRotation(rotation);
				}

				previousMarkersRef.current.set(marker.id, {
					...existedMarker,
					prevMarkerCoords: marker.coords,
					prevBearingCoords: marker.coords,
					prevAnimationCoords: marker.coords,
				});
			} else {
				// add marker to map
				const newMapMarker = new maplibregl.Marker({ element: marker.markerElement })
					.setLngLat(marker.coords)
					.addTo(map.current);

				//add new marker
				previousMarkersRef.current.set(marker.id, {
					mapMarker: newMapMarker,
					prevMarkerCoords: marker.coords,
					prevBearingCoords: marker.coords,
					prevAnimationCoords: marker.coords,
				});
			}
		});

		// clear markers
		for (const key of previousMarkersRef.current.keys()) {
			if (!arrayIcons.has(key)) {
				previousMarkersRef.current.get(key).mapMarker.remove();
				previousMarkersRef.current.delete(key);
			}
		}
	}, [arrayIcons, disabledDrawArrayMarkers, map]);
};
