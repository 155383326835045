/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import cls from 'src/components/MapJs/orders.module.scss'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { ReactComponent as DownLine } from 'src/images/DownLine.svg'
import { ReactComponent as Preferences } from 'src/images/Preferences.svg'
import firstCar from 'src/images/FirstCar.svg'
import secondCar from 'src/images/SecondCar.svg'
import thirdCar from 'src/images/ThirdCar.svg'
import {
  changePaymentTypeOrderRoute,
  changePriceOrderRoute,
  paymentTypes,
  paymentsCart,
  selectedTariffCarOrderRoute,
  clearOrderRoute,
  changeTravelRotesOrderRoute,
} from '../../../OrderRoute/order.route.action'
import { nameComponents, openAnotherComponentBottomMenu } from '../../bottom.navigate.action'
import { postBonus } from '../../../MapJs/order.action'
import { createTravelForMap, textFormator } from '../../../OrderRoute/AutocompleteOrderHandler'
import { ReactComponent as MinusIcon } from 'src/images/MinusIcon.svg'
import { ReactComponent as PlusIcon } from 'src/images/PlusIcon.svg'
import { getValuteSymbol } from '../../../CurrencyDefinition/getValuteSymbol'
import Loader from '../../../../shared/ui/Loader/Loader'
import { useSwipeable } from 'react-swipeable'
import { getOpenFromMenu } from '../../../ProfileForm/profile.action'

let isFirst = true

const imgesCar = (i) => {
  switch (i) {
    case 0:
      return { img: firstCar, tariff: t('78235900-0ce1-4aca-99ca-b89e776f0910') }
    case 1:
      return { img: secondCar, tariff: t('b4c9b252-8d90-400c-bab8-c21f4fd9c7a9') }
    case 2:
      return { img: thirdCar, tariff: t('bdf73206-ed08-4aec-b603-40ee948c53b2') }
  }
}

export const CarPrices = ({
  costOrders,
  editPrice,
  postBonus,
  odrerRoute,
  map,
  sumDopServises,
  userData,
  paymentType,
  userCardsList,
  routes,
  travelRoutes,
  changePriceOrderRoute,
  commentMessage,
  tariffCar,
  selectedTariffCarOrderRoute,
  dopServices,
  changePaymentTypeOrderRoute,
  openAnotherComponentBottomMenu,
  clearOrderRoute,
  symbol,
  changeTravelRotesOrderRoute,
  registrationCountryInfo,
  getOpenFromMenu,
}) => {
  const [isDetails, setIsDetails] = useState(true)
  const maxIncrement = tariffCar.price * 2.5
  const [price, setPrice] = useState(tariffCar.price)
  const [isMinPrice, setIsMinPrice] = useState(false)
  const [isMaxPrice, setIsMaxPrice] = useState(false)
  const [isLoader, setIsLoader] = useState(true)

  const handlers = useSwipeable({
    onSwipedUp: () => setIsDetails(true),
    onSwipedDown: () => setIsDetails(false),
  })

  // Функция, которая проверяет, является ли элемент кнопкой или ссылкой или находится внутри них
  const isButtonOrLink = (element) => {
    if (!element) return false
    if (element.tagName.toLowerCase() === 'button' || element.tagName.toLowerCase() === 'a') {
      return true
    }
    // Рекурсивная проверка для вложенных элементов
    if (element.parentElement) {
      return isButtonOrLink(element.parentElement)
    }
    return false
  }

  useEffect(() => {
    // isLoader &&
    createTravelForMap(routes, map, changeTravelRotesOrderRoute)
    localStorage.removeItem('reload') //? if socket work this line remove
  }, [isLoader])

  useEffect(() => {
    setPrice(tariffCar.price)
  }, [tariffCar.price])

  useEffect(() => {
    setIsMinPrice(price <= tariffCar.price)
    setIsMaxPrice(price >= maxIncrement)
  }, [price, tariffCar.price, maxIncrement])

  useEffect(() => {
    localStorage.setItem('watingAdress', JSON.stringify({ odrerRoute, costOrders }))
  }, [odrerRoute, costOrders])
  const handleIncrement = () => {
    const remainder = price % 5
    const increaseAmount = remainder === 0 ? 5 : 5 - remainder
    const newPrice = price + increaseAmount

    if (newPrice <= maxIncrement) {
      setPrice(newPrice)
      changePriceOrderRoute(newPrice)
    }
  }

  const handleDecrement = () => {
    const minPrice = tariffCar.price
    const newPrice = price - 5

    if (minPrice > newPrice) {
      setPrice(minPrice)
      changePriceOrderRoute(minPrice)
    } else {
      setPrice(newPrice)
      changePriceOrderRoute(newPrice)
    }
  }

  const content = dopServices.length ? (
    <div className={cls.circle_preferences}>{dopServices.length}</div>
  ) : (
    <Preferences />
  )
  const Img = paymentType.Img

  const handleCarClick = (tariff, active) => {
    if (!active) {
      selectedTariffCarOrderRoute(tariff)
    }
  }
  useEffect(() => {
    const watingAdress = JSON.parse(localStorage.getItem('watingAdress'))
    watingAdress && setIsLoader(false)
  }, [])

  useEffect(() => {
    if (editPrice !== null) setPrice(editPrice)

    if (isFirst) {
      isFirst = false
      localStorage.getItem('watingAdress') === null &&
        changePaymentTypeOrderRoute({ ...paymentsCart, text: 'cash' })
    }
  }, [editPrice])

  useEffect(() => {
    if (costOrders.length === 0) return
    if (tariffCar.rate) {
      let i = 0
      const tariff = costOrders.find((e, index) => {
        if (e.rate === tariffCar.rate) {
          i = index
          return true
        }
      })
      const tarrifName = imgesCar(i)

      selectedTariffCarOrderRoute({ ...tariff, ...tarrifName })
    } else selectedTariffCarOrderRoute({ img: firstCar, tariff: t('78235900-0ce1-4aca-99ca-b89e776f0910'), ...costOrders[0] })
    setIsLoader(false)
  }, [costOrders])

  const handleClick = () => {
    clearOrderRoute()
    localStorage.removeItem('watingAdress')
    localStorage.removeItem('destinationTime')
    localStorage.removeItem('globalTimestamp')
    openAnotherComponentBottomMenu(nameComponents.START_SEARCH_PLACE)
    window.location.reload()
  }

  const orderCar = () => {
    localStorage.removeItem('watingAdress')
    const arrsTravels = travelRoutes.routes[0].legs
    const distance = arrsTravels.reduce((a, e) => a + e.distance.value, 0)
    const isPaymentRard = paymentType.type === paymentTypes.CARD
    const cardId = isPaymentRard ? userCardsList[0].id : null
    let priceRised

    if (editPrice > tariffCar.price) {
      priceRised = editPrice - tariffCar.price
    } else {
      priceRised = 0
    }

    let paymentId = isPaymentRard ? 1 : 0
    const destinationPoints = routes.map((e) => {
      const { formatted_address, place_id, geometry } = e.adress
      const l = geometry.location

      const t = textFormator(formatted_address)
      return {
        pointId: place_id,
        name: formatted_address,
        secondaryText: t.secondaryText,
        mainText: t.mainText,
        lat: typeof l.lat === 'number' ? l.lat : l.lat(),
        lon: typeof l.lng === 'number' ? l.lng : l.lng(),
      }
    })

    const countryValue = odrerRoute.infoCountry.country_code || 'UA'
    const currencyValue = odrerRoute.infoCountry.currency_code || 'UAH'

    const data = {
      clientId: userData.id,
      clientFullname: `${userData.firstName ? userData.firstName : ' '} ${
        userData.lastName ? userData.lastName : ' '
      }`,
      clientPhoto: userData.photo,
      clientPhone: userData.phone,
      clientRating: userData.rating,
      cityId: '72d01375-6a0b-4643-9e0f-68d349ea739b', //TODO: change id city or remove it
      destinationPoints: destinationPoints,
      priceEstimated: price,
      priceRised,
      quoteBonus: 0,
      orderPreferences: dopServices.map((e) => e.id),
      comment: commentMessage,
      distance: distance,
      paymentTypeId: paymentId,
      cardId: cardId,
      carClassId: tariffCar.rate,
      country: countryValue,
      currency: currencyValue,
    }
    postBonus(data)

    openAnotherComponentBottomMenu(nameComponents.SEARCH_CAR)
    changePriceOrderRoute(price)
    localStorage.setItem('odrerRoute', JSON.stringify(odrerRoute))
  }

  if (isLoader) return <Loader />
  if (!costOrders || costOrders.length === 0) return null

  return (
    <div className={cls.tariffs_form} {...handlers}>
      <div className={cls.down_line} onClick={() => setIsDetails(!isDetails)}>
        <DownLine className={cls.down_line_icon} />
      </div>
      {costOrders.map((tariff, index) => {
        const car = imgesCar(index)
        const active = tariffCar.rate === tariff.rate
        return (
          <button
            className={`${cls.tariffs} ${active ? cls.active_tariff : ''}`}
            key={tariff.rate}
            onClick={() => handleCarClick({ ...car, ...tariff }, active)}
          >
            <div
              className={`${cls.tariffs_item} ${
                index === costOrders.length - 1 ? cls.last_item : ''
              }`}
            >
              <div className={`${cls.tariffs_name} ${active ? cls.active_name : ''}`}>
                <div className={cls.car_item}>
                  <img src={car.img} alt={car.tariff} />
                </div>
                <p>{t(car.tariff)}</p>
              </div>
              <div className={`${cls.tariffs_cost} ${active ? cls.active_cost : ''}`}>
                <p>{tariff.price - sumDopServises}</p>
                <p>{symbol}</p>
              </div>
            </div>
          </button>
        )
      })}
      <div className={cls.preferences}>
        <Link to="/order-preferences">
          <button className={cls.preferences_btn}>
            {content}
            <p>{t('preferences')}</p>
          </button>
        </Link>
        <Link to="/order-payment">
          <button className={cls.payment_btn} onClick={() => getOpenFromMenu(true)}>
            <div className={cls.name_payment}>
              <Img className={cls.icon_payment} />
              {/* <p>{paymentType.text}</p> */}
              <p>
                {paymentType.type === paymentTypes.CARD ? paymentType.text : t(paymentType.text)}
              </p>
            </div>
          </button>
        </Link>
      </div>
      <div className={cls.price_changes_block}>
        <button onClick={handleDecrement}>
          <MinusIcon className={isMinPrice ? cls.disabled_icon : ''} />
        </button>
        <div className={cls.price_change_cost}>
          <p>{price}</p>
          <p style={{ paddingLeft: '0px', paddingRight: '4px' }}>{symbol}</p>
        </div>
        <button onClick={handleIncrement}>
          <PlusIcon className={isMaxPrice ? cls.disabled_icon : ''} />
        </button>
      </div>
      <div className={cls.confirm_block}>
        <button onClick={orderCar}>{t('order')}</button>
      </div>
      {isDetails && (
        <div className={cls.reject_block}>
          <button onClick={handleClick}>{t('cancel')}</button>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    costOrders: state.order.costOrders,
    tariffCar: state.odrerRoute.tariffCar,
    dopServices: state.odrerRoute.dopServices,
    paymentType: state.odrerRoute.paymentType,
    travelRoutes: state.odrerRoute.travelRoutes,
    routes: state.odrerRoute.routes,
    commentMessage: state.odrerRoute.commentMessage,
    userData: state.profile.userData,
    userCardsList: state.ccard.userCardsList,
    sumDopServises: state.odrerRoute.sumDopServises,
    editPrice: state.odrerRoute.editPrice,
    odrerRoute: state.odrerRoute,
    registrationCountryInfo: state.registration.registrationCountryInfo,
    symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
  }
}

export default connect(mapStateToProps, {
  selectedTariffCarOrderRoute,
  changeTravelRotesOrderRoute,
  getOpenFromMenu,
  postBonus,
  openAnotherComponentBottomMenu,
  changePriceOrderRoute,
  changePaymentTypeOrderRoute,
  clearOrderRoute,
})(CarPrices)
