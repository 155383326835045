import React from 'react'

export const AstericsIcon = (props) => {
  const { width = 24, height = 24, fill = '#212121', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} {...props}>
      <path
        className={className}
        fill="#1056BB"
        stroke="#1056BB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m12 2.5 3.09 6.26L22 9.77l-5 4.87 1.18 6.88L12 18.27l-6.18 3.25L7 14.64 2 9.77l6.91-1.01L12 2.5Z"
      />
    </svg>
  )
}
