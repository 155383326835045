import { t } from 'i18next'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleDialog from '../../common/Dialog'

class ErrorToken extends Component {
  state = {
    show: true,
  }

  render() {
    return this.props.error ? (
      <SimpleDialog
        open={this.state.show}
        close={() => {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('userPhone')
          window.location.replace('/auth')
        }}
        content={<span className="popup-message">{t('account_was_accessed_from_another_device')}</span>}
        showControl={true}
        control={
          <button
            onClick={() => {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('userId')
              localStorage.removeItem('userPhone')
              window.location.replace('/auth')
            }}
            className="control__button control__button--primary"
          >
            Ок
          </button>
        }
      />
    ) : null
  }
}

const mapStateToProps = state => {
  return {
    error: state.errorToken.error,
    errorData: state.errorToken.errorData,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorToken)
