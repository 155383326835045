import {
  GET_QUESTIONS_PENDING,
  GET_QUESTIONS_SUCCESS,
  GET_QUEST_SUCCESS
} from './question.action'

const initialState = {
  userQuestions: [],
  userQuest: {},
  loading: false,
  error: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_QUESTIONS_SUCCESS: {
      return {
        ...state,
        userQuestions: action.payload,
        loading: false,
        error: false,
      }
    }
    case GET_QUEST_SUCCESS: {
      return {
        ...state,
        userQuest: action.payload,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
