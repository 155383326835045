import React from "react";
import cls from "./styles.module.scss";
import { paymentGate } from "src/config";
import PaymentForm from "./components/PaymentForm";

const PAYMENT_TYPE = {
	STRIPE: "Stripe",
	WAYFORPAY: "WayForPay",
};

const AddCardForm = ({ cardFormData, loginPhone, isLimitedCards }) => {
	const renderPaymentForm = () => {
		if (cardFormData.gatewayType === PAYMENT_TYPE.STRIPE && !isLimitedCards) {
			return (
				<PaymentForm
					action={cardFormData.redirectUrl}
					cardId={cardFormData.cardId}
					method={"GET"}
					className={cls.add_card_btn}
				/>
			);
		}

		if (cardFormData.gatewayType === PAYMENT_TYPE.WAYFORPAY && !isLimitedCards) {
			const wayforpayInputs = [
				{ name: "merchantAccount", value: cardFormData.merchantAccount },
				{ name: "language", value: cardFormData.language },
				{ name: "merchantDomainName", value: cardFormData.merchantDomainName },
				{ name: "merchantAuthType", value: cardFormData.merchantAuthType },
				{ name: "merchantSignature", value: cardFormData.merchantSignature },
				{ name: "orderReference", value: cardFormData.orderReference },
				{ name: "amount", value: cardFormData.amount },
				{ name: "returnUrl", value: cardFormData.returnUrl },
				{ name: "currency", value: cardFormData.currency },
				{ name: "serviceUrl", value: `${paymentGate}${cardFormData.serviceUrl}` },
				{ name: "clientPhone", value: loginPhone },
			];

			return (
				<PaymentForm
					formInputs={wayforpayInputs}
					action={cardFormData.paymentSite}
					cardId={cardFormData.cardId}
					method={"POST"}
					className={cls.add_card_btn}
				/>
			);
		}

		return null;
	};

	return <>{renderPaymentForm()}</>;
};

export default AddCardForm;
