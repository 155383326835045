import React from 'react'
import ErrorPage from 'src/pages/ErrorPage'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorInfo: '' }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('error', error)
    console.log('errorInfo', errorInfo)

    this.setState({
      errorInfo: errorInfo?.componentStack || '',
    })
  }

  render() {
    const { hasError, errorInfo } = this.state
    const { children } = this.props

    if (hasError) {
      return <ErrorPage errorInfo={errorInfo} />
    }

    return children
  }
}

export default withTranslation()(ErrorBoundary)
