import React from 'react'

export const CheckCircleIcon = (props) => {
  const { width = 24, height = 24, fill = '#fff', stroke = '#fff', className } = props

  return (
    <svg width={width} height={height} fill="none" {...props}>
      <circle cx={12} cy={12.5} r={10} fill="#1056BB" stroke="#1056BB" strokeWidth={1.5} />
      <path
        className={className}
        fill={fill}
        stroke={stroke}
        strokeWidth={0.2}
        d="M16.28 9.72a.751.751 0 0 1 0 1.06l-5.25 5.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l1.72 1.72 4.72-4.72a.75.75 0 0 1 1.06 0Z"
      />
    </svg>
  )
}
