import React, { useCallback, useState } from 'react'
import { t } from 'i18next'
import cls from './styles.module.scss'
import PageHeader from 'src/components/PageHeader'
import PageLayout from 'src/components/PageLayout'
import AddressPointList from '../AddressPointList'
import { fetchSuggestions } from '../../helpers/fetchSuggestions'

let isCheckSelectedAdress = { is: false };
export const activeInputFocus = () => isCheckSelectedAdress.is = true
export const disactiveInputFocus = () => isCheckSelectedAdress.is = false

function OrderAddresses(props) {

	const {
		setPoint,
		roadPoints,
		setRoadPoints,
		getPolyline,
		passengerCoords,
		getRoutesPrice,
		clearOrderRoute,
		isCorrectDistance,
		isWorkCountry,
		showModalAlert,
		setIsAutocompleteSelection,
		isCurrentLocation,
		isInputHighlighted,
		setSuggestionPlaces,
		suggestionPlaces,
		checkStartRoutePoint,
		getCarArrivalTime,
		setIsMapMove,
		setCanCreateRoute,
		mapLoaded,
		getDiscount,
		registrationCountryInfo,
		openMap,
		openAnotherComponentBottomMenu,
		removeActiveInput,
		nameComponents,
	} = props;

	const [titleModal, setTitleModal] = useState("");
	const currentEditPoint = roadPoints.find((item) => item.active);

	const setManualPoint = useCallback(
		async (addressQuery, pointId) => {
			try {
				setSuggestionPlaces([]);

				const params = {
					addressQuery,
					userLocation: {
						lat: passengerCoords[1] || null,
						lon: passengerCoords[0] || null,
					},
					autocomplete: false,
					registrationCountryInfo
				};

				const { data } = await fetchSuggestions(params);

				if (!data?.length) {
					showModalAlert(true);
					setTitleModal(t("no_results_for_specified_address"));
				} else {
					const { latitude, longitude, address } = data[0];
					setPoint({ lat: latitude, lon: longitude, name: address }, pointId);
				}

				return Boolean(data?.length);
			} catch (error) {
				console.error("Error in setManualPoint:", error);
			}
		},
		[passengerCoords, registrationCountryInfo, setPoint, setSuggestionPlaces, showModalAlert],
	);

	const back = () => {
		clearOrderRoute();
		localStorage.removeItem("watingAdress");
		localStorage.removeItem("discountAndCompensationType");
		openAnotherComponentBottomMenu(nameComponents.START_SEARCH_PLACE);
		openMap()
		window.location.reload()
	};


	return (
		<>
			<PageHeader title={t('path_route')} onClick={back} />
			<PageLayout>
				<div className={cls.AdressesAccountWrapper}>
					<AddressPointList
						setPoint={setPoint}
						roadPoints={roadPoints}
						setRoadPoints={setRoadPoints}
						getPolyline={getPolyline}
						passengerCoords={passengerCoords}
						getRoutesPrice={getRoutesPrice}
						isCorrectDistance={isCorrectDistance}
						isWorkCountry={isWorkCountry}
						setIsAutocompleteSelection={setIsAutocompleteSelection}
						isCurrentLocation={isCurrentLocation}
						isInputHighlighted={isInputHighlighted}
						setSuggestionPlaces={setSuggestionPlaces}
						suggestionPlaces={suggestionPlaces}
						getCarArrivalTime={getCarArrivalTime}
						setIsMapMove={setIsMapMove}
						checkStartRoutePoint={checkStartRoutePoint}
						getDiscount={getDiscount}
						setCanCreateRoute={setCanCreateRoute}
						mapLoaded={mapLoaded}
						openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
						openMap={openMap}
					/>
				</div>
			</PageLayout>
		</>
	)
}

export default OrderAddresses;
