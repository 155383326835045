import React, { memo, useState, useCallback, useEffect, useMemo, useRef } from "react";
import { Navigate } from "react-router-dom";
import { SmsInput } from "../../shared/ui/SmsInput/SmsInput";
import { connect } from "react-redux";
import { resetCode, sendCode } from "./sendcode.action";
import { registration, resetUserData } from "../Registration/registration.action";
import { t } from "i18next";
import { Trans, withTranslation } from "react-i18next";
import Modal from "../../shared/ui/Modal/Modal";
import ActionModal from "../Modals/ui/ActionModal/ActionModal";
import { showModalAlert } from "../Modals/store/modal.action";
import Loader from "../../shared/ui/Loader/Loader";
import { Button, ButtonVariant } from "../../shared/ui/Button/button";
import { MessageIcon } from "../../shared/assets/icons";
import { getSecondsUnit } from "../../shared/helpers/getSecondsUnit";
import cls from "./smsCode.module.scss";

const SendCode = (props) => {
	const {
		codeStatus,
		registration,
		sendCode,
		showModalAlert,
		resetUserData,
		isOpenModalAlert,
		codeMessage,
		codeLoaded,
		resetCode,
	} = props;

	const getPhoneNumber = localStorage.getItem("userPhone");

	const [code, setCode] = useState(new Array(4).fill(""));
	const [seconds, setSeconds] = useState(60);
	const smsTimer = useRef(null);
	const continueButtonRef = useRef(null);
	const [otpCodeFromSmsCalled, setOtpCodeFromSmsCalled] = useState(false);

	const startTimer = useCallback(() => {
		smsTimer.current = setInterval(() => {
			setSeconds((prevSec) => {
				if (prevSec === 0) {
					clearInterval(smsTimer.current);
				} else {
					return prevSec - 1;
				}
			});
		}, 1000);
	}, []);

	useEffect(() => {
		if (seconds !== 0) {
			startTimer();
		}

		if (seconds === 0) {
			showModalAlert(true);
		}

		return () => {
			clearInterval(smsTimer.current);
			resetUserData();
		};
	}, [resetUserData, seconds, showModalAlert, startTimer]);

	const handleChangeCode = useCallback(
		(smsCode) => {
			setCode(smsCode);
			resetCode();
		},
		[resetCode],
	);

	const handleResendCode = useCallback(async () => {
		const data = JSON.parse(localStorage.getItem("registrationData"));
		await registration(data);
		if (seconds === 0) {
			showModalAlert(false);
			setSeconds(60);
			startTimer();
		}
	}, [registration, seconds, showModalAlert, startTimer]);

	const handleSendCode = useCallback(() => {
		const phone = localStorage.getItem("userPhone");
		const fullSmsCode = code.join("");

		const data = {
			phone,
			code: fullSmsCode,
		};

		sendCode(data);
	}, [code, sendCode]);

	const actionDataForModal = useMemo(
		() => [
			{
				label: t("send_code_again"),
				icon: <MessageIcon className={cls.modalIcon} />,
				callback: handleResendCode,
			},
		],
		[handleResendCode],
	);

	const isCorrect = () => {
		const fullSmsCode = code.join("");
		if (fullSmsCode.length === 4 && !codeMessage) {
			return false;
		}

		return true;
	};

	const setOtpCodeFromSms = (event) => {
		const code = event.detail.otp.split("");
		setCode(code);
		setOtpCodeFromSmsCalled(true);
	};

	useEffect(() => {
		// Check if setOtpCodeFromSms was called
		if (otpCodeFromSmsCalled) {
			continueButtonRef.current.click();
			// Reset the flag after the side effect is executed
			setOtpCodeFromSmsCalled(false);
		}
	}, [otpCodeFromSmsCalled]);

	useEffect(() => {
		window.addEventListener("newOtp", setOtpCodeFromSms);

		return () => {
			window.removeEventListener("newOtp", setOtpCodeFromSms);
		};
	}, []);

	if (codeStatus) {
		return <Navigate to="/order" />;
	}

	if (codeLoaded) {
		//TODO добавить лоадер по новому дизайну
		return <Loader />;
	}

	return (
		<div className={cls.SmsCodeWrapper}>
			<Modal isOpen={isOpenModalAlert} isCloseAction>
				<ActionModal
					title={t("did_not_get_code")}
					subtitle={getPhoneNumber}
					actionData={actionDataForModal}
				/>
			</Modal>
			<div className={cls.info}>
				<h2>{t("code_info")}</h2>
				<p className={cls.codeInfo}>{t("we_send_your_code_to_number")}</p>
				<p className={cls.phone}>{getPhoneNumber}</p>
			</div>
			<SmsInput
				autocomplete="one-time-code"
				name="code"
				smsCode={code}
				ckeckCode={handleSendCode}
				changeSmscode={handleChangeCode}
				iosCallback={() => setOtpCodeFromSmsCalled(true)}
				setSmsCode={setCode}
			/>
			{codeMessage && <p className={cls.errorCode}>{t("incorrect_code")}</p>}
			{seconds > 0 ? (
				<p className={cls.newCode}>
					<Trans
						i18nKey="able_to_retrieve_smd_code"
						values={{ seconds: seconds, unit: getSecondsUnit(seconds) }}
					/>
				</p>
			) : (
				<Button
					className={cls.newCode}
					variant={ButtonVariant.clear}
					onClick={() => showModalAlert(true)}
				>
					{t("send_again")}
				</Button>
			)}
			<div className={cls.continueBtn}>
				<Button
					ref={continueButtonRef}
					variant={ButtonVariant.background}
					fullWidth
					disabled={isCorrect()}
					onClick={handleSendCode}
				>
					{t("continue")}
				</Button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loginPhone: state.auth.loginPhone,
		codeLoaded: state.code.codeLoaded,
		codeStatus: state.code.codeStatus,
		codeMessage: state.code.codeMessage,
		isOpenModalAlert: state.modal.isOpenModalAlert,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendCode: (loginInfo) => dispatch(sendCode(loginInfo)),
		registration: (userData) => dispatch(registration(userData)),
		resetUserData: () => dispatch(resetUserData()),
		showModalAlert: (status) => dispatch(showModalAlert(status)),
		resetCode: () => dispatch(resetCode()),
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(memo(SendCode)));
