/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */

import { getOldOrderRoute } from "../OrderRoute/order.route.action";

/* eslint-disable no-unused-vars */
export const nameModals = {
	CAR_NOT_FOUND: "CAR_NOT_FOUND",
	REJECT_ORDER_INFO: "REJECT_ORDER_INFO",
};
export const nameComponents = {
  START_SEARCH_PLACE: 'START_SEARCH_PLACE',
  PLACE_MAP_POINT: 'PLACE_MAP_POINT',
  CAR_PRICE: 'CAR_PRICE',
  ORDER_PREFERENCES: 'ORDER_PREFERENCES',
  ORDER_COMMENT: 'ORDER_COMMENT',
  PLACE_ON_MAP: 'PLACE_ON_MAP',
  SEARCH_CAR: 'SEARCH_CAR',
  ORDER_ACCEPTED: 'ORDER_ACCEPTED',
  ORDER_EXPECT: 'ORDER_EXPECT',
  ORDER_PROGRESS: 'ORDER_PROGRESS',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  ORDER_REVIEW: 'ORDER_REVIEW',
}

const choiceComponent = (status) => {
	switch (status) {
		case 1:
			return nameComponents.ORDER_ACCEPTED;
		case 4:
			return nameComponents.ORDER_EXPECT;
		case 8:
			return nameComponents.ORDER_PROGRESS;
		case 16:
			return nameComponents.ORDER_COMPLETED;
		case 128:
			return nameComponents.ORDER_REVIEW;
		case 0:
			return nameComponents.SEARCH_CAR;
		default:
			return nameComponents.START_SEARCH_PLACE;
	}
};

export const START_CHANGE_COMPONENT_BOTTOM_NAVIGATE = "START_CHANGE_COMPONENT_BOTTOM_NAVIGATE";
export const changeOrderStage = (data) => (dispatch) => {
	const orderRoute = JSON.parse(localStorage.getItem("odrerRoute"));
	if (orderRoute) {
		const newOrderRoute = { ...orderRoute, car: data }
		dispatch(getOldOrderRoute(newOrderRoute))
		localStorage.setItem('odrerRoute', JSON.stringify(newOrderRoute))
		const ComponentName = choiceComponent(data.statusId)
		dispatch({
		  type: START_CHANGE_COMPONENT_BOTTOM_NAVIGATE,
		  payload: ComponentName,
		})
	  }
};

export const OPEN_ANOTHER_COMPONENT_BOTTOM_NAVIGATE = "OPEN_ANOTHER_COMPONENT_BOTTOM_NAVIGATE";
export const openAnotherComponentBottomMenu = (componentName) => ({
	type: OPEN_ANOTHER_COMPONENT_BOTTOM_NAVIGATE,
	payload: componentName,
});

export const fragmentID = {
	PAYMENTS_ORDER: "PAYMENTS_ORDER",
	CAR_INFO: "CAR_INFO",
	DRIVER_CONTACTS: "DRIVER_CONTACTS",
	ORDER_TIMER: "ORDER_TIMER",
	PAID_WAITING_TIMER: "PAID_WAITING_TIMER",
	PAID_DETAILS: "PAID_DETAILS",
	ROUTE_AND_SAFE: "ROUTE_AND_SAFE",
	REJECT_BTN: "REJECT_BTN",
	CAR_NUMBER: "CAR_NUMBER",
	PAGE_HEADER: "PAGE_HEADER",
};

// Cash = 0,
// CreditCard = 1,
// CashAndBonus = 2,
// CreditCardAndBonus = 4

export const CHANGE_MODAL_BOTTOM_NAVIGATE = "CHANGE_MODAL_BOTTOM_NAVIGATE";
export const changeModalBottomMenu = (modal) => ({
	type: CHANGE_MODAL_BOTTOM_NAVIGATE,
	payload: modal,
});

// export const SELECTED_MARKET_BOTTOM_NAVIGATE = 'SELECTED_MARKET_BOTTOM_NAVIGATE'
// export const selectedMarketBottomMenu = (data) => ({
//   type: SELECTED_MARKET_BOTTOM_NAVIGATE,
//   payload: data
// })
