import React from 'react'

export const StarIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#FFDE17"
        d="m7.5.5 2.274 4.608 5.087.743-3.68 3.585.868 5.064-4.55-2.392L2.952 14.5l.869-5.064L.14 5.85l5.085-.743L7.5.5Z"
      />
    </svg>
  )
}
