/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import { clearLocalStoreOrder } from '../BottomMenu/helpers/clearLocalStoreOrder'
import {
  ADD_NEW_ROUTES_ORDER_ROUTE,
  ADD_ROUTES_ORDER_ROUTE,
  KEYBOARD_OPEN,
  KEYBOARD_OPEN_TWO,
  CHANGE_CHANGE_TRAVEL_ORDER_ROUTE,
  COUNT_INPUTS,
  UPDATE_INPUT_COUNT,
  CHANGE_COMMENT_ORDER_ROUTE,
  CHANGE_COUNTRY_PLACE_ORDER_ROUTE,
  CHANGE_DOP_SERVISES_ORDER_ROUTE,
  CHANGE_PAYMENT_TYPE_ORDER_ROUTE,
  CHANGE_PRICE_ORDER_ROUTE,
  CHANGE_START_PLACE_ORDER_ROUTE,
  CLEAR_ORDER_ROUTE,
  CONFIRMED_ADRESS_ORDER_ROUTE,
  GET_CAR_ORDER_ROUTE,
  GET_GOOGLE_MAP_COORDINATES,
  GET_OLD_ORDER_ROUTE,
  SELECED_COMMENT_ID__ORDER_ROUTE,
  SELECED_TARIFF_CAR_ORDER_ROUTE,
  SELECTED_TRAVEL_ORDER_ROUTE,
  getCurrentImg,
  paymentTypes,
  paymentsCart,
  ADD_ORDER_DISTANCE,
  SET_ORDER_CURRENT_PRICE,
  SELECED_TARIFF_CAR_UPDATE_ORDER_ROUTE,
} from './order.route.action'
import { t } from 'i18next'

const initialState = {
  currentGoogleMapCoordinates: null,
  startPlace: false,
  routes: [],
  confirmedAdress: false, // if  user confirmed adress show type car
  // orderPrices : {}
  sumDopServises: 0,
  infoCountry: {},
  dopServices: [],
  commentMessage: '',
  selectedCommentsId: null,
  tariffCar: {},
  travelRoutes: {},
  editPrice: null,
  car: {},
  pointMapMarket: {},
  // paymentType : paymentTypes.CASH
  paymentType: { ...paymentsCart, text: 'cash' },
  numberOfInputs: 2,
  someValue: null,
  someValueTwo: null,
  distance: null,
  currentPrice: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORDER_ROUTE: {
      clearLocalStoreOrder()
      return {
        ...state,
        editPrice: null,
        startPlace: false,
        finallPrice: null,
        confirmedAdress: false,
        sumDopServises: 0,
        dopServices: [],
        commentMessage: '',
        selectedCommentsId: null,
        tariffCar: {},
        travelRoutes: {},
        infoCountry: {
          country_code: 'UA',
        },
        paymentType: { ...paymentsCart, text: 'cash' },
      }
    }
    case CHANGE_CHANGE_TRAVEL_ORDER_ROUTE: {
      return {
        ...state,
        travelRoutes: action.payload,
      }
    }
    case SELECTED_TRAVEL_ORDER_ROUTE: {
      return {
        ...state,
        pointMapMarket: action.payload,
      }
    }

    case GET_CAR_ORDER_ROUTE: {
      return {
        ...state,
        car: action.payload,
      }
    }
    case SET_ORDER_CURRENT_PRICE: {
      return {
        ...state,
        currentPrice: action.payload,
      }
    }
    // case SAVE_ORDER_ID_ORDER_ROUTE: {
    //   return {
    //     ...state,
    //     orderId : action.payload,
    //   }
    // }
    case CHANGE_COUNTRY_PLACE_ORDER_ROUTE: {
      return {
        ...state,
        infoCountry: action.payload,
      }
    }
    case GET_OLD_ORDER_ROUTE: {
      return {
        ...action.payload,
        paymentType: {
          ...action.payload.paymentType,
          Img: getCurrentImg(action.payload.paymentType.type),
        },
      }
    }
    case CHANGE_PRICE_ORDER_ROUTE: {
      return {
        ...state,
        editPrice: action.payload,
      }
    }
    case CHANGE_PAYMENT_TYPE_ORDER_ROUTE: {
      return {
        ...state,
        paymentType: action.payload,
      }
    }
    case SELECED_TARIFF_CAR_ORDER_ROUTE: {
      return {
        ...state,
        tariffCar: action.payload,
      }
    }
    case SELECED_TARIFF_CAR_UPDATE_ORDER_ROUTE: {
      return {
        ...state,
        tariffCar: {...state.tariffCar, ...action.payload},
      }
    }
    case SELECED_COMMENT_ID__ORDER_ROUTE: {
      return {
        ...state,
        selectedCommentsId: action.payload,
      }
    }
    case CHANGE_COMMENT_ORDER_ROUTE: {
      return {
        ...state,
        commentMessage: action.payload,
      }
    }
    case GET_GOOGLE_MAP_COORDINATES: {
      return {
        ...state,
        currentGoogleMapCoordinates: action.payload,
      }
    }
    case CHANGE_DOP_SERVISES_ORDER_ROUTE: {
      return {
        ...state,
        sumDopServises: action.payload.sumDopServises,
        dopServices: action.payload.dopServices,
      }
    }
    case CONFIRMED_ADRESS_ORDER_ROUTE: {
      return {
        ...state,
        confirmedAdress: action.payload,
      }
    }
    case CHANGE_START_PLACE_ORDER_ROUTE: {
      return {
        ...state,
        startPlace: true,
        routes: [
          {
            _id: 'place-adress-1',
            adress: action.payload,
          },
        ],
        // startPlace: action.payload,
      }
    }
    case COUNT_INPUTS: {
      return {
        ...state,
        numberOfInputs: action.payload, // Обновляем счетчик инпутов
      }
    }
    case KEYBOARD_OPEN: {
      return {
        ...state,
        someValue: action.payload,
      }
    }
    case KEYBOARD_OPEN_TWO: {
      return {
        ...state,
        someValueTwo: action.payload,
      }
    }
    case UPDATE_INPUT_COUNT: {
      return {
        ...state,
        numberOfInputs: action.payload, // Обновляем количество инпутов
      }
    }
    case ADD_ROUTES_ORDER_ROUTE: {
      return {
        ...state,
        startPlace: true,
        routes: [...action.payload],
      }
    }
    case ADD_NEW_ROUTES_ORDER_ROUTE: {
      return {
        ...state,
        routes: [...state.routes, action.payload],
      }
    }
    case ADD_ORDER_DISTANCE: {
      return {
        ...state,
        distance: action.payload,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
