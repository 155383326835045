import React, { memo, useState } from 'react'
import Setting from '../../components/Setting'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'

const SettingsPage = memo(() => {
  const [title, setTitle] = useState(t('settings'))

  const changeLanguageTitle = (lang) => {
    setTitle(lang)
  }

  return (
    <>
      <PageHeader title={title} />
      <PageLayout>
        <Setting changeLanguageTitle={changeLanguageTitle} />
      </PageLayout>
    </>
  )
})

export default SettingsPage
