import React from 'react'

export const ClockIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#212121"
        d="M12.75 7.5v4.075l3.386 2.032a.75.75 0 0 1-.772 1.286l-3.75-2.25A.75.75 0 0 1 11.25 12V7.5a.75.75 0 1 1 1.5 0ZM12 3a8.948 8.948 0 0 0-6.367 2.64A38.696 38.696 0 0 0 3.75 7.687V6a.75.75 0 0 0-1.5 0v3.75a.75.75 0 0 0 .75.75h3.75a.75.75 0 1 0 0-1.5H4.594c.67-.79 1.338-1.533 2.099-2.303a7.5 7.5 0 1 1 .155 10.757.752.752 0 0 0-1.03 1.092A9 9 0 1 0 12 3Z"
      />
    </svg>
  )
}
