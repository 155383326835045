import React, { memo } from 'react'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { formatSecondsToTime } from 'src/shared/helpers/formatSecondsToTime'
import useCountdownTimer from 'src/shared/hooks/useCountdownTimer'
import { classNames } from 'src/shared/lib/classNames/classNames'
import styles from './styles.module.scss'

const DriverArrivalTimer = (props) => {
  const { destinationTime } = props

  const { seconds } = useCountdownTimer(destinationTime, 'startDestinationTime')

  const mods = {
    [styles.car_close]: !Boolean(seconds),
  }

  return (
    <div className={classNames(styles.travel_time_driver, mods, [])}>
      {seconds ? (
        <p>
          <Trans i18nKey="arrival_through" values={{ time: formatSecondsToTime(seconds) }} />
        </p>
      ) : (
        <p>{t('car_getting_close')}</p>
      )}
    </div>
  )
}

export default memo(DriverArrivalTimer)
