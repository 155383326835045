import React from 'react'
import DeleteAccount from '../../components/DeleteAccount'
import PageHeader from 'src/components/PageHeader'
import PageLayout from 'src/components/PageLayout'
import { t } from 'i18next'

/**
 * Страница с частыми вопросами
 *
 * @returns {*}
 * @constructor
 */
export default function DeleteAccountPage() {
  return (
    <>
      <PageHeader title={t('delete_account')} />
      <PageLayout>
        <DeleteAccount />
      </PageLayout>
    </>
  )
}
