/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import cls from "./styles.module.scss";
import { Modal } from "@material-ui/core";
import { t } from "i18next";
import { clearOrderRoute } from "../OrderRoute/order.route.action";
import {
	nameComponents,
	openAnotherComponentBottomMenu,
} from "../BottomMenu/bottom.navigate.action";
import { cancelOrder } from "../MapJs/order.action";
import { clearLocalStoreOrder } from "../BottomMenu/helpers/clearLocalStoreOrder";

const ModalRejectOrder = ({
	isOpen,
	onClose,
	orderId,
	clearOrderRoute,
	openAnotherComponentBottomMenu,
	cancelOrder,
}) => {
	const handleClick = async () => {
		try {
			await cancelOrder(orderId);
			clearLocalStoreOrder();
			setTimeout(() => {
				clearOrderRoute();
				openAnotherComponentBottomMenu(nameComponents.START_SEARCH_PLACE);
				window.location.reload();
			}, 1000);
		} catch (error) {
			console.log('error', error)
		}
	};

  return (
    <Modal open={isOpen} className={cls.modal}>
      <div className={cls.content_block}>
        <p className={cls.modal_text}>{t('cancel_your_order')}</p>
        <div className={cls.select_action}>
          <button className={cls.modal_second_btn_red} onClick={handleClick}>
            {t('yes')}
          </button>
          <button className={cls.modal_second_btn} onClick={onClose}>
            {t('no')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => {
	return {
		orderId: state.order.orderId,
	};
};

export default connect(mapStateToProps, {
	clearOrderRoute,
	openAnotherComponentBottomMenu,
	cancelOrder,
})(ModalRejectOrder);
