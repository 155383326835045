/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import {
  changeModalBottomMenu,
  nameComponents,
  openAnotherComponentBottomMenu,
} from './bottom.navigate.action'
import { connect } from 'react-redux'
import StartSearchPlace from './components/StartSearchPlace'
import PlaceMapPoint from './components/PlaceMapPoint'
import CarPrices from './components/CarPrices'
import SearchCar from './components/SearchСar'
import OrderAccepted from './components/OrderAccepted'
import OrderExpect from './components/OrderExpect'
import OrderProgress from './components/OrderProgress'
import OrderCompleted from './components/OrderCompleted'
import OrderReview from './components/OrderReview'
import { getCurrentOrder } from '../SocketConnection/socket.action'
import { Modal } from '../ModalCarNotFound'
import { changeTravelRotesOrderRoute, getOldOrderRoute } from '../OrderRoute/order.route.action'
import { createTravelForMap } from '../OrderRoute/AutocompleteOrderHandler'
import { getOldOrderCost } from '../MapJs/order.action'

const BottomMenu = ({
  nameComponent,
  map,
  orderStatus,
  getOldOrderCost,
  getCurrentOrder,
  getOldOrderRoute,
  routes,
  currentOrderStatus,
  driverData,
  nameModal,
  changeModalBottomMenu,
  openAnotherComponentBottomMenu,
}) => {
  useEffect(() => {
    if (
      orderStatus.status === 666 &&
      !currentOrderStatus &&
      (driverData.driverPhone === '' || driverData.driverPhone === undefined)
    ) {
      getCurrentOrder(orderStatus.orderId)
    }
  }, [orderStatus, currentOrderStatus])

  useEffect(() => {
    const orderId = localStorage.getItem('orderId')
    const odrerRoute = localStorage.getItem('odrerRoute')
    orderId && getCurrentOrder(orderId)
    let interval = null
    if (orderId && odrerRoute) {
      interval = setInterval(() => {
        const orderId = localStorage.getItem('orderId')
        orderId ? getCurrentOrder(orderId) : clearInterval(interval)
      }, 5000)
    }
    return () => {
      interval && clearInterval(interval)
    }
  }, [nameComponent])

  useEffect(() => {
    const odrerRoute = JSON.parse(localStorage.getItem('odrerRoute'))
    if (odrerRoute) {
      const orderId = localStorage.getItem('orderId')
      map && orderId && createTravelForMap(odrerRoute.routes, map, changeTravelRotesOrderRoute)
      return
    }

    const watingAdress = JSON.parse(localStorage.getItem('watingAdress'))

    if (watingAdress && map) {
      createTravelForMap(watingAdress.odrerRoute.routes, map, changeTravelRotesOrderRoute)
      getOldOrderRoute(watingAdress.odrerRoute)
      getOldOrderCost(watingAdress.costOrders)
      openAnotherComponentBottomMenu(nameComponents.CAR_PRICE)
    }
  }, [map])

  const Component = useMemo(() => {
    switch (nameComponent) {
      case nameComponents.START_SEARCH_PLACE:
        return StartSearchPlace
      case nameComponents.PLACE_MAP_POINT:
        return PlaceMapPoint
      case nameComponents.CAR_PRICE:
        return CarPrices
      case nameComponents.SEARCH_CAR:
        return SearchCar
      case nameComponents.ORDER_ACCEPTED:
        return OrderAccepted
      case nameComponents.ORDER_EXPECT:
        return OrderExpect
      case nameComponents.ORDER_PROGRESS:
        return OrderProgress
      case nameComponents.ORDER_COMPLETED:
        return OrderCompleted
      case nameComponents.ORDER_REVIEW:
        return OrderReview
    }
  }, [nameComponent])
  return (
    <>
      <Component map={map} />
      {nameModal && (
        <Modal
          nameModal={nameModal}
          changeModalBottomMenu={changeModalBottomMenu}
          openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    nameComponent: state.bottomNavigate.nameComponent,
    nameModal: state.bottomNavigate.nameModal,
    orderStatus: state.socket.orderStatus,
    driverData: state.socket.driverData,
    currentOrderStatus: state.socket.currentOrderStatus,
    routes: state.odrerRoute.routes,
  }
}

export default connect(mapStateToProps, {
  getCurrentOrder,
  getOldOrderRoute,
  getOldOrderCost,
  changeModalBottomMenu,
  openAnotherComponentBottomMenu,
})(BottomMenu)
