import React from 'react'

export const UserIcon = (props) => {
  const { width = 24, height = 24, stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill="none" {...props}>
      <path
        className={className}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.7}
        d="M12.5 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM20.5 21c0-3.866-3.806-7-8.5-7s-8.5 3.134-8.5 7"
      />
    </svg>
  )
}
