/* eslint-disable react/display-name */
import { useEffect, useState, memo, useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import { t } from "i18next";

import {
	nameComponents,
	openAnotherComponentBottomMenu,
} from "src/components/BottomMenu/bottom.navigate.action";
import cls from "src/components/MapJs/orders.module.scss";

import { ReactComponent as LastAddressPoint } from "src/shared/assets/icons/LastAddressPoint.svg";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Button/button";
import Modal from "src/shared/ui/Modal/Modal";

import { ReactComponent as DownLine } from "src/images//DownLine.svg";
import { ReactComponent as ArrowBack } from "src/images/ArrowBack.svg";
import firstCar from "src/images/FirstCar.svg";
import { ReactComponent as PlusCircle } from "src/images/PlusCircle.svg";
import { ReactComponent as Preferences } from "src/images/Preferences.svg";
import secondCar from "src/images/SecondCar.svg";
import thirdCar from "src/images/ThirdCar.svg";

import TariffsCarousel from "./components/TariffsCarousel";
import Loader from "../../../../shared/ui/Loader/Loader";
import { getValuteSymbol } from "../../../CurrencyDefinition/getValuteSymbol";
import {
	postBonus,
	postOrder,
	resetErrorOrder,
	clearUsedBonuses,
} from "../../../MapJs/order.action";
import {
	changePaymentTypeOrderRoute,
	changePriceOrderRoute,
	paymentTypes,
	paymentsCart,
	selectedTariffCarOrderRoute,
	clearOrderRoute,
	changeTravelRotesOrderRoute,
	setOrderCurrentPrice,
} from "../../../OrderRoute/order.route.action";
import { textFormator } from "../../../OrderRoute/AutocompleteOrderHandler";
import { getOpenFromMenu } from "../../../ProfileForm/profile.action";
import CarTimeDisplay from "../CarTimeDisplay/CarTimeDisplay";
import AlertModal from "../../../Modals/ui/AlertModal/AlertModal";
import { showModalAlert } from "../../../Modals/store/modal.action";
import OrderPriceBlock from "../OrderPriceBlock";

let isFirst = true;

const imgesCar = (i) => {
	switch (i) {
		case 0:
			return { img: firstCar, tariff: t("78235900-0ce1-4aca-99ca-b89e776f0910") };
		case 1:
			return { img: secondCar, tariff: t("b4c9b252-8d90-400c-bab8-c21f4fd9c7a9") };
		case 2:
			return { img: thirdCar, tariff: t("bdf73206-ed08-4aec-b603-40ee948c53b2") };
		default:
			return null;
	}
};

export const CarPricesCustomMap = memo(
	({
		costOrders,
		editPrice,
		postBonus,
		odrerRoute,
		clearUsedBonuses,
		userData,
		paymentType,
		routes,
		changePriceOrderRoute,
		tariffCar,
		selectedTariffCarOrderRoute,
		dopServices,
		changePaymentTypeOrderRoute,
		openAnotherComponentBottomMenu,
		clearOrderRoute,
		symbol,
		getOpenFromMenu,
		carArrivalTimes,
		getCarArrivalTime,
		isOpenModalAlert,
		showModalAlert,
		orderLoaded,
		orderError,
		resetErrorOrder,
		closeMap,
		checkBonus,
		setCheckBonus,
		openDescription,
		setOpenDescription,
		usedBonuses,
		estimatedPrice,
		price,
		setPrice,
	}) => {
		const [isDetails, setIsDetails] = useState(false);
		const [isMinPrice, setIsMinPrice] = useState(false);
		const [isMaxPrice, setIsMaxPrice] = useState(false);
		const [isLoader, setIsLoader] = useState(true);
		const [titleAlert, setTitleAlert] = useState("");


		const maxIncrement = estimatedPrice * 2.5;

		const handlers = useSwipeable({
			onSwipedUp: () => setIsDetails(true),
			onSwipedDown: () => setIsDetails(false),
		});

		const discountAndCompensationType = JSON.parse(
			localStorage.getItem("discountAndCompensationType"),
		);

		useEffect(() => {
			getOpenFromMenu(false);
			localStorage.removeItem("reload"); //? if socket work this line remove
		}, [getOpenFromMenu, isLoader]);

		useEffect(() => {
			if (carArrivalTimes.length === 0) {
				getCarArrivalTime();
			}
		}, [carArrivalTimes, getCarArrivalTime]);

		useEffect(() => {
			setPrice(estimatedPrice);
		}, [estimatedPrice, setPrice]);

		useEffect(() => {
			setIsMinPrice(price <= estimatedPrice);
			setIsMaxPrice(price >= maxIncrement);
		}, [estimatedPrice, maxIncrement, price]);

		useEffect(() => {
			if (costOrders.length > 0) {
				localStorage.setItem(
					"watingAdress",
					JSON.stringify({ odrerRoute: { ...odrerRoute }, costOrders }),
				);
			}
		}, [costOrders, odrerRoute]);

		useEffect(() => {
			const handleOrderError = () => {
				let titleKey;

				switch (orderError) {
					case "payments_failed":
						titleKey = orderError;
						break;

					case "insufficient_funds":
						titleKey = "not_enough_funds_on_your_card";
						break;

					case "card_is_not_available":
						titleKey = "card_is_not_supported_in_the_current_country";
						break;

					default:
						titleKey = orderError;
						break;
				}

				setTitleAlert(t(titleKey));
				showModalAlert(true);
			};

			if (orderError) {
				handleOrderError();
			}
		}, [orderError, showModalAlert]);

		const handleIncrement = useCallback(() => {
			const remainder = price % 5;
			const increaseAmount = remainder === 0 ? 5 : 5 - remainder;
			const newPrice = price + increaseAmount;
			if (newPrice <= maxIncrement) {
				setPrice(newPrice);
				changePriceOrderRoute(newPrice);
			}
		}, [changePriceOrderRoute, maxIncrement, price, setPrice]);

		const handleDecrement = useCallback(() => {
			const minPrice = tariffCar?.estimatedPrice;
			const newPrice = price - 5;

			if (minPrice > newPrice) {
				setPrice(minPrice);
				changePriceOrderRoute(minPrice);
			} else {
				setPrice(newPrice);
				changePriceOrderRoute(newPrice);
			}
		}, [changePriceOrderRoute, price, setPrice, tariffCar?.estimatedPrice]);

		const isDarkTheme = localStorage.getItem("appTheme") === "dark";
		const circlePreferences = isDarkTheme ? cls.circlePreferencesDark : cls.circlePreferences;

		const content = dopServices.length ? (
			<div className={circlePreferences}>{dopServices.length}</div>
		) : (
			<Preferences />
		);

		const handleCarClick = (tariff, active, _matchingCars) => {
			if (!active) {
				selectedTariffCarOrderRoute(tariff);
			}
		};
		useEffect(() => {
			const watingAdress = JSON.parse(localStorage.getItem("watingAdress"));
			watingAdress && setIsLoader(false);
		}, []);

		useEffect(() => {
			if (editPrice !== null) {
				setPrice(editPrice);
			}

			if (isFirst) {
				isFirst = false;
				localStorage.getItem("watingAdress") === null &&
					changePaymentTypeOrderRoute({ ...paymentsCart, text: "cash" });
			}
		}, [changePaymentTypeOrderRoute, editPrice, setPrice]);

		useEffect(() => {
			if (costOrders.length === 0) {
				return;
			}
			if (tariffCar.rate) {
				let i = 0;
				const tariff = costOrders.find((e, index) => {
					if (e.rate === tariffCar.rate) {
						i = index;
						return true;
					}

					return false;
				});

				const tarrifName = imgesCar(i);

				selectedTariffCarOrderRoute({ ...tariff, ...tarrifName });
			} else {
				selectedTariffCarOrderRoute({
					img: firstCar,
					tariff: t("78235900-0ce1-4aca-99ca-b89e776f0910"),
					...costOrders[0],
				});
			}
			setIsLoader(false);
		}, [costOrders, selectedTariffCarOrderRoute, tariffCar.rate]);

		const handleClickReject = () => {
			clearOrderRoute();
			localStorage.removeItem("watingAdress");
			localStorage.removeItem("discountAndCompensationType");
			openAnotherComponentBottomMenu(nameComponents.START_SEARCH_PLACE);
			window.location.reload();
		};

		const getCurrentPaymentId = useCallback(() => {
			const isPaymentСard = paymentType.type === paymentTypes.CARD;
			let paymentId;

			if (isPaymentСard && checkBonus) {
				paymentId = 4;
			} else if (!isPaymentСard && checkBonus) {
				paymentId = 2;
			} else if (isPaymentСard) {
				paymentId = 1;
			} else {
				paymentId = 0;
			}

			return paymentId;
		}, [checkBonus, paymentType.type]);

		const getCardId = useCallback(() => {
			const selectedPaymentId = localStorage.getItem("selectedPaymentId");
			const isPaymentCard = paymentType.type === paymentTypes.CARD;
			const cardId = isPaymentCard ? selectedPaymentId : null;

			return cardId;
		}, [paymentType.type]);

		const orderCar = () => {
			openAnotherComponentBottomMenu(nameComponents.ORDER_PREFERENCES);
		};

		// Handle bonus data submission based on conditions
		useEffect(() => {
			const createBonusData = () => {
				return {
					cardId: getCardId(),
					paymentTypeId: getCurrentPaymentId(),
					quoteBonus: userData.bonuses ?? 0,
					priceEstimated: price,
					country: odrerRoute.infoCountry.country_code || "UA",
				};
			};

			const fetchBonusesToOrder = async () => {
				if (checkBonus && price) {
					const dataBonuses = createBonusData();
					await postBonus(dataBonuses);
				} else {
					clearUsedBonuses();
				}
			};

			fetchBonusesToOrder();
		}, [
			checkBonus,
			clearUsedBonuses,
			getCardId,
			getCurrentPaymentId,
			odrerRoute.infoCountry.country_code,
			postBonus,
			price,
			userData.bonuses,
		]);

		if (isLoader) {
			return <Loader />;
		}

		if (!costOrders || costOrders.length === 0) {
			return null;
		}

		const matchingCars = carArrivalTimes?.filter((car) => car.carClassId === tariffCar.rate);

		return (
			<>
				<div className={cls.top_block_nav}>
					<div className={cls.navigation_back}>
						<div className={cls.backIconWhite}>
							<ArrowBack
								className={cls.black_icon}
								onClick={() => {
									openAnotherComponentBottomMenu(nameComponents.ORDER_ADDRESSES);
									closeMap();
								}}
							/>
						</div>
						<div className={cls.destination_block}>
							<div className={cls.pointIconGray}>
								<LastAddressPoint />
							</div>
							<p>{routes[routes.length - 1].name}</p>
						</div>
						<PlusCircle
							className={cls.black_icon}
							onClick={() => {
								openAnotherComponentBottomMenu(nameComponents.ORDER_ADDRESSES);
								closeMap();
							}}
						/>
					</div>
				</div>
				<Modal isCloseAction isOpen={isOpenModalAlert} onClose={resetErrorOrder}>
					<AlertModal title={titleAlert} />
				</Modal>
				<div className={cls.tariffs_form} {...handlers}>
					{Boolean(tariffCar.discount) && (
						<div
							className={classNames(
								cls.OrderDiscountWrapper,
								{ [cls.active]: Boolean(tariffCar.discount) },
								[],
							)}
						>
							<div className={cls.discount}>
								{discountAndCompensationType && (
									<p>
										{discountAndCompensationType?.discountType === 1 ? (
											<Trans
												i18nKey="you_have_been_discount"
												values={{ discount: tariffCar.discount, currency: symbol }}
											/>
										) : (
											<Trans
												i18nKey="you_have_been_percent_discount"
												values={{ discount: tariffCar.discount }}
											/>
										)}
									</p>
								)}
							</div>
						</div>
					)}
					<div className={cls.downLineSecond} onClick={() => setIsDetails(!isDetails)}>
						<DownLine className={cls.down_line_icon} />
					</div>
					<div className={cls.tariffs_block}>
						<TariffsCarousel
							carArrivalTimes={carArrivalTimes}
							costOrders={costOrders}
							handleCarClick={handleCarClick}
							imgesCar={imgesCar}
							matchingCars={matchingCars}
							symbol={symbol}
							tariffCar={tariffCar}
						/>
					</div>
					<div className={cls.priceBlock}>
						<OrderPriceBlock
							checkBonus={checkBonus}
							content={content}
							discount={tariffCar.discount}
							getOpenFromMenu={getOpenFromMenu}
							handleDecrementPrice={handleDecrement}
							handleIncrementPrice={handleIncrement}
							isMaxPrice={isMaxPrice}
							isMinPrice={isMinPrice}
							openDescription={openDescription}
							paymentType={paymentType}
							paymentTypes={paymentTypes}
							price={price}
							priceBeforeDiscount={tariffCar.priceBeforeDiscount}
							setCheckBonus={setCheckBonus}
							setOpenDescription={setOpenDescription}
							usedBonuses={usedBonuses}
							tariffCar={tariffCar}
						/>
					</div>

					<div className={cls.confirm_block}>
						<Button disabled={orderLoaded} isLoading={orderLoaded} onClick={orderCar}>
							{t("order")}
						</Button>
					</div>
					{isDetails && (
						<div className={cls.reject_block}>
							<button onClick={handleClickReject}>{t("cancel")}</button>
						</div>
					)}
				</div>
			</>
		);
	},
);

const mapStateToProps = (state) => {
	return {
		tariffCar: state.odrerRoute.tariffCar,
		dopServices: state.odrerRoute.dopServices,
		paymentType: state.odrerRoute.paymentType,
		travelRoutes: state.odrerRoute.travelRoutes,
		commentMessage: state.odrerRoute.commentMessage,
		userData: state.profile.userData,
		userCardsList: state.ccard.userCardsList,
		sumDopServises: state.odrerRoute.sumDopServises,
		editPrice: state.odrerRoute.editPrice,
		carArrivalTimes: state.order.carArrivalTimes,
		registrationCountryInfo: state.registration.registrationCountryInfo,
		symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
		distance: state.odrerRoute.distance,
		orderError: state.order.orderError,
		isOpenModalAlert: state.modal.isOpenModalAlert,
		orderLoaded: state.order.orderLoaded,
		usedBonuses: state.order.usedBonuses,
	};
};

export default connect(mapStateToProps, {
	selectedTariffCarOrderRoute,
	changeTravelRotesOrderRoute,
	getOpenFromMenu,
	postBonus,
	postOrder,
	openAnotherComponentBottomMenu,
	changePriceOrderRoute,
	changePaymentTypeOrderRoute,
	clearOrderRoute,
	setOrderCurrentPrice,
	showModalAlert,
	resetErrorOrder,
	clearUsedBonuses,
})(CarPricesCustomMap);
