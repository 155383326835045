import { axiosInstance } from 'src/axios.config'
import { osmMapPolyline } from '../../../config'

const fetchRoute = (start, end) => {
  const routeUrl = `${osmMapPolyline}?start=${start}&end=${end}`
  return axiosInstance.get(routeUrl)
}

export const createRoutes = async (startPoints, endPoints) => {
  try {
    const responses = await Promise.all(
      startPoints.map((start, index) => fetchRoute(start, endPoints[index]))
    )

    let fullDistance = 0
    const formattedCoordinates = responses.flatMap((response) => {
      if (!response.data) {
        return []
      }
      const coordinates = response.data.features[0].geometry.coordinates
      fullDistance = fullDistance + response.data.features[0].properties.segments[0].distance

      const duration = response.data.features[0].properties.summary.duration;
      localStorage.setItem('durationTrip', duration)

      return coordinates
    })

    return { formattedCoordinates, fullDistance }
  } catch (error) {
    console.log('Error createRoutes', error)
  }
}
