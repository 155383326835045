import React, { memo } from 'react'
import ApkPolicy from '../../components/ApkPolicy'
import PageLayout from '../../components/PageLayout'
import PageHeader from '../../components/PageHeader'
import { t } from 'i18next'

const ApkPolicyPage = memo(() => {
  return (
    <>
      <PageHeader title={t('privacy_policy')} />
      <PageLayout>
        <ApkPolicy />
      </PageLayout>
    </>
  )
})

export default ApkPolicyPage
