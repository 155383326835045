import { combineReducers } from "redux";
import auth from "../components/Auth/auth.reducer";
import registration from "../components/Registration/registration.reducer";
import code from "../components/SendCode/sendcode.reducer";
import forgot from "../components/Forgot/forgot.reducer";

import order from "../components/MapJs/order.reducer";
import ccard from "../components/OrderPayment/store/ccard.reducer";
import modal from "../components/Modals/store/modal.reducer";

import dictionary from "../components/Dictionarys/dictionary.reducer";
import feed from "../pages/HelpListPage/store/feed.reducer";
import news from "../pages/NewsPage/news.reducer";
import chat from "../pages/ChatHistoryPage/store/chat.reducer";
import socket from "../components/SocketConnection/socket.reducer";
import history from "../components/History/store/history.reducer";
import profile from "../components/ProfileForm/profile.reducer";
import error from "../components/ErrorComponent/error.reducer";
import errorSocket from "../components/ErrorSocket/error.reducer";
import errorToken from "../components/ErrorToken/error.reducer";
import questions from "../components/Questions/question.reducer";
import favorite from "../components/FavoriteAddress/favorite.reducer";
import odrerRoute from "../components/OrderRoute/order.route.reducer";
import bottomNavigate from "../components/BottomMenu/bottom.navigate.reducer";
import mapMarkers from "../components/MapMarkers/MapMarkers.reducer";
import theme from "./reducers/theme-reducer";
import invoices from "../pages/InvoicesPage/invoices.reducer";

const rootReducer = combineReducers({
	auth,
	modal,
	registration,
	code,
	order,
	dictionary,
	feed,
	news,
	forgot,
	chat,
	socket,
	profile,
	history,
	error,
	questions,
	errorSocket,
	errorToken,
	favorite,
	ccard,
	odrerRoute,
	bottomNavigate,
	mapMarkers,
	theme,
	invoices
});

export default rootReducer;
