/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

export const ChangeFirstWindowMap = ({ inputs, state, someValue }) => {
    const map = document.getElementById('map')
    const l = inputs
    const marker = document.getElementById('market-place-map')
    const changeWindowMap = () => {
        if (!map || !marker) return;
        switch (l) {
            case 1:
                if (window.matchMedia('(max-height: 690px)').matches) {
                    marker.style.marginTop = `-23vh`
                    map.style.height = '80vh';
                }
                else {
                    marker.style.marginTop = `-10vh`
                    map.style.height = '80vh';
                }
                break;
            case 2:
                if (window.matchMedia('(max-height: 690px)').matches) {
                    marker.style.marginTop = `-23vh`
                    map.style.height = '80vh';
                }
                else {
                    marker.style.marginTop = `-10vh`
                    map.style.height = '80vh';
                }
                break;
            case 3:
                if (someValue === 1) {
                    if (window.matchMedia('(max-height: 900px)').matches) {
                        marker.style.marginTop = `-9vh`
                        map.style.height = '84vh';
                    } else {
                        marker.style.marginTop = `-9vh`
                        map.style.height = '84vh';
                    }
                }
                else {
                    if (window.matchMedia('(max-height: 900px)').matches) {
                        marker.style.marginTop = `-15vh`
                        map.style.height = '70vh';
                    } else {
                        marker.style.marginTop = `-13vh`
                        map.style.height = '72vh';
                    }
                }
                break;
            case 4:
                if (someValue === 1) {
                    if (window.matchMedia('(max-height: 900px)').matches) {
                        marker.style.marginTop = `-9vh`
                        map.style.height = '84vh';
                    } else {
                        marker.style.marginTop = `-9vh`
                        map.style.height = '84vh';
                    }
                }
                else {
                    if (window.matchMedia('(max-height: 770px)').matches) {
                        marker.style.marginTop = `-20vh`
                        map.style.height = '60vh';
                    } else if (window.matchMedia('(min-height: 770px) and (max-height: 900px)').matches) {
                        marker.style.marginTop = `-15vh`
                        map.style.height = '65vh';
                    } else {
                        marker.style.marginTop = `-15vh`
                        map.style.height = '67vh';
                    }
                }
                break;
            case 5:
                if (someValue === 1) {
                    if (window.matchMedia('(max-height: 900px)').matches) {
                        marker.style.marginTop = `-9vh`
                        map.style.height = '84vh';
                    } else {
                        marker.style.marginTop = `-9vh`
                        map.style.height = '84vh';
                    }
                }
                else {
                    if (window.matchMedia('(max-height: 770px)').matches) {
                        marker.style.marginTop = `-22vh`
                        map.style.height = '53vh';
                    } else if (window.matchMedia('(min-height: 770px) and (max-height: 900px)').matches) {
                        marker.style.marginTop = `-17vh`
                        map.style.height = '65vh';
                    } else {
                        marker.style.marginTop = `-15vh`
                        map.style.height = '67vh';
                    }
                }
                break;
        }
    }
    useEffect(changeWindowMap, [state])

    // changeWindowMap()

    return (
        <>
        </>
    );
};

const mapStateToProps = state => {
    return {
        inputs: state.odrerRoute.numberOfInputs,
        state,
        someValue: state.odrerRoute.someValue
    };
};

export default connect(mapStateToProps, {})(ChangeFirstWindowMap);
