import React from 'react'

export const BackIcon = (props) => {
  const { width = 24, height = 24, fill = '#212121', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill="none" {...props}>
      <path
        className={className}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m15 18-6-6 6-6"
      />
    </svg>
  )
}
