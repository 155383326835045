import React from 'react'

export const HeadphonesIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#212121"
        d="M18.927 5.124a9.697 9.697 0 0 0-6.853-2.874H12A9.75 9.75 0 0 0 2.25 12v5.25A2.25 2.25 0 0 0 4.5 19.5H6a2.25 2.25 0 0 0 2.25-2.25V13.5A2.25 2.25 0 0 0 6 11.25H3.784a8.261 8.261 0 0 1 14.08-5.07 8.193 8.193 0 0 1 2.353 5.07H18a2.25 2.25 0 0 0-2.25 2.25v3.75A2.25 2.25 0 0 0 18 19.5h2.25A2.25 2.25 0 0 1 18 21.75h-5.25a.75.75 0 1 0 0 1.5H18a3.75 3.75 0 0 0 3.75-3.75V12a9.694 9.694 0 0 0-2.823-6.876ZM6 12.75a.75.75 0 0 1 .75.75v3.75A.75.75 0 0 1 6 18H4.5a.75.75 0 0 1-.75-.75v-4.5H6ZM18 18a.75.75 0 0 1-.75-.75V13.5a.75.75 0 0 1 .75-.75h2.25V18H18Z"
      />
    </svg>
  )
}
