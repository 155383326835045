import { useState, useCallback } from 'react'
import { themes } from '../../mock/themes'

const useThemeManager = () => {
  const [theme, setTheme] = useState(localStorage.getItem('appTheme'))

  const changeTheme = useCallback((theme) => {
    localStorage.setItem('appTheme', theme.name)
    document.querySelector('body').setAttribute('data-theme', theme.name)
    setTheme(theme)
  }, [])

  const getCurrentTheme = useCallback(() => {
    const appTheme = localStorage.getItem('appTheme')
    const currentTheme = themes.find((theme) => theme.name === appTheme)
    setTheme(currentTheme)
  }, [])

  return { theme, changeTheme, getCurrentTheme }
}

export default useThemeManager
