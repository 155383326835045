const getCenterAndZoom = (clientCoordinates, map) => {
	if (!clientCoordinates || !map) {
		return;
	}

	let minLng = Infinity;
	let maxLng = -Infinity;
	let minLat = Infinity;
	let maxLat = -Infinity;

	clientCoordinates.forEach((coord) => {
		minLng = Math.min(minLng, coord[0]);
		maxLng = Math.max(maxLng, coord[0]);
		minLat = Math.min(minLat, coord[1]);
		maxLat = Math.max(maxLat, coord[1]);
	});

	const center = [(minLng + maxLng) / 2, (minLat + maxLat) / 2];

	map.current.setCenter(center);

	map.current.fitBounds(
		[
			[minLng, minLat],
			[maxLng, maxLat],
		],
		{
			padding: {
				top: 100,
				bottom: 100,
				left: 135,
				right: 120,
			},
			linear: true,
			maxDuration: 2000,
		},
	);
};

export default getCenterAndZoom;
