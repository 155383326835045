// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl'
import { useEffect, useRef } from 'react'
import { findNearestPoint } from '../helpers/findSegment'

const useCreatePoints = (props) => {
  const { map, mapPoints, mapLoaded, newPolyline } = props
  const markersRef = useRef([])

  useEffect(() => {
    if ((map.current, mapPoints && mapLoaded && newPolyline)) {
      const passedPoints = localStorage.getItem('passedPoints')
        ? JSON.parse(localStorage.getItem('passedPoints'))
        : []

      // check for not duplicate marks
      if (markersRef.current && passedPoints.length === mapPoints.length) {
        markersRef.current = mapPoints
        return
      }

      // Get the last item index for mapPoints
      const lastItemIndx = mapPoints.length - 1
      const bigPoints = [0, lastItemIndx]

      mapPoints.forEach((item, index) => {
        const nearPoint = findNearestPoint(newPolyline, item)?.point
        // marker html elements
        const pointEl = document.createElement('div')
        const innerPointEl = document.createElement('div')

        // bigger points style
        const isBigPoints = bigPoints.includes(index)

        pointEl.className = `${isBigPoints ? 'pointBig ' : ''} blackPoint`

        innerPointEl.className = `${isBigPoints ? 'innerPointBig ' : ''} innerBlackPoint`

        innerPointEl.innerHTML = `${index + 1}`
        pointEl.appendChild(innerPointEl)

        const marker = new maplibregl.Marker({
          element: pointEl,
          rotationAlignment: 'map',
          pitchAlignment: 'map',
        }).setLngLat(nearPoint)
 // remove argument in Marker({})
        const currentMarker = markersRef.current.find((marker) => marker.name === `${item}`)

        const currentMarkerIndx = markersRef.current.findIndex(
          (marker) => marker.name === `${item}`
        )

        // cleanup points
        if (currentMarker) {
          currentMarker.markerEl.remove()

          markersRef.current[currentMarkerIndx] = {
            name: item.name,
            markerEl: marker,
          }
        } else {
          markersRef.current = [...markersRef.current, { name: `${item}`, markerEl: marker }]
        }

        marker.addTo(map.current)
      })
    }
  }, [mapPoints, mapLoaded, newPolyline, map])
}

export default useCreatePoints
