/* eslint-disable no-undef */

// stop app loader after call
export const onContentLoaded = () => {
    //android
    try {
      Native.onContentLoaded();
    } catch {
      console.log("onContentLoaded its not android");
    }
    //ios
  
    try {
      webkit.messageHandlers.onContentLoaded.postMessage("onContentLoaded");
    } catch {
      console.log("onContentLoaded its not ios");
    }
  };
  