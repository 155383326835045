import {
  GET_FAVORITE_PENDING,
  GET_FAVORITE_SUCCESS,
  GET_FAVORITE_ERROR,
  POST_FAVORITE_PENDING,
  POST_FAVORITE_SUCCESS,
  POST_FAVORITE_ERROR,
  DELETE_FAVORITE_PENDING,
  DELETE_FAVORITE_SUCCESS,
  DELETE_FAVORITE_ERROR,
} from './favorite.action'

const initialState = {
  favoriteDataLoaded: false,
  favoriteDataError: false,
  favoriteData: [],
  favoriteDataPost: false,
  favoriteDataDelete: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAVORITE_PENDING: {
      return {
        ...state,
        favoriteDataLoaded: true,
        favoriteDataError: false,
      }
    }
    case GET_FAVORITE_SUCCESS: {
      return {
        ...state,
        favoriteData: action.payload,
        favoriteDataLoaded: false,
      }
    }
    case GET_FAVORITE_ERROR: {
      return {
        ...state,
        favoriteDataError: action.payload,
      }
    }

    case POST_FAVORITE_PENDING: {
      return {
        ...state,
        favoriteDataLoaded: true,
        favoriteDataError: false,
      }
    }
    case POST_FAVORITE_SUCCESS: {
      return {
        ...state,
        favoriteDataPost: action.payload,
        favoriteDataLoaded: false,
      }
    }
    case POST_FAVORITE_ERROR: {
      return {
        ...state,
        favoriteDataError: action.payload,
      }
    }

    case DELETE_FAVORITE_PENDING: {
      return {
        ...state,
        favoriteDataLoaded: true,
        favoriteDataError: false,
      }
    }
    case DELETE_FAVORITE_SUCCESS: {
      return {
        ...state,
        favoriteDataDelete: action.payload,
        favoriteDataLoaded: false,
      }
    }
    case DELETE_FAVORITE_ERROR: {
      return {
        ...state,
        favoriteDataError: action.payload,
      }
    }

    default:
      return {
        ...state,
      }
  }
}

export default reducer
