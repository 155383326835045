import React, { useState } from 'react'
import { connect } from 'react-redux'
import { t } from 'i18next'
import { removeAccount } from '../ProfileForm/profile.action'
import { Snackbar } from '@material-ui/core'
import cls from './deleteaccount.module.scss'
import { Button } from 'src/shared/ui/Button/button'
import ModalDeleteRequest from '../ModalDeleteRequest'

function DeleteAccount({ removeAccount }) {
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const handleSnackBarClose = () => {
    setSnackBarOpen(false)
  }

  const handleDeleteAccount = () => {
    removeAccount()
      .then((response) => {
        console.log(response)
        if (response.status === 204) {
          setSnackBarMessage(t('request_confirmed'))
          setSnackBarOpen(true)
        } else if (response.status === 409) {
          setSnackBarMessage(t('same_request_confirmed'))
          setSnackBarOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
        console.error('Error deleting account:', error)
      })
  }

  return (
    <div className={cls.DeleteAccountWrapper}>
      <p className={cls.infoAboudDelete}>{t('info_about_delete_account')}</p>
      <div className={cls.btnBlock}>
        <Button className={cls.confirmBtn} fullWidth onClick={handleDeleteAccount}>
          {t('confirm_delete_account')}
        </Button>
      </div>
      <ModalDeleteRequest text={snackBarMessage} isOpen={snackBarOpen} onClose={handleSnackBarClose} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: state?.profile.userData.id,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeAccount: () => dispatch(removeAccount()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount)
