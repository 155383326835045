export const animateMovingMarker = ({
	prevAnimationCoords,
	newCoords,
	durationInSeconds = 1,
	onUpdateAnimationCoords,
}) => {
	// Calculate the animation steps here

	const frameRate = 60; // Frames per second
	const totalFrames = durationInSeconds * frameRate;
	const animationStart = performance.now(); // Get the current time

	const startLngLat = prevAnimationCoords || newCoords;

	if (startLngLat) {
		const distanceLng = newCoords[0] - startLngLat[0];
		const distanceLat = newCoords[1] - startLngLat[1];

		const stepLng = distanceLng / totalFrames;
		const stepLat = distanceLat / totalFrames;

		const animate = (timestamp) => {
			const elapsed = timestamp - animationStart;
			const frame = Math.min(Math.floor((elapsed / 1000) * frameRate), totalFrames);

			if (frame < totalFrames) {
				const lng = startLngLat[0] + stepLng * frame;
				const lat = startLngLat[1] + stepLat * frame;
				onUpdateAnimationCoords({ coords: [lng, lat] });

				prevAnimationCoords.current = [lng, lat];
				requestAnimationFrame(animate);
			} else {
				onUpdateAnimationCoords({ coords: newCoords });
			}
		};

		animate(animationStart);
		
	}
};
