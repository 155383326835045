import React, { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./styles.module.scss";
import { Input } from "src/shared/ui/Input/Input";

const CountrySelector = (props) => {
	const {
		countryInfo,
		countryCode,
		setCountryPhoneCode,
		userData,
		disabled,
		error,
		errorText,
		disabledSecondCountryCode = false,
		phoneInputProps,
		...otherInputProps
	} = props;
	const currentCountry = countryInfo?.country_code?.toLowerCase();
	const [selectedCountry, setSelectedCountry] = useState(currentCountry || "ua");

	const handleCountryChange = useCallback(
		(country) => {
			setSelectedCountry(country);
			setCountryPhoneCode(`+${country}`);
		},
		[setCountryPhoneCode],
	);

	const defaultPhoneInputProps = {
		disabled: disabled,
		placeholder: "",
		inputClass: styles.phone,
		value: countryCode,
		buttonClass: `${styles.button} ${
			phoneInputProps?.disableDropdown ? styles.disableDropdown : ""
		}`,
		dropdownClass: styles.dropdown,
		enableSearch: true,
		searchPlaceholder: t("searching_country"),
		disableCountryCode: true,
		inputProps: {
			readOnly: true,
			disabled: true,
		},
		searchNotFound: t("country_not_found"),
		country: selectedCountry,
		onChange: handleCountryChange,
	};

	useEffect(() => {
		if (userData?.language) {
			setSelectedCountry(userData?.language);
		}

		if (countryInfo?.phone_code) {
			setCountryPhoneCode(countryInfo?.phone_code);
		}
	}, [countryInfo?.phone_code, setCountryPhoneCode, userData]);

	return (
		<div className={styles.counterSelectorWrapper}>
			<div className={styles.fullInputWrap}>
				<div className={styles.phoneInputWrap}>
					<PhoneInput {...defaultPhoneInputProps} {...phoneInputProps} />
				</div>
				<div className={styles.inputPhoneWrap}>
					{!disabledSecondCountryCode && <span className={styles.inputCode}>{countryCode}</span>}
					<Input
						{...otherInputProps}
						className={`${styles.inputPhone} ${error ? "error_field" : ""}`}
					/>
				</div>
			</div>

			{error && <p className={styles.error}>{errorText}</p>}
		</div>
	);
};

export default CountrySelector;
