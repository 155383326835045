/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import cls from 'src/components/MapJs/orders.module.scss'
import { ReactComponent as DownLine } from 'src/images/DownLine.svg'
import { ReactComponent as CarIcon } from 'src/images/CarIcon.svg'
import { t } from 'i18next'
import { paymentTypes } from '../../../OrderRoute/order.route.action'
import IconStrip from '../../../IconStrip'
import ModalRejectOrder from '../../../ModalRejectOrder/ModalRejectOrder'
import { getValuteSymbol } from '../../../CurrencyDefinition/getValuteSymbol'
import { Modal } from '../../../ModalCarNotFound'
import { changeModalBottomMenu, openAnotherComponentBottomMenu } from '../../bottom.navigate.action'
import { parseLocalStorageItem } from 'src/shared/helpers/parseLocalStorageItem'
import { getTextPaymentById } from 'src/services/getTextPayment'
import RotatingSquare from '../RotatingSquare'

const CarClass1 = () => <div>{t('78235900-0ce1-4aca-99ca-b89e776f0910')}</div>
const CarClass2 = () => <div>{t('b4c9b252-8d90-400c-bab8-c21f4fd9c7a9')}</div>
const CarClass3 = () => <div>{t('bdf73206-ed08-4aec-b603-40ee948c53b2')}</div>

export const SearchСar = ({
  tariffCar,
  paymentType,
  routes,
  editPrice,
  symbol,
  nameModal,
  currentPrice,
  currentOrderData,
}) => {
  const Img = paymentType.Img
  const [isDetails, setIsDetails] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)

	const orderPaymentInfo = parseLocalStorageItem("orderPaymentInfo");

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const [startY, setStartY] = useState(null)
  const [endY, setEndY] = useState(null)
  const touchRef = useRef(null)

  const handleTouchStart = (e) => {
    // Проверка, что событие не произошло на элементах button и a (ссылках)
    if (!isButtonOrLink(e.target)) {
      const touch = e.touches[0]
      setStartY(touch.clientY)
      touchRef.current = touch
    }
  }

  const handleTouchEnd = (e) => {
    if (!touchRef.current) return

    // Проверка, что событие не произошло на элементах button и a (ссылках)
    if (!isButtonOrLink(e.target)) {
      const touch = e.changedTouches[0]
      setEndY(touch.clientY)

      const deltaY = endY - startY

      if (deltaY > 50) {
        setIsDetails(false)
      } else if (deltaY < -50) {
        setIsDetails(true)
      }

      touchRef.current = null
    }
  }

  // Функция, которая проверяет, является ли элемент кнопкой или ссылкой или находится внутри них
  const isButtonOrLink = (element) => {
    if (!element) return false
    if (element.tagName.toLowerCase() === 'button' || element.tagName.toLowerCase() === 'a') {
      return true
    }
    // Рекурсивная проверка для вложенных элементов
    if (element.parentElement) {
      return isButtonOrLink(element.parentElement)
    }
    return false
  }

  const textFormator = (text) => {
    const arrAdress = text.split(', ')
    let minusCountElement = 1
    let mainText = arrAdress[0] + ' '

    if (parseInt(arrAdress[1])) {
      mainText += arrAdress[1]
      minusCountElement++
    }

    return {
      mainText,
      secondaryText: arrAdress.slice(minusCountElement, arrAdress.length - 2).join(', '),
    }
  }

  const address = (point) => point?.adress?.formatted_address || point?.name

  let hasSecondaryText = true

  if (address) {
    routes.forEach((point) => {
      const formattedAddress = textFormator(address(point) || '')
      if (!formattedAddress.secondaryText) {
        hasSecondaryText = false
      }
    })
  }

  const firstRoute = routes[0]
  let secondaryTextLength = 0

  if (firstRoute && address) {
    secondaryTextLength = textFormator(address(firstRoute)).secondaryText.length
  }

  return (
    <div
      className={cls.search_car_form}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {isModalOpen && <ModalRejectOrder isOpen={isModalOpen} onClose={closeModal} />}
      <div
        className={cls.down_line}
        onClick={() => setIsDetails(!isDetails)}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <DownLine className={cls.down_line_icon} />
      </div>
			<RotatingSquare/>
      <div className={cls.main_search_block}>
        <p className={cls.search_car_text}>{t('searching_car')}</p>
        <p className={cls.car_class_search}>{tariffCar.tariff}</p>
      </div>
      <button className={cls.reject_btn} onClick={openModal}>
        {t('cancel')}
      </button>
      {isDetails && (
        <div className={cls.details_search}>
          <div className={cls.details_items}>
            <div className={cls.order_costs}>
              <p>{t('order_cost')}</p>
              <div className={cls.price_section}>
                <p>
                  {currentOrderData?.priceEstimated - currentOrderData?.quoteBonus || currentPrice}
                </p>
                <p>{symbol}</p>
              </div>
            </div>
            <div className={cls.type_of_payment}>
              <p>{t('payment_type')}</p>
              <div className={cls.type_section}>
                <Img className={cls.icon_payment} />
								<p>{t(getTextPaymentById(orderPaymentInfo.paymentId))}</p>
              </div>
            </div>
            <div className={cls.order_path}>
              <p>{t('route')}</p>
            </div>
            <div className={cls.order_path_details} style={{ paddingBottom: '20px' }}>
              <div className={cls.path_icons}>
                <IconStrip iconCount={routes.length} secondaryTextLength={secondaryTextLength} />
              </div>
              <div className={cls.path_route}>
                {routes.map((point) => {
                  const formattedAddress = textFormator(address(point))
                  return (
                    <div className={cls.path_single_item} key={point.id}>
                      <p className={cls.main_text}>{formattedAddress.mainText}</p>
                      {hasSecondaryText && (
                        <p className={cls.secondary_text}>{formattedAddress.secondaryText}</p>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    startPlace: state.odrerRoute.startPlace,
    currentGoogleMapCoordinates: state.odrerRoute.currentGoogleMapCoordinates,
    routes: state.odrerRoute.routes,
    paymentType: state.odrerRoute.paymentType,
    tariffCar: state.odrerRoute.tariffCar,
    editPrice: state.odrerRoute.editPrice,
    symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
    nameModal: state.bottomNavigate.nameModal,
    currentOrderData: state.socket.currentOrderData,
    currentPrice: state.odrerRoute.currentPrice,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeModalBottomMenu: (value) => dispatch(changeModalBottomMenu(value)),
    openAnotherComponentBottomMenu: (component) =>
      dispatch(openAnotherComponentBottomMenu(component)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchСar)
