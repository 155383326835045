export const stylesStructure = [
  { elementType: "geometry", stylers: [{ color: "#191a1c" }] }, // Темнее фон
  { elementType: "labels.text.stroke", stylers: [{ color: "#1e2532" }] }, // Темнее названий улиц
  { elementType: "labels.text.fill", stylers: [{ color: "#b9bbbf" }] }, // Цвет названий улиц

  // Обновление цвета для всех надписей
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [{ color: "#b9bbbf" }],
  },

  // Обновление цвета дорог
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#3b4043" }],
  },

  // Остальные стили оставляем без изменений или обновляем по необходимости
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#b9bbbf" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#b9bbbf" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#b9bbbf" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#b9bbbf" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#b9bbbf" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];
