import React, { memo } from 'react'
import { t } from 'i18next'
import Typography from '../../common/Typography'
import cls from './apkPolicy.module.scss'

const ApkPolicy = memo(() => {
  return (
    <div className={cls.ApkPolicyWrapper}>
      {' '}
      <Typography>
        <p className={cls.title}>{t('main_text_new_line_first')}</p>
        <p className={cls.title}>{t('main_text_new_line_second')}</p>
        <p className={cls.title}>{t('main_text_new_line_third')}</p>
        <p className={cls.title}>{t('main_text_new_line_fourth')}</p>
        <p className={cls.title}>{t('main_text_new_line_fifth')}</p>
        <p className={cls.title}>{t('main_text_new_line_sixth')}</p>
        <p className={cls.title}>{t('main_text_new_line_seventh')}</p>
        <p className={cls.title}>{t('main_text_new_line_eighth')}</p>

        <p className={cls.mainTitle}>
          <b>{t('main_text_new_line_ninth')}</b>
        </p>
        <p className={cls.title}>{t('main_text_new_line_tenth')}</p>
        <p className={cls.title}>{t('main_text_new_line_twelfth')}</p>
        <p className={cls.title}>{t('main_text_new_line_thirtieth')}</p>

        <p className={cls.mainTitle}>
          <b>{t('information_disclosure')}</b>
        </p>
        <p className={cls.title}>{t('main_text_new_line_fourteenth')}</p>
        <p className={cls.title}>{t('main_text_new_line_fifteenth')}</p>

        <p className={cls.mainTitle}>
          <b>{t('information_storage')}</b>
        </p>
        <p className={cls.title}>{t('main_text_new_line_sixteenth')}</p>

        <p className={cls.mainTitle}>
          <b>{t('security')}</b>
        </p>
        <p className={cls.title}>{t('main_text_new_line_seventeenth')}</p>
        <p className={cls.title}>{t('main_text_new_line_eighteenth')}</p>
        <p className={cls.title}>{t('main_text_new_line_nineteenth')}</p>
        <p className={cls.title}>{t('main_text_new_line_twentieth')}</p>
        <p className={cls.title}>{t('main_text_new_line_twentyFirst')}</p>
        <p className={cls.title}>{t('main_text_new_line_twentySecond')}</p>

        <p className={cls.mainTitle}>
          <b>{t('changing_privacy_policy')}</b>
        </p>
        <p className={cls.title}>{t('main_text_new_line_twentyThird')}</p>
        <p className={cls.title}>{t('main_text_new_line_twentyFourth')}</p>
      </Typography>
    </div>
  )
})

export default ApkPolicy
