import React, { memo } from 'react'
import { classNames } from '../../../shared/lib/classNames/classNames'
import cls from './chatMessage.module.scss'

const ChatMessage = memo(({ id, text, isSupport, avatar, postTime, isNew, putChatSeen }) => {
  const mods = {
    [cls.supportMessage]: isSupport === true,
    [cls.clientMessage]: isSupport === false,
  }

  return (
    <div className={classNames(cls.message, mods, [])}>
      {isSupport && <img className={cls.avatar} src={avatar} alt="Support Avatar" />}
      <div className={cls.container}>
        <div className={cls.messageContent}>
          <div className={cls.messageText}>{text}</div>
          <div className={cls.postTime}>{postTime}</div>
          {isNew && putChatSeen(id)}
        </div>
        {/* {!isSupport && (
          <div>
            <img className="message-image" src={avatar} alt="Support Avatar" />
          </div>
        )} */}
      </div>
    </div>
  )
})

export default ChatMessage
