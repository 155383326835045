import { useState, useEffect, useCallback, useRef } from 'react'
import { parseLocalStorageItem } from '../../../shared/helpers/parseLocalStorageItem'

const useWaitingTimer = (initialSeconds = 0, isCountdown = true) => {
  const storedCountdownPreference = parseLocalStorageItem('isCountdown') ?? isCountdown

  const [seconds, setSeconds] = useState(initialSeconds)
  const [isCountingDown, setIsCountingDown] = useState(storedCountdownPreference)

  const intervalIdRef = useRef(null)

  useEffect(() => {
    const startWaitingTime = parseLocalStorageItem('startWaitingTime')
    const isCountdownStorage = parseLocalStorageItem('isCountdown')

    if (startWaitingTime === null) {
      localStorage.setItem('startWaitingTime', Date.now())
    }

    if (isCountdownStorage === null) {
      localStorage.setItem('isCountdown', isCountdown)
    }
  }, [isCountdown])

  const stopTimer = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }
  }, [])

  const modifyTimer = useCallback(() => {
    localStorage.setItem('startWaitingTime', Date.now())
    localStorage.setItem('isCountdown', false)
    setIsCountingDown(false)
  }, [])

  const calculateRemainingSeconds = useCallback(() => {
    const startWaitingTime = Number(localStorage.getItem('startWaitingTime'))

    if (!startWaitingTime) {
      return stopTimer()
    }

    const elapsedSeconds = Math.floor((Date.now() - startWaitingTime) / 1000)
    const remainingSeconds = isCountingDown
      ? Math.max(initialSeconds - elapsedSeconds, 0)
      : elapsedSeconds

    if (remainingSeconds === 0) {
      modifyTimer()
    }

    setSeconds(remainingSeconds)
  }, [modifyTimer, initialSeconds, isCountingDown, stopTimer])

  const startTimer = useCallback(() => {
    calculateRemainingSeconds()

    intervalIdRef.current = setInterval(() => {
      calculateRemainingSeconds()
    }, 1000)
  }, [calculateRemainingSeconds])

  const initializeTimer = useCallback(() => {
    const startWaitingTime = parseLocalStorageItem('startWaitingTime')


    if (startWaitingTime) {
      startTimer()
    }
  }, [startTimer])

  useEffect(() => {
    initializeTimer()

    return stopTimer
  }, [initializeTimer, stopTimer])

  return { seconds, isCountingDown }
}

export default useWaitingTimer
