import { useState, useRef } from 'react'
import { connect } from 'react-redux'
import cls from 'src/components/MapJs/orders.module.scss'
import { ReactComponent as DownLine } from 'src/images/DownLine.svg'
import { ReactComponent as LocationPin } from 'src/images/LocationPin.svg'
import { ReactComponent as BlueCheck } from 'src/images/BlueCheck.svg'
import { t } from 'i18next'
import IconStrip from '../../../IconStrip'
import ModalRejectOrder from '../../../ModalRejectOrder/ModalRejectOrder'
import { getValuteSymbol } from '../../../CurrencyDefinition/getValuteSymbol'
import DriverArrivalTimer from '../DriverArrivalTimer'
import { getNonEmptyPoints } from '../../../AddressComponents/helpers/getNonEmptyPoints'
import { parseLocalStorageItem } from 'src/shared/helpers/parseLocalStorageItem'
import { getTextPaymentById } from 'src/services/getTextPayment'
import { clearOrderRoute } from "../../../OrderRoute/order.route.action";
import { nameComponents, openAnotherComponentBottomMenu } from "../../bottom.navigate.action";
import { cancelOrder } from "../../../MapJs/order.action";

const CarClass1 = () => <div>{t('78235900-0ce1-4aca-99ca-b89e776f0910')}</div>
const CarClass2 = () => <div>{t('b4c9b252-8d90-400c-bab8-c21f4fd9c7a9')}</div>
const CarClass3 = () => <div>{t('bdf73206-ed08-4aec-b603-40ee948c53b2')}</div>

export const OrderAccepted = ({
  tariffCar,
  paymentType,
  routes,
  editPrice,
  driverData,
  symbol,
  currentOrderData,
  carClassName,
  currentPrice,
}) => {
  const [isDetails, setIsDetails] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [startY, setStartY] = useState(null)
  const [endY, setEndY] = useState(null)

  const touchRef = useRef(null)

  const Img = paymentType.Img
  const destinationTime = parseInt(localStorage.getItem('destinationTime') ?? 0, 10)

	const orderPaymentInfo = parseLocalStorageItem("orderPaymentInfo");

  const handleTouchStart = (e) => {
    // Проверка, что событие не произошло на элементах button и a (ссылках)
    if (!isButtonOrLink(e.target)) {
      const touch = e.touches[0]
      setStartY(touch.clientY)
      touchRef.current = touch
    }
  }

  const handleTouchEnd = (e) => {
    if (!touchRef.current) {
      return
    }

    // Проверка, что событие не произошло на элементах button и a (ссылках)
    if (!isButtonOrLink(e.target)) {
      const touch = e.changedTouches[0]
      setEndY(touch.clientY)

      const deltaY = endY - startY

      if (deltaY > 50) {
        setIsDetails(false)
      } else if (deltaY < -50) {
        setIsDetails(true)
      }

      touchRef.current = null
    }
  }
  // Функция, которая проверяет, является ли элемент кнопкой или ссылкой или находится внутри них
  const isButtonOrLink = (element) => {
    if (!element) {
      return false
    }
    if (element.tagName.toLowerCase() === 'button' || element.tagName.toLowerCase() === 'a') {
      return true
    }
    // Рекурсивная проверка для вложенных элементов
    if (element.parentElement) {
      return isButtonOrLink(element.parentElement)
    }
    return false
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  let carClass

  if (carClassName === '78235900-0ce1-4aca-99ca-b89e776f0910') {
    carClass = <CarClass1 />
  } else if (carClassName === 'b4c9b252-8d90-400c-bab8-c21f4fd9c7a9') {
    carClass = <CarClass2 />
  } else if (carClassName === 'bdf73206-ed08-4aec-b603-40ee948c53b2') {
    carClass = <CarClass3 />
  } else {
    carClass = <div className={cls.default_icon} />
  }

  const textFormator = (text) => {
    if (text) {
      const arrAdress = text.split(', ')
      let minusCountElement = 1
      let mainText = arrAdress[0] + ' '

      if (parseInt(arrAdress[1])) {
        mainText += arrAdress[1]
        minusCountElement++
      }

      return {
        mainText,
        secondaryText: arrAdress.slice(minusCountElement, arrAdress.length - 2).join(', '),
      }
    }

    return {}
  }

  let hasSecondaryText = true
  const address = (point) => point?.adress?.formatted_address || point?.name

  if (address) {
    routes.forEach((point) => {
      const formattedAddress = textFormator(address(point) || '')
      if (!formattedAddress.secondaryText) {
        hasSecondaryText = false
      }
    })
  }

  const firstRoute = routes[0]

  let secondaryTextLength = 0

  if (firstRoute && address) {
    secondaryTextLength = textFormator(address(firstRoute)).secondaryText.length
  }

  return (
    <div
      className={cls.search_car_form}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <ModalRejectOrder isOpen={isModalOpen} onClose={closeModal} />
      <div
        className={cls.down_line}
        onClick={() => setIsDetails(!isDetails)}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <DownLine className={cls.down_line_icon} />
      </div>
      <div className={cls.header_order_block}>
        <div className={cls.header_main_text}>
          <BlueCheck />
          <p>{t('driver_on_move')}</p>
        </div>

        <DriverArrivalTimer destinationTime={destinationTime} />

        <div className={cls.current_address_block}>
          <LocationPin />
          <p>{textFormator(address(routes[0])).mainText}</p>
        </div>
      </div>
      <div className={cls.car_class_info_block}>
        <div className={cls.car_name_with_class}>
          <p>
            {t(driverData.carColorName)} {driverData.carName}
          </p>
          <div className={cls.car_circle} />
          <p>{carClass}</p>
        </div>
        <div className={cls.car_number}>{driverData.carNumber}</div>
      </div>
      {isDetails && (
        <div className={cls.details_search}>
          <div className={cls.details_items}>
            <button className={cls.reject_btn} onClick={openModal} style={{ marginTop: '0px' }}>
              {t('cancel')}
            </button>
            <div className={cls.order_costs}>
              <p>{t('order_cost')}</p>
              <div className={cls.price_section}>
                <p>
                  {currentOrderData?.priceEstimated - currentOrderData?.quoteBonus || currentPrice}
                </p>
                <p>{symbol}</p>
              </div>
            </div>
            <div className={cls.type_of_payment}>
              <p>{t('payment_type')}</p>
              <div className={cls.type_section}>
                <Img className={cls.icon_payment} />
									<p>{t(getTextPaymentById(orderPaymentInfo.paymentId))}</p>
              </div>
            </div>
            <div className={cls.order_path}>
              <p>{t('route')}</p>
            </div>
            <div className={cls.order_path_details} style={{ paddingBottom: '20px' }}>
              <div className={cls.path_icons}>
                <IconStrip
                  iconCount={getNonEmptyPoints(routes).length}
                  secondaryTextLength={secondaryTextLength}
                />
              </div>
              <div className={cls.path_route}>
                {getNonEmptyPoints(routes).map((point) => {
                  const formattedAddress = textFormator(address(point))
                  return (
                    <div className={cls.path_single_item} key={point.id}>
                      <p className={cls.main_text}>{formattedAddress.mainText}</p>
                      {hasSecondaryText && (
                        <p className={cls.secondary_text}>{formattedAddress.secondaryText}</p>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    startPlace: state.odrerRoute.startPlace,
    currentGoogleMapCoordinates: state.odrerRoute.currentGoogleMapCoordinates,
    routes: state.odrerRoute.routes,
    paymentType: state.odrerRoute.paymentType,
    tariffCar: state.odrerRoute.tariffCar,
    editPrice: state.odrerRoute.editPrice,
    driverData: state.socket.driverData,
    carClassName: state.odrerRoute.car.carClassId,
    symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
    currentOrderData: state.socket.currentOrderData,
    currentPrice: state.odrerRoute.currentPrice,
  }
}

export default connect(mapStateToProps, {})(OrderAccepted)
