/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MyGeolocation from "src/images/MyGeolocation.svg";
import cls from "src/components/MapJs/orders.module.scss";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { ReactComponent as LocationPin } from "src/images/LocationPin.svg";
import { nameComponents, openAnotherComponentBottomMenu } from "../../bottom.navigate.action";
import {
	addNewlOrderRoute,
	addRoutesOrderRoute,
	changeStartPlaceOrderRoute,
} from "../../../OrderRoute/order.route.action";
import { ReactComponent as ArrowBack } from "src/images/ArrowBack.svg";
import { editFirstMarket, editIdMapMarket } from "../../../MapMarkers/MapMarkers.action";

let listener = null;
let currentInputValue = null;

export const PlaceMapPoint = ({
	map,
	firstAdress,
	firstMarket,
	input,
	currentMarketId,
	routes,
	editIdMapMarket,
	editFirstMarket,
	addRoutesOrderRoute,
	openAnotherComponentBottomMenu,
	currentGoogleMapCoordinates,
}) => {
	const [value, setValue] = useState("");
	const [adress, setAdress] = useState({});

	useEffect(() => {
		listener = window.google.maps.event.addListener(map, "dragend", addMarkerMap);
		if (input === undefined) {
			// !place
			const i = currentMarketId[currentMarketId.length - 1] - 1;
			const elem = routes[i];
			if (!elem) return;

			setValue(elem.adress.formatted_address);
			setAdress(elem.adress);
			// setValue(currentGoogleMapCoordinates.formatted_address);
			// setAdress(currentGoogleMapCoordinates)
		}
	}, []);

	const removeEvent = () => {
		window.google.maps.event.removeListener(listener);
		listener = null;
		currentInputValue = null;
	};
	// const navigate = use
	const confirmedRedirect = (e) => currentMarketId !== "place-start-adress-2" && e.preventDefault();
	const addMarket = (adress) => {
		const marker = new window.google.maps.Marker({
			position: adress.geometry.location,
			map: map,
			icon: {
				url: MyGeolocation,
				scaledSize: new window.google.maps.Size(60, 60),
			},
		});
		editFirstMarket({ firstMarket: marker, firstAdress: adress });

		//? add logical remove
	};
	const confirmed = () => {
		if (adress.formatted_address === undefined) return;
		removeEvent();
		if (input) {
			input.value = adress.formatted_address;
		} else {
			// !place
			// editIdMapMarket(inputs[0]._id)
			if (currentMarketId === "place-start-adress-2") {
				routes[1] = {
					_id: "place-adress-2",
					adress,
				};

				// addNewlOrderRoute(data);
				// selectedMarketBottomMenu(null)
			} else {
				// localStorage.setItem('startPlace', JSON.stringify(adress))
				routes[0] = {
					_id: "place-adress-1",
					adress,
				};
				addMarket(adress);

				//!place
				// const newPosition = { lat: 40.7128, lng: -74.0060 }; // New center position (New York City)
				// console.error(adress.geometry.location, '---------------------');
				// map.setCenter(adress.geometry.location);
				//!place

				// add market for map
				setTimeout(() => {
					document.getElementById("origin-input").value = adress.formatted_address;
				}, 1000);
			}

			addRoutesOrderRoute(routes);
			editIdMapMarket(null);
			openAnotherComponentBottomMenu(nameComponents.START_SEARCH_PLACE);
		}
	};

	const back = () => {
		if (input) {
			return;
		} else {
			openAnotherComponentBottomMenu(nameComponents.START_SEARCH_PLACE);
			removeEvent();
			const id_1 = "place-start-adress-1";
			setTimeout(() => {
				if (currentMarketId === id_1) {
					const oi = document.getElementById("origin-input");
					if (firstAdress) {
						oi.value = firstAdress.formatted_address;
						firstMarket && firstMarket.setMap(map);
					} else {
						// oi.value = currentGoogleMapCoordinates.formatted_address;

						//!place
						choiceBackAdress(oi);
					}

					// currentMarketId === 'place-start-adress-2'
					// changePointOrderRoute(id_1, firstAdress)
				}
				// // if( ) {
				// oi.value = currentGoogleMapCoordinates.formatted_address;
				// //  }
			}, 1000);
		}
	};

	const addMarkerMap = (name) => {
		const geocoder = new window.google.maps.Geocoder();
		const center = map.getCenter();
		// map.setZoom(13);

		geocoder.geocode(
			{
				latLng: center,
			},
			(results, status) => {
				if (status === window.google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						setValue(results[0].formatted_address);
						setAdress(results[0]);
					}
				}
			},
		);
	};

	const choiceBackAdress = (input) => {
		const { lat, lng } = currentGoogleMapCoordinates.geometry.location;

		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode(
			{
				latLng: lat() + " " + lng(),
			},
			(results, status) => {
				if (status === window.google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						input.value = results[0].formatted_address;

						// setValue(results[0].formatted_address)
						// setAdress(results[0])
					}
				}
			},
		);
	};

	const confirmedClass = adress.formatted_address
		? cls.location_confirm_button
		: cls.location_not_confirm_button;
	return (
		<div>
			<div className={cls.back_to_button} onClick={back}>
				<div className={cls.back_to_icon}>
					<ArrowBack />
				</div>
			</div>
			<div id="market-place-map" className={cls.user_geolocation}>
				<img src={MyGeolocation} alt="" />
			</div>
			<div className={cls.location_block}>
				<div className={cls.location_item}>
					<div className={cls.location_change}>
						<div className={cls.change_item}>
							<div className={cls.location_block_icon}>
								<LocationPin />
							</div>
							<input
								id="input-map-place"
								type="text"
								placeholder={t("write_geolocation")}
								value={value}
								onChange={
									() => {}
									// this.setState({
									//   stepFavoriteAddress: false,
									//   stepAddress: false,
									// })
								}
							/>
						</div>
					</div>
					<Link to="/order-route" onClick={confirmedRedirect}>
						<button className={confirmedClass} onClick={confirmed}>
							{t("confirm")}
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentGoogleMapCoordinates: state.odrerRoute.currentGoogleMapCoordinates,
		currentMarketId: state.mapMarkers.currentMarketId,
		firstAdress: state.mapMarkers.firstAdress,
		firstMarket: state.mapMarkers.firstMarket,
		routes: state.odrerRoute.routes,

		// startPlace: state.odrerRoute.startPlace,
		// sumDopServises: state.odrerRoute.sumDopServises,
	};
};

export default connect(mapStateToProps, {
	openAnotherComponentBottomMenu,
	editFirstMarket,
	editIdMapMarket,
	addRoutesOrderRoute,
	changeStartPlaceOrderRoute,
	addNewlOrderRoute,
})(PlaceMapPoint);
