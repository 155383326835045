import React, { memo } from 'react'
import { connect } from 'react-redux'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import cls from './ActionModal.module.scss'

const ActionModal = memo((props) => {
  const { title, subtitle, actionData } = props

  const mods = {
    [cls.noSubtitle]: !Boolean(subtitle),
  }

  return (
    <div className={cls.ActionModal}>
      <div className={cls.titleInfo}>
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
      <div className={classNames(cls.actionsContainer, mods, [])}>
        {actionData.map((data, index) => (
          <div className={cls.action} onClick={data.callback} key={index}>
            {data.icon}
            <p>{data.label}</p>
          </div>
        ))}
      </div>
    </div>
  )
})

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionModal)
