/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import { axiosInstance } from "src/axios.config";
import { apiOrder, apiData, apiRPC, apiRPCSecond } from "../../config";
import { clearLocalStoreOrder } from "../BottomMenu/helpers/clearLocalStoreOrder";
import { errorFunction } from "../ErrorComponent/error.action";
import { errorTokenFunction } from "../ErrorToken/error.action";
import { setOrderCurrentPrice } from "../OrderRoute/order.route.action";

export const UPDATE_POS = "UPDATE_POS";

export const updPos = (pos) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_POS,
			payload: { ...pos },
		});
	};
};

export const GET_CAR_CLASS_TIME_INFO_SUCCESS = "GET_CAR_CLASS_TIME_INFO_SUCCESS";
export const getCarClassTimeInfo = (infoOrderData) => {
	const accessToken = localStorage.getItem("accessToken");

	return async (dispatch) => {
		// dispatch({
		//   type: GET_ORDER_COST_PENDING,
		// })

		return axiosInstance
			.post(apiData + "/searchdriver/carclasstimeinfo", infoOrderData, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then((response) => {
				const hasCar = response.data?.length ? response.data : ["response.data.Data"];

				dispatch({
					type: GET_CAR_CLASS_TIME_INFO_SUCCESS,
					payload: hasCar,
				});
			})
			.catch((error) => {
				if (error.response.status === 406) {
					dispatch(errorFunction(error.response));
				}

				if (error.response.statusText === "Unauthorized") {
					dispatch(errorTokenFunction(error));
				} else {
					dispatch(errorFunction(error.response));
				}
			});
	};
};

export const GET_ORDER_COST_PENDING = "GET_ORDER_COST_PENDING";
export const GET_ORDER_COST_SUCCESS = "GET_ORDER_COST_SUCCESS";
export const GET_ORDER_COST_ERROR = "GET_ORDER_COST_ERROR";
export const GET_ORDER_IDENTIFIER = "GET_ORDER_IDENTIFIER";

export const getOldOrderCost = (data) => ({
	type: GET_ORDER_COST_SUCCESS,
	payload: data,
});

export const getOrderCost = (infoOrderData) => {
	const accessToken = localStorage.getItem("accessToken");

	return async (dispatch) => {
		dispatch({
			type: GET_ORDER_COST_PENDING,
		});

		return axiosInstance
			.post(apiRPC, infoOrderData, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then((response) => {
				dispatch({
					type: GET_ORDER_COST_SUCCESS,
					payload: response.data.data,
				});

				dispatch({
					type: GET_ORDER_IDENTIFIER,
					payload: response.data.identifier,
				});

				localStorage.setItem("orderIdentifier", response.data.identifier);

				if (response.data.discountType || response.data.compensationType) {
					const discountAndCompensation = {
						discountType: response.data?.discountType || 0,
						compensationType: response.data?.compensationType || 0,
					};

					localStorage.setItem(
						"discountAndCompensationType",
						JSON.stringify(discountAndCompensation),
					);
				}
				return response.data
			})
			.catch((error) => {
				if (error.response.status === 406) {
					dispatch(errorFunction(error.response));
				}

				if (error.response.statusText === "Unauthorized") {
					dispatch(errorTokenFunction(error));
				} else {
					dispatch(errorFunction(error.response));
				}

				dispatch({
					type: GET_ORDER_COST_ERROR,
					payload: error,
				});
			});
	};
};

export const POST_BONUS_PENDING = "POST_BONUS_PENDING";
export const POST_BONUS_SUCCESS = "POST_BONUS_SUCCESS";
export const POST_BONUS_ERROR = "POST_BONUS_ERROR";
export const CLEAR_USED_BONUSES = "CLEAR_USED_BONUSES";

export const postBonus = (orderData) => async (dispatch) => {
	dispatch({ type: POST_ORDER_PENDING, payload: true });

	try {
		const accessToken = localStorage.getItem("accessToken");
		const bonusData = {
			cardId: orderData.cardId,
			paymentTypeId: orderData.paymentTypeId,
			quoteBonus: orderData.quoteBonus,
			priceEstimated: orderData.priceEstimated,
			country: orderData.country,
		};

		const response = await axiosInstance.post(apiData + "/orders/get-bonus-quote", bonusData, {
			headers: { Authorization: `Bearer ${accessToken}` },
		});

		dispatch({ type: POST_BONUS_SUCCESS, payload: response });

		const isBlocked = localStorage.getItem("isBlocked");
		if (isBlocked === "true") {
			dispatch(errorFunction("Пользователь заблокирован"));
			clearLocalStoreOrder();
			localStorage.removeItem("time");
		} else {
			return orderData;
		}
	} catch (error) {
		if (error.response?.statusText === "Unauthorized") {
			dispatch(errorTokenFunction(error));
		} else {
			dispatch(errorFunction(error.response));
		}

		dispatch({ type: POST_BONUS_ERROR, payload: error });
	} finally {
		dispatch({ type: POST_ORDER_PENDING, payload: false });
	}
};

export const clearUsedBonuses = () => (dispatch) => {
	dispatch({ type: CLEAR_USED_BONUSES });
};

export const POST_ORDER_PENDING = "POST_ORDER_PENDING";
export const POST_ORDER_SUCCESS = "POST_ORDER_SUCCESS";
export const POST_ORDER_ERROR = "POST_ORDER_ERROR";

export const postOrder = (orderData) => async (dispatch) => {
	dispatch({ type: POST_ORDER_PENDING, payload: true });

	try {
		const accessToken = localStorage.getItem("accessToken");

		const response = await axiosInstance.post(apiOrder + "/rider/orders", orderData, {
			headers: { Authorization: `Bearer ${accessToken}` },
		});

		dispatch(setOrderCurrentPrice(orderData.priceEstimated - orderData.quoteBonus));
		localStorage.setItem("orderId", response.data.data.id);

		dispatch({ type: POST_ORDER_SUCCESS, payload: response.data.data });

		// Add order in back
		const identifier = localStorage.getItem("orderIdentifier");
		const connectStore = {
			orderId: response.data.data.id,
			identifier: identifier,
		};

		return false;
	} catch (error) {
		//!TODO: Need fix when the server sends the correct error format
		const errorDataMessage = error.response.data.Message;
		const errorDataType = error.response.data.Data;

		dispatch({ type: POST_ORDER_PENDING, payload: false });

		if (errorDataMessage.includes("card_is_not_available")) {
			dispatch({ type: POST_ORDER_ERROR, payload: "card_is_not_available" });
			return "card_is_not_available";
		}

		if (errorDataMessage.includes("Insufficient funds")) {
			dispatch({ type: POST_ORDER_ERROR, payload: "insufficient_funds" });
			return "insufficient_funds";
		}

		if (errorDataType === "payments_failed") {
			dispatch({ type: POST_ORDER_ERROR, payload: "payments_failed" });
			return "payments_failed";
		}

		if (error.response?.statusText === "Unauthorized") {
			dispatch(errorTokenFunction(error));
			return;
		}

		dispatch(errorFunction(error.response));
		dispatch({ type: POST_ORDER_ERROR, payload: error });
	}
};

export const CANCEL_ORDER_PENDING = "CANCEL_ORDER_PENDING";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_ERROR = "CANCEL_ORDER_ERROR";

export const cancelOrder = (orderId) => {
	const accessToken = localStorage.getItem("accessToken");
	return (dispatch) => {
		dispatch({
			type: CANCEL_ORDER_PENDING,
		});
		axiosInstance
			.post(apiOrder + `/rider/${orderId}/reject`, null, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then((response) => {
				dispatch({
					type: CANCEL_ORDER_SUCCESS,
					payload: true,
				});
				localStorage.removeItem("time"); //TODO : time
			})
			.catch((error) => {
				if (error.response.statusText === "Unauthorized") {
					dispatch(errorTokenFunction(error));
				} else {
					dispatch(errorFunction(error.response));
				}
				dispatch({
					type: CANCEL_ORDER_ERROR,
					payload: error,
				});
			});
	};
};

export const PUT_RATING_PENDING = "PUT_RATING_PENDING";
export const PUT_RATING_SUCCESS = "PUT_RATING_SUCCESS";
export const PUT_RATING_ERROR = "PUT_RATING_ERROR";

export const putRating = (data) => {
	const accessToken = localStorage.getItem("accessToken");
	return (dispatch) => {
		dispatch({
			type: PUT_RATING_PENDING,
		});
		axiosInstance
			.put(apiOrder + `/rider/rate`, data, { headers: { Authorization: `Bearer ${accessToken}` } })
			.then((response) => {
				dispatch({
					type: PUT_RATING_SUCCESS,
					payload: true,
				});
				window.location.replace("/order");
			})
			.catch((error) => {
				if (error.response.statusText === "Unauthorized") {
					dispatch(errorTokenFunction(error));
				} else {
					dispatch(errorFunction(error.response));
				}
				dispatch({
					type: PUT_RATING_ERROR,
					payload: error,
				});
			});
	};
};

export const resetErrorOrder = () => async (dispatch) => {
	dispatch({ type: POST_ORDER_ERROR, payload: false });
};
