import { useState, useCallback } from 'react'
import { languagesNames } from '../../mock/languages'
import { useTranslation } from 'react-i18next'
import { setLocaleToDayJs } from '../helpers/setLocaleToDayJs'

const useLanguageManager = () => {
  const [t, i18n] = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('language_name'));

  const localeMap = languagesNames.reduce((map, language) => {
    map[language.locale.toLowerCase()] = language.locale;
    return map;
  }, {});

  const changeLanguage = useCallback(
    (newLanguage) => {
      if (newLanguage.locale) {
        localStorage.setItem('language_name', newLanguage.locale)
      } else {
        localStorage.setItem('language_name', newLanguage.toUpperCase())
      }
      i18n.changeLanguage(
        newLanguage.locale ? newLanguage.locale.toLowerCase() : newLanguage.toLowerCase()
      )
      setLanguage(newLanguage);
      if (newLanguage.locale) {
        setLocaleToDayJs(localeMap[newLanguage.locale.toLowerCase()]);
      }
    },
    [i18n]
  )

  const getCurrentLanguage = useCallback(() => {
    const languageName = localStorage.getItem('language_name')
    const currentLanguage = languagesNames.find(
      (language) => language.locale === languageName?.toUpperCase()
    )
    setLanguage(currentLanguage);
    if (currentLanguage.locale) {
      setLocaleToDayJs(localeMap[currentLanguage.locale.toLowerCase()]);
    }
  }, [])

  return { language, changeLanguage, getCurrentLanguage }
}

export default useLanguageManager
