import React from 'react'
import { nameModals } from '../BottomMenu/bottom.navigate.action'
import ModalCarNotFound from './ModalCarNotFound'
import ModalRejectInfo from '../ModalRejectInfo/ModalRejectInfo'

export const Modal = (props) => {
  switch(props.nameModal){
    case nameModals.CAR_NOT_FOUND : return <ModalCarNotFound {...props} />;
    case nameModals.REJECT_ORDER_INFO : return <ModalRejectInfo {...props} />;
    default : <></>;
  }
}
