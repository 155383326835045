import { axiosInstance } from 'src/axios.config'
import { apiLogSelectedAddress } from '../../../config'

export const fetchLogSelectedAddress = async (selectedAddress) => {
  try {
    const accessToken = localStorage.getItem('accessToken')
    return await axiosInstance.post(apiLogSelectedAddress, selectedAddress, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  } catch (error) {
    console.log('Error fetch log selected address')
  }
}
