import { axiosInstance } from 'src/axios.config'
import { osmMapPolyline } from '../../../config'

export const typeOfError = {
  ERROR_CREATE_ROUTE: 'ERROR_CREATE_ROUTE',
}

export const fetchDirection = async (coordinates) => {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const { data } = await axiosInstance.post(
      osmMapPolyline,
      {
        coordinates,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return data
  } catch (error) {
    if (error.response.status === 404) {
      return typeOfError.ERROR_CREATE_ROUTE
    }

    return []
  }
}
