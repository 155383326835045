import { axiosInstance } from 'src/axios.config'
import { apiOrder } from '../../../config'
import { errorFunction } from '../../ErrorComponent/error.action'
import { errorTokenFunction } from '../../ErrorToken/error.action'

export const GET_HISTORY_PENDING = 'GET_HISTORY_PENDING'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR'

export const getHistory = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_HISTORY_PENDING,
    })
    axiosInstance
      .get(apiOrder + '/rider/history/?skip=0&take=1000', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log('Action* - история поездок УСПЕХ: ', response)
        dispatch({
          type: GET_HISTORY_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        console.log('Action* - история поездок ОШИБКА: ', error)
        dispatch({
          type: GET_HISTORY_ERROR,
          payload: error,
        })
      })
  }
}

export const GET_LAST_RIDE_PENDING = 'GET_LAST_RIDE_PENDING'
export const GET_LAST_RIDE_SUCCESS = 'GET_LAST_RIDE_SUCCESS'
export const GET_LAST_RIDE_ERROR = 'GET_LAST_RIDE_ERROR'

export const getLastRide = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_LAST_RIDE_PENDING,
    })
    axiosInstance
      .get(apiOrder + '/rider/history/?skip=0&take=3', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        let lastPoints = []
        if (response.data.data.length === 1) {
          lastPoints = [response.data.data[0].destinationPoints[1]]
        } else if (response.data.data.length === 3) {
          lastPoints = [
            response.data.data[0].destinationPoints[1],
            response.data.data[1].destinationPoints[1],
            response.data.data[2].destinationPoints[1],
          ]
        }
        dispatch({
          type: GET_LAST_RIDE_SUCCESS,
          payload: lastPoints,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_LAST_RIDE_ERROR,
          payload: error,
        })
      })
  }
}
