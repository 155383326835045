import React, { memo, useCallback } from "react";
import { t } from "i18next";
import { ReactComponent as Check } from "src/images/check.svg";
import { ReactComponent as TrashIcon } from "src/images/trash.svg";
import { PaymentsMethodsType } from "src/shared/variables/payments";
import cls from "../../orderPayment.module.scss";

const CardType = (props) => {
	const {
		type,
		text,
		Img,
		changeType,
		id,
		selectedPaymentId,
		setSelectedPaymentId,
		onOpenDeleteModal,
	} = props;

	const isSelectedCard = selectedPaymentId === id;

	const choiceType = useCallback(() => {
		if (id) {
			setSelectedPaymentId(id);
			localStorage.setItem("selectedPaymentId", id);
		}

		changeType({ type, Img, text });
	}, [Img, changeType, id, setSelectedPaymentId, text, type]);

	const handleDeleteCard = useCallback(
		(event) => {
			event.stopPropagation();
			onOpenDeleteModal();
		},
		[onOpenDeleteModal],
	);

	return (
		<div
			onClick={choiceType}
			className={`${cls.payment_type_item} ${isSelectedCard && cls.active_payment}`}
		>
			<div className={cls.payment_single_item}>
				<div className={cls.name_payment}>
					<Img className={cls.icon_payment} />
					<p>{id === "cash" ? t("cash") : t(text)}</p>
				</div>
				<div className={cls.paymentIcons}>
					<div onClick={(event) => handleDeleteCard(event, id)}>
						{type === PaymentsMethodsType.CARD && <TrashIcon className={cls.trash_icon} />}
					</div>
					{isSelectedCard && (
						<div className={cls.check_icon}>
							<Check />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(CardType);
