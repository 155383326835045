/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { createRef, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as DownLine } from 'src/images/DownLine.svg'
import { ReactComponent as LocationPin } from 'src/images/LocationPin.svg'
import { ReactComponent as Close } from 'src/images/Close.svg'
import { ReactComponent as BackClock } from 'src/images/BackClock.svg'
import MyGeolocation from 'src/images/MyGeolocation.svg'
import { Link } from 'react-router-dom'
import { changeStartPlaceOrderRoute } from '../../../OrderRoute/order.route.action'
import { clearOrderRoute } from '../../../OrderRoute/order.route.action'
import cls from 'src/components/MapJs/orders.module.scss'
import { t } from 'i18next'
import { ReactComponent as UserLocation } from 'src/images/userLocation.svg'
import {
  nameComponents,
  openAnotherComponentBottomMenu,
  selectedMarketBottomMenu,
} from '../../bottom.navigate.action'
import { useSwipeable } from 'react-swipeable'
import Loader from '../../../../shared/ui/Loader/Loader'
import AutocompleteOrderHandler, { initMarker } from '../../../OrderRoute/AutocompleteOrderHandler'
import { useNavigate } from 'react-router-dom'
import { addRoutesOrderRoute } from '../../../OrderRoute/order.route.action'
import { editFirstMarket, editIdMapMarket } from '../../../MapMarkers/MapMarkers.action'
import ModalIdentityAdress from '../../../ModalIdentityAdress/ModalIdentityAdress'
import OrderRoute from '../../../OrderRoute'

const textFormator = (text) => {
  const arrAdress = text.split(', ')
  let minusCountElement = 1
  let mainText = arrAdress[0] + ' '

  if (parseInt(arrAdress[1])) {
    mainText += arrAdress[1]
    minusCountElement++
  }

  return {
    mainText,
    secondaryText: arrAdress.slice(minusCountElement, arrAdress.length - 2).join(', '),
  }
}

const StartSearchPlace = ({
  currentGoogleMapCoordinates,
  changeStartPlaceOrderRoute,
  lastPoints,
  openAnotherComponentBottomMenu,
  clearOrderRoute,
  addRoutesOrderRoute,
  geolocation,
  editIdMapMarket,
  // selectedMarketBottomMenu,
  editFirstMarket,
  firstMarket,
  firstAdress,
  routes,
  map,
  keyboardOpen,
}) => {
  const [isDetails, setIsDetails] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [editInput, setEditInput] = useState(null)
  const [adress, setAdress] = useState(null)

  const commonConfig = JSON.parse(localStorage.getItem('commonConfig'))

  const [inputs, setInputs] = useState([
    {
      _id: 'place-start-adress-1',
      ref: createRef('place-start-adress-1'),
      adress: {},
    },
    {
      _id: 'place-start-adress-2',
      ref: createRef('place-start-adress-2'),
      adress: {},
    },
  ])
  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handlers = useSwipeable({
    onSwipedUp: () => setIsDetails(true),
    onSwipedDown: () => setIsDetails(false),
  })

  // const history = useNavigate();

  const handleClearInput = () => {
    editFirstMarket({ firstMarket: null, firstAdress: null })
    inputs[0].ref.current.value = ''
    firstMarket && firstMarket.setMap(null)
    inputs[0].ref.current.focus()
  }
  const changeFirstInput = () => {
    editFirstMarket({ firstMarket: null, firstAdress: null })
    firstMarket && firstMarket.setMap(null)
  }

  // useEffect(() => {
  //   firstMarket && firstMarket.setMap(map); //! create if firstMarket the same like geoposition
  //   // console.log(firstMarket);
  // }, [firstMarket])

  // useEffect(() => {
  //   if(map) {
  //     AutocompleteOrderHandler(inputs, changePointOrderRoute)
  //   }
  // }, [map]);

  // useEffect(() => {
  //    if (map) {
  //     console.log(map);
  //      const listener = window.google.maps.event.addListener(map, 'dragend', () => addMarkerMap());
  //      return () => {
  //        window.google.maps.event.removeListener(listener);
  //      }
  //    }

  // },[map, editInput])

  // useEffect(() => {
  //   if (geolocation && routes[0] === undefined) {
  //     //!plcae
  //     // geolocation.place_id
  //     initMarker(geolocation.place_id, inputs[0]._id, changePointOrderRoute)
  //   }
  // }, [geolocation])

  // useEffect(() => {
  //   const f = routes[0]?.adress;
  //   const s = routes[1]?.adress;

  //   if (f) inputs[0].ref.current.value = f.formatted_address
  //   if (s) inputs[1].ref.current.value = s.formatted_address

  //   // -----------------------------

  // }, [])

  // useEffect(() => {
  //   const f = inputs[0];
  //   const s = inputs[1];

  //   const f_v = (f.ref.current.value.trim() !== '' || routes[0]?.adress.place_id);
  //   const s_v = (s.ref.current.value.trim() !== '' || routes[1]?.adress.place_id);

  //   const routes_new = [];

  //   const adress_f = (f.adress && f.adress.place_id) ? f.adress : routes[0]?.adress;
  //   const adress_s = (s.adress && s.adress.place_id) ? s.adress : routes[1]?.adress;

  //   if (f_v) routes_new[0] = ({ adress: adress_f, _id: 'place-adress-1' })
  //   if (s_v) routes_new[1] = ({ adress: adress_s, _id: 'place-adress-2' })

  //   if (f_v && s_v) {
  //     clearOrderRoute()
  //     localStorage.removeItem('odrerRoute')
  //     localStorage.removeItem('orderId')
  //     history.push('/order-route')
  //   }

  //   addRoutesOrderRoute(routes_new)
  // }, [inputs])

  const checkAdress = (e) => {
    const v = e.target.value
    if (v.trim() === '') {
      initMarker(geolocation.place_id, inputs[0]._id, changePointOrderRoute)
      map.setCenter(geolocation.geometry.location)
      setEditInput(null)
      setAdress(null)
    }
  }

  const changePointOrderRoute = async (_id, adress) => {
    const i = inputs.findIndex((e) => e._id === _id)
    const elem = inputs[i]

    if (inputs[i - 1] !== undefined) {
      const e = inputs[i - 1]
      if (e.adress.place_id === adress.place_id) {
        setModalOpen(true)
        return (elem.ref.current.value = '')
      }
    } else if (inputs[i + 1] !== undefined) {
      const e = inputs[i + 1]
      if (e.adress.place_id === adress.place_id) {
        setModalOpen(true)
        return (elem.ref.current.value = '')
      }
    }
    elem.adress = adress
    elem.ref.current.value = adress.formatted_address
    if (elem._id === inputs[0]._id) addFirstMarker(adress)
    setInputs([...inputs])
    // addRoutesOrderRoute(routes)
  }

  const addFirstMarker = (adress) => {
    if (adress?.place_id === geolocation?.place_id) return
    const marker = new window.google.maps.Marker({
      position: adress.geometry.location,
      map: map,
      icon: {
        url: MyGeolocation,
        scaledSize: new window.google.maps.Size(60, 60),
      },
    })
    editFirstMarket({ firstMarket: marker, firstAdress: adress })
    map.setCenter(adress.geometry.location)
  }

  const addMarkerMap = (name) => {
    if (editInput === null) return
    const geocoder = new window.google.maps.Geocoder()
    const center = map.getCenter()

    geocoder.geocode(
      {
        latLng: center,
      },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            editInput.ref.current && (editInput.ref.current.value = results[0].formatted_address)
            setAdress(results[0])
          }
        }
      }
    )
  }

  const addMarket = (adress) => {
    // console.log(adress);
    const marker = new window.google.maps.Marker({
      position: adress.geometry.location,
      map: map,
      icon: {
        url: MyGeolocation,
        scaledSize: new window.google.maps.Size(60, 60),
      },
    })
    editFirstMarket({ firstMarket: marker, firstAdress: adress })

    //? add logical remove
  }

  const saveAdress = () => {
    if (adress === null || editInput === null) return

    if (editInput._id === inputs[0]._id) {
      routes[0] = {
        _id: 'place-adress-1',
        adress,
      }
      addMarket(adress)
    } else {
      routes[1] = {
        _id: 'place-adress-2',
        adress,
      }
    }
    addRoutesOrderRoute(routes)
    setEditInput(null)
    setAdress(null)
    setInputs([...inputs])
  }

  const choiceAdressHistory = (point) => {
    const f = inputs[0]
    if (f.ref.current.value.trim() === '') initMarker(point.pointId, f._id, changePointOrderRoute)
    else {
      initMarker(point.pointId, inputs[1]._id, changePointOrderRoute)
    }
  }

  const startTravel = (e) => {
    clearOrderRoute()
    localStorage.removeItem('odrerRoute')
    localStorage.removeItem('orderId')
  }

  // const memoHistory = lastPoints.map((point) => {
  //   const formattedAddress = textFormator(point.name)
  //   return (
  //     <div
  //       key={point.pointId}
  //       className={cls.first_address}
  //       onClick={() => choiceAdressHistory(point)}
  //     >
  //       <div className={cls.address_text}>
  //         <div className={cls.history_icon}>
  //           <BackClock />
  //         </div>
  //         <div className={cls.favorite_addresses}>
  //           <p className={cls.main_text}>{formattedAddress.mainText}</p>
  //           <p className={cls.secondary_text}>{formattedAddress.secondaryText}</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // })

  return (
    <div className={cls.order_form} style={{ paddingBottom: '30px' }} {...handlers}>
      <ModalIdentityAdress isOpen={isModalOpen} onClose={closeModal} />
      <div className={cls.main_orders_window}>
        {currentGoogleMapCoordinates == null && (
          <div>
            <Loader />
          </div>
        )}
        {commonConfig?.mapProvider === 'google' && <OrderRoute map={map} />}
        <div style={{ display: 'none' }}>
          <div className={cls.orders_item}>
            <div className={cls.route_block}>
              <div
                className={
                  inputs[0].ref.current && inputs[0].ref.current.value.trim() === ''
                    ? cls.location_icon_empty
                    : cls.location_icon
                }
              >
                <UserLocation className={cls.icon_users} />
              </div>
              <input
                id="origin-input"
                className={cls.location_input}
                placeholder={t('write_geolocation')}
                type="text"
                // ref={input}
                onFocus={() => {
                  setEditInput(inputs[0])
                  firstMarket && firstMarket.setMap(null)
                }}
                onBlur={(e) => {
                  saveAdress()
                  checkAdress(e)
                }}
                // onBlur={checkAdress}
                ref={inputs[0].ref}
                onChange={changeFirstInput}
              />
            </div>
            <div className={cls.close_icon}>
              <Close onClick={handleClearInput} />
            </div>
          </div>
          <div
            // onClick={startTravel} //TODO call back it
            className={cls.orders_button}
          >
            <div
              className={
                inputs[1].ref.current && inputs[1].ref.current.value.trim() === ''
                  ? cls.location_icon_empty
                  : cls.location_icon
              }
            >
              <LocationPin />
            </div>
            <input
              id="destination-input-8"
              className={cls.location_input}
              placeholder={t('where_shall_we_go')}
              type="text"
              ref={inputs[1].ref}
              onFocus={() => setEditInput(inputs[1])}
              onBlur={saveAdress}
              // ref={secondInput}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
      {/* {isDetails && <div className={cls.addresses}>{memoHistory}</div>} */}
      {editInput && (
        <div id="market-place-map" className={cls.user_geolocation}>
          <img src={MyGeolocation} alt="" />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentGoogleMapCoordinates: state.odrerRoute.currentGoogleMapCoordinates,
    lastPoints: state.history.lastPoints,
    routes: state.odrerRoute.routes,
    geolocation: state.mapMarkers.geolocation,
    firstMarket: state.mapMarkers.firstMarket,
    firstAdress: state.mapMarkers.firstAdress,
    keyboardOpen: state.odrerRoute?.someValue,
  }
}

export default connect(mapStateToProps, {
  clearOrderRoute,
  changeStartPlaceOrderRoute,
  openAnotherComponentBottomMenu,
  addRoutesOrderRoute,
  editIdMapMarket,
  editFirstMarket,
  // selectedMarketBottomMenu
})(StartSearchPlace)
