import React, { memo } from 'react'
import Authentication from '../../components/Authentication'
import { Navigate } from 'react-router-dom'

const AuthenticationPage = memo(() => {
  const accesToken = localStorage.getItem('accessToken')

  if (accesToken) {
    return <Navigate to="/order" />
  }
  return <Authentication />
})

export default AuthenticationPage
