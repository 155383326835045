import React, { memo } from "react";
import { classNames } from "../../shared/lib/classNames/classNames";
import { ReactComponent as LocationPin } from "../../images/LocationPin.svg";
import { ReactComponent as WaypointPin } from "../../shared/assets/icons/WaypointPin.svg";
import cls from "./addressStripHistory.module.scss";

const AddressStripHistory = ({ route, statusId, className, isDetailHistory }) => {
	const isFailedTrip = statusId === 2 || statusId === 32;

	const mods = {
		[cls.failureTrip]: isFailedTrip,
		[cls.detailHistory]: isDetailHistory,
	};

	return (
		<>
			{route?.map(({ name }, idx) => {
				const isStartOrEnd = idx === 0 || idx === route.length - 1;
				const iconClass = isStartOrEnd ? cls.locationIcon : cls.waypointIcon;

				return (
					<div className={classNames(cls.AddressStripHistoryWrapper, mods, [className])} key={idx}>
						<div className={cls.placeContent}>
							{isStartOrEnd ? (
								<LocationPin className={iconClass} width={22} height={22} />
							) : (
								<WaypointPin className={iconClass} width={20} height={20} />
							)}
							<p className={cls.place}>{name}</p>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default memo(AddressStripHistory);
