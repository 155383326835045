import React, { memo } from 'react'
import { Navigate } from 'react-router-dom'
import Forgot from '../../components/Forgot'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'

/**
 * Страница восстановления пароля
 *
 * @returns {*}
 * @constructor
 */

const ForgotPage = memo(() => {
  const accesToken = localStorage.getItem('accessToken')

  if (accesToken) {
    return <Navigate to="/order" />
  }

  return (
    <>
      <PageHeader title={t('recover_password')} />
      <PageLayout>
        <Forgot />
      </PageLayout>
    </>
  )
})

export default ForgotPage
