/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
  
  /* Method toget non-empty geo points from array */
  const getNonEmptyPoints = (arr) => {
    let result = arr.filter(function (point) {
      return Object.keys(point).length !== 0;
    });
    return result;
  };
  const getWaypoints = (arr) => {
    return arr.map((item) => {
      return {
        location : { placeId: item.place_id},
        // location:`${item.lat} ${item.lon}`,
        // location: item.name, // `${geoPoints[0].lat} ${geoPoints[0].lon}`
        stopover: true,
      };
    });
  };

export const getGoogleTravel = async (routes, callback) => {
   const directionsService = new window.google.maps.DirectionsService();
   const geoPoints = getNonEmptyPoints(routes);

 return await directionsService.route(
    {
      origin: { placeId: geoPoints[0].place_id },
      destination: { placeId: geoPoints[geoPoints.length - 1].place_id },
      waypoints: getWaypoints(geoPoints.slice(1, -1)),
      optimizeWaypoints: true,
      travelMode: window.google.maps.TravelMode.DRIVING,
    },
    async function (response, status) {
      if (status === "OK") {
        // console.log(
        //   "Distance :",
        //   response.routes["0"].legs["0"].distance.value
        // );
        // console.log(response);
        return response

      } else {
        callback && callback()
        console.log("Get distance error:", status, response);

        return {status : null}
      }
    }
  );
}


