import { useCallback, useEffect, useRef, useState } from "react";
import { nameComponents } from "src/components/BottomMenu/bottom.navigate.action";
import { findSegment } from "src/components/CustomMap/helpers/findSegment";

const useFromLocalSore = (props) => {
	const {
		setMapPoints,
		setRoadPoints,
		createPoints,
		createRoutes,
		setPolylinePositions,
		getOldOrderRoute,
		getOldOrderCost,
		openAnotherComponentBottomMenu,
		acceptedDriverCoordinates,
		orderStatus,
		polylinePositions,
	} = props;

	const [routeToClient, setRouteToClient] = useState(null);
	const coordsCheckInterval = useRef(null);

	useEffect(() => {
		coordsCheckInterval.current = setInterval(async () => {
			if (polylinePositions) {
				if (acceptedDriverCoordinates?.lat) {
					const editedCoordsDriver = [acceptedDriverCoordinates.lon, acceptedDriverCoordinates.lat];

					const distanceInMeters = findSegment(
						polylinePositions,
						editedCoordsDriver,
					)?.distanceInMeters;

					if (distanceInMeters > 40) {
						setRouteToClient(acceptedDriverCoordinates);
					}
				} else {
					clearInterval(coordsCheckInterval.current);
				}
			}
		}, 200);

		return () => {
			clearInterval(coordsCheckInterval.current);
		};
	}, [acceptedDriverCoordinates, polylinePositions]);

	const createRoute = useCallback(
		async (routes) => {
			const { start, end } = createPoints(routes);
			const points = routes.map((item) => [item.lon, item.lat]);
			setMapPoints(points);

			if (orderStatus.status === 1) {
				const routesOnlyToClient = routes.slice(1);
				setRoadPoints(routesOnlyToClient);
			} else {
				setRoadPoints(routes);
			}

			const { formattedCoordinates } = await createRoutes(start, end);
			setPolylinePositions(formattedCoordinates);
		},
		[
			createPoints,
			createRoutes,
			orderStatus.status,
			setMapPoints,
			setPolylinePositions,
			setRoadPoints,
		],
	);

	useEffect(() => {
		if (!routeToClient?.lat && !routeToClient?.lon) {
			setRouteToClient(acceptedDriverCoordinates);
		}
	}, [acceptedDriverCoordinates, routeToClient]);

	useEffect(() => {
		const setFromLocalStore = async () => {
			const watingAdress = localStorage.getItem("watingAdress")
				? JSON.parse(localStorage.getItem("watingAdress"))
				: null;

			const odrerRoute = localStorage.getItem("odrerRoute")
				? JSON.parse(localStorage.getItem("odrerRoute"))
				: null;

			// step - choose CAR_PRICE
			if (watingAdress) {
				const { routes } = watingAdress.odrerRoute;
				createRoute(routes);
				getOldOrderRoute(watingAdress.odrerRoute);
				getOldOrderCost(watingAdress.costOrders);
				openAnotherComponentBottomMenu(nameComponents.CAR_PRICE);
				return;
			}

			// step - ORDER_PROGRESS
			if (odrerRoute && orderStatus.status > 1) {
				const routes = odrerRoute.routes;
				createRoute(routes);
			}

			// Draw route from driver to client
			if (odrerRoute && orderStatus.status === 1 && routeToClient.lat && routeToClient.lon) {
				const routes = odrerRoute.routes;
				const driverToClientCoords = [
					{ id: "driver", lat: routeToClient?.lat, lon: routeToClient?.lon },
					{ id: routes[0].id, name: routes[0].name, lat: routes[0].lat, lon: routes[0].lon },
				];

				createRoute(driverToClientCoords);
			}
		};

		setFromLocalStore();
	}, [
		createRoute,
		getOldOrderCost,
		getOldOrderRoute,
		openAnotherComponentBottomMenu,
		setMapPoints,
		setPolylinePositions,
		setRoadPoints,
		orderStatus.status,
		routeToClient,
	]);

	return null;
};

export default useFromLocalSore;
