import {
  GET_HISTORY_PENDING,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_ERROR,
  GET_LAST_RIDE_PENDING,
  GET_LAST_RIDE_SUCCESS,
  GET_LAST_RIDE_ERROR,
} from './history.action'

const initialState = {
  historyData: [],
  historyLoaded: false,
  historyError: false,
  lastPoints: [],
}

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_PENDING: {
      return {
        ...state,
        historyLoaded: true,
      }
    }
    case GET_HISTORY_SUCCESS: {
      return {
        ...state,
        historyLoaded: false,
        historyData: action.payload,
      }
    }
    case GET_HISTORY_ERROR: {
      return {
        ...state,
        historyError: true,
      }
    }
    case GET_LAST_RIDE_PENDING: {
      return {
        ...state,
        historyLoaded: true,
      }
    }
    case GET_LAST_RIDE_SUCCESS: {
      return {
        ...state,
        historyLoaded: false,
        lastPoints: action.payload,
      }
    }
    case GET_LAST_RIDE_ERROR: {
      return {
        ...state,
        historyError: true,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default historyReducer
