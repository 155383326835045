import React, { memo, useCallback } from 'react'
import { t } from 'i18next'
import cls from './historyDetailPaid.module.scss'
import { getValuteSymbol } from '../../../CurrencyDefinition/getValuteSymbol'

const HistoryDetailPaid = memo((props) => {
  const {
    totalQuote,
    pendingsDuration,
    pendingsQuote,
    orderPreferences,
    paymentTypeId,
    priceRised,
    currency,
    statusId,
  } = props

  const renderOrderPreferences = () => {
    return orderPreferences?.map((pref) => (
      <div className={cls.item} key={pref.id}>
        <span className={cls.label}>{t(pref.name)}</span>
        <p className={cls.amount}>
          {pref.price}
          <span className={cls.currency}>{getValuteSymbol(currency)}</span>
        </p>
      </div>
    ))
  }

  const getTypeOfPaid = useCallback((paymentTypeId) => {
    let typePaid
    switch (paymentTypeId) {
      case 0:
        typePaid = t('cash')
        break
      case 1:
        typePaid = t('card')
        break
      case 2:
        typePaid = t('cash_and_bonuses')
        break
      case 4:
        typePaid = t('card_and_bonuses')
        break
      default:
        typePaid = ''
    }

    return typePaid
  }, [])

  return (
    <>
      <div className={cls.totalPrice}>
        <h1>{statusId === 2 ? t('payable_to') : t('paid')}</h1>
        <p>
          {totalQuote}
          <span className={cls.currency}>{getValuteSymbol(currency)}</span>
        </p>
      </div>
      <div className={cls.listPrice}>
        <div className={cls.item}>
          <span className={cls.label}>{t('payment_method')}</span>
          <p className={cls.amount}>{getTypeOfPaid(paymentTypeId)}</p>
        </div>
        {Boolean(priceRised) && (
          <div className={cls.item}>
            <span className={cls.label}>{t('price_raised')}</span>
            <p className={cls.amount}>{priceRised}</p>
          </div>
        )}
        {/* //TODO */}
        {/* <div className={cls.item}>
          <span className={cls.label}>{t('tariff')}</span>
          <p className={cls.amount}>
            10<span className={cls.currency}>₴</span>
          </p>
        </div> */}
        {Boolean(pendingsQuote) && (
          <div className={cls.item}>
            <div className={cls.leftContent}>
              <div>
                <span className={cls.label}>{t('waiting_fee')}</span>
                <span className={cls.dot}>.</span>
              </div>
              <div className={cls.dotTimeContainer}>
                <span className={cls.amount}>{`${pendingsDuration} ${t('min')}`}</span>
              </div>
            </div>
            <div className={cls.rightContent}>
              <div>
                <p className={cls.amount}>
                  {pendingsQuote}
                  <span className={cls.currency}>₴</span>
                </p>
              </div>
            </div>
          </div>
        )}
        {renderOrderPreferences()}
        {/* //TODO */}
        {/* <div className={classNames(cls.item, {}, [[cls.itemDiscount]])}>
          <span className={cls.label}>{t('discount')}</span>
          <p className={cls.amount}>
            10<span className={cls.currency}>₴</span>
          </p>
        </div> */}
      </div>
    </>
  )
})

export default HistoryDetailPaid
