import React from 'react';
import cls from './iconStrip.module.scss';
import { ReactComponent as MapPin } from '../../images/MapPin.svg';
import { ReactComponent as LocationPin } from '../../images/LocationPin.svg';

const IconStrip = ({ iconCount, secondaryTextLength }) => {

    const isSmallIcon = secondaryTextLength < 2;

    const icons = Array.from({ length: iconCount }, (_, index) => {
        const iconName = index === 0 || index === iconCount - 1 ? <LocationPin /> : <MapPin />;
        const iconClasses = isSmallIcon ? `${cls.icon} ${cls.icon_small}` : cls.icon;
        return (
            <div key={index} className={iconClasses}>
                {iconName}
            </div>
        );
    });

    return <div className={cls.icon_strip}>{icons}</div>;
};

export default IconStrip;
