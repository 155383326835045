import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import {
	changeModalBottomMenu,
	nameComponents,
	openAnotherComponentBottomMenu,
} from "src/components/BottomMenu/bottom.navigate.action";
import {
	getCarClassTimeInfo,
	getOldOrderCost,
	getOrderCost,
	updPos,
} from "src/components/MapJs/order.action";
import { ReactComponent as MasterCard } from "../../images/MasterCardIcon.svg";
import {
	addOrderDistance,
	addRoutesOrderRoute,
	changeCommentOrderRoute,
	changePaymentTypeOrderRoute,
	changeTravelRotesOrderRoute,
	clearOrderRoute,
	getOldOrderRoute,
	paymentTypes,
} from "src/components/OrderRoute/order.route.action";
import { getCurrentOrder } from "src/components/SocketConnection/socket.action";

import { AddressPointsList } from "src/components/AddressComponents";
import {
	fetchDirection,
	typeOfError,
} from "src/components/AddressComponents/helpers/fetchDirection";
import { fetchGeocodeFromCoords } from "src/components/AddressComponents/helpers/fetchGeocodeFromCoords";
import { getNonEmptyPoints } from "src/components/AddressComponents/helpers/getNonEmptyPoints";
import CustomMap from "src/components/CustomMap";
import { createRoutes } from "src/components/CustomMap/helpers/createRoutes";
import { getUserData } from "src/components/ProfileForm/profile.action";
import { Modal } from "../../components/ModalCarNotFound";

import styles from "./style.module.scss";

import CarPricesCustomMap from "src/components/BottomMenu/components/CarPricesCustomMap";
import OrderAccepted from "src/components/BottomMenu/components/OrderAccepted";
import OrderCompleted from "src/components/BottomMenu/components/OrderCompleted";
import OrderExpect from "src/components/BottomMenu/components/OrderExpect";
import OrderProgress from "src/components/BottomMenu/components/OrderProgress";
import OrderReview from "src/components/BottomMenu/components/OrderReview";
import SearchCar from "src/components/BottomMenu/components/SearchСar";
import { getCities, getPreferences } from "src/components/Dictionarys/dictionary.action";
import useFromLocalSore from "./hooks/useFromLocalSore";

import OrderDiscount from "src/components/AddressComponents/components/OrderDiscount";
import { fetchDiscount } from "src/components/AddressComponents/helpers/fetchDiscount";
import { getFavoriteAddress } from "src/components/FavoriteAddress/favorite.action";
import { getLastRide } from "src/components/History/store/history.action";
import { sendCoordsToRpc } from "src/components/Registration/registration.action";
import { updateCoordsIntervalMs } from "src/config-in-git";
import Loader from "src/shared/ui/Loader/Loader";
import { getValuteSymbol } from "src/services/getValuteSymbol";
import { parseLocalStorageItem } from "src/shared/helpers/parseLocalStorageItem";
import { useCoordinatesUtils } from "src/shared/hooks/useCoordinatesUtils";
import { maxOrderDistance } from "src/shared/variables";
import { useMap } from "./context/MapContext";
import { setCardToFormat } from "src/components/OrderPayment/helpers/setCardToFormat";
import { getUserCards } from "src/components/OrderPayment/store/ccard.action";
import OrderAddresses from "src/components/AddressComponents/components/OrderAddresses";
import FirstPlace from "src/components/AddressComponents/components/FirstPlace";
import MapPreferences from "src/components/MapPreferences";
import MapComment from "src/components/MapComment";
import PlaceOnMap from "src/components/AddressComponents/components/PlaceOnMap";
import { onContentLoaded } from "src/shared/callbacks";
import { getPointsCountryData } from "src/pages/OrderPage/api/getPointsCountryData";

const createPoints = (geoPoints) => {
	const start = [];
	const end = [];
	for (let i = 1; i < geoPoints.length; i++) {
		const current = geoPoints[i];
		const previous = geoPoints[i - 1];

		if (current.lat && current.lon && previous.lat && previous.lon) {
			start.push(`${previous.lon},${previous.lat}`);
			end.push(`${current.lon},${current.lat}`);
		}
	}

	return { start, end };
};

const MapWrapper = (props) => {
	const {
		theme,
		getUserData,
		getCurrentOrder,
		openAnotherComponentBottomMenu,
		getOldOrderCost,
		getOrderCost,
		getOldOrderRoute,
		nameComponent,
		updPos,
		odrerRoute,
		costOrders,
		addRoutesOrderRoute,
		getPreferences,
		getCities,
		getUserCards,
		sendCoordsToRpc,
		getCarClassTimeInfo,
		addOrderDistance,
		registrationCountryInfo,
		acceptedDriverCoordinates,
		orderStatus,
		paymentType,
		changePaymentTypeOrderRoute,
		userCardsList,
		nameModal,
		symbol,
		clearOrderRoute,
		routes,
		commentMessage,
		changeCommentOrderRoute,
		tariffCar,
		nearDriversCoords,
	} = props;

	const [roadPoints, setRoadPoints] = useState([{ id: 0 }, { id: 1 }]);
	const [isShowMap, setIsShowMap] = useState(true);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [zoomFullRoute, setZoomFullRoute] = useState(false);
	const [mapPoints, setMapPoints] = useState(null);
	const [isMapMove, setIsMapMove] = useState(false);
	const [isCorrectDistance, setIsCorrectDistance] = useState(true);
	const [isWorkCountry, setIsWorkCountry] = useState(true);
	const [polylinePositions, setPolylinePositions] = useState(null);
	const [isAutocompleteSelection, setIsAutocompleteSelection] = useState(false);
	const [isCurrentLocation, setIsCurrentLocation] = useState(true);
	const [isInputHighlighted, setInputHighlighted] = useState(null);
	const [suggestionPlaces, setSuggestionPlaces] = useState([]);
	const [canCreateRoute, setCanCreateRoute] = useState(false);
	const [discount, setDiscount] = useState({ type: null, maxDiscount: null });
	const [checkBonus, setCheckBonus] = useState(false);
	const [price, setPrice] = useState(
		tariffCar?.estimatedPrice || tariffCar?.estimatedPriceBeforeVat || 0,
	);
	const estimatedPrice = tariffCar?.estimatedPrice || 0;

	const closeMap = useCallback(() => setIsShowMap(false), []);
	const openMap = useCallback(() => setIsShowMap(true), []);

	const phonePatternUA = new RegExp(/^\+380\d{9}$/);

	const { setIsInputActive, isPriceCalculated } = useMap();

	const updateLocalStorage = useRef(null);

	const { coordsClient, checkLocalStorageCoords, updateLocalStorageCoords } = useCoordinatesUtils();

	const [openDescription, setOpenDescription] = useState(false);

	const commonConfig = useMemo(() => JSON.parse(localStorage.getItem("commonConfig")), []);
	// check client coords in local storage
	useEffect(() => {
		updateLocalStorage.current = setInterval(() => {
			const coordsClient = parseLocalStorageItem("coords");

			if (!coordsClient) {
				checkLocalStorageCoords();
			}

			if (coordsClient?.latitude && coordsClient?.longitude) {
				updateLocalStorageCoords();
			}
		}, updateCoordsIntervalMs);

		return () => clearInterval(updateLocalStorage.current);
	}, [checkLocalStorageCoords, updateLocalStorageCoords]);

	useEffect(() => {
		updPos({
			lat: coordsClient?.latitude,
			lon: coordsClient?.longitude,
		});
	}, [coordsClient?.latitude, coordsClient?.longitude, updPos]);

	const handleCloseDescription = (e) => {
		if (!e.target.closest(".question_svg_icon")) {
			setOpenDescription(false);
		}
	};

	// full route positions
	const getPolyline = useCallback(async () => {
		const geoPoints = getNonEmptyPoints(roadPoints);

		if (geoPoints.length < 1) {
			return;
		}
		const { start, end } = createPoints(geoPoints);
		const points = geoPoints.map((item) => [item.lon, item.lat]);
		const { formattedCoordinates, fullDistance } = await createRoutes(start, end);
		// order distance to store
		addOrderDistance(fullDistance);

		setPolylinePositions(formattedCoordinates);
		setZoomFullRoute(true);
		setMapPoints(points);
	}, [addOrderDistance, roadPoints]);

	useEffect(() => {
		const updateRoute = async () => {
			await Promise.all([getPolyline()]);
		};

		if (!canCreateRoute) {
			updateRoute();
			setCanCreateRoute(true);
		}
	}, [canCreateRoute, getPolyline]);
	// ! setCanCreateRoute(false) использовать в тот момент когда нужно откріть компоненту с картой и есть адресса
	// calculate price
	const getRoutesPrice = async (roadPointsParam = roadPoints) => {
		setIsCorrectDistance(true);
		setIsWorkCountry(true);

		const geoPoints = getNonEmptyPoints(roadPointsParam);
		const coordinates = geoPoints.map((point) => [point.lon, point.lat]);

		const directionResponse = await fetchDirection(coordinates);
		if (typeOfError.ERROR_CREATE_ROUTE === directionResponse) {
			setIsWorkCountry(false);
			return;
		}

		if (directionResponse.routes[0].summary.distance > maxOrderDistance) {
			setIsCorrectDistance(false);
			return;
		}

		const countryCode = localStorage.getItem("country_code");
		const clientPhone = props.userData?.phone;
		const orderInfoData = {
			...(phonePatternUA.test(clientPhone) && { clientPhone }),
			directionResponse,
			orderPreferencesSum: 0,
			originCountryCode: countryCode,
			mapProvider: commonConfig.mapProvider,
		};

		await getOrderCost(orderInfoData);

		addRoutesOrderRoute(roadPointsParam);
		openMap();
		openAnotherComponentBottomMenu(nameComponents.CAR_PRICE);
	};
	const removeActiveInput = async (id) => {
		//
		setRoadPoints((prevRoadPoints) => {
			const updatedPoints = prevRoadPoints.map((point) => {
				// if (point.id === id) {
				return { ...point, active: false };
				// }
				// return point;
			});

			return updatedPoints;
		});
	};

	// get car time for do order
	const getCarArrivalTime = useCallback(async () => {
		const firstPoint = roadPoints[0];

		const requestParams = {
			destinationPoints: {
				lat: firstPoint.lat,
				lon: firstPoint.lon,
			},
			orderPreferences: [],
		};

		await getCarClassTimeInfo(requestParams);
	}, [getCarClassTimeInfo, roadPoints]);

	const handleAnimationSelectedInput = (pointId) => {
		setInputHighlighted(pointId);
		setTimeout(() => {
			setInputHighlighted(null);
		}, 1000);
	};

	const checkStartRoutePoint = useCallback(
		async (lat, lng, shouldUpdateData = true) => {
			const result = await sendCoordsToRpc(`${lat} ${lng}`, true, false, shouldUpdateData);
			return result;
		},
		[sendCoordsToRpc],
	);
	// Getting a possible customer discount
	const getDiscount = useCallback(async (lat, lon) => {
		const point = {
			lat,
			lon,
		};

		const { success, data } = await fetchDiscount(point);

		if (success && data) {
			const discountData = {
				type: data.discount_type,
				maxDiscount: Math.max(...Object.values(data.discount_value)),
			};

			setDiscount(discountData);
		} else {
			setDiscount({ type: null, maxDiscount: null });
		}

		return success;
	}, []);

	const setPoint = useCallback(async (point, pointId) => {
		let districtId = null;
		if (point.lat && point.lon) {
			const coordsData = `${point.lat} ${point.lon}`;
			const rpcData = await getPointsCountryData({ coordsData, isAuthentication: true });
			districtId = rpcData.data.country?.district_id || null;
		}

		let finalPoint = point;
		if (districtId) {
			finalPoint = { ...finalPoint, districtId };
		}

		setRoadPoints((prevRoadPoints) =>
			prevRoadPoints.map((item) => {
				if (item.id === pointId && point.lat && point.lon) {
					handleAnimationSelectedInput(pointId);
				}

				return item.id === pointId ? { ...item, ...finalPoint } : item;
			}),
		);
	}, []);

	// took coords from map and get location
	// получать по кооординатам адрес обьектом
	const setPointByCoords = async (coords, pointId) => {
		const reverseGeocodeParams = {
			point: {
				lat: coords?.lat,
				lon: coords?.lng,
			},
			registrationCountryInfo,
		};

		const { data } = await fetchGeocodeFromCoords(reverseGeocodeParams);

		const point = {
			lat: reverseGeocodeParams.point?.lat,
			lon: reverseGeocodeParams.point?.lon,
			name: data,
		};

		setPoint(point, pointId);
	};

	const setDefaultPaymentCard = useCallback(() => {
		const selectedPaymentId = localStorage.getItem("selectedPaymentId");
		const isCardPayment = selectedPaymentId !== "cash" && paymentType.type !== paymentTypes.CARD;

		if (!selectedPaymentId) {
			return;
		}

		if (isCardPayment) {
			for (const cardsValues of Object.values(userCardsList)) {
				if (Array.isArray(cardsValues)) {
					const cardElement = cardsValues.find((card) => card.id === selectedPaymentId);

					if (cardElement) {
						const cardData = setCardToFormat(cardElement, MasterCard);
						changePaymentTypeOrderRoute(cardData);
						break;
					}
				}
			}
		}
	}, [changePaymentTypeOrderRoute, paymentType.type, userCardsList]);

	const openFirstHistory = async (placeHistory) => {
		roadPoints[1] = { ...placeHistory, id: 1 };
		await setRoadPoints([...roadPoints]);
		await getPolyline();
		await getRoutesPrice(roadPoints);
	};

	const Component = () => {
		switch (nameComponent) {
			case nameComponents.START_SEARCH_PLACE:
				return (
					<div className={styles.MapPointsWrapper}>
						<OrderDiscount
							discount={discount.maxDiscount}
							discountType={discount.type}
							symbol={symbol}
						/>
						<FirstPlace
							openFirstHistory={openFirstHistory}
							closeMap={closeMap}
							openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
							nameComponents={nameComponents}
						/>
					</div>
				);
			case nameComponents.ORDER_ADDRESSES:
				return (
					<OrderAddresses
						setPoint={setPoint}
						roadPoints={roadPoints}
						setRoadPoints={setRoadPoints}
						getPolyline={getPolyline}
						passengerCoords={[coordsClient?.longitude, coordsClient?.latitude]}
						getRoutesPrice={getRoutesPrice}
						isCorrectDistance={isCorrectDistance}
						isWorkCountry={isWorkCountry}
						setIsAutocompleteSelection={setIsAutocompleteSelection}
						isCurrentLocation={isCurrentLocation}
						isInputHighlighted={isInputHighlighted}
						setSuggestionPlaces={setSuggestionPlaces}
						suggestionPlaces={suggestionPlaces}
						getCarArrivalTime={getCarArrivalTime}
						setIsMapMove={setIsMapMove}
						checkStartRoutePoint={checkStartRoutePoint}
						getDiscount={getDiscount}
						setCanCreateRoute={setCanCreateRoute}
						mapLoaded={mapLoaded}
						openMap={openMap}
						clearOrderRoute={clearOrderRoute}
						removeActiveInput={removeActiveInput}
						openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
						nameComponents={nameComponents}
					/>
				);
			case nameComponents.PLACE_ON_MAP:
				return (
					<PlaceOnMap
						setPoint={setPoint}
						roadPoints={roadPoints}
						setRoadPoints={setRoadPoints}
						getPolyline={getPolyline}
						passengerCoords={[coordsClient?.longitude, coordsClient?.latitude]}
						getRoutesPrice={getRoutesPrice}
						isCorrectDistance={isCorrectDistance}
						isWorkCountry={isWorkCountry}
						setIsAutocompleteSelection={setIsAutocompleteSelection}
						isCurrentLocation={isCurrentLocation}
						isInputHighlighted={isInputHighlighted}
						setSuggestionPlaces={setSuggestionPlaces}
						suggestionPlaces={suggestionPlaces}
						getCarArrivalTime={getCarArrivalTime}
						setIsMapMove={setIsMapMove}
						checkStartRoutePoint={checkStartRoutePoint}
						getDiscount={getDiscount}
						setCanCreateRoute={setCanCreateRoute}
						mapLoaded={mapLoaded}
						closeMap={closeMap}
						clearOrderRoute={clearOrderRoute}
						removeActiveInput={removeActiveInput}
						nameComponents={nameComponents}
						openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
					/>
				);
			case nameComponents.CAR_PRICE:
				return (
					<CarPricesCustomMap
						routes={getNonEmptyPoints(roadPoints)}
						odrerRoute={odrerRoute}
						costOrders={costOrders}
						getCarArrivalTime={getCarArrivalTime}
						closeMap={closeMap}
						checkBonus={checkBonus}
						setCheckBonus={setCheckBonus}
						openDescription={openDescription}
						setOpenDescription={setOpenDescription}
						price={price}
						setPrice={setPrice}
						estimatedPrice={estimatedPrice}
					/>
				);
			case nameComponents.ORDER_PREFERENCES:
				return (
					<MapPreferences
						openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
						nameComponents={nameComponents}
						routes={getNonEmptyPoints(roadPoints)}
						odrerRoute={odrerRoute}
						costOrders={costOrders}
						getCarArrivalTime={getCarArrivalTime}
						closeMap={closeMap}
						checkBonus={checkBonus}
						setCheckBonus={setCheckBonus}
						price={price}
						setPrice={setPrice}
						estimatedPrice={estimatedPrice}
					/>
				);
			case nameComponents.ORDER_COMMENT:
				return (
					<MapComment
						openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
						nameComponents={nameComponents}
						routes={routes}
						commentMessage={commentMessage}
						changeCommentOrderRoute={changeCommentOrderRoute}
					/>
				);
			case nameComponents.SEARCH_CAR:
				return <SearchCar />;
			case nameComponents.ORDER_ACCEPTED:
				return <OrderAccepted routes={getNonEmptyPoints(odrerRoute.routes)} />;
			case nameComponents.ORDER_EXPECT:
				return <OrderExpect />;
			case nameComponents.ORDER_PROGRESS:
				return <OrderProgress />;
			case nameComponents.ORDER_COMPLETED:
				return <OrderCompleted />;
			case nameComponents.ORDER_REVIEW:
				return <OrderReview />;
			default:
				return null;
		}
	};

	const getActiveDriverLocation = useCallback(() => {
		if (acceptedDriverCoordinates?.lat && acceptedDriverCoordinates?.lon) {
			return [acceptedDriverCoordinates.lon, acceptedDriverCoordinates.lat];
		}

		return null;
	}, [acceptedDriverCoordinates]);

	// get data from local storage
	useFromLocalSore({
		setMapPoints,
		setRoadPoints,
		createPoints,
		createRoutes,
		setPolylinePositions,
		getOldOrderRoute,
		getOldOrderCost,
		openAnotherComponentBottomMenu,
		acceptedDriverCoordinates,
		orderStatus,
		polylinePositions,
	});
	useEffect(() => {
		if (registrationCountryInfo?.country_code) {
			const countryCode = localStorage.getItem("country_code");
			getUserData(registrationCountryInfo?.country_code || countryCode);
		}
	}, [getUserData, registrationCountryInfo?.country_code]);

	// get full data for map
	useEffect(() => {
		const countryCode = localStorage.getItem("country_code");
		getUserCards();
		getPreferences(countryCode);
		getCities();
	}, [getCities, getPreferences, getUserCards]);

	// Set the default payment card that the user has selected
	useEffect(() => {
		setDefaultPaymentCard();
	}, [setDefaultPaymentCard]);

	// get address by client coordinates
	useEffect(() => {
		const reverseGeocodeParams = {
			point: {
				lat: coordsClient?.latitude,
				lon: coordsClient?.longitude,
			},
			registrationCountryInfo,
		};

		const firstPoint = 0;

		if (nameComponent === nameComponents.START_SEARCH_PLACE && !roadPoints[0]?.firstLoad) {
			const inOrder = localStorage.getItem("orderStage");

			const getPointFromGeocode = async () => {
				const successful = await checkStartRoutePoint(
					reverseGeocodeParams.point.lat,
					reverseGeocodeParams.point.lon,
				);

				if (!successful) {
					setIsWorkCountry(false);
				}

				const { data } = await fetchGeocodeFromCoords(reverseGeocodeParams);

				setPoint(
					{
						lat: reverseGeocodeParams.point.lat,
						lon: reverseGeocodeParams.point.lon,
						name: data,
						firstLoad: true,
					},
					firstPoint,
				);
			};

			const getDiscountFromGeocode = async () => {
				await getDiscount(reverseGeocodeParams.point.lat, reverseGeocodeParams.point.lon);
			};

			if (reverseGeocodeParams.point.lat && reverseGeocodeParams.point.lon && !inOrder) {
				getPointFromGeocode();
				getDiscountFromGeocode();
			}
		}
	}, [
		checkStartRoutePoint,
		coordsClient?.latitude,
		coordsClient?.longitude,
		getDiscount,
		nameComponent,
		roadPoints,
		setPoint,
		registrationCountryInfo.country_code,
		registrationCountryInfo,
	]);

	// check current order status
	useEffect(() => {
		const orderId = localStorage.getItem("orderId");

		if (orderId) {
			getCurrentOrder(orderId);
		}
	}, [getCurrentOrder]);

	// inform app about finish map load
	useEffect(() => {
		if (mapLoaded) {
			onContentLoaded();
		}
	}, [mapLoaded]);

	const overShow = isShowMap ? styles.overflowMap : styles.dontShowMapOverflow;
	const dontOverShow = isShowMap ? styles.overflowMapNone : styles.dontShowMap;

	const overflowMapClass =
		nameComponent === nameComponents.START_SEARCH_PLACE ? overShow : dontOverShow;

	const nearDrivers = useMemo(() => {
		const result = new Map();

		nearDriversCoords?.forEach((item) => {
			// create a DOM element for the marker
			const markerElement = document.createElement("div");
			markerElement.className = "marker";
			markerElement.style.backgroundImage = `url(/img/car-marker.svg)`;
			markerElement.style.width = `30px`;
			markerElement.style.height = `40px`;

			result.set(item.driverId, {
				coords: [item.longitude, item.latitude],
				id: item.driverId,
				markerElement,
			});
		});

		return result;
	}, [nearDriversCoords]);

	return (
		<div className={overflowMapClass} onClick={handleCloseDescription}>
			{Boolean(coordsClient) ? (
				<div className={styles.MapWrapper} style={{ display: isShowMap ? "block" : "none" }}>
					<CustomMap
						discount={discount.maxDiscount}
						coordsDriver={getActiveDriverLocation()}
						arrayIcons={nearDrivers}
						passengerCoords={[coordsClient?.longitude, coordsClient?.latitude]}
						mapLoaded={mapLoaded}
						setMapLoaded={(value) => setMapLoaded(value)}
						theme={theme}
						polylinePositions={polylinePositions}
						zoomFullRoute={zoomFullRoute}
						mapPoints={mapPoints}
						setZoomFullRoute={setZoomFullRoute}
						roadPoints={roadPoints}
						setPointByCoords={setPointByCoords}
						isAutocompleteSmarkerElementection={isAutocompleteSelection}
						setIsAutocompleteSelection={setIsAutocompleteSelection}
						setIsCurrentLocation={setIsCurrentLocation}
						setSuggestionPlaces={setSuggestionPlaces}
						setIsMapMove={setIsMapMove}
						isMapMove={isMapMove}
						setIsInputActive={setIsInputActive}
						isPriceCalculated={isPriceCalculated}
						disabledFunctions={{
							disabledDrawArrayMarkers: nameComponent === nameComponents.SEARCH_CAR,
							disableButtons:
								nameComponent === nameComponents.CAR_PRICE ||
								nameComponent === nameComponents.ORDER_COMMENT,
							disableMarkerAnimation: nameComponent !== nameComponents.SEARCH_CAR,
							disableClientMarker: nameComponent === nameComponents.SEARCH_CAR,
						}}
					/>
				</div>
			) : (
				<Loader />
			)}
			{Component()}
			{nameModal && (
				<Modal
					nameModal={nameModal}
					changeModalBottomMenu={changeModalBottomMenu}
					openAnotherComponentBottomMenu={openAnotherComponentBottomMenu}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	theme: state.theme.theme,
	userData: state.profile.userData,
	nameComponent: state.bottomNavigate.nameComponent,
	acceptedDriverCoordinates: state.socket.acceptedDriverCoordinates,
	odrerRoute: state.odrerRoute,
	costOrders: state.order.costOrders,
	sumDopServises: state.odrerRoute.sumDopServises,
	orderStatus: state.socket.orderStatus,
	registrationCountryInfo: state.registration.registrationCountryInfo,
	paymentType: state.odrerRoute.paymentType,
	userCardsList: state.ccard.userCardsList,
	nameModal: state.bottomNavigate.nameModal,
	symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
	lastPoints: state.history.lastPoints,
	homeAddress: state.profile?.userData.homeAddress,
	workAddress: state.profile?.userData.workAddress,
	preferences: state.dictionary.preferences,
	routes: state.odrerRoute.routes,
	commentMessage: state.odrerRoute.commentMessage,
	tariffCar: state.odrerRoute.tariffCar,
});

const mapDispatchToProps = (dispatch) => {
	return {
		getOrderCost: (orderInfo) => dispatch(getOrderCost(orderInfo)),
		getUserData: (data) => dispatch(getUserData(data)),
		openAnotherComponentBottomMenu: (component) =>
			dispatch(openAnotherComponentBottomMenu(component)),
		updPos: (data) => dispatch(updPos(data)),
		addRoutesOrderRoute: (routes) => dispatch(addRoutesOrderRoute(routes)),
		getCurrentOrder: (orderId) => dispatch(getCurrentOrder(orderId)),
		getOldOrderRoute: (route) => dispatch(getOldOrderRoute(route)),
		getOldOrderCost: (data) => dispatch(getOldOrderCost(data)),
		changeModalBottomMenu: (value) => dispatch(changeModalBottomMenu(value)),
		changeTravelRotesOrderRoute: (travelRoutes) =>
			dispatch(changeTravelRotesOrderRoute(travelRoutes)),
		getPreferences: (countryCode) => dispatch(getPreferences(countryCode)),
		getCities: () => dispatch(getCities()),
		getUserCards: () => dispatch(getUserCards()),
		getLastRide: () => dispatch(getLastRide()),
		getFavoriteAddress: () => dispatch(getFavoriteAddress()),
		sendCoordsToRpc: (coordsData, hasError, isAuthentication, shouldUpdateData) =>
			dispatch(sendCoordsToRpc(coordsData, hasError, isAuthentication, shouldUpdateData)),
		getCarClassTimeInfo: (info) => dispatch(getCarClassTimeInfo(info)),
		addOrderDistance: (distance) => dispatch(addOrderDistance(distance)),
		changePaymentTypeOrderRoute: (data) => dispatch(changePaymentTypeOrderRoute(data)),
		clearOrderRoute: () => dispatch(clearOrderRoute()),
		changeCommentOrderRoute: (t) => dispatch(changeCommentOrderRoute(t)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MapWrapper);
