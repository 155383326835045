/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
import React, { memo, useCallback, useEffect, useState } from 'react'
import { t } from 'i18next'
import styles from 'src/components/MapJs/orders.module.scss'

const OrderTimer = () => {
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(true)

  const updateNow = useCallback(() => localStorage.setItem('startDuration', Date.now()), [])
  const updateDurationTrip = useCallback((t) => localStorage.setItem('durationTripTimer', t), [])

  useEffect(() => {
    const t = localStorage.getItem('durationTrip');
    const tims = localStorage.getItem('durationTripTimer');
    const startDuration = localStorage.getItem('startDuration')

    let currentTime;

    if (startDuration) {
      const tim = (Date.now() - startDuration) / 1000;
      currentTime = tims - tim;
      setTime(currentTime);
    } else {
      currentTime = Math.ceil(t * 1.2);
      setTime(currentTime);
      updateNow()
    }
    setLoading(false)

    const interval = setInterval(() => {
      currentTime--;
      setTime(currentTime)
      updateNow()
      updateDurationTrip(currentTime)
      if (currentTime <= 0)
        return clearInterval(interval)
    }, 1000)

    updateNow()
    return () => clearInterval(interval)
  }, [])

  if (loading) return <p className={styles.timerEndText}>{t('downloading')}</p>
  if (time <= 0) return <p className={styles.timerEndText}>{t('you_must_be_at_place')}</p>

  const second = Math.floor(time % 60)
  const minutes = Math.floor(time / 60);
  const minutesMod = minutes % 60;
  const hours = Math.floor(minutes / 60);

  const SS = second > 9 ? second : '0' + second;
  const MM = minutesMod > 9 ? minutesMod : '0' + minutesMod;
  const HH = hours > 9 ? hours : '0' + hours;

  const timer = (hours ? HH + ':' : '') + MM + ':' + SS

  return (
    <p className={styles.timerBoldText}>{timer}</p>
  )
}

export default memo(OrderTimer)