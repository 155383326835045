import { axiosInstance } from 'src/axios.config'
import { api } from '../../config'
import { errorFunction } from '../ErrorComponent/error.action'

export const GET_PASS_PENDING = 'GET_PASS_PENDING'
export const GET_PASS_SUCCESS = 'GET_PASS_SUCCESS'
export const GET_PASS_ERROR = 'GET_PASS_ERROR'
export const RESET_PASS_INFO = 'RESET_PASS_INFO'
/**
 * @returns {Function} */

export const resetForgotPass = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PASS_INFO,
    })
  }
}

export const forgot = (loginInfo) => {
  return (dispatch) => {
    dispatch({
      type: GET_PASS_PENDING,
    })
    axiosInstance
      .post(api + '/rider/reset-password', {
        phone: loginInfo.phone,
				branchId: 2
      })
      .then((response) => {
        console.log('Action* - сброс пароля УСПЕХ: ', response)
        dispatch({
          type: GET_PASS_SUCCESS,
          payload: {
            check: true,
            phone: loginInfo.phone,
          },
        })
      })
      .catch((error) => {
        console.log('Action* - сброс пароля ОШИБКА: ', error)
        // dispatch(errorFunction(error.response))
        dispatch({
          type: GET_PASS_ERROR,
          payload: {
            check: false,
            phone: loginInfo.phone,
          },
        })
      })
  }
}
