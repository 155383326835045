import {
  GET_USERNEWS_PENDING,
  GET_USERNEWS_SUCCESS,
  GET_USERNEWS_ERROR,
  GET_USERNEW_PENDING,
  GET_USERNEW_SUCCESS,
  // GET_USERNEW_ERROR,
} from './news.action'

const initialState = {
  userNews: [],
  userNew: {},
  loading: false,
  error: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERNEWS_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_USERNEW_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_USERNEWS_SUCCESS: {
      return {
        ...state,
        userNews: action.payload,
        loading: false,
        error: false,
      }
    }
    case GET_USERNEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        userNew: action.payload,
      }
    }
    case GET_USERNEWS_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
