import { useEffect, useRef } from "react";

const canUseDOM = () => {
  if (typeof window === "undefined" || !window.document || !window.document.createElement) {
    return false;
  }
  return true;
};

export const ZendeskAPI = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn("Zendesk is not initialized yet");
  }
};

const ZendeskWidget = (props) => {
  const { onLoaded, zendeskKey, defer } = props;

  const scriptRef = useRef(null);

  const onScriptLoaded = () => {
    if (typeof onLoaded === "function") {
      onLoaded();
    }
  };

  const initializeScript = () => {
    scriptRef.current = document.createElement("script");

    if (defer) {
      scriptRef.current.defer = true;
    } else {
      scriptRef.current.async = true;
    }

    scriptRef.current.id = "ze-snippet";
    scriptRef.current.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;

    document.body.appendChild(scriptRef.current);

    scriptRef.current.addEventListener("load", onScriptLoaded);
  };

  useEffect(() => {
    initializeScript();

    return () => {
      if (!canUseDOM || !window.zE) {
        return;
      }

      document.body.removeChild(scriptRef.current);
    };
  }, []);

  return null;
};

export default ZendeskWidget;
