import React from 'react'
import { Button, ButtonVariant } from 'src/shared/ui/Button/button'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

import { ChevronRightIcon } from '../../shared/assets/icons'
import cls from './ErrorPage.module.scss'

import { t } from 'i18next'

const ErrorPage = ({ errorInfo }) => {
  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div className={cls.ErrorPageWrapper}>
      <div className={cls.header}>
        <h1>{t('oops')}</h1>
        <h4>{t('something_went_wrong')}</h4>
        <div className={cls.accordionError}>
          <Accordion style={{ borderRadius: '16px' }}>
            <AccordionSummary
              expandIcon={<ChevronRightIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t('error_details')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{errorInfo}</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className={cls.errorBtnBlock}>
        <Button variant={ButtonVariant.background} fullWidth onClick={reloadPage}>
          {t('back')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
