import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { UserIcon } from '../../../shared/assets/icons'
import cls from './profileHeader.module.scss'
import { t } from 'i18next'
import PhotoBlock from '../PhotoBlock'

const ProfileHeader = memo((props) => {
  const { userData } = props

  return (
    <div className={cls.ProfileHeaderWrap}>
      <div className={cls.profile}>
        <PhotoBlock photo={userData?.photo} />
        <h2>{!userData?.firstName ? t('unknown_name') : userData?.firstName}</h2>
        <p className={cls.phone}>{userData?.phone}</p>
        <Link className={cls.link} to="/edit-profile">
          {t('edit')}
        </Link>
      </div>
    </div>
  )
})

export default ProfileHeader
