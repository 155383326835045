import { axiosInstance } from "src/axios.config";
import { svcApi } from "src/config";
import { ErrorTypes } from "src/shared/variables/errors";

export const fetchIsServerErrorStatus = async () => {
	try {
		const { data } = await axiosInstance.get(svcApi);
		return data.status;
	} catch (error) {
		return ErrorTypes.MAINTENANCE_ERROR;
	}
};