import { axiosInstance } from 'src/axios.config'
import { apiGeocode } from '../../../config'

export const getLanguageCountry = (countryCode) => {
  switch (countryCode) {
    case 'UA':
      return 'uk';
    case 'SK':
      return 'sk';
    default:
      return 'en';
  }
};

export const fetchSuggestions = async (params) => {
  const accessToken = localStorage.getItem('accessToken')

	const { addressQuery, userLocation, autocomplete, registrationCountryInfo } = params

	const customParams = {
		addressQuery,
		userLocation,
		autocomplete,
		language: getLanguageCountry(registrationCountryInfo?.country_code)
	}

	if (registrationCountryInfo?.country_code) {
    customParams.country = registrationCountryInfo.country_code;
  }

	try {
    const response = await axiosInstance.post(apiGeocode, customParams, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log('Error fetching suggestions:', error)
    return [] // Return an empty array or handle the error as needed
  }
}
