import React, { useEffect, useState } from "react";
import Menu from "src/components/Menu";
import MapJs from "src/components/MapJs";
import MapWrapper from "src/entities/MapWrapper";
import { mapProvider } from "src/shared/variables/map-provider";
import { MapProvider } from "src/entities/MapWrapper/context/MapContext";
import { updateCoords } from "src/shared/api/updateCoords";
import { getNearbyDrivers } from "./api/getNearbyDrivers";

/**
 * Страница заказа
 *ss
 * @returns {*}
 * @constructor
 */
export default function OrderPage() {
	const [currentMapProvider, setCurrentMapProvider] = useState(null);

	const [nearDriversCoords, setNearDriversCoords] = useState(null);

	useEffect(() => {
		const coords = JSON.parse(localStorage.getItem("coords"));

		const randomDriversInterval = setInterval(async () => {
			const response = await getNearbyDrivers({ coords });

			if (coords.length === 0) {
				return;
			}

			setNearDriversCoords(response?.data || null);
		}, 3000);

		return () => clearInterval(randomDriversInterval);
	}, []);

	// Maps
	const mapSwitcher = () => {
		switch (currentMapProvider) {
			case mapProvider.google:
				return (
					<div className="row">
						<MapJs />
					</div>
				);
			case mapProvider.osmCarma:
				return (
					<MapProvider>
						<MapWrapper nearDriversCoords={nearDriversCoords} />
					</MapProvider>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage("getGPSdata");
		}
	}, []);

	// get current map from localStorage
	useEffect(() => {
		const commonConfig = localStorage.getItem("commonConfig")
			? JSON.parse(localStorage.getItem("commonConfig"))
			: null;

		if (commonConfig?.mapProvider) {
			setCurrentMapProvider(commonConfig?.mapProvider);
		} else {
			const defaultMap = mapProvider.osmCarma;
			localStorage.setItem(
				"commonConfig",
				JSON.stringify({ ...commonConfig, mapProvider: defaultMap }),
			);
			setCurrentMapProvider(defaultMap);
		}
	}, []);

	// update coords for client for profile city
	useEffect(() => {
		const sendClientCoords = setInterval(() => {
			const coords = localStorage.getItem("coords")
				? JSON.parse(localStorage.getItem("coords"))
				: null;

			if (coords) {
				updateCoords({ lat: coords.latitude, lon: coords.longitude });
				return clearInterval(sendClientCoords);
			}
		}, 1000);

		return () => {
			clearInterval(sendClientCoords);
		};
	}, []);

	return (
		<>
			<Menu />
			{mapSwitcher()}
		</>
	);
}
