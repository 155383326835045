/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect } from "react";
import maplibregl from "!maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const useCreateMap = ({ map, mapContainer, mapTheme, passengerCoords, setMapLoaded }) => {
	useEffect(() => {
		const startLoadMap = async () => {
			// Check if the map has already been created
			if (map.current) {
				return;
			}

			// stops map from intializing more than once
			map.current = new maplibregl.Map({
				container: mapContainer.current,
				style: mapTheme,
				center: passengerCoords,
				zoom: 18,
				attributionControl: false,
			});

			map.current.addControl(
				new maplibregl.AttributionControl({
					compact: true,
				}),
				"top-left",
			);

			// close default credent
			await map.current.on("load", () => {
				// Find the attribution control's button
				const attributionControlButton = document.querySelector(".maplibregl-ctrl-attrib-button");
				map.current.resize();

				if (attributionControlButton) {
					// Simulate a click event to close the attribution control
					attributionControlButton.click();
				}

				setMapLoaded(true);
			});

			// NOT DELETE, FOR TESTING - set car coords on click
			// map.current.on('click', function (e) {
			//   // Get the coordinates (longitude and latitude) of the clicked point
			//   const coords = e.lngLat
			//   setTestCoordsDriver([coords.lng, coords.lat])
			//   // Log the coordinates to the console or use them as needed
			// })

			// Set the mapCreated state to true to prevent recreation

			// Clean up the map instance when the component unmounts
			return () => {
				map.current.remove();
				map.current = null;
				setMapLoaded(false);
			};
		};

		startLoadMap();
	}, [map, mapContainer, mapTheme, passengerCoords, setMapLoaded]);
	return null;
};

export default useCreateMap;
