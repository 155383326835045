import React from 'react'

export const CameraCircleIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <rect width={24} height={24} x={0.5} fill="#fff" rx={12} />
      <path
        className={className}
        fill="#212121"
        d="M17.885 6.696h-1.866L15.1 5.252a.549.549 0 0 0-.193-.185.519.519 0 0 0-.254-.067h-4.308a.519.519 0 0 0-.254.067.55.55 0 0 0-.193.185L8.98 6.696H7.115c-.428 0-.839.178-1.142.496a1.74 1.74 0 0 0-.473 1.2v7.912c0 .45.17.881.473 1.2.303.317.714.496 1.142.496h10.77c.428 0 .839-.179 1.142-.497a1.74 1.74 0 0 0 .473-1.199V8.391c0-.45-.17-.88-.473-1.199a1.578 1.578 0 0 0-1.142-.496Zm-2.962 5.37c0 .502-.142.994-.408 1.412a2.452 2.452 0 0 1-1.088.937c-.442.193-.93.243-1.4.145a2.39 2.39 0 0 1-1.24-.696 2.585 2.585 0 0 1-.664-1.303 2.66 2.66 0 0 1 .138-1.47 2.52 2.52 0 0 1 .893-1.14c.398-.28.867-.43 1.346-.43.643 0 1.259.269 1.713.746.455.477.71 1.124.71 1.798Z"
      />
    </svg>
  )
}
