import React from 'react'

export const ChevronRightIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} viewBox="0 0 24 24" {...props}>
      <path
        className={className}
        stroke="#8391A2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m9.5 18.5 6-6-6-6"
      />
    </svg>
  )
}
