import React, { memo, useMemo } from 'react'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

const CarTimeDisplay = memo((props) => {
  const { carArrivalTimes, tariffId, className } = props

  const timeForSelectedTariff = useMemo(() => {
    const matchingCars = carArrivalTimes?.filter((car) => car.carClassId === tariffId)

    if (matchingCars.length > 0) {
      return matchingCars.map((car) => (
        <p className={className} key={car.carClassId}>
          <Trans i18nKey="pickup_time" values={{ min: car.time }} />
        </p>
      ))
    } else {
      return null
    }
  }, [carArrivalTimes, tariffId, className])

  return <>{timeForSelectedTariff}</>
})

export default CarTimeDisplay
