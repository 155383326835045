// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from "!maplibre-gl";
import calculateBearing from "src/services/calculateBearing";

export const calculateBearingByMapParams = ({
	markerCoords,
	bearingAccuracyMeters,
	prevBearingCoords,
}) => {
	let convertedPrevMarkerCoords = new maplibregl.LngLat(prevBearingCoords[0], prevBearingCoords[1]);
	let convertedMarkerCoords = new maplibregl.LngLat(markerCoords[0], markerCoords[1]);

	const isBearingCoordsChange =
		convertedPrevMarkerCoords.distanceTo(convertedMarkerCoords) > bearingAccuracyMeters;

	if (isBearingCoordsChange && prevBearingCoords) {
		const bearing = calculateBearing(
			prevBearingCoords[1],
			prevBearingCoords[0],
			markerCoords[1],
			markerCoords[0],
		);

		if (bearing !== 0) {
			return bearing;
		}
	}

	return null;
};
