export const maxOrderDistance = 1000000;

export const availablePaymentMethods = {
	CASH: "cash",
	CARD: "card",
	CASH_AND_BONUSES: "cash_and_bonuses",
	CARD_AND_BONUSES: "card_and_bonuses",
};

// pages which stop android preloader with inner logic
export const pagesCustomStopPreloader = ["/", "/order"];
