import React from 'react'

export const WorkIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill="none" {...props}>
      <path
        className={className}
        fill="#343330"
        d="M20.25 5.25H16.5V4.5a2.25 2.25 0 0 0-2.25-2.25h-4.5A2.25 2.25 0 0 0 7.5 4.5v.75H3.75a1.5 1.5 0 0 0-1.5 1.5v12a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5v-12a1.5 1.5 0 0 0-1.5-1.5ZM9 4.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v.75H9V4.5Zm11.25 2.25v6.75H3.75V6.75h16.5Zm0 12H3.75V15h16.5v3.75Z"
      />
    </svg>
  )
}
