import React from 'react'

export const PasswordIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#212121"
        d="M4.5 5.25v13.5a.75.75 0 1 1-1.5 0V5.25a.75.75 0 0 1 1.5 0Zm7.875 5.11-1.875.609V9A.75.75 0 1 0 9 9v1.969l-1.875-.61a.75.75 0 1 0-.469 1.427l1.875.609-1.157 1.593a.751.751 0 1 0 1.214.882l1.156-1.594 1.157 1.594a.75.75 0 1 0 1.213-.882l-1.157-1.593 1.875-.609a.75.75 0 0 0-.457-1.427Zm9.938.481a.75.75 0 0 0-.938-.482l-1.875.61V9A.75.75 0 1 0 18 9v1.969l-1.875-.609a.75.75 0 1 0-.464 1.427l1.875.609-1.157 1.593a.748.748 0 0 0 .424 1.188.751.751 0 0 0 .79-.306l1.156-1.594 1.157 1.594a.751.751 0 1 0 1.213-.882l-1.157-1.593 1.875-.609a.75.75 0 0 0 .476-.946Z"
      />
    </svg>
  )
}
