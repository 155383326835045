import React from 'react'

export const MapPinIcon = (props) => {
  const { width = 24, height = 24, fill = '#212121', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill="none" {...props}>
      <path
        className={className}
        fill={fill}
        stroke={stroke}
        strokeWidth={0.2}
        d="M12 6a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 6Zm0 6a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-10.5a8.26 8.26 0 0 0-8.25 8.25c0 2.944 1.36 6.064 3.938 9.023a23.837 23.837 0 0 0 3.885 3.591.75.75 0 0 0 .861 0 23.835 23.835 0 0 0 3.879-3.59c2.573-2.96 3.937-6.08 3.937-9.024A8.26 8.26 0 0 0 12 1.5Zm0 19.313c-1.55-1.22-6.75-5.696-6.75-11.063a6.75 6.75 0 0 1 13.5 0c0 5.365-5.2 9.844-6.75 11.063Z"
      />
    </svg>
  )
}
