/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import cls from "src/components/MapJs/orders.module.scss";
import { ReactComponent as DownLine } from "src/images/DownLine.svg";
import { ReactComponent as Headphones } from "src/images/Headphones.svg";
import { ReactComponent as ChevronRight } from "src/images/ChevronRight.svg";
import { Link } from "react-router-dom";
import { t } from "i18next";
import IconStrip from "../../../IconStrip";
import { clearOrderRoute } from "../../../OrderRoute/order.route.action";
import { nameComponents, openAnotherComponentBottomMenu } from "../../bottom.navigate.action";
import { getValuteSymbol } from "../../../CurrencyDefinition/getValuteSymbol";
import { getCurrentOrder } from "../../../SocketConnection/socket.action";
import Loader from "src/shared/ui/Loader/Loader";
import { parseLocalStorageItem } from "src/shared/helpers/parseLocalStorageItem";
import { getTextPaymentById } from "src/services/getTextPayment";

export const OrderCompleted = ({
	tariffCar,
	paymentType,
	routes,
	sumDopServises,
	editPrice,
	symbol,
	priceWaiting,
	currentPrice,
	currentOrderData,
	getCurrentOrder,
	isLoadingOrder
}) => {
	const [isDetails, setIsDetails] = useState(false);

	const [startY, setStartY] = useState(null);
	const [endY, setEndY] = useState(null);
	const touchRef = useRef(null);

	const orderPaymentInfo = parseLocalStorageItem("orderPaymentInfo");

	useEffect(() => {
		const isReloadPage = localStorage.getItem('reload')

		const fetchOrderData = async () => {
			const orderId = localStorage.getItem("orderId");
			await getCurrentOrder(orderId);
		};

		if(!isReloadPage) {
			fetchOrderData();
		}
	}, []);

	const handleTouchStart = (e) => {
		// Проверка, что событие не произошло на элементах button и a (ссылках)
		if (!isButtonOrLink(e.target)) {
			const touch = e.touches[0];
			setStartY(touch.clientY);
			touchRef.current = touch;
		}
	};

	const handleTouchEnd = (e) => {
		if (!touchRef.current) return;

		// Проверка, что событие не произошло на элементах button и a (ссылках)
		if (!isButtonOrLink(e.target)) {
			const touch = e.changedTouches[0];
			setEndY(touch.clientY);

			const deltaY = endY - startY;

			if (deltaY > 50) {
				setIsDetails(false);
			} else if (deltaY < -50) {
				setIsDetails(true);
			}

			touchRef.current = null;
		}
	};

	// Функция, которая проверяет, является ли элемент кнопкой или ссылкой или находится внутри них
	const isButtonOrLink = (element) => {
		if (!element) return false;
		if (element.tagName.toLowerCase() === "button" || element.tagName.toLowerCase() === "a") {
			return true;
		}
		// Рекурсивная проверка для вложенных элементов
		if (element.parentElement) {
			return isButtonOrLink(element.parentElement);
		}
		return false;
	};

	const Img = paymentType.Img;

	let priceRised;

	if (editPrice > tariffCar.price) {
		priceRised = editPrice - tariffCar.price;
	} else {
		priceRised = 0;
	}

	const textFormator = (text) => {
		const arrAdress = text.split(", ");
		let minusCountElement = 1;
		let mainText = arrAdress[0] + " ";

		if (parseInt(arrAdress[1])) {
			mainText += arrAdress[1];
			minusCountElement++;
		}

		return {
			mainText,
			secondaryText: arrAdress.slice(minusCountElement, arrAdress.length - 2).join(", "),
		};
	};

	const address = (point) => point.adress?.formatted_address || point.name;
	let hasSecondaryText = true;
	routes.forEach((point) => {
		const formattedAddress = textFormator(address(point) || "");
		if (!formattedAddress.secondaryText) {
			hasSecondaryText = false;
		}
	});
	const firstRoute = routes[0];
	let secondaryTextLength = 0;

	if (firstRoute) {
		secondaryTextLength = textFormator(address(firstRoute)).secondaryText.length;
	}

	const priceFromOrder =
		Number(currentOrderData?.priceEstimated - currentOrderData?.quoteBonus) || currentPrice;

		if(isLoadingOrder) {
			return <Loader />
		}

	return (
		<>
			<div
				className={cls.search_car_form}
				onTouchStart={handleTouchStart}
				onTouchEnd={handleTouchEnd}
			>
				<div
					className={cls.down_line}
					onClick={() => setIsDetails(!isDetails)}
					onTouchStart={handleTouchStart}
					onTouchEnd={handleTouchEnd}
				>
					<DownLine className={cls.down_line_icon} />
				</div>
				<div className={cls.header_order_block}>
					<div className={cls.header_main_text}>
						<p style={{ fontSize: "18px" }}>{t("your_trip_completed")}</p>
					</div>
				</div>
				<div className={cls.order_costs}>
					<p>{t("order_cost")}</p>
					<div className={cls.price_section}>
						<p>{priceFromOrder} </p>
						<p>{symbol}</p>
					</div>
				</div>
				<div className={cls.type_of_payment}>
					<p>{t("payment_type")}</p>
					<div className={cls.type_section}>
						<Img className={cls.icon_payment} />
						<p>{t(getTextPaymentById(orderPaymentInfo.paymentId))}</p>
					</div>
				</div>
				{isDetails && (
					<div className={cls.details_search}>
						<div className={cls.details_items}>
							<div className={cls.add_cost_order_price}>
								{tariffCar.price && (
									<div className={cls.add_cost_order_item}>
										<p>{t("tariff")}</p>
										<div className={cls.add_cost_order_item_price}>
											<p>{tariffCar.price - sumDopServises}</p>
											<p>{symbol}</p>
										</div>
									</div>
								)}
								<div className={cls.add_cost_order_item} style={{ paddingTop: "28px" }}>
									<p>{t("add_waiting_time")}</p>
									<div className={cls.add_cost_order_item_price}>
										<p>{priceWaiting || 0}</p>
										<p>{symbol}</p>
									</div>
								</div>
								<div className={cls.add_cost_order_item} style={{ paddingTop: "28px" }}>
									<p>{t("ad_services")}</p>
									<div className={cls.add_cost_order_item_price}>
										<p>{sumDopServises}</p>
										<p>{symbol}</p>
									</div>
								</div>
							</div>
							<div className={cls.order_path}>
								<p>{t("route")}</p>
							</div>
							<div className={cls.order_path_details}>
								<div className={cls.path_icons}>
									<IconStrip iconCount={routes.length} secondaryTextLength={secondaryTextLength} />
								</div>
								<div className={cls.path_route}>
									{routes.map((point) => {
										const formattedAddress = textFormator(address(point));
										return (
											<div className={cls.path_single_item} key={point.id}>
												<p className={cls.main_text}>{formattedAddress.mainText}</p>
												{hasSecondaryText && (
													<p className={cls.secondary_text}>{formattedAddress.secondaryText}</p>
												)}
											</div>
										);
									})}
								</div>
							</div>
							<div className={cls.order_main_safe}>
								<div className={cls.order_path} style={{ marginTop: "5px" }}>
									<p>{t("safe")}</p>
								</div>
								<Link to="/chats" className={cls.link_btn}>
									<div className={cls.link_name}>
										<Headphones />
										<p>{t("support_service")}</p>
									</div>
									<ChevronRight />
								</Link>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		startPlace: state.odrerRoute.startPlace,
		currentGoogleMapCoordinates: state.odrerRoute.currentGoogleMapCoordinates,
		routes: state.odrerRoute.routes,
		paymentType: state.odrerRoute.paymentType,
		tariffCar: state.odrerRoute.tariffCar,
		sumDopServises: state.odrerRoute.sumDopServises,
		editPrice: state.odrerRoute.editPrice,
		currentOrderData: state.socket.currentOrderData,
		currentPrice: state.odrerRoute.currentPrice,
		priceWaiting: state.socket.currentOrderData.priceWaiting,
		symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
		isLoadingOrder: state.socket.isLoadingOrder
	};
};

export default connect(mapStateToProps, {
	clearOrderRoute,
	openAnotherComponentBottomMenu,
	getCurrentOrder,
})(OrderCompleted);
