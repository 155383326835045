import React from 'react'

export const SortDownIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#fff"
        d="M11.25 10.75a.75.75 0 0 1-.75.75H3.75a.75.75 0 1 1 0-1.5h6.75a.75.75 0 0 1 .75.75ZM3.75 5.5H16.5a.75.75 0 1 0 0-1.5H3.75a.75.75 0 0 0 0 1.5ZM9 16H3.75a.75.75 0 1 0 0 1.5H9A.75.75 0 1 0 9 16Zm11.78-2.03a.747.747 0 0 0-1.06 0l-2.47 2.47V9.25a.75.75 0 1 0-1.5 0v7.19l-2.47-2.47a.75.75 0 1 0-1.06 1.06l3.75 3.75a.747.747 0 0 0 1.06 0l3.75-3.75a.747.747 0 0 0 0-1.06Z"
      />
    </svg>
  )
}
