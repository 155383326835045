import React, { memo } from 'react'
import { t } from 'i18next'
import cls from './historyDetailRoutes.module.scss'
import AddressStripHistory from '../../../AddressStripHistory'

const HistoryDetailRoutes = memo(({ routePoints }) => {
  const test = [
    {
      name: 'вулиця Гетьмана Мазепи, 1Б, Львів, Львівська область, Украина, 79068',
      lat: 49.8699355,
      lon: 24.02273499999999,
      pointId: 'ChIJXTJZRmfdOkcRdt-nSGjEQYE',
      mainText: '',
      secondaryText: '',
    },
    {
      name: 'вулиця Гетьмана Мазепи, 1Б, Львів, Львівська область, Украина, 79068',
      lat: 49.8699355,
      lon: 24.02273499999999,
      pointId: 'ChIJXTJZRmfdOkcRdt-nSGjEQYE',
      mainText: '',
      secondaryText: '',
    },
    {
      name: 'вулиця Гетьмана Мазепи, 1Б, Львів, Львівська область, Украина, 79068',
      lat: 49.8699355,
      lon: 24.02273499999999,
      pointId: 'ChIJXTJZRmfdOkcRdt-nSGjEQYE',
      mainText: '',
      secondaryText: '',
    },
    {
      name: 'вулиця Гетьмана Мазепи, 1Б, Львів, Львівська область, Украина, 79068',
      lat: 49.8699355,
      lon: 24.02273499999999,
      pointId: 'ChIJXTJZRmfdOkcRdt-nSGjEQYE',
      mainText: '',
      secondaryText: '',
    },
    {
      name: 'вулиця Гетьмана Мазепи, 1Б, Львів, Львівська область, Украина, 79068',
      lat: 49.8699355,
      lon: 24.02273499999999,
      pointId: 'ChIJXTJZRmfdOkcRdt-nSGjEQYE',
      mainText: '',
      secondaryText: '',
    },
  ]

  return (
    <div className={cls.routeContainer}>
      <h1>{t('route')}</h1>
      <AddressStripHistory route={routePoints} isDetailHistory />
    </div>
  )
})

export default HistoryDetailRoutes
