import { ACTION_ERROR_TOKEN } from './error.action'

const initialState = {
  error: false,
  errorData: '',
}

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_ERROR_TOKEN: {
      return {
        ...state,
        error: true,
        errorData: "В ваш аакаунт вошли с другого устройста!",
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default errorReducer
