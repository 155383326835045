import React from 'react'

export const LightModeIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} {...props}>
      <path
        className={className}
        fill="#343330"
        stroke="#212121"
        strokeWidth={0.125}
        d="M12.063 3.75v-.063H12A8.322 8.322 0 0 0 3.687 12 8.322 8.322 0 0 0 12 20.313h.063V3.75Zm-5.445.195A9.688 9.688 0 0 1 12 2.313 9.698 9.698 0 0 1 21.688 12a9.688 9.688 0 1 1-15.07-8.055Z"
      />
    </svg>
  )
}
