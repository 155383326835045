import { axiosInstance } from 'src/axios.config'
import { apiData } from '../../../config'
import { errorFunction } from '../../../components/ErrorComponent/error.action'
import { errorTokenFunction } from '../../../components/ErrorToken/error.action'

export const GET_USERCHATS_PENDING = 'GTE_USERCHATS_PENDING'
export const GET_USERCHATS_SUCCESS = 'GTE_USERCHATS_SUCCESS'
export const GET_IS_VIEW = 'GET_IS_VIEW'
export const GET_USERCHATS_ERROR = 'GTE_USERCHATS_ERROR'

export const getUserChats = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_USERCHATS_PENDING,
    })
    axiosInstance
      .get(apiData + '/profile/support/chat', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        dispatch({
          type: GET_USERCHATS_SUCCESS,
          payload: response.data.data,
        })
        dispatch({
          type: GET_IS_VIEW,
          payload: response.data.data.find((item) => item?.isNew === true),
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_USERCHATS_ERROR,
          payload: error,
        })
      })
  }
}

export const GET_USERCHAT_PENDING = 'GTE_USERCHAT_PENDING'
export const GET_USERCHAT_SUCCESS = 'GTE_USERCHAT_SUCCESS'
export const GET_USERCHAT_ERROR = 'GTE_USERCHAT_ERROR'

export const putChatSeen = (chatId) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_USERCHAT_PENDING,
    })
    axiosInstance
      .put(apiData + `/profile/support/chat/${chatId}/set-read`, null, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        dispatch({
          type: GET_USERCHAT_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_USERCHAT_ERROR,
          payload: error,
        })
      })
  }
}
