import React, { memo } from 'react'
import cls from './formBlockInfo.module.scss'
import { classNames } from 'src/shared/lib/classNames/classNames'

const FormBlockInfo = memo((props) => {
  const { icon: Icon, title, value, className, additionalStyle = false } = props

  return (
    <div
      className={classNames(cls.info, { [cls.additionalStyleInfo]: additionalStyle }, [className])}
    >
      <Icon className={cls.icon} />
      <p>{title}</p>
      <span>{value}</span>
    </div>
  )
})

export default FormBlockInfo
