import React, { useState, useEffect, memo, useCallback } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import { getUserChats } from "../../pages/ChatHistoryPage/store/chat.action";
import { classNames } from "../../shared/lib/classNames/classNames";
import MenuHeader from "./MenuHeader";
import MenuItemLink from "./MenuItemLink";
import MenuFooter from "./MenuFooter";
import Drawer from "../../common/Drawer";
import { List } from "../../common/List";
import {
	MenuIcon,
	ClockIcon,
	CardIcon,
	MapPinIcon,
	NewsIcon,
	HeadphonesIcon,
} from "../../shared/assets/icons";
import { ReactComponent as DocumentsIcon } from "../../images/DocumentsIcon.svg"
import "./Menu.scss";
import { nameComponents } from "../BottomMenu/bottom.navigate.action";
import { getUserNews } from "../../pages/NewsPage/news.action";
import moment from "moment";

const Menu = memo(
	({
		getUserChats,
		getUserNews,
		userNews,
		userData,
		isViewMessage,
		nameComponent,
		registrationCountryInfo,
	}) => {
		const [left, setLeft] = useState(false);

		const registrationDay = userData.registrationDate;

		const filteredNews = userNews.filter((oneNew) =>
			moment(oneNew.createdAt).isAfter(registrationDay),
		);

		const countUnreadNews = useCallback(() => {
			const unreadNews = filteredNews.filter((news) => news.isRead !== true);
			return unreadNews.length;
		}, [filteredNews]);

		const toggleFunction = (namePopup, statePopup) => {
			if (namePopup === "left") {
				setLeft(!statePopup);
			}
		};

		const isShowBtn =
			nameComponent === nameComponents.START_SEARCH_PLACE

		useEffect(() => {
			getUserChats();
			getUserNews();
		}, [getUserChats, getUserNews]);

		return (
			<div>
				{isShowBtn && (
					<div
						className="menu__button"
						role="presentation"
						onClick={() => toggleFunction("left", left)}
					>
						<div className="menu_icon">
							<MenuIcon className="icon_menu_btn" />
						</div>
					</div>
				)}

				<Drawer
					className={classNames("menuWrapper", {}, [])}
					open={left}
					onClose={() => toggleFunction("left", left)}
				>
					<div
						role="presentation"
						className="menuHeader"
						onClick={() => toggleFunction("left", left)}
					>
						<List className="menu__header">
							<MenuHeader userData={userData} />
						</List>
						<div className="menu__body">
							<List>
								<MenuItemLink icon={ClockIcon} text={t("trips")} path="/history" />
								<MenuItemLink icon={CardIcon} text={t("payments")} path="/order-payment" />
								<MenuItemLink icon={MapPinIcon} text={t("favorite_addresses")} path="/favorite" />
								<MenuItemLink icon={DocumentsIcon} text={t("invoices")} path="/invoices" />
								<MenuItemLink
									icon={NewsIcon}
									showBadge={true}
									badgeContent={countUnreadNews()}
									text={t("news")}
									path="/news"
								/>
								<MenuItemLink icon={HeadphonesIcon} text={t("help")} path="/help" />
							</List>
						</div>
						<MenuFooter
							className="menu__footer"
							userData={userData}
							registrationCountryInfo={registrationCountryInfo}
						/>
					</div>
				</Drawer>
			</div>
		);
	},
);

const mapStateToProps = (state) => {
	return {
		userData: state.profile.userData,
		userNews: state.news.userNews,
		isViewMessage: state.chat.isViewMessage,
		nameComponent: state.bottomNavigate.nameComponent,
		registrationCountryInfo: state.registration.registrationCountryInfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserChats: () => dispatch(getUserChats()),
		getUserNews: () => dispatch(getUserNews()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
