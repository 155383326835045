import {
  GET_USERDATA_PENDING,
  GET_USERDATA_SUCCESS,
  GET_USERDATA_ERROR,
  POST_USERDATA_PENDING,
  POST_USERDATA_SUCCESS,
  POST_USERDATA_ERROR,
  POST_IMAGE_CODE_SUCCESS,
  POST_CHANGEPASSWORD_SUCCESS,
  RESET_USERDATA,
  IS_MENU_OPEN,
  SET_PROFILE_LANGUAGE,
} from './profile.action'

const initialState = {
  userData: {},
  userImgId: null,
  userDataLoaded: false,
  userDataError: false,
  userChangedPassword: false,
  changeUserData: false,
  isOpenFromMenu: false,
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERDATA_PENDING: {
      return {
        ...state,
        userDataLoaded: true,
        userDataError: false,
      }
    }
    case GET_USERDATA_SUCCESS: {
      return {
        ...state,
        userData: action.payload,
        userDataLoaded: false,
        userDataError: false,
      }
    }
    case IS_MENU_OPEN: {
      return {
        ...state,
        isOpenFromMenu: action.payload,
      }
    }
    case GET_USERDATA_ERROR: {
      return {
        ...state,
        userDataError: action.payload,
      }
    }
    case SET_PROFILE_LANGUAGE: {
      return {
        ...state,
        userData: { ...state.userData, language: action.payload },
      }
    }
    case POST_USERDATA_PENDING: {
      return {
        ...state,
        changeUserData: false,
      }
    }
    case POST_USERDATA_SUCCESS: {
      return {
        ...state,
        changeUserData: true,
      }
    }
    case POST_USERDATA_ERROR: {
      return {
        ...state,
        changeUserData: false,
      }
    }
    case POST_IMAGE_CODE_SUCCESS: {
      return {
        ...state,
        userImgId: action.payload,
      }
    }
    case POST_CHANGEPASSWORD_SUCCESS: {
      return {
        ...state,
        userChangedPassword: action.payload,
      }
    }
    case RESET_USERDATA: {
      return {
        ...state,
        userChangedPassword: false,
        changeUserData: false,
        userImgId: null,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default profileReducer
