
import { apiCards } from 'src/config'
import { axiosInstance } from '../../axios.config'

export const GET_CLIENT_INVOICES = 'GET_CLIENT_INVOICES'

export const getClientInvoices = (skip = 0, take = 50) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem('accessToken')

      const response = await axiosInstance.get(`${apiCards}/v1/client/invoices`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { skip, take }
      })

      dispatch({
        type: GET_CLIENT_INVOICES,
        payload: response.data.data
      })
    } catch (error) {
      console.log('Error fetching driver invoices:', error)
    }
  }
}