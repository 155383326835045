import React, { Component } from 'react'
import { connect } from 'react-redux'

import { putRating } from './order.action'
import { getCurrentOrder } from '../SocketConnection/socket.action'
import { getUserData } from '../ProfileForm/profile.action'

import SimpleDialog from '../../common/Dialog'
import TextField from '../../common/TextField'
import Rating from '../../common/Rating'

import iconCashPay from '../../images/pay.svg'
import addGreyIcon from '../../images/addGrey.svg'
import ToIcon from '../../images/to.svg'
import LocationOnIcon from '../../common/material-ui-icons/LocationOnIcon'
import AccountCircleIcon from '../../common/material-ui-icons/AccountCircleIcon'
import { t } from 'i18next'

class OrderDone extends Component {
  state = {
    rating: 0,
    comment: '',
  }

  componentDidMount() {
    const countryCode = localStorage.getItem('country_сode')

    this.props.getCurrentOrder(this.props.orderStatus.orderId || localStorage.getItem('orderId'))
    this.props.getUserData(countryCode)
    localStorage.removeItem('time')
  }

  /**
   * Пересчет цены после выбора бонусов. Скидка не
   * должна привышать 50% от цены.
   *
   * @returns {*} */
  calcBonusPrice = (priceEstimated, quoteBonus) =>
    quoteBonus > 0 ? (
      <>
        <span className="order-sum__old-price">
          {priceEstimated} {t('currency')}
        </span>
        <span>
          {priceEstimated - quoteBonus} {t('currency')}
        </span>
      </>
    ) : (
      <>{`${priceEstimated} ${t('currency')}`}</>
    )

  setInputValue = (event) => {
    let value
    if (event.target.name === 'rating') {
      value = Number(event.target.value)
    } else {
      value = event.target.value
      value = value.replace(/[.*+?^${}()|[\]\\]/g, '')
    }
    this.setState({
      [event.target.name]: value,
    })
  }

  render() {
    const currentOrder = this.props.currentOrderData
    const driver = this.props.driverData
    const statusOrder = this.props.orderStatus

    const ratingBloch = (
      <>
        <div className="row border" />

        <div className="row">
          <div className="col-5">
            <span className="rating__text">{t('evaluate_trip')}</span>
          </div>
          <div className="col-7 rating">
            <Rating value={this.state.rating} name="rating" onChange={this.setInputValue} />
          </div>
        </div>

        <div className="row border" />

        <div className="row mb-2">
          <div className={!this.state.rating ? 'col-12 text-comment' : 'col-12'}>
            <TextField
              label={t('commentary_variant2')}
              placeholder={t('enter_comment')}
              margin="normal"
              name="comment"
              pattern="[a-zA-Z0-9]+"
              fullWidth
              variant="outlined"
              disabled={!this.state.rating}
              value={this.state.comment}
              onChange={this.setInputValue}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="finish-block">
              {this.props.finishOrderLoaded ? null : (
                <button
                  className={this.state.rating ? 'text-button' : 'text-button text-button_disabled'}
                  disabled={!this.state.rating}
                  onClick={() => {
                    this.props.putRating({
                      orderId: statusOrder?.orderId,
                      rating: this.state.rating,
                      comment: this.state.comment,
                    })
                    // localStorage.removeItem('orderId')
                    localStorage.removeItem('time')
                    localStorage.removeItem('timeCount')
                  }}
                >
                  {this.state.rating ? t('send') : t('leave_review')}
                </button>
              )}
              <button
                className="text-button"
                onClick={() => {
                  // localStorage.removeItem('orderId')
                  localStorage.removeItem('time')
                  localStorage.removeItem('timeCount')
                  window.location.replace('/order')
                }}
              >
                {t('no_review')}
              </button>
            </div>
          </div>
        </div>
      </>
    )

    return this.props.currentOrderStatus || statusOrder?.status === 128 ? (
      <>
        <style></style>
        <SimpleDialog
          className="with-close-button"
          open={true}
          isHide={true}
          hideBack={statusOrder?.status !== 128}
          close={() => {
            if (statusOrder?.status === 128) {
              // localStorage.removeItem('orderId')
              localStorage.removeItem('time')
              localStorage.removeItem('timeCount')
              window.location.replace('/order')
            }
          }}
          showControl={true}
          header={t('trip_completed')}
          fullScreen={true}
          control={false}
          content={
            <div className="order-finish">
              <div className="row mb-3 mt-3">
                <div className="col-1">
                  <img src={iconCashPay} alt="" />
                </div>
                <div className="col-3">
                  {this.calcBonusPrice(currentOrder?.priceEstimated, currentOrder?.quoteBonus)}
                </div>
                <div className="col-3" />
                <div className="col-4">
                  <img src={addGreyIcon} alt="" /> + {currentOrder?.orderPreferences?.length}{' '}
                  {t('services')}
                </div>
                {currentOrder.priceWaiting > 0 && (
                  <div className="col-12 mt-1">
                    {t('including_downtime')} ({currentOrder?.priceWaiting} {t('currency')})
                  </div>
                )}
              </div>
              <div className=" row border" />
              <div className="row mt-3">
                <div className="col-12 mb-3">
                  <img src={ToIcon} alt="" /> {currentOrder?.orderAddressInfo?.startAddressName}
                </div>
                <div className="col-12 mb-3">
                  <LocationOnIcon className="location-icon" />{' '}
                  {currentOrder?.orderAddressInfo?.endAddressName}
                </div>
              </div>
              <div className=" row border" />
              <div className="order-driver my-3">
                <div className="row no-gutters">
                  <div className="col-3" style={{ marginTop: '10px', marginRight: '8px' }}>
                    {driver?.driverPhoto === '' ? (
                      <AccountCircleIcon />
                    ) : (
                      <img className="order-driver__photo" src={driver?.driverPhoto} alt="" />
                    )}
                  </div>
                  <div className="col-9 mt-2">
                    <p className="order-driver__text">
                      {driver?.driverName} {driver?.driverRating}
                    </p>
                    <p className="order-driver__text">
                      {driver?.carName}({t(driver?.carColorName)})
                    </p>
                    <p className="order-driver__text">{driver?.carNumber}</p>
                  </div>
                </div>
              </div>

              {statusOrder?.status === 128 && ratingBloch}
            </div>
          }
        />
      </>
    ) : (
      <></>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentOrderData: state.socket.currentOrderData,
    driverData: state.socket.driverData,
    currentOrderStatus: state.socket.currentOrderStatus,
    userData: state.profile.userData,
    orderStatus: state.socket.orderStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    putRating: (data) => dispatch(putRating(data)),
    getCurrentOrder: (orderId) => dispatch(getCurrentOrder(orderId)),
    getUserData: (data) => dispatch(getUserData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDone)
