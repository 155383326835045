export const onLanguageChangedCB = () => {
  const appType = localStorage.getItem('appType')

  try {
    if (appType === 'android') {
      // eslint-disable-next-line no-undef
      Native.onLanguageChanged()
    } else {
      // eslint-disable-next-line no-undef
      webkit.messageHandlers.onLanguageChanged.postMessage('onLanguageChanged')
    }
  } catch (e) {
    console.log('not Apple or Android device')
  }
}
