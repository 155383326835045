import React, { memo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { t } from 'i18next'
import { postChangePassword, resetUserData } from '../profile.action'
import { showModalAlert } from '../../Modals/store/modal.action'
import { Input } from '../../../shared/ui/Input/Input'
import { Button } from '../../../shared/ui/Button/button'
import AlertModal from '../../Modals/ui/AlertModal/AlertModal'
import Modal from '../../../shared/ui/Modal/Modal'
import cls from './formChangePass.module.scss'

const FormChangePas = memo((props) => {
  const {
    postChangePassword,
    isOpenModalAlert,
    showModalAlert,
    userChangedPassword,
    resetUserData,
  } = props
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  useEffect(() => {
    if (userChangedPassword) {
      showModalAlert(true)
    }

    return () => resetUserData()
  }, [resetUserData, showModalAlert, userChangedPassword])

  const isPasswordValid = useCallback(
    (password) => password.length >= 4 && password.length <= 30,
    []
  )
  const doPasswordsMatch = useCallback(
    (password, confirmPassword) => password === confirmPassword,
    []
  )

  const isFormValid = isPasswordValid(password) && doPasswordsMatch(password, passwordRepeat)

  const onChangePassword = useCallback((event) => {
    const str = event.target.value.replace(/\s+/g, '').trim()

    if (event.target.name === 'new-password-1') {
      setPassword(str)
    } else if (event.target.name === 'new-password-2') {
      setPasswordRepeat(str)
    }
  }, [])

  const setNewPassword = useCallback(async () => {
    const passwords = {
      password: password,
      confirmPassword: passwordRepeat,
    }
    await postChangePassword(passwords)
    setPassword('')
    setPasswordRepeat('')
  }, [password, passwordRepeat, postChangePassword])

  return (
    <div className={cls.ChangePassword}>
      <Modal isOpen={isOpenModalAlert} isCloseAction>
        <AlertModal title={t('password_was_change')} />
      </Modal>
      <Input
        name="new-password-1"
        value={password}
        className={cls.password}
        type="password"
        isPassword
        onChange={onChangePassword}
        placeholder={t('new_password')}
        isError={password && !isPasswordValid(password)}
        valueError={t('min_and_max_characters_for_password')}
      />
      <Input
        name="new-password-2"
        value={passwordRepeat}
        className={cls.newPassword}
        type="password"
        isPassword
        onChange={onChangePassword}
        placeholder={t('repeat_new_password')}
        isError={passwordRepeat && !doPasswordsMatch(password, passwordRepeat)}
        valueError={t('passwords_do_not_match')}
      />
      <div className={cls.btnBlock}>
        <Button className={cls.saveBtn} fullWidth onClick={setNewPassword} disabled={!isFormValid}>
          {t('save')}
        </Button>
      </div>
    </div>
  )
})

const mapStateToProps = (state) => {
  return {
    isOpenModalAlert: state.modal.isOpenModalAlert,
    userChangedPassword: state.profile.userChangedPassword,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postChangePassword: (data) => dispatch(postChangePassword(data)),
    resetUserData: () => dispatch(resetUserData()),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormChangePas)
