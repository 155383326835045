import { memo } from "react";
import { t } from "i18next";
import { connect, useSelector } from "react-redux";
import OrderPayment from "src/components/OrderPayment";
import {
	addCardData,
	deleteCard,
	getUserCardById,
	getUserCards,
} from "src/components/OrderPayment/store/ccard.action";
import { changePaymentTypeOrderRoute } from "src/components/OrderRoute/order.route.action";
import PageHeader from "src/components/PageHeader";
import PageLayout from "src/components/PageLayout";

const OrderPaymentPage = (props) => {
	const { isOpenFromMenu } = useSelector((state) => state.profile);

	return (
		<>
			<PageHeader title={t("payment")} backPage={"/order"} isBackIconVisible={!isOpenFromMenu} />
			<PageLayout>
				<OrderPayment {...props} />
			</PageLayout>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		paymentType: state.odrerRoute.paymentType,
		cardFormData: state.ccard.addCardData,
		isLoading: state.ccard.isLoading,
		loginPhone: state.auth.loginPhone,
		userCardsList: state.ccard.userCardsList,
		isOpenFromMenu: state.profile.isOpenFromMenu,
		registrationCountryInfo: state.registration.registrationCountryInfo,
	};
};
export default memo(
	connect(mapStateToProps, {
		changePaymentTypeOrderRoute,
		getUserCards,
		addCardData,
		getUserCardById,
		deleteCard,
	})(OrderPaymentPage),
);
