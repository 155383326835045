/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import cls from './ModalCarNotFound.module.scss'
import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { t } from 'i18next'
import { nameComponents } from '../BottomMenu/bottom.navigate.action'
import { clearOrderRoute } from '../OrderRoute/order.route.action'

const ModalCarNotFound = ({
  changeModalBottomMenu,
  clearOrderRoute,
}) => {
  const close = () => {
    changeModalBottomMenu(null)
    clearOrderRoute()
    window.location.reload()
  }

  return (
    <Modal open={true} className={cls.modal}>
      <div className={cls.content_block}>
        <p className={cls.modal_text}>{t('car_not_found')}</p>
        <div className={cls.select_action}>
          <button className={cls.modal_second_btn} onClick={close}>
            {t('close')}
          </button>
          {/* <button className={cls.modal_second_btn} onClick={continueClick}>{t('repeat')}</button> */}
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { clearOrderRoute })(ModalCarNotFound)
