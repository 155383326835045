import React from 'react'

export const HomeIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#343330"
        stroke=""
        d="M22.5 19.5H21v-8.667a1.5 1.5 0 0 0-.485-1.105l-7.5-7.076a1.5 1.5 0 0 0-2.029-.01l-.01.01-7.491 7.076A1.5 1.5 0 0 0 3 10.833V19.5H1.5a.75.75 0 1 0 0 1.5h21a.75.75 0 1 0 0-1.5Zm-18-8.667.01-.01L12 3.75l7.49 7.071.01.01V19.5H15V15a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 9 15v4.5H4.5v-8.667Zm9 8.667h-3V15h3v4.5Z"
      />
    </svg>
  )
}
