import React, { memo, useCallback, useEffect, useMemo } from "react";
import { ReactComponent as DoubleArrow } from "src/shared/assets/icons/DoubleArrow.svg";
import { ReactComponent as DoubleArrowDark } from "src/shared/assets/icons/DoubleArrowDark.svg";
import { ReactComponent as CircleQuestion } from "src/shared/assets/icons/CircleQuestion.svg";
import { t } from "i18next";
import { connect } from "react-redux";
import { ReactComponent as CashbackIcon } from "src/shared/assets/icons/CashbackIcon.svg";
import { ReactComponent as CheckArrow } from "src/shared/assets/icons/CheckArrow.svg";
import { getValuteSymbol } from "src/services/getValuteSymbol";
import cls from "./styles.module.scss";
import { getUserData } from "src/components/ProfileForm/profile.action";
import { Link } from "react-router-dom";
import classNames from "classnames";

const OrderPriceBlock = memo((props) => {
	const {
		handleDecrementPrice,
		handleIncrementPrice,
		isMaxPrice,
		isMinPrice,
		price,
		checkBonus,
		setCheckBonus,
		symbol,
		userData,
		registrationCountryInfo,
		getUserData,
		priceBeforeDiscount,
		getOpenFromMenu,
		paymentType,
		paymentTypes,
		content,
		openDescription,
		setOpenDescription,
		usedBonuses,
		tariffCar,
	} = props;

	const isDarkTheme = localStorage.getItem("appTheme") === "dark";
	const highDemandColor = tariffCar?.highDemandColor || null;

	const currentCountry =
		registrationCountryInfo?.country_code ?? localStorage.getItem("country_code");

	useEffect(() => {
		if (!userData.id) {
			getUserData(currentCountry);
		}
	}, [currentCountry, getUserData, userData.id]);

	const cashbackBlockDisabled = isDarkTheme
		? cls.cashbackBlockDisabledDark
		: cls.cashbackBlockDisabled;
	const titleCashbackDisabled = isDarkTheme
		? cls.titleCashbackDisabledDark
		: cls.titleCashbackDisabled;

	const Img = paymentType.Img;

	const calcBonusPrice = useCallback(
		(initialPrice) => {
			return (
				<>
					<div className={cls.currentPriceBlock}>
						<p>{Math.ceil(price - usedBonuses)}</p>
						<p style={{ paddingLeft: "0px", paddingRight: "4px" }}>{symbol}</p>
					</div>
					<div className={cls.priceWithoutBonusBlock}>
						<p className={cls.priceWithoutBonus}>{initialPrice}</p>
						<p style={{ paddingLeft: "0px", paddingRight: "4px" }}>{symbol}</p>
					</div>
				</>
			);
		},
		[price, symbol, usedBonuses],
	);

	const renderPriceBlock = useMemo(() => {
		if (checkBonus) {
			return calcBonusPrice(priceBeforeDiscount || price);
		}

		if (Boolean(priceBeforeDiscount) && priceBeforeDiscount !== price) {
			return (
				<>
					<div className={cls.currentPriceBlock}>
						<p>{price}</p>
						<p style={{ paddingLeft: "0px", paddingRight: "4px" }}>{symbol}</p>
					</div>
					<div className={cls.priceWithoutBonusBlock}>
						<p className={cls.priceWithoutBonus}>{priceBeforeDiscount}</p>
						<p style={{ paddingLeft: "0px", paddingRight: "4px" }}>{symbol}</p>
					</div>
				</>
			);
		} else {
			return (
				<div className={cls.price_item}>
					<p>{price}</p>
					<p style={{ paddingLeft: "0px", paddingRight: "4px" }}>{symbol}</p>
				</div>
			);
		}
	}, [calcBonusPrice, checkBonus, price, priceBeforeDiscount, symbol]);

	const handleCheckBonus = () => {
		setCheckBonus(!checkBonus);
	};

	return (
		<>
			<div
				className={classNames(cls.price_container, {
					[cls.price_container_multi_items]: highDemandColor,
				})}
			>
				{highDemandColor && (
					<div className={cls.description}>
						<DoubleArrowDark
							className={cls.arrowIcons}
							style={{ "--high-demand-color": highDemandColor }}
						/>
						<div className={cls.titleBlock}>
							<p>{t("hign_demand")}</p>
							<p>{t("speed_up_search")}</p>
						</div>
					</div>
				)}
				<div className={cls.price_changes_block}>
					<button onClick={handleDecrementPrice}>
						<p className={`${cls.active_sign_minus} ${isMinPrice ? cls.disabled_sign : ""}`}>–</p>
					</button>
					<div className={cls.price_change_cost}>{renderPriceBlock}</div>
					<button onClick={handleIncrementPrice}>
						<p className={`${cls.active_sign} ${isMaxPrice ? cls.disabled_sign : ""}`}>+</p>
					</button>
				</div>
			</div>

			<div className={cls.services_block}>
				<div className={cls.checkboxBonus}>
					<div className={cls.question_icon}>
						<CircleQuestion onClick={() => setOpenDescription(!openDescription)} />
						{openDescription && (
							<div className={cls.questionBlock}>
								<p>{t("trips_bonuses")}</p>
							</div>
						)}
					</div>
					{Boolean(userData.bonuses) ? (
						<>
							<div
								className={`${cls.cashback_block} ${price === 0 ? cashbackBlockDisabled : ""}`}
								onClick={handleCheckBonus}
							>
								<div className={`${cls.titleCashback} ${price === 0 ? titleCashbackDisabled : ""}`}>
									<p>{t("debit")}</p>
									{checkBonus ? (
										<div className={cls.check_icon}>
											<CheckArrow />
										</div>
									) : (
										<div className={cls.cashback_icon}>
											<CashbackIcon />
										</div>
									)}
								</div>
								<div className={cls.count_cashback}>
									<p>
										{t("your_cashback")}: {userData.bonuses}
										{symbol}{" "}
									</p>
								</div>
							</div>
						</>
					) : (
						<div className={`${cls.cashback_block} ${cashbackBlockDisabled}`}>
							<div className={`${cls.titleCashback} ${titleCashbackDisabled}`}>
								<p>{t("debit")}</p>
								<div className={cls.cashback_icon}>
									<CashbackIcon />
								</div>
							</div>
							<div className={cls.count_cashback}>
								<p>
									{t("your_cashback")}: 0{symbol}{" "}
								</p>
							</div>
						</div>
					)}
				</div>
				<Link to="/order-preferences">
					<button className={cls.preferences_btn}>
						{content}
						<p>{t("options")}</p>
					</button>
				</Link>
				<Link to="/order-payment">
					<button className={cls.payment_btn} onClick={() => getOpenFromMenu(true)}>
						<div className={cls.name_payment}>
							<Img className={cls.icon_payment} />
							<p>
								{paymentType.type === paymentTypes.CARD ? paymentType.text : t(paymentType.text)}
							</p>
						</div>
					</button>
				</Link>
			</div>
		</>
	);
});

const mapStateToProps = (state) => {
	return {
		registrationCountryInfo: state.registration.registrationCountryInfo,
		userData: state.profile.userData,
		symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
	};
};

export default memo(connect(mapStateToProps, { getUserData })(OrderPriceBlock));
