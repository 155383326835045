import React, { memo } from 'react'
import { Navigate } from 'react-router-dom'
import AuthPassword from '../../components/AuthPassword'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'

const AuthPasswordPage = memo(() => {
  const accesToken = localStorage.getItem('accessToken')

  if (accesToken) {
    return <Navigate to="/order" />
  }

  return (
    <>
      <PageHeader title={t('confirm_password')} />
      <PageLayout>
        <AuthPassword />
      </PageLayout>
    </>
  )
})

export default AuthPasswordPage
