import React, { useMemo } from 'react'
import { ReactComponent as ArrowBack } from '../../../../shared/assets/icons/ArrowBack.svg';
import { ReactComponent as LastAddressPoint } from '../../../../shared/assets/icons/LastAddressPoint.svg';
import styles from './styles.module.scss';
import { t } from 'i18next'
import { Button } from 'src/shared/ui/Button/button';

const PlaceOnMap = ({ closeMap, openAnotherComponentBottomMenu, removeActiveInput, roadPoints, nameComponents }) => {

    const point = useMemo((e) => {
        const selectedPoint = roadPoints.find(e => e.active);
        if(selectedPoint && selectedPoint.name) return selectedPoint;
        const first = roadPoints[0];
        if(!first.name) first.name = first.mainText;
        return first
    },[roadPoints])

    const onClickButtonBack = () => {
        closeMap()
        removeActiveInput()
        openAnotherComponentBottomMenu(nameComponents.ORDER_ADDRESSES);
    }

    const selectPlace = () => {
        closeMap()
        removeActiveInput()
        openAnotherComponentBottomMenu(nameComponents.ORDER_ADDRESSES)
    };

    return (
        <>
            <div className={styles.arrow_back} onClick={onClickButtonBack}>
                <ArrowBack />
            </div>
            <div className={styles.placeWrapper}>
                <div className={styles.selected_addresses}>
                    <LastAddressPoint />
                    <p>{point.name}</p>
                </div>    
                <p className={styles.confirm_place}>{t('confirm_place')}</p>
                <Button fullWidth className={styles.btn} onClick={selectPlace}>
                    {t('confirm')}
                </Button>
            </div>
        </>
    )
}

export default PlaceOnMap;