import {
  SET_DRIVER_LOCATION,
  ORDER_STATUS,
  ORDER_INFO,
  CLEAR_DRIVERS,
  GET_CURRENT_ORDER_SUCCESS,
  SET_ACCEPTED_DRIVER_COORDINATES,
  GET_CURRENT_ORDER_PENDING,
  GET_CURRENT_ORDER_ERROR
} from './socket.action'

const initialState = {
  acceptedDriverCoordinates: {},
  driverData: {
    carColorName: '',
    carName: '',
    carNumber: '',
    driverName: '',
    driverPhone: '',
    driverPhoto: '',
    driverRating: '',
  },
  orderStatus: {
    orderId: '',
    status: 0,
  },
  currentOrderData: {},
  currentOrderSummaryData: {},
  currentOrderStatus: false,
  killOrder: false,
  isLoadingOrder: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_CURRENT_ORDER_PENDING: {
      return {
        ...state,
        isLoadingOrder: true
      }
    }

    case GET_CURRENT_ORDER_ERROR: {
      return {
        ...state,
        isLoadingOrder: false
      }
    }

    case SET_ACCEPTED_DRIVER_COORDINATES: {
      return {
        ...state,
        acceptedDriverCoordinates: action.payload,
      }
    }

    case ORDER_STATUS: {
      return {
        ...state,
        orderStatus: {
          orderId: action.payload.orderId,
          status: action.payload.status,
        },
        currentOrderStatus: false,
      }
    }

    case ORDER_INFO: {
      const activeDriverId = action.payload.driverId
      const newDriverData = {
        carColorName: action.payload?.carColorName,
        carName: action.payload.carName,
        carNumber: action.payload.carNumber,
        driverName: action.payload.driverName,
        driverPhone: action.payload.driverPhone,
        driverPhoto: action.payload.driverPhoto,
        driverRating: action.payload.driverRating,
        driverId: activeDriverId,
        driverLocation: {
          lat: action.payload.lat,
          lon: action.payload.lon,
        },
      }

      const driverData = newDriverData.driverPhone ? newDriverData : state.driverData

      return {
        ...state,
        driverData: driverData,
        orderStatus: {
          orderId: action.payload.orderId,
          status: action.payload.status ? action.payload.status : state.orderStatus.status,
        },
        currentOrderStatus: false,
      }
    }

    case GET_CURRENT_ORDER_SUCCESS: {
      return {
        ...state,
        isLoadingOrder: false,
        currentOrderStatus: true,
        driverData: {
          carColorName: action.payload?.carColorName,
          carName: action.payload.carName,
          carNumber: action.payload.carNumber,
          driverName: action.payload.driverName,
          driverPhone: action.payload.driverPhone,
          driverPhoto: action.payload.driverPhoto,
          driverRating: action.payload.driverRating,
          driverLocation: {
            lat: 0,
            lon: 0,
          },
        },
        currentOrderSummaryData: {
          paymentTypeId: action.payload.paymentTypeId,
          cardId: action.payload.cardId,
        },
        orderStatus: {
          orderId: action.payload.id,
          status: action.payload.statusId,
        },
        currentOrderData: {
          orderAddressInfo: {
            startAddressName: action.payload.destinationPoints[0].name,
            startAddressId: action.payload.destinationPoints[0].pointId,
            startAddressLocation: {
              lat: action.payload.destinationPoints[0].lat,
              lng: action.payload.destinationPoints[0].lon,
            },
            endAddressName: action.payload.destinationPoints[1].name,
            endAddressId: action.payload.destinationPoints[1].pointId,
            endAddressLocation: {
              lat: action.payload.destinationPoints[1].lat,
              lng: action.payload.destinationPoints[1].lon,
            },
            distance: action.payload.distance,
          },
          orderPreferences: action.payload.orderPreferences,
          priceEstimated: Number(action.payload.totalQuote),
          priceWaiting: action.payload.totalQuote - action.payload.priceEstimated,
          quotePaid: action.payload.quotePaid,
          quoteBonus: action.payload.quoteBonus,
        },
        killOrder: action.killData,
        orderLoaded: false,
        orderError: false,
      }
    }

    default:
      return {
        ...state,
      }
  }
}

export default reducer
