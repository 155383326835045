import {
  PUT_USERDATA_PENDING,
  PUT_USERDATA_SUCCESS,
  PUT_USERDATA_ERROR,
  RESET_USERDATA,
  GET_USER_COORDS_SUCCESS,
  GET_USER_COORDS_ERROR,
  POST_PRC_INFO_ERROR,
  POST_PRC_INFO_SUCCESS,
} from './registration.action'

const initialState = {
  registrationLoaded: false,
  rpcLoaded: true,
  registrationStatus: false,
  registrationMessage: false,
  registrationError: false,
  registrationCoords: null,
  registrationCoordsError: false,
  registrationCountryInfo: {},
  registrationRpcError: false,
}

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_USERDATA_PENDING: {
      return {
        ...state,
        registrationLoaded: true,
        registrationError: false,
        rpcLoaded: true,
      }
    }
    case PUT_USERDATA_SUCCESS: {
      return {
        ...state,
        registrationStatus: true,
        registrationLoaded: false,
        registrationError: false,
      }
    }
    case PUT_USERDATA_ERROR: {
      return {
        ...state,
        registrationStatus: false,
        registrationLoaded: false,
        rpcLoaded: false,
        registrationError: true,
        registrationMessage: action.payload,
      }
    }
    case GET_USER_COORDS_SUCCESS: {
      return {
        ...state,
        registrationCoords: action.payload,
        registrationLoaded: false,
        rpcLoaded: false,
      }
    }
    case GET_USER_COORDS_ERROR: {
      return {
        ...state,
        registrationCoordsError: true,
        registrationLoaded: false,
        rpcLoaded: false,
      }
    }
    case POST_PRC_INFO_SUCCESS: {
      return {
        ...state,
        registrationCountryInfo: action.payload,
        registrationLoaded: false,
        rpcLoaded: false,
      }
    }
    case POST_PRC_INFO_ERROR: {
      return {
        ...state,
        registrationRpcError: action.payload,
        registrationLoaded: false,
        rpcLoaded: false,
      }
    }
    case RESET_USERDATA: {
      return {
        ...state,
        registrationStatus: false,
        registrationError: false,
        registrationCoordsError: false,
        registrationRpcError: false,
        rpcLoaded: true,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default registrationReducer
