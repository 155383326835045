export const getSecondsUnit = (seconds) => {
  let lastWord = 'секунд'

  const lastNumber = seconds % 10
  const lastTwoNumber = seconds % 100

  if (lastTwoNumber >= 11 && lastTwoNumber <= 19) {
    lastWord = 'секунд'
  } else if (lastNumber === 1) {
    lastWord = 'секунду'
  } else if ([2, 3, 4].includes(lastNumber)) {
    lastWord = 'секунды'
  }

  return lastWord
}
