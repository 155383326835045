/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import { t } from 'i18next';
import PageLayout from '../../components/PageLayout';
import cls from './orderComment.module.scss';
import { ReactComponent as Check } from '../../images/check.svg';
import { changeCommentOrderRoute, selectedCommentIdOrderRoute } from '../OrderRoute/order.route.action';

function OrderComment({ commentMessage, selectedCommentsId, changeCommentOrderRoute, selectedCommentIdOrderRoute }) {
    const comments = [
        { id: '1', name: t('not_call'), value: t('not_call') },
        { id: '2', name: t('cost_change'), value: t('cost_change_without') },
        { id: '3', name: t('move_from'), value: t('move_from_without') },
      ];
    const [text, setText] = useState(commentMessage);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setText(inputValue);
    };

    const changeActive = (comment, isActive) => {
        if (isActive) {
            selectedCommentIdOrderRoute(null);
            setText('')
        } else {
            selectedCommentIdOrderRoute(comment.id);
            setText(comment.value)
        }
    }

    useEffect(() => {
        return () => {
            changeCommentOrderRoute(text);
        };
    }, [text, changeCommentOrderRoute]);

    return (
        <>
            <PageHeader title={t('clients_comment')} />
            <PageLayout>
                <div className={cls.comment_block}>
                    <div className={cls.comment_area}>
                        <textarea
                            placeholder={t('write_comment')}
                            maxLength={195}
                            value={text}
                            onChange={handleChange}
                        />
                        <p className={cls.text_counter}>{text.length}/195</p>
                    </div>
                    <div className={cls.comment_services}>
                        {comments.map((comment, index) => {
                            const isActiveComments = selectedCommentsId === comment.id;
                            return <button
                                key={comment.id}
                                className={`${cls.comment_item} ${isActiveComments ? cls.active_comment : ''}`}
                                onClick={() => changeActive(comment, isActiveComments)}
                            >
                                <div className={`${cls.comment_single_item} ${index === comments.length - 1 ? cls.last_comment : ''}`}>
                                    <p>{comment.name}</p>
                                    {isActiveComments && (
                                        <div className={cls.check}>
                                            <Check />
                                        </div>
                                    )}
                                </div>
                            </button>
                        })}
                    </div>
                </div>
            </PageLayout>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        commentMessage: state.odrerRoute.commentMessage,
        selectedCommentsId: state.odrerRoute.selectedCommentsId,
    };
};


export default connect(mapStateToProps, { changeCommentOrderRoute, selectedCommentIdOrderRoute })(OrderComment);