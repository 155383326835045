import React from 'react'

export const CardIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} {...props}>
      <path
        className={className}
        fill="#212121"
        d="M21 4.5H3A1.5 1.5 0 0 0 1.5 6v12A1.5 1.5 0 0 0 3 19.5h18a1.5 1.5 0 0 0 1.5-1.5V6A1.5 1.5 0 0 0 21 4.5ZM21 6v2.25H3V6h18Zm0 12H3V9.75h18V18Z"
      />
      <circle cx={14.5} cy={14.5} r={2} stroke="#212121" />
      <circle cx={17.5} cy={14.5} r={2} stroke="#212121" />
    </svg>
  )
}
