import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as LtLitters } from 'src/images/lt-litters.svg'
import cls from './Loader.module.scss'
import { classNames } from '../../lib/classNames/classNames'

const useStylesLoader = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  bottom: {
    // color: localStorage.getItem('appTheme') === 'light' ? '#EEEEEE' : '#444348', // меньшая часть
    color: '#EEEEEE', // меньшая часть
  },
  'MuiCircularProgress-circle': {
    strokeDasharray: ['25px', '200px'],
  },
  top: {
    color: 'var(--dark-blue-color)',
    animationDuration: '550ms',
    position: 'absolute',
  },
  circle: {
    strokeLinecap: 'round',
  },
}))

export const Loader = ({ className }) => {
  const classes = useStylesLoader()

  return (
    <div className={classNames(cls.Loader, {}, [className])}>
      <div className={classNames(cls.progress, {}, [classes.root])}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={75}
          thickness={3}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classNames(cls.animationBlock, {}, [classes.top])}
          size={75}
          classes={{
            circle: classes.circle,
          }}
          thickness={3}
        />
        <div className={cls.logoWord}>
          <LtLitters className={cls.CarmaLogoWord} />
        </div>
      </div>
    </div>
  )
}

export default Loader
