import React, { useCallback, useEffect, useRef, useState } from "react";
import { classNames } from "../../lib/classNames/classNames";
import cls from "./SmsInput.module.scss";

let currentSmsIndex = 0;

export const SmsInput = ({
	className,
	smsCode,
	setSmsCode,
	children,
	changeSmscode,
	ckeckCode,
	iosCallback = () => null,
	...otherProps
}) => {
	const [activeSmsIndex, setActiveSmsIndex] = useState(0);
	const inputsRef = useRef(null);

	useEffect(() => {
		setActiveSmsIndex(0);
	}, []);

	const handleOnChange = useCallback(
		({ target }) => {
			const { value } = target;
			//for ios code from message
			if (value.length === 4) {
				changeSmscode(value.split(""));
				return iosCallback();
			}
			const newSmsCode = [...smsCode];
			newSmsCode[currentSmsIndex] = value.substring(value.length - 1);

			setActiveSmsIndex(currentSmsIndex + 1);

			if (!value) {
				setActiveSmsIndex(currentSmsIndex - 1);
			} else {
				setActiveSmsIndex(currentSmsIndex + 1);
			}

			setSmsCode(newSmsCode);
			changeSmscode(newSmsCode);
		},
		[smsCode, changeSmscode],
	);

	const handleOnKeyDown = useCallback(
		({ key }, index) => {
			currentSmsIndex = index;
			if (key === "Backspace") {
				setActiveSmsIndex(currentSmsIndex - 1);
			}

			const fullSmsCode = smsCode.join("");
			if (key === "Enter" && fullSmsCode.length === 4) {
				ckeckCode();
			}
		},
		[ckeckCode, smsCode],
	);

	useEffect(() => {
		if (inputsRef.current) {
			inputsRef.current.focus();
		}
	}, [activeSmsIndex]);

	return (
		<div className={classNames(cls.SmsInput, {}, [className])}>
			{smsCode.map((_, index) => {
				return (
					<input
						{...otherProps}
						type="number"
						ref={index === activeSmsIndex ? inputsRef : null}
						key={index}
						value={smsCode[index]}
						onChange={handleOnChange}
						onKeyDown={(e) => handleOnKeyDown(e, index)}
					/>
				);
			})}
		</div>
	);
};
