import { useState, useCallback } from 'react'
import { parseLocalStorageItem } from '../helpers/parseLocalStorageItem'

export const useCoordinatesUtils = (mapName) => {
  const storedCoordsClient = parseLocalStorageItem('coords')

  const defaultCenterGoogleMaps = {
    latitude: storedCoordsClient?.latitude || 0,
    longitude: storedCoordsClient?.longitude || 0,
  }

  const [center, setCenter] = useState(defaultCenterGoogleMaps)

  const [coordsClient, setCoordsClient] = useState(storedCoordsClient || null)

  const setStandardCoordinates = useCallback(() => {
    localStorage.setItem('coords', null)
    setCoordsClient(null)
  }, [])

  const checkLocalStorageCoords = useCallback(() => {
    const coordsClientStorage = parseLocalStorageItem('coords')

    if (coordsClientStorage?.latitude && coordsClientStorage?.longitude) {
      setCoordsClient({
        latitude: coordsClientStorage.latitude,
        longitude: coordsClientStorage.longitude,
      })

      if (mapName === 'googleMap') {
        setCenter({
          latitude: coordsClientStorage.latitude,
          longitude: coordsClientStorage.longitude,
        })
      }
    }
  }, [mapName])

  const updateLocalStorageCoords = useCallback(() => {
    const coordsClientStorage = parseLocalStorageItem('coords')

    if (
      coordsClientStorage &&
      (coordsClientStorage.longitude !== coordsClient?.longitude ||
        coordsClientStorage.latitude !== coordsClient?.latitude)
    ) {
      setCoordsClient({
        latitude: coordsClientStorage.latitude,
        longitude: coordsClientStorage.longitude,
      })
    }
  }, [coordsClient])

  return {
    coordsClient,
    center,
    setStandardCoordinates,
    checkLocalStorageCoords,
    updateLocalStorageCoords,
  }
}
