import {
  GET_LOGIN_PENDING,
  GET_LOGIN_CHECK,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_ERROR,
  GET_LOGOUT,
  RESET_LOGIN_DATA,
  SET_COUNTRY_PHONE_CODE,
} from './auth.action'

const initialState = {
  loginLoaded: false,
  loginPhoneCheck: '',
  loginPhone: '',
  loginStatus: false,
  loginError: false,
  countryCode: '+380',
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN_PENDING: {
      return {
        ...state,
        loginLoaded: true,
      }
    }
    case GET_LOGIN_CHECK: {
      return {
        ...state,
        loginLoaded: false,
        loginPhoneCheck: action.payload.check,
        loginPhone: action.payload.phone,
        loginError: false,
      }
    }
    case GET_LOGIN_SUCCESS: {
      return {
        ...state,
        loginLoaded: false,
        loginStatus: action.payload.loginStatus,
        loginPhone: action.payload.phone,
      }
    }
    case GET_LOGIN_ERROR: {
      return {
        ...state,
        loginLoaded: false,
        loginStatus: false,
        loginError: action.payload,
      }
    }
    case GET_LOGOUT: {
      return {
        ...state,
        authStatus: false,
      }
    }
    case SET_COUNTRY_PHONE_CODE: {
      return {
        ...state,
        countryCode: action.payload,
      }
    }
    case RESET_LOGIN_DATA: {
      return {
        ...state,
        loginLoaded: false,
        loginPhoneCheck: '',
        loginStatus: false,
        loginError: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default authReducer
