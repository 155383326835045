import { memo, useCallback, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { maxLimitPaymentCards } from "src/config-in-git";
import Loader from "src/shared/ui/Loader/Loader";
import { ReactComponent as MasterCard } from "../../images/MasterCardIcon.svg";
import { Button, ButtonVariant } from "../../shared/ui/Button/button";
import { paymentsCart } from "../OrderRoute/order.route.action";
import AddCardForm from "./components/AddCardForm";
import CardType from "./components/CardType";
import { setCardToFormat } from "./helpers/setCardToFormat";
import Modal from "src/shared/ui/Modal/Modal";
import AlertModal from "../Modals/ui/AlertModal/AlertModal";
import { PaymentsMethodsId } from "src/shared/variables/payments";
import cls from "./orderPayment.module.scss";

const defaultCashPayment = [{ id: "cash", type: "CASH", Img: paymentsCart.Img, text: "cash" }];
const maxAttempts = 40;
const pollingInterval = 500;

const formatCards = (cards, cardFormatter) =>
	cards.map((card) => setCardToFormat(card, cardFormatter));

const OrderPayment = (props) => {
	const {
		paymentType,
		cardFormData,
		loginPhone,
		changePaymentTypeOrderRoute,
		getUserCards,
		addCardData,
		userCardsList,
		isOpenFromMenu,
		getOpenFromMenu,
		isLoading,
		registrationCountryInfo,
		getUserCardById,
		deleteCard,
	} = props;

	const [cards, setCards] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [cardToDeleteId, setCardToDeleteId] = useState(null);
	const [selectedPaymentId, setSelectedPaymentId] = useState(
		localStorage.getItem("selectedPaymentId") ?? PaymentsMethodsId.CASH,
	);

	const pollingTimeoutRef = useRef(null);
	const attemptCountRef = useRef(0);

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const isLimitedCards = userCardsList.totalCards >= maxLimitPaymentCards;
	const clientCountry =
		registrationCountryInfo.country_code ?? localStorage.getItem("country_code");

	const pullCardStatus = useCallback(async () => {
		const cardId = localStorage.getItem("cardId");
		const shouldContinuePolling = attemptCountRef.current < maxAttempts;

		const status = await getUserCardById(cardId);

		if (status === "Pending" && shouldContinuePolling) {
			pollingTimeoutRef.current = setTimeout(async () => {
				attemptCountRef.current += 1;
				await pullCardStatus();
			}, pollingInterval);
		} else {
			clearTimeout(pollingTimeoutRef.current);
			attemptCountRef.current = 0;
			localStorage.removeItem("cardId");
			navigate("/order-payment");
		}
	}, [getUserCardById, navigate]);

	const handleGoBack = useCallback(() => {
		navigate("/order");
	}, [navigate]);

	const onOpenDeleteModal = useCallback((cardId) => {
		setCardToDeleteId(cardId);
		setShowModal(true);
	}, []);

	const onDeleteCardById = useCallback(async () => {
		await deleteCard(cardToDeleteId);

		const selectedPaymentId = localStorage.getItem("selectedPaymentId");

		if (cardToDeleteId === selectedPaymentId) {
			localStorage.setItem("selectedPaymentId", PaymentsMethodsId.CASH);
			setSelectedPaymentId(PaymentsMethodsId.CASH);
		}

		setShowModal(false);
	}, [cardToDeleteId, deleteCard]);

	//Requesting user cards
	useEffect(() => {
		const fetchUserCards = async () => {
			const phone = localStorage.getItem("userPhone");

			const userInfo = {
				country: clientCountry,
				phone,
			};

			await Promise.all([getUserCards(), addCardData(userInfo)]);
		};

		fetchUserCards();
	}, [addCardData, clientCountry, getOpenFromMenu, getUserCards]);

	// Make request every 500ms if the user has added a card
	useEffect(() => {
		if (searchParams.size) {
			pullCardStatus();
		} else {
			localStorage.removeItem("cardId");
		}

		return () => {
			clearTimeout(pollingTimeoutRef.current);
		};
	}, [pullCardStatus, searchParams.size]);

	useEffect(() => {
		const { stripe = [], wayForPay = [], totalCards } = userCardsList || {};

		if (stripe.length || wayForPay.length) {
			const stripeCards = formatCards(stripe, MasterCard);
			const wayforpayCards = formatCards(wayForPay, MasterCard);

			const allCards = [...defaultCashPayment, ...stripeCards, ...wayforpayCards];

			setCards(allCards);
		}

		if (!totalCards) {
			setCards(defaultCashPayment);
		}
	}, [userCardsList]);

	if (isLoading || !cardFormData.gatewayType || searchParams.size) {
		return <Loader />;
	}

	return (
		<>
			<div className={cls.payment_block}>
				<Modal
					isOpen={showModal}
					onClose={() => setShowModal(false)}
					onConfirm={onDeleteCardById}
					isControlAction
				>
					<AlertModal title={t("want_to_delete_this_debit_card")} />
				</Modal>
				{isOpenFromMenu && <h1>{t("payment_type")}</h1>}
				{cards.length &&
					cards.map((card) => (
						<CardType
							key={card.id}
							changeType={changePaymentTypeOrderRoute}
							activeType={paymentType}
							setSelectedPaymentId={setSelectedPaymentId}
							selectedPaymentId={selectedPaymentId}
							onOpenDeleteModal={() => onOpenDeleteModal(card.id)}
							{...card}
						/>
					))}

				<AddCardForm
					cardFormData={cardFormData}
					loginPhone={loginPhone}
					isLimitedCards={isLimitedCards}
					className={cls.add_card_btn}
				/>
			</div>

			{isOpenFromMenu && (
				<div className={cls.continueBtn}>
					<Button variant={ButtonVariant.background} fullWidth onClick={handleGoBack}>
						{t("confirm")}
					</Button>
				</div>
			)}
		</>
	);
};

export default memo(OrderPayment);
