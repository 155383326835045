import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '../../common/material-ui-icons/ArrowBackIcon'

import './Header.scss'

class Header extends Component {
  /**
   * @type {{link: *, title: *}}
   */
  static propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className="main-header pt-4 pb-4 px-3">
        <div className="row">
          <div className="col-2 pt-3">
            {this.props.link.length === 0 ? null : (
              <Link className="main-header__back" to={this.props.link}>
                <ArrowBackIcon />
              </Link>
            )}
          </div>
          <div className="col-8 pt-3">
            <h1>{this.props.title}</h1>
          </div>
          <div className="col-2" />
        </div>
      </div>
    )
  }
}

export default Header
