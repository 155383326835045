import React, { Component } from 'react'

import './TextBlock.scss'

class TextBlock extends Component {
  render() {
    return <div className="text-block">{this.props.text}</div>
  }
}

export default TextBlock
