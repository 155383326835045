import { useEffect, useMemo, useRef, useState } from 'react'
import colors from '../../../style/colors/index'
import createPolyline from '../helpers/createPolyline'
import { calculatePointOnLine } from '../helpers/calculatePointOnLine.js'
import useCreatePoints from './useCreatePoints'

const fullRouteId = 'fullRouteId'
const passedRouteId = 'passedRouteId'

const useBuildFullRoute = (props) => {
  const { polylinePositions, map, currentTheme, coordsDriver, mapPoints, mapLoaded } = props

  const [newPolyline, setNewPolyline] = useState(null)

  const lastPointIndx = useRef(null)

  const finalPolyline = useMemo(() => {
    if (polylinePositions && mapLoaded) {
      let pointIndx1
      let pointIndx2
      let currentLustPoint = lastPointIndx.current
      const polyPos = [...polylinePositions]
      if (coordsDriver) {
        // get point on line between two near points
        const result = calculatePointOnLine(
          map,
          [coordsDriver[1], coordsDriver[0]],
          polylinePositions
        )

        if (result && result?.nearsPoints) {
          const { nearsPoints, coords } = result

          const pointOnLine = [coords[1], coords[0]]

          const nearsPoint1 = nearsPoints[0].reverse()
          const nearsPoint2 = nearsPoints[1].reverse()

          polyPos.forEach((item, index) => {
            if (JSON.stringify(item) === JSON.stringify(nearsPoint1)) {
              pointIndx1 = index
            }

            if (JSON.stringify(item) === JSON.stringify(nearsPoint2)) {
              pointIndx2 = index
            }
          })

          let lowerIndx = pointIndx1

          if (pointIndx1 > pointIndx2) {
            lowerIndx = pointIndx2
          }
          polyPos.splice(lowerIndx + 1, 0, pointOnLine)

          polyPos.forEach((item, index) => {
            if (JSON.stringify(item) === JSON.stringify(pointOnLine)) {
              currentLustPoint = index
            }
          })
        }
      }

      lastPointIndx.current = currentLustPoint ? currentLustPoint : 0
      setNewPolyline(polyPos)

      const newPolyPoints = polyPos.slice(lastPointIndx.current)
      return newPolyPoints
    }

    return null
  }, [coordsDriver, map, mapLoaded, polylinePositions])

  // create route
  useEffect(() => {
    if (map.current && newPolyline && mapLoaded && finalPolyline) {
      let passedRoute = []
      if (lastPointIndx.current) {
        passedRoute = newPolyline.slice(0, lastPointIndx.current + 1)
      }

      localStorage.setItem('passedRoute', JSON.stringify(passedRoute))

      // create polylines
      const routeGeoJSON = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: polylinePositions,
        },
      }

      const passedRouteGeoJSON = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: passedRoute,
        },
      }

      // load route on first render

      const fullRouteStyles = {
        id: fullRouteId,
        type: 'line',
        source: fullRouteId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': `${colors[currentTheme].routeColor}`,
          'line-width': 10,
        },
      }

      const passedRouteStyles = {
        id: passedRouteId,
        type: 'line',
        source: passedRouteId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#A1A1A1', // Color of the polyline
          'line-width': 10, // Width of the polyline
        },
      }

      if (passedRoute.length > 0) {
        createPolyline({
          map,
          sourceId: passedRouteId,
          routeGeoJSON: passedRouteGeoJSON,
          layoutStyles: passedRouteStyles,
        })
      }

      createPolyline({
        map,
        sourceId: fullRouteId,
        routeGeoJSON,
        layoutStyles: fullRouteStyles,
      })
    }
  }, [mapLoaded, finalPolyline, newPolyline, map, currentTheme, polylinePositions])

  //create points
  // useCreatePoints({
  //   map,
  //   mapPoints,
  //   mapLoaded,
  //   newPolyline,
  //   coordsDriver,
  // })
}

export default useBuildFullRoute
