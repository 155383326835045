import React, { useState } from 'react';
import cls from './RatingOrder.module.scss';
import { ReactComponent as ReviewIcon } from '../../images/ReviewIcon.svg';

const RatingOrder = ({setRating, rating}) => {
   

    const handleIconClick = (value) => {
      if (rating === value) {
        setRating(null);
      } else {
        setRating(value);
      }
    };
  return (
    <div className={cls.ratingIcons}>
      {[1, 2, 3, 4, 5].map((value) => (
        <span
          key={value}
          className={`${cls.icon} ${rating >= value ? cls.active : ''}`}
          onClick={() => handleIconClick(value)}
        >
          <ReviewIcon />
        </span>
      ))}
    </div>
  );
};

export default RatingOrder;
