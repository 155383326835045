import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'
import { classNames } from '../../shared/lib/classNames/classNames'
import './Dialog.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SimpleDialog = (props) => {
  const {
    open,
    title = t('back'),
    hasTitle = true,
    fullScreen,
    content,
    header,
    close,
    hideBack,
    isHide,
    showControl,
    control,
    onClick,
  } = props
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={fullScreen}
        onClose={close}
        className="dialog-wrapper"
      >
        <PageHeader
          title={hasTitle && title}
          className={classNames('DialogHeader', { small_header: !hasTitle }, [])}
          onClick={onClick}
        />
        <PageLayout className={classNames('PageLayout', { small_layout: !hasTitle }, [])}>
          {header && (
            <DialogTitle id="alert-dialog-slide-title">
              <div className="header"></div>
            </DialogTitle>
          )}

          {content}
        </PageLayout>
      </Dialog>
    </>
  )
}

export default SimpleDialog
