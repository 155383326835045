import { SHOW_MODAL, SHOW_MODAL_ACTION, SHOW_MODAL_ALERT } from './modal.action'

const initialState = {
  isOpenModal: false,
  isOpenModalAlert: false,
  isOpenModalAction: false,
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL_ALERT: {
      return {
        ...state,
        isOpenModalAlert: action.payload,
      }
    }
    case SHOW_MODAL_ACTION: {
      return {
        ...state,
        isOpenModalAction: action.payload,
      }
    }
    case SHOW_MODAL: {
      return {
        ...state,
        isOpenModal: action.payload,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default modalReducer
