/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import { EDIT_FIRST_MARKET_MARKET, EDIT_ID_MAP_MARKET, GET_GEOLOCATION_MAP_MARKET, GET_ME_MARKER, REMOVE_ONE_MARKER, SAVE_NEW_MARKER } from "./MapMarkers.action"

const initialState = {
  geolocation : null,
  currentMarketId : null,
  firstMarket : null,
  firstAdress : null,
  meMarker : null,

  markets : [
    {
      // market : null,
      // adress : null
    },
    {},
    {},
    {},
    {}
  ] 

}

const reducer = (state = initialState, action) => {
  switch (action.type) {
   
    case GET_GEOLOCATION_MAP_MARKET : return {
      ...state,
      geolocation : action.payload
    }
    case EDIT_ID_MAP_MARKET : return {
      ...state,
      currentMarketId : action.payload
    }
    case SAVE_NEW_MARKER : return {
      ...state,
      markets : action.payload
    }
    case REMOVE_ONE_MARKER : return {
      ...state,
      markets : action.payload
    }
    case GET_ME_MARKER : return {
      ...state,
      meMarker : action.payload
    }
    case EDIT_FIRST_MARKET_MARKET : return {
      ...state,
      firstMarket : action.payload.firstMarket,
      firstAdress : action.payload.firstAdress,  
    }
   
    default:
      return {
        ...state,
      }
  }
}

export default reducer
