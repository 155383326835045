import axios from "axios";
import { api } from "./config";

// Default config options
const defaultOptions = {
	withCredentials: true,
	headers: { "Access-Control-Allow-Origin": "*", crossDomain: true },
};

const createResponseInterceptor = (providedInstance) => {
	let isRefreshed = false;

	providedInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalRequest = error.config;

			if (error.response?.status === 401 && !isRefreshed) {
				isRefreshed = true;
				try {
					const refreshToken = localStorage.getItem("refreshToken");
					const result = await providedInstance.post(`${api}/tokens/${refreshToken}/refresh`, {
						refreshToken: refreshToken,
					});

					if (result.status === 200) {
						if (result.data && result.data.refreshToken) {
							localStorage.setItem("refreshToken", result.data.refreshToken);
						}

						if (result.data && result.data.accessToken) {
							localStorage.setItem("accessToken", result.data.accessToken);
							originalRequest.headers["Authorization"] = `Bearer ${result.data.accessToken}`;
							// Retry the original request with the updated token
							return providedInstance(originalRequest);
						}
					}
				} catch (error) {
					localStorage.clear();
					return (window.location.href = "/");
				}
			}

			// For other errors, reject the promise
			console.log("providedInstance interceptors: ", error);
			return Promise.reject(error);
		},
	);
};

// default axiosInstance and errors handler
export const axiosInstance = axios.create(defaultOptions);

// axiosInstance with token and errors handler
export const axiosInstanceWithToken = axios.create(defaultOptions);

axiosInstanceWithToken.interceptors.request.use(function (config) {
	const accessToken = localStorage.getItem("accessToken");
	config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
	return config;
});

createResponseInterceptor(axiosInstance);
createResponseInterceptor(axiosInstanceWithToken);
