import React, { useState, useCallback } from 'react'

const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [dialogData, setDialogData] = useState(null)

  const openDialog = (data) => {
    setIsOpen(true)
    if (data) {
      setDialogData(data)
    }
  }

  const closeDialog = () => {
    setIsOpen(false)
    setDialogData(null)
  }

  return {
    isOpen,
    dialogData,
    openDialog,
    closeDialog,
  }
}

export default useDialog
