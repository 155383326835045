import React from 'react'
import { Navigate } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import SendCode from '../../components/SendCode'
import { t } from 'i18next'
import { withTranslation } from 'react-i18next'

/**
 * Страница с отправки кода в сообщении
 *
 * @returns {*}
 * @constructor
 */

function SendCodePage() {
  const accesToken = localStorage.getItem('accessToken')

  if (accesToken) {
    return <Navigate to="/order" />
  }

  return (
    <>
      <PageHeader title={t('confirm_number')} />
      <PageLayout>
        <SendCode />
      </PageLayout>
    </>
  )
}

export default withTranslation()(SendCodePage)
