import React from 'react'
import { connect } from 'react-redux'
import { t } from 'i18next'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import ChatHistory from '../../components/ChatHistory'
import { getUserChats, putChatSeen } from './store/chat.action'
import { sendMessage } from '@microsoft/signalr/dist/esm/Utils'
import cls from './chatHistoryPage.module.scss'

const ChatHistoryPage = (props) => {
  return (
    <>
      <PageHeader title={t('online_help')} />
      <PageLayout className={cls.scrollable}>
        <ChatHistory {...props} />
      </PageLayout>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userChats: state.chat.userChats,
    userChat: state.chat.userChat,
    loading: state.chat.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserChats: () => dispatch(getUserChats()),
    putChatSeen: (chatId) => dispatch(putChatSeen(chatId)),
    sendMessage: (header, message) => dispatch(sendMessage(header, message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistoryPage)