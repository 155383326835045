import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languagesNames } from 'src/mock/languages'

const supportedLanguages = languagesNames.map((lang) => lang.locale.toLowerCase())
const translations = {}
supportedLanguages.forEach((lang) => {
  translations[lang] = {
    translations: require(`./locales/${lang}/translations.json`),
  }
})

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem('language_name')?.toLowerCase() || 'uk',
  lng: localStorage.getItem('language_name')?.toLowerCase() || 'uk',
  resources: { ...translations },
  ns: ['translations'],
  defaultNS: 'translations',
})

i18n.languages = supportedLanguages

export default i18n
