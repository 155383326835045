// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

import { findNearsetPoinInRoute } from '../helpers/findSegment'

export function calculatePointOnLine(map, coordsDriver, polylinePositions) {
  if (polylinePositions.length > 1) {
    let cleanPolylinePositions
    // polylinePositions.forEach(item => item.reverse())
    let reversedPolylinePositions = polylinePositions
      .map((item) => [item[1], item[0]])
      .filter((item) => JSON.stringify(item) !== JSON.stringify(coordsDriver))

    cleanPolylinePositions = reversedPolylinePositions

    //get location of neares point in route
    const result = findNearsetPoinInRoute(cleanPolylinePositions, coordsDriver)

    return result
  }

  return null
}
