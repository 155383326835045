// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

const createMarker = ({
  map,
  markerElement,
  position,
  markerRef,
  rotation,
  className,
  markerId,
  length
}) => {
  const addMarkerToMap = () => {

    const marker = new maplibregl.Marker({
      element: markerElement,
      rotationAlignment: 'map',
      pitchAlignment: 'map',
      anchor: 'bottom',
      offset: [6, 0],
      className: className,
    }).setLngLat(position).addTo(map.current)

    return marker
  }

  const removeMarkerById = (id) => {
    if (!map.current) {
      return
    }

    const marker = map.current.getLayer(id)
    if (marker) {
      marker.remove()
    }
  }

  switch (markerRef?.current) {
    case null:
      const marker = addMarkerToMap()
      markerRef.current = marker
      markerRef.current.removeMarker = () => removeMarkerById(markerId)
      markerRef.current.id = markerId
      break
    case undefined:
      addMarkerToMap()
      break
    default:
      markerRef.current.setLngLat(position)
      if (rotation) {
        markerRef.current.setRotation(rotation)
      }
  }
}

export default createMarker
