import React, { useCallback } from 'react';
import debounce from "lodash.debounce";
import { fetchSuggestions } from '../../helpers/fetchSuggestions';
import { fetchLogSelectedAddress } from '../../helpers/fetchLogSelectedAddress';
import { ReactComponent as AutocompleteIcon } from '../../../../shared/assets/icons/AutocompleteIcon.svg';
import { useMap } from 'src/entities/MapWrapper/context/MapContext';
import styles from "./styles.module.scss"
import { textFormator } from 'src/components/OrderRoute';
import { disactiveInputFocus } from '../OrderAddresses';

export const AutocompleteSuggestions = (props) => {
    const {
        setPoint,
        name,
        passengerCoords,
        removeActiveInput,
        setIsAutocompleteSelection,
        setSuggestionPlaces,
        suggestionPlaces,
        setCanCreateRoute,
        setManualPoint,
        registrationCountryInfo,
    } = props;

    const { isInputActive, setIsInputActive } = useMap();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetSuggestions = useCallback(
        debounce(async (addressQuery) => {
            const params = {
                addressQuery,
                userLocation: {
                    lat: passengerCoords[1] || null,
                    lon: passengerCoords[0] || null,
                },
                autocomplete: true,
                registrationCountryInfo
            };

            const { data } = await fetchSuggestions(params);
            setSuggestionPlaces(data);
        }, 300),
        [],
    );

    const handleSelectInput = async (infoAddress) => {
        const { address, latitude, longitude, mapProvider, properties } = infoAddress;

        setPoint({
            lat: latitude,
            lon: longitude,
            name: address,
        });

        if (mapProvider && properties) {
            await fetchLogSelectedAddress(infoAddress);
        }

        setIsInputActive(false);
        setIsAutocompleteSelection(true);
        await setSuggestionPlaces([]);
        await handleApplyPoint()
    };

    const handleApplyPoint = useCallback(async () => {
        removeActiveInput();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInputActive, name, removeActiveInput, setCanCreateRoute, setManualPoint]);

    return (
        <div>
            {suggestionPlaces && suggestionPlaces?.length > 0 && (
                <>
                    {suggestionPlaces?.map((suggestion, idx) => (
                        <div className={styles.singleSuggestion} key={idx} onClick={() => {
                            handleSelectInput(suggestion);
                            disactiveInputFocus();
                        }}>
                            <AutocompleteIcon />
                            <div className={styles.textBlock}>
                                <p className={styles.mainTextSuggest}>{textFormator(suggestion.address).mainText}</p>
                                <p className={styles.secondaryTextSuggest}>{textFormator(suggestion.address).secondaryText}</p>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};