/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

export const ChangeMapSize = ({ window, state }) => {
    const map = document.getElementById('map')
 const changeSizeMap = () => {
    if (window === "CAR_PRICE") {
        if(map) {
            map.style.height = '55vh'
        }
    } else if (window === "SEARCH_CAR" || window === "ORDER_ACCEPTED" || window === "ORDER_PROGRESS" || window === "ORDER_COMPLETED") {
        if(map) map.style.height = '80vh'
    } else if (window === "ORDER_EXPECT") {
        if(map) map.style.height = '67vh'
    } else if (window === "ORDER_REVIEW") {
        if(map) map.style.height = '100vh'
    }
  }
    useEffect(changeSizeMap,[state])
    
  changeSizeMap()
    
    return (
        <>
        </>
    );
};

const mapStateToProps = state => {
    return {
        window: state.bottomNavigate.nameComponent,
        state
    };
};

export default connect(mapStateToProps, {})(ChangeMapSize);
