import React, { forwardRef } from "react";
import { CircularProgress } from "@mui/material";
import { classNames } from "../../lib/classNames/classNames";
import cls from "./button.module.scss";

export const ButtonVariant = {
	background: "background",
	select: "select",
	clear: "clear",
	success: "success",
	outline: "outline",
};

export const ButtonSize = {
	S: "size_s",
	M: "size_m",
	L: "size_l",
	XL: "size_xl",
};

export const ButtonComponent = (props, ref) => {
	const {
		className,
		children,
		variant = ButtonVariant.background,
		fullWidth,
		disabled,
		isLoading = false,
		loaderSize = 30,
		size = ButtonSize.L,
		...otherProps
	} = props;

	const mods = {
		[cls.disabled]: disabled,
		[cls.fullWidth]: fullWidth,
	};

	return (
		<button
			ref={ref}
			type="button"
			className={classNames(cls.Button, mods, [className, cls[variant], cls[size]])}
			disabled={disabled}
			{...otherProps}
		>
			{isLoading ? <CircularProgress className={cls.circularProgress} color="inherit" size={loaderSize} /> : children}
		</button>
	);
};

export const Button = forwardRef(ButtonComponent);
