/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'

import AutocompleteDirectionsHandler from './AutocompleteDirectionsHandler'
import { stylesStructure } from './mapBlackStyle'
import { getPreferences, getCities, getCitiesTariff } from '../Dictionarys/dictionary.action'
import { getUserData } from '../ProfileForm/profile.action'
import { postBonus, cancelOrder, updPos, getOrderCost } from './order.action'
import { getCurrentOrder } from '../SocketConnection/socket.action'
import { getUserCards } from '../OrderPayment/store/ccard.action'
import { getLastRide } from '../History/store/history.action'
import { getFavoriteAddress } from '../FavoriteAddress/favorite.action'

import SimpleDialog from '../../common/Dialog'
import { ReactComponent as UserLocation } from '../../images/userLocation.svg'
import CircularProgress from '../../common/Preloader'
import Checkbox from '../../common/Checkbox'
import SimpleSelect from '../../common/Select'
import OrderPopup from './OrderPopup'

import LocationOnIcon from '../../common/material-ui-icons/LocationOnIcon'
import AddCircleOutlineIcon from '../../common/material-ui-icons/AddCircleOutlineIcon'
import AddIcon from '../../common/material-ui-icons/AddIcon'
import RemoveIcon from '../../common/material-ui-icons/RemoveIcon'
import CheckIcon from '../../common/material-ui-icons/CheckIcon'
import AccountCircleIcon from '../../common/material-ui-icons/AccountCircleIcon'
import addIcon from '../../images/add.svg'
import userIcon from '../../images/userLocation.svg'
import carIcon from '../../images/car.svg'
import carClass1 from '../../images/1.svg'
import carClass2 from '../../images/2.svg'
import carClass3 from '../../images/3.svg'
import roadIcon from '../../images/road.svg'
import searchIcon from '../../images/search.svg'
import timeIcon from '../../images/time.svg'
import timeActive from '../../images/timeActive.svg'
import phoneIcon from '../../images/phone.svg'
import iconCash from '../../images/cash.svg'
import iconPay from '../../images/mastercard.svg'
import { ReactComponent as LocationTracker } from '../../images/LocationTracker.svg'
import userLocation from '../../images/userLocation.svg'

import { MAXIMUM_FARE_MULTIPLIER, PRICE_CHANGE_LIMIT } from '../../config-in-git'
import './MapJs.scss'
import cls from './orders.module.scss'
import './OrderForm.scss'
import { t } from 'i18next'
import {
  changeStartPlaceOrderRoute,
  getGoogleMapCoordinates,
} from '../OrderRoute/order.route.action'
import BottomMenu from '../BottomMenu'
import { getGeolocationMapMarket, getMeMarker } from '../MapMarkers/MapMarkers.action'

// Хрень в которой вся карта
let map
let userPosition = false
let mapLoader = true

// remove adress from last seance
localStorage.removeItem('startPlace')
class MapJs extends Component {
  state = {
    mapRef: null,
    showDetails: false,
    pos: {
      lat: 0,
      lon: 0,
    },

    stepFavoriteAddress: false,
    stepAddress: false,
    stepFindCar: false,
    stepCheckMarker: false,
    // classMarker: '',
    stepCheckMarkerName: '',
    confirmedAdress: false,
    checkGPS: false,
    popupPayment: false, // Попап выбора оплаты
    popupRoadError: false, // Попап c ошибками построения маршрута
    popupRoadLimit: false, // Попап c ошибками построения маршрута
    // popupCityLimit: false, // Попап c ошибками несоответствия выбраного города
    popupAddressError: false, // Попап c ошибками построения маршрута
    popupCancelOrder: false,
    cancelByUser: false,
    stepAddPreferences: false, // Открытие попапа с предпочтениями
    checkHillOrder: false,
    newRoute: false,
    orderData: {}, // Объект для данных ордера
    activePrice: 0, // Последняя цена со всеми плюшками
    preferencesPrices: 0, // Сумма всех выбранных доп. опций
    checkedPreferences: [], // Выбраные доп опции
    activeCity: 0,
    activeCarClass: 0, // Активный клас авто
    activeCreditCard: '0', // Идентификатор активной карты или 0 если кеш
    activePayment: 0, // Способ оплаты 0-кеш 1-карта 2-кешбонус 3-картабонус
    checkBonus: false, // Статус выбраны ли бонусы
    bonusCount: 0, // Количество выбраных бонусов
    paymentName: t('cash'), // Наименование выбора оплаты(без бонусов)
    checkCurrentOrder: false, //
    isOn: false,
    comment: '',
  }

  interval = null

  markers = []

  calculateCostsForClasses = async () => {
    // const orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
    // const { preferencesPrices } = this.state
    // const orderInfoData = {
    //   totalDistance: orderInfo.distance,
    //   origin: `${orderInfo.startAddressLocation.lat} ${orderInfo.startAddressLocation.lng}`,
    //   destination: `${orderInfo.endAddressLocation.lat} ${orderInfo.endAddressLocation.lng}`,
    //   polylines: polylinesArr,
    //   orderPreferencesSum: preferencesPrices || 0,
    // }
    // --------------------------------------------- //TODO for save state
    // const orderInfoResponse = JSON.parse(localStorage.getItem('orderInfoResponse'))
    // const orderInfoData = {
    //   directionResponse: {
    //     geocoded_waypoints: orderInfoResponse.geocoded_waypoints,
    //     routes: orderInfoResponse.routes,
    //     status: 'OK',
    //   },
    //   orderPreferencesSum: this.state.preferencesPrices,
    // }
    // await this.props.getOrderCost(orderInfoData)
    // ---------------------------------------------
  }

  componentDidMount() {
    const countryCode = localStorage.getItem('country_code')

    localStorage.removeItem('activeOrder')
    const city = localStorage.getItem('city')
    if (city) {
      this.setState({ activeCity: city })
    }

    if (!window.google) {
      const script = this.createScript()
      script.addEventListener('load', () => {
        this.initMap()
      })
    } else {
      this.initMap()
    }

    // Нужно запускать запросы, в случае если произойдет сбой или тупо обновим ст в веб версии
    this.props.getPreferences(countryCode)
    this.props.getCities()
    this.props.getUserData(countryCode)
    this.props.getUserCards()
    this.props.getLastRide()
    this.props.getFavoriteAddress()

    // Удаление заказа руками если что-то пошло не так((
    // this.props.cancelOrder("")

    /**
     * Решение для проверки заполнености полей и перехода на следующий этап заказа */
    // this.interval = setInterval(async () => {
    //   try {
    //     if (document.getElementById('check-address').value === '1') {
    //       const originInput = document.getElementById('origin-input')
    //       const destinationInput = document.getElementById('destination-input')

    //       if (
    //         this.props.orderStatus.status !== 666 || //TODO remove orderStatus
    //         (this.state.newRout && originInput?.value && destinationInput?.value)
    //       ) {
    //         const orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
    //         await this.calculateCostsForClasses() // Получаем актуальную стоимость

    //         this.props.updPos({
    //           lat: orderInfo.startAddressLocation.lat,
    //           lon: orderInfo.startAddressLocation.lng,
    //         })
    //         this.setState({
    //           pos: {
    //             lat: orderInfo.startAddressLocation.lat,
    //             lon: orderInfo.startAddressLocation.lng,
    //           },
    //           stepAddress: true,
    //           orderData: orderInfo,
    //           activePrice: this.props.costOrders[this.state.activeCarClass]?.price,
    //         })
    //       }
    //     } else if (document.getElementById('check-address').value === '-1') {
    //       this.setState({
    //         popupRoadError: true,
    //       })
    //       document.getElementById('check-address').value = 0
    //     } else if (document.getElementById('check-address').value === '-2') {
    //       this.setState({
    //         popupAddressError: true,
    //       })
    //       document.getElementById('check-address').value = 0
    //     } else if (document.getElementById('check-address').value === '-3') {
    //       this.setState({
    //         popupRoadLimit: true,
    //       })
    //     }
    //   } catch (e) {
    //     document.getElementById('check-address').value = 0
    //     // this.setState({
    //     //   popupCityLimit: true,
    //     // })
    //   }
    // }, 1000)
  }

  componentWillUnmount() {
    this.setState({
      checkHillOrder: false,
      newRoute: true,
    })
    clearInterval(this.interval)
  }

  /**
   * Смена состояний страницы по прийденым статусам с сокета
   *
   * Заказ создан status: 0
   * Заказ принял водила status: 1
   * Поездка отменен status: 2
   * Водила на месте status: 4
   * Поездка в пути status: 8
   * Поездка завершена status: 16
   * Машин не найдено status: 32
   * Поездка оплачена status: 128 */
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.orderStatus.status === 666 && !nextProps.currentOrderStatus) {
      //TODO remove orderStatus
      // nextProps.getCurrentOrder(nextProps.orderStatus.orderId)
      // orderStatus: state.socket.orderStatus,
      // console.error(state);
      // console.error('---------------------0------------------------------------');
      // Если при открытии у нас уже есть созданый заказ
      const orderId = localStorage.getItem('orderId')
      // if (orderInfo === null) {
      //   return null
      // }
      // ---------------------- //TODO for make market on map
      // const autocompleteHandler = new AutocompleteDirectionsHandler(state.mapRef)
      // const orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
      // autocompleteHandler.setupPlaceToMap(orderInfo.startAddressId, 'origin')
      // autocompleteHandler.setupPlaceToMap(orderInfo.endAddressId, 'destination')
      // ----------------------

      return {
        activePrice: nextProps.currentOrderData.priceEstimated || 0,
        checkedPreferences: nextProps.currentOrderData.orderPreferences || 0,
        checkHillOrder: orderId ? false : true,
        stepAddress: true,
        stepCheckMarker: false,
        stepFindCar: true,
      }
    } else if (nextProps.currentOrderStatus) {
      /**
       * Восстановление заказа */
      // if (
      //   nextProps.orderStatus.status === 1 ||
      //   nextProps.orderStatus.status === 4 ||
      //   nextProps.orderStatus.status === 8 ||
      //   nextProps.orderStatus.status === 16
      // ) {
      //   if (!state.checkCurrentOrder) {
      //     return {
      //       stepFindCar: true,
      //       stepAddress: true,
      //       orderData: nextProps.currentOrderData.orderAddressInfo,
      //       activePrice: nextProps.currentOrderData.priceEstimated,
      //       checkBonus: nextProps.currentOrderData.quoteBonus > 0,
      //       bonusCount: nextProps.currentOrderData.quoteBonus,
      //       checkedPreferences: nextProps.currentOrderData.orderPreferences,
      //       activeCreditCard: nextProps.currentOrderSummaryData.cardId,
      //       activePayment: nextProps.currentOrderSummaryData.paymentTypeId,
      //       paymentName:
      //         nextProps.currentOrderSummaryData.paymentTypeId === 0 ||
      //         nextProps.currentOrderSummaryData.paymentTypeId === 2
      //           ? t('cash')
      //           : localStorage.getItem('cardPan'),
      //       checkCurrentOrder: true,
      //       newRoute: true,
      //       // isOn: nextProps.orderStatus.status === 8,
      //     }
      //   } else {
      //     return {
      //       stepFindCar: true,
      //       stepAddress: true,
      //     }
      //   }
      // }
    }
    //  else if (nextProps.orderStatus.status === 8) {
    //   return {
    //     isOn: true,
    //   }
    // } else {
    //   return null
    // }
    return null
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error in order page: ', error)
    window.location.reload()
  }

  async updatePreferenceChange() {
    await this.calculateCostsForClasses()

    const { costOrders } = this.props
    const { activeCarClass, activePrice } = this.state

    const currentPrice = costOrders[activeCarClass]?.price
    const maxCurrenPrice = currentPrice * MAXIMUM_FARE_MULTIPLIER

    if (activePrice > maxCurrenPrice) {
      const newPrice = activePrice - maxCurrenPrice
      this.setState({
        activePrice: this.state.activePrice - newPrice,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.checkedPreferences?.length !== this.state.checkedPreferences?.length) {
      this.updatePreferenceChange()
    }
  }

  increasePrice = () => {
    const { activePrice } = this.state
    const currentActivePrice =
      this.props.costOrders[this.state.activeCarClass]?.price * MAXIMUM_FARE_MULTIPLIER

    if (currentActivePrice > activePrice) {
      if (activePrice % 5 === 0) {
        this.setState({
          activePrice: activePrice + PRICE_CHANGE_LIMIT,
        })
      } else {
        this.setState({
          activePrice: Math.ceil(activePrice / 5) * 5,
        })
      }
    }
  }

  decreasePrice = () => {
    const { activePrice } = this.state
    const currentActivePrice = this.props.costOrders[this.state.activeCarClass]?.price
    const newPrice = activePrice - PRICE_CHANGE_LIMIT

    if (currentActivePrice >= activePrice) {
      return
    }

    if (newPrice < currentActivePrice) {
      this.setState({
        activePrice: currentActivePrice,
      })
    } else if (activePrice % 5 === 0) {
      this.setState({
        activePrice: activePrice - PRICE_CHANGE_LIMIT,
      })
    } else {
      this.setState({
        activePrice: Math.floor(activePrice / 5) * 5,
      })
    }
  }

  calculatePriceDifference = () => {
    return this.state.activePrice - this.props.costOrders[this.state.activeCarClass]?.price
  }

  /**
   * Создание карты */
  initMap = async () => {
    const latitude = localStorage.getItem('lat')
    const longitude = localStorage.getItem('lng')
    map = new window.google.maps.Map(document.getElementById('map'), {
      center: {
        lat: Number(latitude) || 47.9097,
        lng: Number(longitude) || 33.3804,
      },
      zoom: 13,
      styles: localStorage.getItem('appTheme') === 'dark' ? stylesStructure : false,
      disableDefaultUI: true,
      mapTypeId: 'roadmap',
    })
    this.setState({
      mapRef: map,
    })

    // Обрабатываем координаты на карте
    await setTimeout(() => {
      this.initCity()
    }, 3000)

    window.google.maps.event.addListener(map, 'mousedown', (event) => {
      // this.setState({
      //   classMarker: 'animate',
      // })
      const market = document.getElementById('market-place-map')
      market?.classList.add('animate')
    })
    window.google.maps.event.addListener(map, 'mouseup', (event) => {
      // this.setState({
      //   classMarker: '',
      // })
      const market = document.getElementById('market-place-map')
      market?.classList.remove('animate')
    })

    this.map = new AutocompleteDirectionsHandler(map)

    // Постройка маршрута с страници истории поездок
    // if (localStorage.getItem('repeatTrip')) {
    //   const repeatInfo = JSON.parse(localStorage.getItem('repeatTrip'))
    //   document.getElementById('origin-input').value = repeatInfo.startName
    //   document.getElementById('destination-input').value = repeatInfo.endName
    //   this.map.setupPlaceToMap(repeatInfo.start, 'origin')
    //   this.map.setupPlaceToMap(repeatInfo.end, 'destination')
    //   localStorage.removeItem('repeatTrip')
    // }
  }

  /**
   * Подключение скриптов гугл карты по ключу
   *
   * @returns {HTMLScriptElement} */
  createScript = () => {
    //
    const scriptTag = document.createElement('script')
    // scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places&language=ru&region=UA/`
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyComxUEAcX-c__nGZOrJHKQfCyKY0hbrjA&callback=Function.prototype&libraries=places&language=ru&region=UA/`

    scriptTag.async = true
    scriptTag.defer = true
    if (document.body) {
      document.body.appendChild(scriptTag)
    }
    return scriptTag
  }

  toggleFunction = (namePopup, statePopup) => {
    this.setState({
      [namePopup]: !statePopup,
    })
  }

  setInputValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  /**
   * Проверка координат и выбор города */
  initCity = async () => {
    if (this.props.confirmedAdress) return //!
    let pos
    await this.props.getCities()
    const coordsMobile = localStorage.getItem('coordsMob')
    let city = localStorage.getItem('city')

    if (!!city) {
      this.setState({
        activeCity: city,
      })
    }

    if (coordsMobile) {
      // Если зашли с телефона и получили координаты
      if (coordsMobile !== 'false') {
        const _coordsMobile = JSON.parse(coordsMobile)
        const marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng({
            lat: _coordsMobile.coords.latitude,
            lng: _coordsMobile.coords.longitude,
          }),
          map: map,
          icon: userLocation,
          animation: window.google.maps.Animation.DROP,
        })
        this.props.getMeMarker(marker)
        userPosition = [_coordsMobile.coords.latitude, _coordsMobile.coords.longitude]
        const cityFoundStatus = await this.getCityByCoords(
          [_coordsMobile.coords.latitude, _coordsMobile.coords.longitude],
          this.props.cities[city ? city : 0]?.name,
          !city
        )
        if (cityFoundStatus.status) {
          pos = {
            lat: _coordsMobile.coords.latitude,
            lng: _coordsMobile.coords.longitude,
          }
        } else {
          pos = {
            lat: city ? this.props.cities[city]?.latitude : _coordsMobile.coords.latitude,
            lng: city ? this.props.cities[city]?.longitude : _coordsMobile.coords.longitude,
          }
        }
      }

      // Если GPS отключен и мы смотрим с телефона
      if (coordsMobile === 'false') {
        pos = {
          lat: this.props.cities[city ? city : 0]?.latitude,
          lng: this.props.cities[city ? city : 0]?.longitude,
        }
      }

      mapLoader = false

      map.setCenter({ lat: pos.lat, lng: pos.lng })
      this.props.updPos({
        lat: pos.lat,
        lon: pos.lng,
      })
      this.setState({
        pos: {
          lat: pos.lat,
          lon: pos.lng,
        },
      })
    }
    // Смотрим с браузера
    else {
      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition(
          async (position) => {
            // Проверяем в выбраном городе пользователь или нет
            const cityFoundStatus = await this.getCityByCoords(
              [position.coords.latitude, position.coords.longitude],
              this.props.cities[city ? city : 0]?.name,
              !city
            )
            const marker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }),
              map: map,
              icon: userLocation,
              animation: window.google.maps.Animation.DROP,
            })
            this.props.getMeMarker(marker)
            userPosition = [position.coords.latitude, position.coords.longitude]
            if (cityFoundStatus.status) {
              pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }
            } else {
              pos = {
                lat: city ? this.props.cities[city]?.latitude : position.coords.latitude,
                lng: city ? this.props.cities[city]?.longitude : position.coords.longitude,
              }
            }

            mapLoader = false
            map.setCenter({ lat: pos.lat, lng: pos.lng })
            this.props.updPos({
              lat: pos.lat,
              lon: pos.lng,
            })
            this.setState({
              pos: {
                lat: pos.lat,
                lon: pos.lng,
              },
            })

            const $this = this

            const coordinates = new window.google.maps.LatLng(pos.lat, pos.lng)
            // console.error(coordinates);
            var geocoder = new window.google.maps.Geocoder()
            geocoder.geocode({ location: coordinates }, function (results, status) {
              if (status === 'OK') {
                if (results[0]) {
                  //   var address = results[0].formatted_address;
                  // const i = document.getElementById('origin-input')
                  // !place

                  $this.props.getGeolocationMapMarket(results[0])
                  $this.props.getGoogleMapCoordinates(results[0]) //TODO get valute from first adress

                  // if(i) {
                  //   i.value = results[0].formatted_address ;

                  //   // console.log('change route ---------------------------------------------------------------------- ')
                  // }

                  // document.getElementById('address').textContent = address;
                } else {
                  // document.getElementById('address').textContent = 'No results found.';
                }
              } else {
                // document.getElementById('address').textContent = 'Error fetching data.';
              }
            })
          },
          (err) => {
            pos = {
              lat: this.props.cities[city ? city : 0]?.latitude,
              lng: this.props.cities[city ? city : 0]?.longitude,
            }
            map.setCenter({ lat: pos.lat, lng: pos.lng })
            this.props.updPos({
              lat: pos.lat,
              lon: pos.lng,
            })
            this.setState({
              pos: {
                lat: pos.lat,
                lon: pos.lng,
              },
            })
            mapLoader = false
          }
        )
      } else {
        pos = {
          lat: this.props.cities[city ? city : 0]?.latitude,
          lng: this.props.cities[city ? city : 0]?.longitude,
        }
        mapLoader = false
      }
    }
  }

  /**
   * Проверка города по координатам
   *
   * @param coords
   * @param cityName
   * @param bollCity
   * @returns {Promise<unknown>} */
  getCityByCoords = (coords, cityName, bollCity) => {
    const geocoder = new window.google.maps.Geocoder()
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        {
          latLng: new window.google.maps.LatLng(coords[0], coords[1]),
          language: 'RU',
        },
        (results, status) => {
          // eslint-disable-next-line
          if (status == window.google.maps.GeocoderStatus.OK) {
            let activeCity
            const getUserCity = results.filter((item) => {
              if (item.formatted_address.includes(cityName)) {
                return true
              }
              return false
            })

            // в выпадающем списке городов, указываем город в котором находится пользователь
            this.props.cities.filter((item, i) => {
              results.filter((resultItem) => {
                if (resultItem.formatted_address.includes(item.name)) {
                  if (bollCity) {
                    activeCity = i
                    localStorage.setItem('city', i)
                    this.setState({
                      activeCity: i,
                    })
                  }
                  return true
                }
                return false
              })
              return false
            })

            if (getUserCity.length > 0) {
              // resolve(true)
              resolve({
                status: true,
                city: activeCity,
              })
            } else {
              // resolve(false)
              resolve({
                status: false,
                city: activeCity,
              })
            }
          } else {
            reject(status)
          }
        }
      )
    })
  }

  /**
   * Центровка к точке пользователя */
  setCenterToUser = () => {
    map.setCenter({ lat: userPosition[0], lng: userPosition[1] })
  }

  /**
   * Смена класса авто
   *
   * @param price
   * @param count */
  changeCarClass = (price, count) => {
    const newPrice = price[count]?.price

    this.setState({
      activePrice: newPrice,
      activeCarClass: count,
    })
  }

  /**
   * Выбор предпочтений для поездки
   *
   * @param id */
  changePreferences = (id) => {
    this.setState((state) => {
      let preferences = [...state.checkedPreferences]
      let preferencesPrices = this.state.preferencesPrices
      let activePrice = this.state.activePrice

      const chousenPref = this.props.preferences.filter((item) => {
        return item.id === id
      })

      if (this.state.checkedPreferences.indexOf(id) !== -1) {
        preferencesPrices -= Number(chousenPref[0].price)
        activePrice -= Number(chousenPref[0].price)
        preferences = this.state.checkedPreferences.filter((item) => {
          return item !== id
        })
      } else {
        preferencesPrices += Number(chousenPref[0].price)
        activePrice += Number(chousenPref[0].price)
        preferences.push(id)
      }
      return {
        checkedPreferences: preferences,
        preferencesPrices,
        activePrice,
      }
    })
  }

  /**
   * Пересчет цены после выбора бонусов. Скидка не
   * должна привышать 50% от цены.
   *
   * @returns {*} */
  calcBonusPrice = () => {
    if (this.state.activePrice / 2 >= this.props.userData.bonuses) {
      return (
        <>
          <span className="order-sum__old-price">
            {this.state.activePrice} {t('currency')}
          </span>
          <span>
            {this.state.activePrice - this.props.userData.bonuses} {t('currency')}
          </span>
        </>
      )
    } else {
      return (
        <>
          <span className="order-sum__old-price">
            {this.state.activePrice} {t('currency')}
          </span>
          <span>
            {Math.round(this.state.activePrice / 2)} {t('currency')}
          </span>
        </>
      )
    }
  }

  addMarkerMap = (name) => {
    const geocoder = new window.google.maps.Geocoder()
    const center = map.getCenter()
    map.setZoom(13)

    geocoder.geocode(
      {
        latLng: center,
      },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            if (name === 'start' || name === 'my') {
              // document.getElementById('origin-input').value = results[0].formatted_address
              this.setState({ stepCheckMarker: false })
              this.map.setupPlaceToMap(results[0].place_id, 'origin')
            }
            if (name === 'end') {
              // document.getElementById('destination-input').value = results[0].formatted_address
              this.setState({ stepCheckMarker: false })
              this.map.setupPlaceToMap(results[0].place_id, 'destination')
            }
          }
        }
      }
    )
  }

  initFavoriteAddress = (name, data) => {
    document.getElementById(`${name}-input`).value = data.name
    this.map.setupPlaceToMap(data.pointId, name)
  }

  reorderCancelledTrip = () => {
    if (!this.state.cancelByUser) {
      const orderInfoL = JSON.parse(localStorage.getItem('orderInfo'))
      localStorage.setItem(
        'repeatTrip',
        JSON.stringify({
          start: orderInfoL.startAddressId,
          startName: orderInfoL.startAddressName,
          end: orderInfoL.endAddressId,
          endName: orderInfoL.endAddressName,
        })
      )
      window.location.reload()
    } else {
      window.location.reload()
    }
  }

  calculateCurrentPrice = () => {
    const { costOrders } = this.props
    const activePrice = this.state.activePrice

    // const activePrice = costOrders[this.state.activeCarClass]?.price || this.state.activePrice
    if (activePrice === 0) {
      return costOrders[this.state.activeCarClass]?.price
    }

    return activePrice
  }

  chechObj = (driveObj) => {
    for (let i in driveObj) {
      if (driveObj[i] === '' || driveObj[i] === null) {
        return false
      }
    }
    return true
  }
  textFormator = (text) => {
    const arrAdress = text.split(', ')
    let minusCountElement = 1
    let mainText = arrAdress[0] + ' '

    if (parseInt(arrAdress[1])) {
      mainText += arrAdress[1]
      minusCountElement++
    }

    return {
      mainText,
      secondaryText: arrAdress.slice(minusCountElement).join(', '),
    }
  }

  showDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    })
  }

  render() {
    /**
     *  Отрисовывает машины, которые на смене
     *  и добавляет маркеры на карту */
    if (this.props.driversLocation.length !== 0) {
      window.google.maps.Map.prototype.clearMarkers = function () {
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null)
        }
        this.markers = []
      }
      this.props.driversLocation.map((item) => {
        this.markers = this.markers.filter((markersItem) => {
          if (item.id === markersItem.carid) {
            // del marker
            markersItem.marker.setMap(null)
            return false
          }
          return true
        })
        const pos = {
          lat: item.lat,
          lng: item.lon,
        }
        const marker = new window.google.maps.Marker({
          position: pos,
          icon: carIcon,
        })
        this.markers.push({
          carid: item.id,
          marker: marker,
        })
        return marker.setMap(map)
      })
    }

    if (this.state.newRoute) {
      setTimeout(() => {
        this.map.originPlaceId = this.props.currentOrderData.orderAddressInfo.startAddressId
        this.map.destinationPlaceId = this.props.currentOrderData.orderAddressInfo.endAddressId
        this.map.route(false)
        this.toggleFunction('newRoute', true)
      }, 500)
    }

    /**
     * Просчет времени в дороге */
    // if (
    //   this.props.orderStatus.status === 8 && //TODO remove orderStatus
    //   this.state.checkHillOrder !== true &&
    //   !localStorage.getItem('timeCount')
    // ) {
    //   const directionsService = new window.google.maps.DirectionsService() // Todo:
    //   const request = {
    //     origin: `${this.state?.orderData?.endAddressLocation?.lat},${this.state?.orderData?.endAddressLocation?.lng}`,
    //     destination: `${this.props.driverData?.driverLocation?.lat},${this.props.driverData?.driverLocation?.lon}`,
    //     travelMode: 'DRIVING',
    //   }
    //   directionsService.route(request, (result, status) => {
    //     if (status === 'OK') {
    //       localStorage.setItem('timeCount', result.routes[0].legs[0].duration.text)
    //     }
    //   })
    // }

    /**
     * Просчет времени до прибытия */
    if (
      // this.props.orderStatus.status === 1 &&
      this.state.checkHillOrder !== true &&
      !localStorage.getItem('time')
    ) {
      if (
        this.state.orderData &&
        this.state.orderData.startAddressLocation &&
        this.props.driverData &&
        this.props.driverData.driverLocation
      ) {
        const origin = `${this.state.orderData.startAddressLocation.lat},${this.state.orderData.startAddressLocation.lng}`
        const destination = `${this.props.driverData.driverLocation.lat},${this.props.driverData.driverLocation.lon}`
        const directionsService = new window.google.maps.DirectionsService()
        const request = {
          origin,
          destination,
          travelMode: 'DRIVING',
        }
        directionsService.route(request, (result, status) => {
          if (status === 'OK') {
            localStorage.setItem('time', result.routes[0].legs[0].duration.text)
          }
        })
      }
    }

    /**
     * Завершение заказа и переход на страницу завершения поездки */
    // if (this.props.orderStatus.status === 16 || this.props.orderStatus.status === 128) {
    //   return <Navigate to={{ pathname: '/order-done' }} />
    // }

    const carNotFoundPopup = <></>
    // (
    // <SimpleDialog
    //   open={this.props.orderStatus.status === 32}
    //   close={() => window.location.reload()}
    //   content={<span className="popup-message">{t('car_not_found')}</span>}
    //   showControl={true}
    //   control={
    //     <div className="container-fluid">
    //       <div className="row">
    //         <div className="col-6">
    //           <button
    //             onClick={() => this.reorderCancelledTrip()}
    //             className="control__button control__button--primary"
    //           >
    //             {t('repeat')}
    //           </button>
    //         </div>
    //         <div className="col-6">
    //           <button
    //             onClick={() => window.location.reload()}
    //             className="control__button control__button--primary-2"
    //           >
    //             {t('close')}
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   }
    // />
    // )

    const infoPopups = (
      <OrderPopup
        status={
          // this.props.orderStatus.status === 2 ||
          this.state.checkGPS ||
          this.state.popupRoadError ||
          this.state.popupAddressError ||
          this.state.popupRoadLimit
          // this.state.popupCityLimit
        }
        close={() => window.location.reload()}
        content={
          // this.props.orderStatus.status === 2 ? (
          //   <span className="popup-message">
          //     {this.state.cancelByUser ? t('order_cancelled') : t('order_cancelled_by_driver')}
          //   </span>
          // ) :
          this.state.checkGPS ? (
            <span className="popup-message">{t('denied_GPS_access')}</span>
          ) : this.state.popupRoadError ? (
            <span className="popup-message">{t('addresses_must_not_match')}</span>
          ) : this.state.popupAddressError ? (
            <span className="popup-message">{t('choose_address_from_list')}</span>
          ) : this.state.popupRoadLimit ? (
            <span className="popup-message">{t('distance_limit_exceeded')}</span>
          ) : null
        }
        /*

          // ) : this.state.popupCityLimit ? (
          //   <span className="popup-message">Выбраный город не совпадает с городом адресса подачи авто!</span>
        */
        action={() => {
          // localStorage.removeItem('orderId')
          // if (this.props.orderStatus.status === 2) {
          //   // this.reorderCancelledTrip()
          // } else
          if (this.state.checkGPS) {
            this.toggleFunction('checkGPS', this.state.checkGPS)
          } else if (this.state.popupRoadError) {
            this.toggleFunction('popupRoadError', this.state.popupRoadError)
            window.location.reload()
          } else if (this.state.popupAddressError) {
            this.toggleFunction('popupAddressError', this.state.popupAddressError)
          } else if (this.state.popupRoadLimit) {
            this.toggleFunction('popupRoadLimit', this.state.popupRoadLimit)
          } else {
            // this.state.popupCityLimit
            return null
          }
        }}
      />
    )

    const paymentPopup = this.state.popupPayment ? (
      <SimpleDialog
        open={this.state.popupPayment}
        header={t('payment_choice')}
        className="payment-popup"
        fullScreen={true}
        close={() => this.toggleFunction('popupPayment', this.state.popupPayment)}
        content={
          <div className="row mt-3">
            <div className="col-12 mb-3">
              <div
                className="card-block"
                onClick={() => {
                  this.setState({
                    activeCreditCard: 0,
                    activePayment: 0,
                    paymentName: t('cash'),
                  })
                  this.toggleFunction('popupPayment', this.state.popupPayment)
                }}
              >
                <img src={iconCash} alt="" />
                {t('cash')}
              </div>
            </div>
            {this.props.userCardsList.length !== 0
              ? this.props.userCardsList.map((item, key) => (
                  <div className="col-12 mb-3" key={key}>
                    <div
                      className="card-block"
                      onClick={() => {
                        localStorage.setItem('cardPan', item.cardPan)
                        this.setState({
                          activeCreditCard: item.id,
                          activePayment: 1,
                          paymentName: item.cardPan,
                        })
                        localStorage.setItem('cardPan', item.cardPan)
                        this.toggleFunction('popupPayment', this.state.popupPayment)
                      }}
                    >
                      <img src={iconPay} alt="" />
                      {item.cardPan}
                    </div>
                  </div>
                ))
              : null}
          </div>
        }
        showControl={true}
        control={false}
      />
    ) : null

    const preferencesBlock = this.state.stepAddPreferences ? (
      <SimpleDialog
        open={this.state.stepAddPreferences}
        close={() => this.toggleFunction('stepAddPreferences', this.state.stepAddPreferences)}
        header={t('additional_services')}
        fullScreen={true}
        content={this.props.preferences.map((item) => (
          <div
            className="row preferences"
            onClick={() => this.changePreferences(item.id)}
            key={item.id}
          >
            <div className="col-10">
              {t(item.name)}{' '}
              <span className="preferences__price">
                ({item.price} {t('currency')})
              </span>
            </div>
            <div className="col-2">
              {this.state.checkedPreferences.indexOf(item.id) !== -1 ? (
                <CheckIcon />
              ) : (
                <AddCircleOutlineIcon />
              )}
            </div>
          </div>
        ))}
        showControl={false}
      />
    ) : null

    const cancelPopup = this.state.popupCancelOrder ? (
      <SimpleDialog
        open={this.state.popupCancelOrder}
        close={() => {
          this.setState({
            popupCancelOrder: false,
          })
        }}
        content={<span className="popup-message">{t('cancel_your_order')}</span>}
        showControl={true}
        control={
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <button
                  onClick={() => {
                    this.props.cancelOrder(this.props.orderId)
                    this.setState({
                      popupCancelOrder: false,
                      cancelByUser: true,
                    })
                  }}
                  className="control__button control__button--primary"
                >
                  {t('yes')}
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={() => {
                    this.setState({
                      popupCancelOrder: false,
                    })
                  }}
                  className="control__button control__button--primary-2"
                >
                  {t('no')}
                </button>
              </div>
            </div>
          </div>
        }
      />
    ) : null
    //   {/* {userPosition && (
    //   <button className={cls.my_location_button} onClick={this.setCenterToUser} role="presentation">
    //     <LocationTracker />
    //   </button>
    // )} */}
    //   bottomMenu =  <div className={cls.order_form} style={{ paddingBottom: '30px' }}>
    //   {userPosition && (
    //     <button className={cls.my_location_button} onClick={this.setCenterToUser} role="presentation">
    //       <LocationTracker />
    //     </button>
    //   )}
    //   <div className={cls.down_line} onClick={this.showDetails}>
    //     <DownLine
    //       className={cls.down_line_icon}
    //     />
    //   </div>
    //   <div className={cls.main_orders_window}>
    //     <div className={cls.orders_item}>
    //       <div className={cls.route_block}>
    //         <div className={cls.location_icon}>
    //           <LocationPin
    //             onClick={() => {
    //               this.setState({
    //                 stepCheckMarker: true,
    //                 stepCheckMarkerName: 'start',
    //                 stepFavoriteAddress: false,
    //               })
    //             }}
    //           />
    //         </div>
    //         <input
    //           id="origin-input"
    //           className={cls.location_input}
    //           type="text"
    //           onChange={() =>
    //             this.setState({
    //               stepFavoriteAddress: false,
    //               stepAddress: false,
    //             })
    //           }
    //         />
    //       </div>
    //       <div className={cls.close_icon}>
    //         <Close />
    //       </div>
    //     </div>
    //     <Link to="/order-route"
    //       onClick={() => {
    //         const startPlace = JSON.parse(localStorage.getItem('startPlace'))
    //         localStorage.removeItem('startPlace');
    //         const adress = startPlace ? startPlace : this.props.currentGoogleMapCoordinates;
    //         this.props.changeStartPlaceOrderRoute(adress)
    //       }}
    //       className={cls.orders_button}>
    //       <div className={cls.arrow_right_icon}>
    //         <ArrowRight />
    //       </div>
    //       <div className={cls.orders_text_button}>
    //         <p>{t('where_shall_we_go')}</p>
    //       </div>
    //     </Link>
    //   </div>
    //   {this.state.showDetails && <div className={cls.addresses}>
    //     {this.props?.lastPoints[0]?.pointId && (
    //       <div
    //         key={this.props.lastPoints[0].pointId}
    //         className={cls.first_address}
    //         onClick={() => {
    //           this.initFavoriteAddress(this.state.stepFavoriteAddress, this.props.lastPoints[0])
    //           this.setState({
    //             stepFavoriteAddress: false,
    //           })
    //         }}
    //       >
    //         <div className={cls.address_text}>
    //           <div className={cls.history_icon}>
    //             <BackClock />
    //           </div>
    //           <p>{this.props.lastPoints[0].name}</p>
    //         </div>
    //       </div>
    //     )}
    //     <div className={cls.border} />
    //     {this.props?.lastPoints[1]?.pointId &&
    //       this.props?.lastPoints[0]?.pointId !== this.props?.lastPoints[1]?.pointId && (
    //         <div
    //           key={this.props.lastPoints[1].pointId}
    //           className={cls.second_address}
    //           onClick={() => {
    //             this.initFavoriteAddress(this.state.stepFavoriteAddress, this.props.lastPoints[1])
    //             this.setState({
    //               stepFavoriteAddress: false,
    //             })
    //           }}
    //         >
    //           <div className={cls.address_text}>
    //             <div className={cls.history_icon}>
    //               <BackClock />
    //             </div>
    //             <p>{this.props.lastPoints[1].name}</p>
    //           </div>
    //         </div>
    //       )}
    //   </div>}
    // </div>

    // const checkMarker = this.state.stepCheckMarker ? (
    //   <>
    //     <div className={`${cls.user_geolocation} ${this.state.classMarker}`}>
    //       <img src={MyGeolocation} alt="" />
    //     </div>
    //     <div className={cls.location_block}>
    //       {userPosition && (
    //         <button className={cls.my_location_button} onClick={this.setCenterToUser} role="presentation" style={{ marginRight: '0px' }}>
    //           <LocationTracker />
    //         </button>
    //       )}
    //       <div className={cls.location_item}>
    //         <div className={cls.location_change}>
    //           <div className={cls.change_item}>
    //             <div className={cls.location_block_icon}>
    //               <LocationPin />
    //             </div>
    //             <input
    //               id="origin-input"
    //               type="text"
    //               onChange={() =>
    //                 this.setState({
    //                   stepFavoriteAddress: false,
    //                   stepAddress: false,
    //                 })
    //               }
    //             />
    //           </div>
    //         </div>
    //         <button className={cls.location_confirm_button} onClick={() => this.addMarkerMap(this.state.stepCheckMarkerName)}>
    //           {t('confirm')}
    //         </button>
    //       </div>
    //     </div>
    //   </>
    // ) : null

    const cancel = <></>
    // this.props.orderStatus.status === 0 || this.props.orderStatus.status === 1 ? (
    //   <div
    //     className="col-3 order-find-car__cancel"
    //     role="presentation"
    //     onClick={() => {
    //       this.setState({
    //         popupCancelOrder: true,
    //       })
    //     }}
    //   >
    //     {t('cancellation')}
    //   </div>
    // ) : null

    const orderCars = (
      <div className="order-cars row no-gutters">
        {this.props.classes.map((item, key) => {
          return (
            <div
              className={
                key === this.state.activeCarClass
                  ? 'col-4 order-cars__item active'
                  : 'col-4 order-cars__item'
              }
              key={item.id}
              data-price={item.price}
              onClick={() => this.changeCarClass(this.props.costOrders, key)}
            >
              <div className="order-cars__header">{t(item.name)}</div>
              <div className="order-cars__img">{carClassImg[key]}</div>
              <div className="order-cars__price">
                {this.props.costOrders[key]?.price} {t('currency')}
              </div>
            </div>
          )
        })}
      </div>
    )

    let orderText = <></>
    // if (this.props.orderStatus.status === 0 || this.props.orderStatus.status === 2) {
    //   orderText = (
    //     <>
    //       <div className="col-8 order-find-car__header">
    //         <img src={searchIcon} alt="" /> {t('searching_car')}
    //       </div>
    //     </>
    //   )
    // } else if (this.props.orderStatus.status === 1) {
    //   orderText = (
    //     <div className="col-8 order-find-car__header">
    //       <img src={carClass1} alt="" /> {t('driver_on_move')}
    //     </div>
    //   )
    // } else if (this.props.orderStatus.status === 4) {
    //   orderText = (
    //     <div className="col-8 order-find-car__header">
    //       <img src={timeActive} alt="" /> {t('expect_to_hear')}
    //     </div>
    //   )
    // } else if (this.props.orderStatus.status === 8 || this.props.orderStatus.status === 16) {
    //   orderText = (
    //     <div className="col-8 order-find-car__header">
    //       <img src={roadIcon} alt="" /> {t('on_road')}
    //     </div>
    //   )
    // } else if (this.props.orderStatus.status === 32) {
    //   orderText = (
    //     <div className="col-8 order-find-car__header">
    //       <img src={carClass1} alt="" /> {t('car_not_found')}
    //     </div>
    //   )
    // }

    const statusCar = (
      <div className="order-find-car">
        <div className="row no-gutters">
          {orderText}
          <div className="col-1" />
          {cancel}
        </div>
      </div>
    )

    const driverInfo = <></>
    // this.props.orderStatus.status !== 0 || this.props.currentOrderStatus ? (
    //   <div className="order-driver">
    //     <div className="row no-gutters">
    //       <div className="col-3" style={{ marginTop: '7px', marginRight: '8px' }}>
    //         {this.props.driverData.driverPhoto === '' ? (
    //           <AccountCircleIcon />
    //         ) : (
    //           <img
    //             className="order-driver__photo"
    //             src={this.props.driverData.driverPhoto}
    //             alt=""
    //           />
    //         )}
    //       </div>
    //       <div className="col-7">
    //         <p className="order-driver__text">
    //           {this.props.driverData.driverName} {this.props.driverData.driverRating}
    //         </p>
    //         <p className="order-driver__text">
    //           {this.props.driverData.carName}({t(this.props.driverData.carColorName)})
    //         </p>
    //         <p className="order-driver__text">{this.props.driverData.carNumber}</p>
    //       </div>
    //       <div className="col-2">
    //         <a
    //           className={!this.props.driverData.driverPhone ? cls.hidden : 'order-driver__phone'}
    //           href={'tel:' + this.props.driverData.driverPhone}
    //         >
    //           <img src={phoneIcon} alt="" />
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // ) : null

    return (
      <>
        {/* <SignalR gps={disableGPS} coords={this.state.pos} /> */}
        <input id="check-address" type="hidden" value="0" />
        <div id="map" />
        {/* {bottomMenu} */}
        <div className={cls.my_location_c}>
          {/* {userPosition && (
            <button
              className={cls.my_location_button}
              onClick={this.setCenterToUser}
              role="presentation"
            >
              <UserLocation className={cls.icon_users} />
            </button>
          )} */}
          <BottomMenu map={map} />
        </div>

        {this.state.stepCheckMarker && (
          <div
            className={cls.back_to_button}
            role="presentation"
            style={{ marginLeft: '15px' }}
            onClick={() => {
              map.setZoom(13)
              this.setState({
                stepCheckMarker: false,
              })
            }}
          >
            {/* <div className={cls.back_to_icon}>
              <ArrowBack />
            </div> */}
          </div>
        )}

        {preferencesBlock}
        {paymentPopup}
        {/* {checkMarker} */}
        {cancelPopup}
        {carNotFoundPopup}
        {infoPopups}
        {/* {mapLoader && (
          <div className="custom-loader">
            <Loader
              type="Bars"
              color="#3BB525"
              height={50}
              width={50}
              timeout={45000} // 45 секудн максимум
            />
          </div>
        )} */}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error.error,
    preferences: state.dictionary.preferences,
    classes: state.dictionary.classes,
    cities: state.dictionary.cities,
    currentCoordinates: state.dictionary.currentCoordinates,
    currentGoogleMapCoordinates: state.odrerRoute.currentGoogleMapCoordinates,
    driversLocation: state.socket.driversLocation,
    driverData: state.socket.driverData,
    orderStatus: state.socket.orderStatus,
    userData: state.profile.userData,
    orderId: state.order.orderId,
    confirmedAdress: state.odrerRoute.confirmedAdress,
    finishOrderLoaded: state.order.finishOrderLoaded,
    currentOrderStatus: state.socket.currentOrderStatus,
    currentOrderData: state.socket.currentOrderData,
    currentOrderSummaryData: state.socket.currentOrderSummaryData,
    userCardsList: state.ccard.userCardsList,
    lastPoints: state.history.lastPoints,
    favoriteData: state.favorite.favoriteData,
    pos: state.order.pos,
    costOrders: state.order.costOrders,
    costOrdersLoaded: state.order.costOrdersLoaded,
    identifier: state.order.identifier,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getMeMarker: (m) => dispatch(getMeMarker(m)),
    postBonus: (data) => dispatch(postBonus(data)),
    cancelOrder: (orderId) => dispatch(cancelOrder(orderId)),
    getCurrentOrder: (orderId) => dispatch(getCurrentOrder(orderId)),
    getUserData: (data) => dispatch(getUserData(data)),
    getPreferences: (countryCode) => dispatch(getPreferences(countryCode)),
    getGeolocationMapMarket: (data) => dispatch(getGeolocationMapMarket(data)),
    getCities: () => dispatch(getCities()),
    getCitiesTariff: (id) => dispatch(getCitiesTariff(id)),
    getUserCards: () => dispatch(getUserCards()),
    getLastRide: () => dispatch(getLastRide()),
    getFavoriteAddress: () => dispatch(getFavoriteAddress()),
    updPos: (data) => dispatch(updPos(data)),
    getOrderCost: (data) => dispatch(getOrderCost(data)),
    getGoogleMapCoordinates: (coordinates) => dispatch(getGoogleMapCoordinates(coordinates)),
    // changeStartPlaceOrderRoute: (adress) => dispatch(changeStartPlaceOrderRoute(adress)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapJs)
