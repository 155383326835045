import React, { Component } from 'react'
import SimpleDialog from '../../common/Dialog'

class OrderPopup extends Component {
  componentDidMount() {
    // localStorage.removeItem('orderId')
  }

  render() {
    return (
      <SimpleDialog
        open={this.props.status}
        close={() => {
          window.location.reload()
        }}
        content={this.props.content}
        showControl={true}
        control={
          <button onClick={this.props.action} className="control__button control__button--primary">
            Ок
          </button>
        }
      />
    )
  }
}

export default OrderPopup
