import React, { useEffect, useState, useCallback, useMemo } from "react";
import PageHeader from "../PageHeader";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import PageLayout from "../PageLayout";
import cls from "./orderPreferences.module.scss";
import { connect } from "react-redux";
import {
	changeDopServicesOrderRoute,
	changeCommentOrderRoute,
	selectedCommentIdOrderRoute,
} from "../OrderRoute/order.route.action";
import { getGoogleTravel } from "../OrderRoute/helperCalculate";
import { getOrderCost, getCarClassTimeInfo } from "../MapJs/order.action";
import { ReactComponent as Check } from "../../images/check.svg";
import { getValuteSymbol } from "../CurrencyDefinition/getValuteSymbol";
import { mapProvider } from "src/shared/variables/map-provider";
import { fetchDirection } from "../AddressComponents/helpers/fetchDirection";
import { Button, ButtonVariant } from "src/shared/ui/Button/button";

const getOrderInfoData = async ({ currentMapProvider, routes, sum, country, commonConfig }) => {
	let dataForMap = null;
	let orderInfoData = null;

	switch (currentMapProvider) {
		// request data for google maps
		case mapProvider.google:
			dataForMap = routes.map((e) => e.adress);
			const response = await getGoogleTravel(dataForMap);
			orderInfoData = {
				directionResponse: {
					geocoded_waypoints: response.geocoded_waypoints,
					routes: response.routes,
					status: "OK",
				},
				orderPreferencesSum: sum,
				originCountryCode: country,
				mapProvider: commonConfig.mapProvider,
			};
			return orderInfoData;
		case mapProvider.osmCarma:
			dataForMap = routes.map((point) => [point.lon, point.lat]);
			const directionResponse = await fetchDirection(dataForMap);

			orderInfoData = {
				directionResponse,
				orderPreferencesSum: sum,
				originCountryCode: country || "UA",
				mapProvider: commonConfig.mapProvider,
			};
			return orderInfoData;
		default:
			return null;
	}
};

function OrderPreferences({
	preferences,
	dopServices,
	routes,
	getOrderCost,
	changeDopServicesOrderRoute,
	symbol,
	commentMessage,
	selectedCommentsId,
	changeCommentOrderRoute,
	selectedCommentIdOrderRoute,
	onClick,
	country,
	getCarClassTimeInfo,
}) {
	const navigate = useNavigate();
	const commonConfig = useMemo(() => JSON.parse(localStorage.getItem("commonConfig")), []);
	const [disabledSubmit, setDisabledSubmit] = useState(false);

	const getCarArrivalTime = useCallback(
		async (orderPreferences) => {
			const watingAdress = JSON.parse(localStorage.getItem("watingAdress"));
			const firstPoint = watingAdress.odrerRoute.routes[0];

			const infoObj = {
				destinationPoints: {
					lat: firstPoint.lat,
					lon: firstPoint.lon,
				},
				orderPreferences,
			};

			await getCarClassTimeInfo(infoObj);
		},
		[getCarClassTimeInfo],
	);

	const handleGoBack = useCallback(async () => {
		setDisabledSubmit(true);
		const sum = dopServices.reduce((a, e) => a + e.price, 0);
		const orderInfoData = await getOrderInfoData({
			currentMapProvider: commonConfig.mapProvider,
			routes,
			sum,
			country,
			commonConfig,
		});

		await getOrderCost(orderInfoData);
		await getCarArrivalTime(dopServices);
		if (onClick) {
			onClick();
			setDisabledSubmit(false);
			return;
		}
		navigate(-1);
		setDisabledSubmit(false);
	}, [
		commonConfig,
		country,
		dopServices,
		getCarArrivalTime,
		getOrderCost,
		navigate,
		onClick,
		routes,
	]);

	const [text, setText] = useState(commentMessage);

	const handleChange = (event) => {
		const inputValue = event.target.value;
		setText(inputValue);
	};

	useEffect(() => {
		return () => {
			changeCommentOrderRoute(text);
		};
	}, [text, changeCommentOrderRoute]);

	const changeDopServices = async (servece) => {
		const dopServece = dopServices.find((e) => e.id === servece.id);
		let newServeses;
		if (dopServece) {
			newServeses = dopServices.filter((e) => e.id !== servece.id);
		} else {
			newServeses = [...dopServices, servece];
		}
		const sum = newServeses.reduce((a, e) => a + e.price, 0);
		changeDopServicesOrderRoute({
			dopServices: newServeses,
			sumDopServises: sum,
		});
	};

	return (
		<>
			<PageHeader isBackIconVisible={false} title={t("options")} />
			<PageLayout>
				<div className={cls.OrderPreferencesWrapper}>
					<div className={cls.preferences_block}>
						<h1>{t("services")}</h1>
						<div className={cls.preferences_services}>
							{preferences.map((serv, index) => {
								const active = dopServices.find((e) => e.id === serv.id);
								return (
									<button
										key={serv.id}
										className={`${cls.preferences_services_btn} ${active ? cls.active : ""}`}
										onClick={() => changeDopServices(serv)}
									>
										<div
											className={`${cls.preferences_services_item} ${
												index === preferences.length - 1 ? cls.last_item : ""
											}`}
										>
											<p>{t(serv.name)}</p>
											<div className={cls.preferences_price}>
												<div className={`${cls.sum} ${active ? cls.sum_active : ""}`}>
													<p>+{serv.price}</p>
													<p>{symbol}</p>
												</div>
												{active && (
													<div
														className={cls.check}
														onClick={(e) => {
															e.stopPropagation();
														}}
													>
														<Check />
													</div>
												)}
											</div>
										</div>
									</button>
								);
							})}
						</div>
					</div>
					{/* <div className={cls.comment_block}>
            <h1>{t('commentary')}</h1>
            <div className={cls.comment_area}>
              <textarea
                placeholder={t('write_comment')}
                maxLength={195}
                value={text}
                onChange={handleChange}
              />
              <p className={cls.text_counter}>{text.length}/195</p>
            </div>
          </div> */}
					<div className={cls.confirm_block}>
						<Button
							disabled={disabledSubmit}
							variant={ButtonVariant.background}
							fullWidth
							onClick={handleGoBack}
						>
							{t("complet")}
						</Button>
					</div>
				</div>
			</PageLayout>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		preferences: state.dictionary.preferences,
		dopServices: state.odrerRoute.dopServices,
		routes: state.odrerRoute.routes,
		commentMessage: state.odrerRoute.commentMessage,
		selectedCommentsId: state.odrerRoute.selectedCommentsId,
		country: state.odrerRoute.infoCountry.country_code,
		symbol: getValuteSymbol(state.odrerRoute.infoCountry.currency_code),
	};
};

export default connect(mapStateToProps, {
	changeDopServicesOrderRoute,
	getOrderCost,
	changeCommentOrderRoute,
	selectedCommentIdOrderRoute,
	getCarClassTimeInfo,
})(OrderPreferences);
