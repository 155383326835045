export const formatSecondsToTime = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  if (minutes === 0) {
    return `0:${remainingSeconds.toString().padStart(2, '0')}`
  } else {
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }
}

// export const formatSecondsToTime = (seconds) => {
//   if (seconds >= 60) {
//     const minutes = Math.floor(seconds / 60);
//     const remainingSeconds = seconds % 60;

//     if (remainingSeconds === 0) {
//       return `через ${minutes} мин`;
//     } else {
//       return `через ${minutes} мин ${remainingSeconds} сек`;
//     }
//   } else {
//     return `${seconds} сек`;
//   }
// }
