import React, { memo, useCallback, useState } from 'react'
import { classNames } from '../../lib/classNames/classNames'
import { ReactComponent as OpenEye } from '../../assets/icons/eye.svg'
import { ReactComponent as CloseEye } from '../../assets/icons/eye-off.svg'
import cls from './Input.module.scss'

export const Input = memo(
  ({
    className,
    children,
    value,
    name,
    handleClearValue,
    isPassword,
    readOnly,
    type,
    onChange,
    isError,
    placeholder,
    valueError,
    ref,
    isRequired,
    ...otherProps
  }) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleChange = useCallback(
      (event) => {
        if (onChange) {
          onChange(event)
        }
      },
      [onChange]
    )

    const handleShowPassword = useCallback(() => {
      setShowPassword(!showPassword)
    }, [showPassword])

    return (
      <>
        <div className={classNames(cls.InputWrapper, {}, [className])}>
          <input
            className={classNames(cls.input, {}, [])}
            type={!showPassword && isPassword ? 'password' : 'text'}
            placeholder={!isRequired ? placeholder : `${placeholder} *`}
            name={name}
            readOnly={readOnly}
            value={value}
            onChange={handleChange}
            {...otherProps}
          />
          {value && isPassword && (
            <div className={cls.inputIcon} onClick={handleShowPassword}>
              {!showPassword ? <CloseEye className={cls.icon} /> : <OpenEye className={cls.icon} />}
            </div>
          )}
          {isError && <p className={cls.error}>{valueError}</p>}
        </div>
      </>
    )
  }
)
