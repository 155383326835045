import React, { useCallback, useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from "!maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { ReactComponent as LocationArrow } from "../../shared/assets/icons/LocationArrow.svg"; // зеленый человечек для самой первой точкий
import Loader from "src/shared/ui/Loader/Loader";
import "./styles.scss";

import useBuildFullRoute from "./hooks/useBuildFullRoute";
import useDrawMarker from "./hooks/useDrawMarker";
import useDrawClientMarker from "./hooks/useDrawClientMarker";
import CreateSearchMarkers from "./components/CreateSearchMarkers";
import { classNames } from "src/shared/lib/classNames/classNames";
import getCenterAndZoom from "./helpers/getCenterAndZoom";
import useCreateMap from "./hooks/useCreateMap";
import { useDrawArrayMarkers } from "./hooks/useDrawArrayMarkers";

const CustomMap = (props) => {
	const {
		coordsDriver,
		passengerCoords,
		polylinePositions,
		mapPoints,
		zoomFullRoute,
		mapLoaded,
		setMapLoaded,
		theme,
		setZoomFullRoute = () => null,
		roadPoints,
		setPointByCoords,
		isAutocompleteSelection,
		setIsAutocompleteSelection,
		setIsCurrentLocation,
		setSuggestionPlaces,
		isMapMove,
		setIsMapMove,
		setIsInputActive,
		isPriceCalculated,
		discount,
		disabledFunctions,
		arrayIcons,
	} = props;

	const [tracking, setTracking] = useState(false);
	const bearingAccuracyMeters = 20;
	const mapContainer = useRef(null);
	const map = useRef(null);
	const carMarkerRef = useRef(null);
	const clientMarkerRef = useRef(null);
	const currentTheme = theme === "light" ? "lightTheme" : "darkTheme";

	const {
		disableSeparateRoute = false,
		disableButtons = false,
		disableGlueCar = false,
		disabledCarMarker = false,
		disableMarkerAnimation = false,
		disabledDrawArrayMarkers = false,
		disableClientMarker = false,
	} = disabledFunctions;

	//TEST, NOT DELETE
	// const [testCoordsDriver, setTestCoordsDriver] = useState([0, 0])

	const mapTheme =
		currentTheme === "lightTheme" ? "/map-files/styles.json" : "/map-files/dark-map-styles.json";

	// route position
	const prevPolylinePositions = useRef(null);

	const handleMapActions = useCallback(() => {
		// Disable tracking when the map is swiped
		setIsAutocompleteSelection(false);
		setTracking(false);
	}, [setIsAutocompleteSelection]);

	// create map
	useCreateMap({ map, mapContainer, mapTheme, passengerCoords, setMapLoaded });

	// zoom map to full route view
	useEffect(() => {
		if (mapLoaded && map.current && mapPoints?.length > 0 && !tracking) {
			getCenterAndZoom(mapPoints, map);
		}
	}, [mapLoaded, mapPoints, isPriceCalculated, tracking]);

	// draw client marker
	useDrawClientMarker({
		map,
		mapLoaded,
		passengerCoords,
		clientMarkerRef,
		disableClientMarker,
	});

	// add car marker and calculate rotation
	useDrawMarker({
		map,
		carMarkerRef,
		polylinePositions,
		bearingAccuracyMeters,
		coordsDriver,
		mapLoaded,
	});

	// build full route
	useBuildFullRoute({
		polylinePositions,
		map,
		prevPolylinePositions,
		currentTheme,
		coordsDriver,
		mapPoints,
		mapLoaded,
	});

	//  draw marker by array with coords and html elements
	useDrawArrayMarkers({ map, arrayIcons, disabledDrawArrayMarkers });

	// custom geolocation
	useEffect(() => {
		if (tracking && mapLoaded && !zoomFullRoute && passengerCoords) {
			map.current.flyTo({ center: passengerCoords, zoom: 18 });
			map.current.on("wheel", () => {
				handleMapActions();
			});

			map.current.on("drag", () => {
				handleMapActions();
			});
		}

		// Clean up event listeners when the component unmounts or when tracking changes
		return () => {
			if (map.current) {
				map.current.off("wheel", handleMapActions);
				map.current.off("drag", handleMapActions);
			}
		};
	}, [tracking, zoomFullRoute, handleMapActions, mapLoaded, passengerCoords]);

	// search markers on map
	useEffect(() => {
		if (roadPoints.length && map.current && isAutocompleteSelection) {
			const getActivePoint = roadPoints.find((point) => point.active);

			if (getActivePoint?.lat && getActivePoint?.lon) {
				const centerActivePoint = [getActivePoint.lon, getActivePoint.lat];
				map.current.flyTo({ center: centerActivePoint, zoom: 18 });
			}
		}
	}, [isAutocompleteSelection, roadPoints]);

	useEffect(() => {
		const findActivePoint = roadPoints.find((point) => point.active);

		if (findActivePoint && tracking) {
			const coords = {
				lat: passengerCoords[1],
				lng: passengerCoords[0],
			};

			setPointByCoords(coords, findActivePoint.id);

			if (roadPoints[0]?.active) {
				setIsCurrentLocation(true);
			}

			setTracking(false);
		}
	}, [passengerCoords, roadPoints, setIsCurrentLocation, setPointByCoords, tracking]);

	const handleGpsButtonClick = () => {
		setTracking(!tracking);
		setZoomFullRoute(false);

		if (roadPoints[0]?.active) {
			setIsCurrentLocation(true);
		}
	};

	return (
		<>
			{!mapLoaded && <Loader className={classNames("Loader", {}, [])} />}
			<div
				className="maplibre-map"
				ref={mapContainer}
				style={{ position: "relative", top: 0, bottom: 0, width: "100%" }}
			>
				{/* Gps */}
				{!disableButtons && (
					<div
						onClick={handleGpsButtonClick}
						className={classNames("location-button", { "location-button-top": discount }, [])}
					>
						<div className="locationIcon">
							<LocationArrow />
						</div>
					</div>
				)}
				{/* On input typing create markers */}
				{roadPoints &&
					roadPoints.map((point, index) => (
						<CreateSearchMarkers
							key={`${point.id}_${index}`}
							map={map}
							mapLoaded={mapLoaded}
							point={point}
							setPointByCoords={setPointByCoords}
							setIsAutocompleteSelection={setIsAutocompleteSelection}
							setIsCurrentLocation={setIsCurrentLocation}
							tracking={tracking}
							roadPoints={roadPoints}
							setTracking={setTracking}
							setSuggestionPlaces={setSuggestionPlaces}
							isMapMove={isMapMove}
							setIsMapMove={setIsMapMove}
							setIsInputActive={setIsInputActive}
							disableMarkerAnimation={disableMarkerAnimation}
						/>
					))}
			</div>
		</>
	);
};

export default React.memo(CustomMap);
