import { axiosInstance } from 'src/axios.config'
import { apiData } from '../../config'
import { errorFunction } from '../ErrorComponent/error.action'
import { errorTokenFunction } from '../ErrorToken/error.action'

export const GET_QUESTIONS_PENDING = 'GET_QUESTIONS_PENDING'
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS'
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR'

export const getQuestion = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_QUESTIONS_PENDING,
    })
    axiosInstance
      .get(apiData + '/profile/faq', { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        console.log('Action* - список обращений УСПЕХ: ', response)
        dispatch({
          type: GET_QUESTIONS_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        console.log('Action* - список обращений ОШИБКА: ', error)
        dispatch({
          type: GET_QUESTIONS_ERROR,
          payload: error,
        })
      })
  }
}

export const GET_QUEST_PENDING = 'GET_QUEST_PENDING'
export const GET_QUEST_SUCCESS = 'GET_QUEST_SUCCESS'
export const GET_QUEST_ERROR = 'GET_QUEST_ERROR'

export const getQuestionText = (id) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_QUEST_PENDING,
    })
    axiosInstance
      .get(apiData + `/profile/faq/${id}`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        console.log('Action* - текст обращения УСПЕХ: ', response)
        dispatch({
          type: GET_QUEST_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        console.log('Action* - текст обращения ОШИБКА: ', error)
        dispatch({
          type: GET_QUEST_ERROR,
          payload: error,
        })
      })
  }
}
