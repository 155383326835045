import React, { memo } from 'react'
import { connect } from 'react-redux'
import cls from './AlertModal.module.scss'

const AlertModal = memo((props) => {
  const { title, subtitle } = props

  return (
    <div className={cls.AlertModal}>
      <div className={cls.titleInfo}>
        <h1>{title}</h1>
        <p>{Boolean(subtitle) && subtitle}</p>
      </div>
    </div>
  )
})

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal)
