import React from 'react'

export const ExitIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#343330"
        d="M10.5 20.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3h5.25a.75.75 0 1 1 0 1.5H4.5v15h5.25a.75.75 0 0 1 .75.75Zm10.28-8.78-3.75-3.75a.75.75 0 1 0-1.06 1.06l2.47 2.47H9.75a.75.75 0 1 0 0 1.5h8.69l-2.47 2.47a.75.75 0 1 0 1.06 1.06l3.75-3.75a.747.747 0 0 0 0-1.06Z"
      />
    </svg>
  )
}
