import { axiosInstance } from 'src/axios.config'
import { apiReverseGeocode } from '../../../config'
import { getLanguageCountry } from './fetchSuggestions'

export const fetchGeocodeFromCoords = async (reverseGeocodeParams) => {
  const accessToken = localStorage.getItem('accessToken')

	const { point, registrationCountryInfo } = reverseGeocodeParams

	const customParams = {
		point,
		language: getLanguageCountry(registrationCountryInfo?.country_code)
	}

	if (registrationCountryInfo?.country_code) {
    customParams.country = registrationCountryInfo.country_code;
  }

  try {
    const response = await axiosInstance.post(apiReverseGeocode, customParams, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log('Error fetching suggestions:', error)
    return [] // Return an empty array or handle the error as needed
  }
}
