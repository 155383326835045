import React from "react";
import cls from "./detailNews.module.scss";
import { formatDateToDayMonthYear } from "../../../shared/helpers/formatDateToDayMonthYear";
import Loader from "../../../shared/ui/Loader/Loader";

const DetailNews = ({ userNew, loading }) => {
	if (loading) {
		return <Loader />;
	}

	return (
		<div className={cls.DetailNewsWrapper}>
			<h4 className={cls.date}>{formatDateToDayMonthYear(userNew.createdAt)}</h4>
			<h2 className={cls.title}>{userNew.title}</h2>
			<div className={cls.contentInfo}>
				<p>{userNew.content}</p>
			</div>
		</div>
	);
};

export default DetailNews;
