/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import { t } from 'i18next'
import MyGeolocation from '../../images/MyGeolocation.svg'
import MyGeolocationGreen from '../../images/MyGeolocationGreen.svg'
import cls from './orderRoute.module.scss'
import { ReactComponent as LocationPin } from '../../images/LocationPin.svg'
import { ReactComponent as Close } from '../../images/Close.svg'
import { ReactComponent as PlusCircle } from '../../images/PlusCircle.svg'
import { ReactComponent as Pictogram } from '../../images/Pictogram.svg'
import { ReactComponent as MapPin } from '../../images/MapPin.svg'
import { ReactComponent as BackClock } from '../../images/BackClock.svg'
import { ReactComponent as VerticalDots } from '../../images/VerticalDots.svg'
import AutocompleteOrderHandler, {
  createTravelForMap,
  initMarker,
} from './AutocompleteOrderHandler'
import {
  addRoutesOrderRoute,
  changePaymentTypeOrderRoute,
  // changeTravelRotesOrderRoute,
  confirmedAdressOrderRoute,
  paymentsCart,
  updateInputCount,
  keyboardOpen,
} from './order.route.action'
import { getOrderCost } from '../MapJs/order.action'
import { getGoogleTravel } from './helperCalculate'
import {
  nameComponents,
  openAnotherComponentBottomMenu,
} from '../BottomMenu/bottom.navigate.action'
import ModalIdentityAdress from '../ModalIdentityAdress/ModalIdentityAdress'
import { sendCoordsToRpc } from '../Registration/registration.action'
import ModalCountry from '../ModalCountry/ModalCountry'
import ModalLargeRequest from '../ModalLargeRequest/ModalLargeRequest'
import { useNavigate } from 'react-router-dom'
import { removeOneMarker, saveNewMarker } from '../MapMarkers/MapMarkers.action'
import { ReactComponent as UserLocation } from '../../images/userLocation.svg'
import ChangeFirstWindowMap from '../ChangeFirstWindowMap/ChangeFirstWindowMap'

export const textFormator = (text) => {
  const arrAdress = text.split(', ')
  let minusCountElement = 1
  let mainText = arrAdress[0] + ' '

  if (parseInt(arrAdress[1])) {
    mainText += arrAdress[1]
    minusCountElement++
  }

  return {
    mainText,
    secondaryText: arrAdress.slice(minusCountElement, arrAdress.length - 2).join(', '),
  }
}

function OrderRoute({
  map,
  lastPoints,
  markets,
  changePaymentTypeOrderRoute,
  currentGoogleMapCoordinates,
  sumDopServises,
  saveNewMarker,
  geolocation,
  sendCoordsToRpc,
  removeOneMarker,
  keyboardOpen,
  routes,
  getOrderCost,
  addRoutesOrderRoute,
  openAnotherComponentBottomMenu,
  confirmedAdressOrderRoute,
  country,
  meMarker,
  travelRoutes,
  numberOfInputs,
  updateInputCount,
  someValue,
  someValueTwo
}) {
  const [inputs, setInputs] = useState([
    {
      _id: 'place-adress-1',
      ref: createRef('place-adress-1'),
    },
    {
      _id: 'place-adress-2',
      ref: createRef('place-adress-2'),
    },
  ])
  const [notice, setNotice] = useState({
    top: 0,
    text: t('identity_adresses'),
    isOpen: '',
  })
  const mapMarkerG = useRef(null)
  const [autocompleteDivs, setAutocompleteDivs] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalOpenCountry, setModalOpenCountry] = useState(false)
  const [isModalOpenLarge, setModalOpenLarge] = useState(false)
  const [editInput, setEditInput] = useState(null)
  const [adress, setAdress] = useState(null)
  const [coordsForMap, setCoordsForMap] = useState(null)
  const [historyEdit, setHistoryEdit] = useState([])

  useEffect(() => {
    if (map) {
      const listener = window.google.maps.event.addListener(map, 'dragend', () => addMarkerMap())
      const listenerClick = window.google.maps.event.addListener(map, 'dragstart', () =>
        addAnimateForMarker(true)
      )
      return () => {
        window.google.maps.event.removeListener(listener)
        window.google.maps.event.removeListener(listenerClick)
      }
    }
  }, [map, editInput, mapMarkerG])
  const addAnimateForMarker = (type) =>
    type
      ? mapMarkerG.current.classList.add(cls.animate)
      : mapMarkerG.current.classList.remove(cls.animate)

  const addMarkerMap = (name) => {
    if (editInput === null) return
    const geocoder = new window.google.maps.Geocoder()
    const center = map.getCenter()
    addAnimateForMarker()
    setCoordsForMap(center) //TODO edit comment when edit back for accurate adress

    geocoder.geocode(
      {
        latLng: center,
      },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            editInput.elem.ref.current &&
              (editInput.elem.ref.current.value = results[0].formatted_address)
            const adress = results[0]
            if (editInput === null) return
            const id = inputs[editInput.i]._id
            routes[editInput.i] = {
              _id: id,
              adress,
            }
            setAdress(adress)
            changeStyle(true, editInput.elem.ref.current)
            addRoutesOrderRoute(routes)
          }
        }
      }
    )
  }

  const saveAdress = () => {
    editInput?.elem.ref.current.value.trim() !== '' && addMarket(adress)
    setEditInput(null)
    setAdress(null)
  }

  const addMarket = (adress, index) => {
    if (adress === null) return
    // const i = index === undefined ? editInput.i : index;
    const i = editInput.i ;
    const location = coordsForMap ? coordsForMap : adress.geometry.location;

    // markets[i].market?.setMap(null)

    const img = i === 0 ? MyGeolocationGreen : MyGeolocation

    const marker = new window.google.maps.Marker({
      position: location,
      map: map,
      icon: {
        url: img,
        scaledSize: new window.google.maps.Size(60, 60),
      },
    })
//!place
    markets[i] = {
      market: marker,
      adress,
    }
    setCoordsForMap(null)
    saveNewMarker(markets)
    //? add logical remove
  }
  const checkAdress = () => {
    markets[0].market?.setMap(null)
    setAdress(null)
    setEditInput(null)
    // const v = ed.target.value;
    const v = editInput.elem.ref.current.value.trim()
    if (v.trim() === '') {
      map.setCenter(geolocation.geometry.location)
      setMyAdress()

      // markets[0]?.market && //TODO look
      //   setEditInput(null)
    }
  }

  const imageMap = editInput && editInput.i === 0 ? MyGeolocationGreen : MyGeolocation

  const isFirstMyAdress = () =>
    geolocation && routes[0] && routes[0].adress?.place_id === geolocation?.place_id

  const setMyAdress = () => {
    inputs[0].ref.current.value = geolocation.formatted_address
    changeStyle(true, inputs[0].ref.current)
    routes[0] = {
      _id: inputs[0]._id,
      adress: geolocation,
    }

    addRoutesOrderRoute(routes)
  }

  useEffect(() => {
    changePaymentTypeOrderRoute({ ...paymentsCart, text: 'cash' })
  }, [])
  useEffect(() => {
    if (geolocation) {
      setMyAdress()

      const getCountry = async () => {
        const l = geolocation.geometry.location
        const successful = await sendCoordsToRpc(l.lat() + ' ' + l.lng())
        if (successful === false) {
          clearInput(inputs[0])
        }
      }
      getCountry()
    }
  }, [geolocation])

  if (editInput && inputs.length > 2) {
    keyboardOpen(1)
  } else {
    keyboardOpen(2)
  }

  useEffect(() => {
    const autoComplits = document.querySelectorAll('.pac-container.pac-logo.hdpi')
    autoComplits.forEach((div) => div.remove())
    localStorage.setItem('inputsL', inputs.length)
    updateInputCount(inputs.length)
    map && AutocompleteOrderHandler(inputs, changePointOrderRoute)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, map, routes, editInput])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const closeModalCountry = () => {
    setModalOpenCountry(false)
  }

  const closeModalLarge = () => {
    setModalOpenLarge(false)
  }

  const addPoint = () => {
    const elemL = inputs[inputs.length - 1]
    const l_id = +elemL._id[elemL._id.length - 1]
    const newId = 'place-adress-' + (l_id + 1)
    setInputs([
      ...inputs,
      {
        _id: newId,
        ref: createRef(newId),
      },
    ])
  }

  const changeStyle = (isActive, input) => {
    const teg = input.closest('#adressPoint')
    const cont = input.closest('.order-route-container-animation')
    cont.classList.add(cls.animation_input)
    setTimeout(() => cont.classList.remove(cls.animation_input), 2000)
    if (isActive) {
      teg.classList.add(cls.route_item_full)
      teg.classList.remove(cls.route_item_unfull)
    } else {
      teg.classList.remove(cls.route_item_full)
      teg.classList.add(cls.route_item_unfull)
    }
  }

  const changePointOrderRoute = async (_id, adress, callback) => {

    const i = inputs.findIndex((e) => e._id === _id)
    const elem = inputs[i]

    if (elem._id === 'place-adress-1') {
      const l = adress.geometry.location
      const successful = await sendCoordsToRpc(l.lat() + ' ' + l.lng())
      if (successful === false) {
        clearInput(inputs[0])
        setModalOpenCountry(true)
        return 'error'
      }


    }

    if (routes[i - 1] !== undefined) {
      const e = routes[i - 1]
      if (e.adress.place_id === adress.place_id) {
        const data = elem.ref.current.getBoundingClientRect()
        setNotice({ top: data.top + data.height + 10, text: t('identity_adresses'), isOpen: true })
        setModalOpen(true)
        return (elem.ref.current.value = '')
      }
    } else if (routes[i + 1] !== undefined) {
      const e = routes[i + 1]
      if (e.adress.place_id === adress.place_id) {
        const data = elem.ref.current.getBoundingClientRect()
        setNotice({ top: data.top + data.height + 10, text: t('identity_adresses'), isOpen: true })
        setModalOpen(true)
        return (elem.ref.current.value = '')
      }
    }

    changeStyle(true, elem.ref.current)
    const elemFromReducer = routes.find((e) => e?._id === _id)
    if (elemFromReducer) {
      elemFromReducer.adress = adress
    } else {
      const orderAdress = {
        _id,
        adress,
      }
      routes.push(orderAdress)
    }
    elem.ref.current.value = adress.formatted_address
    addRoutesOrderRoute(routes)
    map.setCenter(adress.geometry.location)
    if(markets[i]) markets[i].market?.setMap(null)
    addMarket(adress)
    setEditInput(null)
    setAdress(null)
  }

  const clearInput = (elem, i) => {
    const input = elem.ref.current
    changeStyle(false, input)
    input.value = ''
    const newRoutes = routes.filter((e) => e?._id !== elem._id)
    addRoutesOrderRoute(newRoutes)
  }

  const removeInput = (elem) => {
    const newInputs = inputs.filter((e) => e._id !== elem._id)
    const newRoutes = routes.filter((e) => e?._id !== elem?._id)
    setInputs(newInputs)
    addRoutesOrderRoute(newRoutes)
  }
  // console.log(editInput);
  /* Центровка к точке пользователя */
  const setCenterToUser = () => {
    setTimeout(() =>  map.setCenter(geolocation.geometry.location),150)

    // console.log(inputs);
    // console.log('click, ------------------------');
    if(editInput && editInput.elem.ref.current.value.trim() !== '') {
      const id = editInput.elem._id;
      routes[editInput.i] = {
        _id: id,
        adress : geolocation,
      }
      setAdress(geolocation)
      changeStyle(true, editInput.elem.ref.current)
      addRoutesOrderRoute(routes)
      editInput.elem.ref.current.value = geolocation.formatted_address
      markets[editInput.i] && markets[editInput.i].market?.setMap(null)
      setCoordsForMap(null)
      // addMarket(geolocation)
      // saveAdress
    } else if(inputs.length === 2  ) {
      const l = historyEdit[historyEdit.length - 1];

      let i;
      let _id
      if(l === undefined) {
        i = 1;
        _id = inputs[1]._id
      } else {
        _id = l;
        i = inputs.findIndex((elem) => elem._id === _id)
      }
      if(inputs[i]?.ref.current.value.trim() === '') return
      // if(i === 1) addMarket(geolocation, 1)

      const input = inputs[i];
      setEditInput({elem : input, i})
      input.ref.current.focus()
      //!place

      // console.log(historyEdit);
      // const id = inputs[1]._id;
      routes[i] = {
        _id: _id,
        adress : geolocation,
      }
      setAdress(geolocation)
      changeStyle(true, inputs[i].ref.current)
      addRoutesOrderRoute(routes)

      inputs[i].ref.current.value = geolocation.formatted_address
      markets[i] && markets[i].market?.setMap(null)

    }
//     } else if(inputs.length === 2 && routes[0] && routes[0].adress.place_id !== geolocation.place_id ) {
// console.log(historyEdit);
//       const id = inputs[1]._id;
//       routes[1] = {
//         _id: id,
//         adress : geolocation,
//       }
//       setAdress(geolocation)
//       changeStyle(true, inputs[1].ref.current)
//       addRoutesOrderRoute(routes)
//       inputs[1].ref.current.value = geolocation.formatted_address
//       markets[1] && markets[1].market?.setMap(null)

//     }

    // markets[0].market && markets[0].market.setMap(null);
    // setMyAdress()
  }
  const creatError = () => {
    setNotice({  text: t('identity_adresses'), isOpen: true })
    setModalOpen(true)
  }

  const creatErrorLarge = () => {
    // setNotice({  text: t('identity_adresses'), isOpen: true })
    setModalOpenLarge(true)
  }
// window.routes = routes
  const confirmed = async () => {
    if (routes.length < 2) return;
    const _routes = routes.filter((elem) => elem && elem.adress && elem.adress.place_id)
    addRoutesOrderRoute(_routes)
    // ----------------------------
    const isDouble = _routes.some((elem, i) => {
      const prev = _routes[i - 1];
      const next = _routes[i + 1];
      let isDouble = false;
      if(!elem) return isDouble
      if(isDouble === false && prev !== undefined) isDouble = elem.adress.place_id === prev.adress.place_id;
      if(isDouble === false && next !== undefined) isDouble = elem.adress.place_id === next.adress.place_id;
      return isDouble
    })
    if(isDouble) return creatError()

    // ----------------------------
    const dataForMap = _routes.map((e) => e.adress)
    const response = await getGoogleTravel(dataForMap, creatErrorLarge)
    const arrsTravels = response.routes[0].legs
    const distance = arrsTravels.reduce((a, e) => a + e.distance.value, 0)
    if (distance < 400000) {
      confirmedAdressOrderRoute(true)
      openAnotherComponentBottomMenu(nameComponents.CAR_PRICE)
      markets.forEach((m) => m.market && m.market.setMap(null))
      meMarker && meMarker.setMap(null)
      const orderInfoData = {
        directionResponse: {
          geocoded_waypoints: response.geocoded_waypoints,
          routes: response.routes,
          status: 'OK',
        },
        orderPreferencesSum: sumDopServises,
        originCountryCode: country,
      }
      getOrderCost(orderInfoData)
    } else {
      setModalOpenLarge(true)
    }
  }

  const choiceAdressHistory = (point) => {
    const input = inputs.find((input) => input.ref.current.value.trim() === '')
    if (input === undefined) return
    initMarker(point.pointId, input._id, changePointOrderRoute)
  }

  const removeMarket = (i) => {
    markets[i]?.market && markets[i].market.setMap(null)
    markets.splice(i, 1)
    removeOneMarker(markets)
  }

  const centerMapToAddress = (address) => {
    return () => {
      if (editInput === null) return
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        {
          address: address,
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              const location = results[0].geometry.location
              map.setCenter(location)
            }
          }
        }
      )
    }
  }
  const saveChange = async () => {
    if (editInput?.i === 0) {
      if (
        editInput.elem.ref.current.value.trim() === '' ||
        editInput.elem.ref.current.value === geolocation.formatted_address
      ) {
        checkAdress()
      } else {
        saveAdress()
        if (adress === null) return
        // changePointOrderRoute(inputs[0]._id, adress, saveAdress)
        const l = adress.geometry.location
        const successful = await sendCoordsToRpc(l.lat() + ' ' + l.lng())
        if (successful === false) {
          clearInput(inputs[0])
          setModalOpenCountry(true)
          routes[0] = {};
          addRoutesOrderRoute(routes)

          return 'error'
        }
      }
    } else {
      saveAdress()
    }
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const focusInput = (elem, i, elemRoute) => {
    // console.log('focus, ---------------');
    // console.log(editInput);

    // setTimeout(() => {

      elem.ref.current.value.trim() === '' && map.setCenter(geolocation.geometry.location)
      setEditInput({ elem, i })
      historyEdit.push(elem._id)
      // setHistoryEdit([...ed])
      if (elemRoute?.adress) {
        setAdress(elemRoute.adress)
        // const l = historyEdit[historyEdit.length - 1];
        // if(elem._id !== id)

        map.setCenter(elemRoute.adress.geometry.location)
      }
      markets[i]?.market && markets[i].market.setMap(null)
    // },550)

  }

  const memoInputs = useMemo(() => {
    const lastI = inputs.length - 1
    const data = []
    const iconMove = lastI >= 3

    inputs.slice(1, lastI).forEach((e, i) => {
      if (e.ref.current && e.ref.current.value !== '') data.push(i + 1)
    })

    data.length === 1 && (data.length = 0)

    return inputs.map((elem, i) => {
      const elemFromReducer = routes.find((e) => elem._id === e?._id)
      const isFirstAndLast = i === 0 || i === lastI
      const isNotHasAdress = elemFromReducer === undefined

      const dopFullClass = isNotHasAdress ? cls.route_item_unfull : cls.route_item_full

      let Icon = MapPin
      if (isNotHasAdress) Icon = Pictogram
      else if (isFirstAndLast) Icon = LocationPin
      const iconMove = data.includes(i)
      const haveLastAdress = (i === 4 && someValue === 2 && routes[4] && routes[4].adress?.place_id ) ;
      let classForOneInput = ''
      if (i === 0) {
        if (inputs.length === 2) {
          const e = inputs[1]
          const fullElem = e.ref.current && e.ref.current.value !== ''
          if (fullElem) classForOneInput = cls.fullRemoveX
        } else {
          const array = inputs.slice(1)
          const fullElem = array.find((e) => e.ref.current && e.ref.current.value !== '')
          if (fullElem) classForOneInput = cls.hiddenX
        }
      }

      if (i === 4) {
        if (inputs.length === 2) {
          const e = inputs[1]
          const fullElem = e.ref.current && e.ref.current.value !== ''
          if (fullElem) classForOneInput = cls.fullRemoveX
        } else if(inputs.length === 5){
          if (haveLastAdress) classForOneInput = cls.fullRemoveX

          // if(someValue === 2 ) {
          //   console.log(elem);
          //   // if( elem.ref.current?.value.trim() )  classForOneInput = cls.fullRemoveX
          //   //
          // }
        }
        // else {
        //   const array = inputs.slice(1)
        //   const fullElem = array.find((e) => e.ref.current && e.ref.current.value !== '')
        //   if (fullElem) classForOneInput = cls.hiddenX
        // }
      }

      const focus = (e) => focusInput(elem, i, elemFromReducer)
      // TODO change first focus  (may be change inputs new key) change firstMarket
      const blur = (e) => {
        if (someValue === 1) return
        saveChange()
      }

      let hidden = ''
      if (lastI >= 2 && editInput) {
        hidden = editInput.i === i ? cls.show : cls.hiddenElem
      }

      let notMargin = ''
      if (someValue === 1) {
        notMargin = cls.additional_class
      }

      let icon = <Icon />
      if (i === 0) {
        icon = isFirstMyAdress() ? (
          <UserLocation className={cls.icon_users} />
        ) : (
          <LocationPin className={cls.icon_green} />
        )
      }

      // const haveLastAdress = (i === 4 && someValue === 2 && routes[4] && routes[4].adress?.place_id ) ;


      // console.log(haveLastAdress, i);
      // console.log(i === 4 , someValue === 2 , routes[4] , routes[4]?.adress?.place_id , i);
      return (
        <div className={hidden + ' ' + dopFullClass} id="adressPoint" key={elem._id}>
          <div
            className={
              cls.input_block +
              ' ' +
              notMargin +
              ' ' +
              classForOneInput +
              ' order-route-container-animation'
            }
          >
            <div className={cls.location_icon}>{icon}</div>
            <input
              placeholder={i === 0 ? t('write_geolocation') : t('where_shall_we_go')}
              id="first-adress"
              ref={elem.ref}
              onFocus={focus}
              onClick={() => centerMapToAddress(elem.ref.current?.value)}
              onBlur={blur}
            />
            {(isFirstAndLast || isNotHasAdress || someValue === 1 ) && ( //
              <div
                className={cls.close_icon + ' ' + classForOneInput}
                onClick={() => {
                  // console.log(isNotHasAdress, inputs.length);
                  if (elem.ref.current.value.trim() === '' && inputs.length > 2 && someValue === 2) {
                    removeInput(elem)
                    removeMarket(i)
                    // elem.ref.current.blur();
                  } else {
                    elem.ref.current.focus()
                    clearInput(elem, i)
                    historyEdit.push( elem._id)

                    markets[i]?.market && markets[i].market.setMap(null)
                  }
                }}
              >
                <Close />
              </div>
            )}
            {/* {iconMove && (
              <div className={cls.close_icon}>
                <VerticalDots className={cls.dots_icon} />
              </div>
            )} */}
          </div>
          {(isFirstAndLast === false && isNotHasAdress === false) && ( //i !== 0 &&  && !haveLastAdress
            <div
              // className={cls.close_icon}
              className={`${cls.close_icon} ${(someValue === 1 ) ? cls.display_none_class : ''}`}
              onClick={() => {
                removeInput(elem)
                removeMarket(i)
              }}
            >
              <Close />
            </div>
          )}
          {haveLastAdress && (
            <div
              className={`${cls.close_icon} ${(someValue === 1 ) ? cls.display_none_class : ''}`}
              onClick={() => {
                removeInput(elem)
                removeMarket(i)
              }}
            >
              <Close />
            </div>
          )}
          {someValue === 2 && lastI === i && i < 4 && (
            <div className={cls.add_point} onClick={addPoint}>
              <PlusCircle />
            </div>
          )}
        </div>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, routes, editInput, adress, markets, geolocation, meMarker, someValue, someValue,  historyEdit])

  return (
    <>
      <div className={cls.my_location_c}>
        {geolocation && (
          <button className={cls.my_location_button} onClick={setCenterToUser} role="presentation">
            <UserLocation className={cls.icon_users} />
          </button>
        )}
      </div>
      <div className={cls.route_block}>
        <ChangeFirstWindowMap />
        {memoInputs}
        <div className={cls.confirm}>
          {editInput && inputs.length > 2 ? (
            <button className={cls.confirm_btn} onClick={saveChange}>
              {t('apply')}
            </button>
          ) : (
            <Link
              to="/order"
              className={cls.confirm_btn + ' ' + (routes.length < 2 ? cls.disabled_btn : '')}
              onClick={confirmed}
            >
              {t('calculate_cost')}
            </Link>
          )}
        </div>
      </div>
      <ModalLargeRequest isOpen={isModalOpenLarge} onClose={closeModalLarge} />
      <ModalCountry isOpen={isModalOpenCountry} onClose={closeModalCountry} />
      {notice.isOpen && <ModalIdentityAdress isOpen={isModalOpen} onClose={closeModal} />}
      <div
        id="market-place-map"
        ref={mapMarkerG}
        className={cls.user_geolocation + ' ' + cls.animate}
        style={{ display: editInput ? 'block' : 'none' }}
      >
        <img src={imageMap} alt="" />
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    geolocation: state.mapMarkers.geolocation,
    markets: state.mapMarkers.markets,
    meMarker: state.mapMarkers.meMarker,
    currentGoogleMapCoordinates: state.odrerRoute.currentGoogleMapCoordinates,
    routes: state.odrerRoute.routes,
    sumDopServises: state.odrerRoute.sumDopServises,
    lastPoints: state.history.lastPoints,
    country: state.odrerRoute?.infoCountry?.country_code,
    travelRoutes: state.odrerRoute?.travelRoutes,
    numberOfInputs: state.odrerRoute.numberOfInputs,
    someValue: state.odrerRoute.someValue,
    someValueTwo: state.odrerRoute.someValueTwo,
  }
}

export default connect(mapStateToProps, {
  addRoutesOrderRoute,
  saveNewMarker,
  getOrderCost,
  confirmedAdressOrderRoute,
  sendCoordsToRpc,
  removeOneMarker,
  keyboardOpen,
  changePaymentTypeOrderRoute,
  openAnotherComponentBottomMenu,
  updateInputCount,
})(OrderRoute)