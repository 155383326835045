import { PUT_CODE_PENDING, PUT_CODE_SUCCESS, PUT_CODE_ERROR, RESET_CODE } from './sendcode.action'

const initialState = {
  codeLoaded: false,
  codeStatus: false,
  codeMessage: false,
}

const sendCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_CODE_PENDING: {
      return {
        ...state,
        codeLoaded: true,
      }
    }
    case PUT_CODE_SUCCESS: {
      return {
        ...state,
        codeStatus: true,
        codeLoaded: false,
        codeMessage: false,
      }
    }
    case PUT_CODE_ERROR: {
      return {
        ...state,
        codeStatus: false,
        codeLoaded: false,
        codeMessage: true,
      }
    }
    case RESET_CODE: {
      return {
        ...state,
        codeStatus: false,
        codeMessage: false,
        codeLoaded: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default sendCodeReducer
