import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ Component }) => {
  const isAuth = Boolean(localStorage.getItem('accessToken'))

  return isAuth ? <Component /> : <Navigate to="/login" />
}

export default ProtectedRoute
