import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { getHistory } from './store/history.action'
import HistoryItem from './HistoryItem'
import Loader from '../../shared/ui/Loader/Loader'
import { sortItemsByDate } from '../../shared/helpers/sortItemsByDate'
import { SortUpIcon, SortDownIcon } from '../../shared/assets/icons'
import SimpleDialog from '../../common/Dialog'
import HistoryDetailItem from './HistoryDetailItem'
import cls from './history.module.scss'
import useDialog from '../../shared/hooks/useDialog'
import moment from 'moment'

const History = memo((props) => {
  const { getHistory, historyData, historyLoaded } = props

  const [sortDirection, setSortDirection] = useState('desc')
  const { isOpen, dialogData, openDialog, closeDialog } = useDialog()

  useEffect(() => {
    const languageName = localStorage.getItem('language_name')?.toLocaleLowerCase()

    getHistory()
    moment.locale(languageName);
  }, [getHistory])

  const sortedTrips = useMemo(() => {
    if (!historyData?.length) {
      return []
    }
    return sortItemsByDate([...historyData], sortDirection)
  }, [historyData, sortDirection])

  const handleSortClick = useCallback(() => {
    const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc'
    setSortDirection(newSortDirection)
  }, [sortDirection])

  const setDetailTrip = useCallback(
    (tripData) => {
      openDialog(tripData)
    },
    [openDialog]
  )

  const detailOrderInfo = isOpen && (
    <SimpleDialog
      fullScreen
      open={isOpen}
      close={closeDialog}
      content={<HistoryDetailItem {...dialogData} />}
      onClick={closeDialog}
    />
  )

  if (historyLoaded) {
    return <Loader />
  }

  return (
    <div className={cls.HistoryWrapper}>
      {Object.keys(sortedTrips).map((tripDate, idx) => (
        <div className={cls.historyItemBlock} key={tripDate}>
          <div className={cls.dateBlock}>
            <h3>{tripDate}</h3>
            {idx === 0 &&
              (sortDirection === 'asc' ? (
                <SortUpIcon onClick={handleSortClick} className={cls.sortIcon} />
              ) : (
                <SortDownIcon onClick={handleSortClick} className={cls.sortIcon} />
              ))}
          </div>
          {sortedTrips[tripDate].map((trip) => (
            <HistoryItem key={trip.id} trip={trip} onClick={() => setDetailTrip(trip)} />
          ))}
        </div>
      ))}
      {detailOrderInfo}
    </div>
  )
})

const mapStateToProps = (state) => {
  return {
    historyData: state.history.historyData,
    historyLoaded: state.history.historyLoaded,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getHistory: () => dispatch(getHistory()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(History)
