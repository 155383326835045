import { useEffect, useRef } from "react";
import createMarker from "../../helpers/createMarker";
import debounce from "lodash.debounce";
import styles from "./styles.module.scss";
import useMarkerAnimation from "../../hooks/useMarkerAnimation";

const CreateSearchMarkers = (props) => {
	const {
		map,
		mapLoaded,
		point,
		setPointByCoords,
		setIsAutocompleteSelection,
		setIsCurrentLocation,
		tracking,
		roadPoints,
		setSuggestionPlaces,
		isMapMove,
		setIsMapMove,
		setIsInputActive,
		disableMarkerAnimation,
	} = props;

	const markerRef = useRef(null);
	const markerElement = useRef(null);
	const pulseClass = useMarkerAnimation(disableMarkerAnimation);

	const debouncedSetPointByCoords = debounce(async (mapRef, pointId) => {
		setPointByCoords(mapRef.getCenter(), pointId);
	}, 500);

	useEffect(() => {
		const mapRef = map.current;

		if (!mapRef || !mapLoaded) {
			return;
		}

		const mapCenter = mapRef.getCenter();
		const pointCenter = point.lat && point.lon ? [point.lon, point.lat] : mapCenter;
		if (!markerRef.current) {
			markerElement.current = document.createElement("div");
			const isPointZero = point.id === 0;
			const markerClass = point.name
				? isPointZero
					? `${styles.pointMarker} ${pulseClass}`
					: styles.pointMarkerBlue
				: styles.pointMarkerGrey;

			createMarker({
				map,
				markerElement: markerElement.current,
				position: pointCenter,
				markerRef,
				className: `${styles.markerElement} ${markerClass}`,
				markerId: point.id,
				length: roadPoints.length,
			});
		} else {
			!isMapMove && markerRef.current.setLngLat(pointCenter);
		}

		if (point.active) {
			const onMove = (e) => {
				debouncedSetPointByCoords.cancel();

				setIsAutocompleteSelection(false);
				setSuggestionPlaces([]);
				if (markerRef?.current) {
					markerRef.current.setLngLat(mapRef.getCenter());

					const isPointZero = point.id === 0;
					const markerClass = isPointZero ? styles.pointMarker : styles.pointMarkerBlue;

					if (typeof TouchEvent !== "undefined" && e.originalEvent instanceof TouchEvent) {
						if (!tracking && roadPoints[0].active) {
							setIsCurrentLocation(false);
						}

						setIsInputActive(false);
						setIsMapMove(true);

						markerElement.current.classList.remove(styles.pointMarkerGrey);
						markerElement.current.classList.add(markerClass);
						markerElement.current.classList.add(styles.animateMarker);
					}
				}
			};
			mapRef.on("move", onMove);

			const onMoveEnd = (e) => {
				if (e.originalEvent instanceof TouchEvent) {
					debouncedSetPointByCoords(mapRef, point.id);
				}

				if (tracking && roadPoints[0].active) {
					setIsCurrentLocation(true);
				}

				markerRef.current.setLngLat(mapRef.getCenter());
				markerElement.current.classList.remove(styles.animateMarker);
			};
			mapRef.on("moveend", onMoveEnd);

			return () => {
				// Remove the event listeners
				mapRef.off("move", onMove);
				mapRef.off("moveend", onMoveEnd);
			};
		}

		if (!point.lat && !point.lon && markerRef.current) {
			markerRef.current.remove();
			markerRef.current = null;
		}

		return () => {
			if (markerRef.current) {
				markerRef.current.remove();
				markerRef.current = null;
			}
		};
	}, [
		debouncedSetPointByCoords,
		isMapMove,
		map,
		mapLoaded,
		point,
		roadPoints,
		setIsAutocompleteSelection,
		setIsCurrentLocation,
		setIsInputActive,
		setIsMapMove,
		setSuggestionPlaces,
		tracking,
		pulseClass,
	]);

	// move to the marker, if there is one
	useEffect(() => {
		if (point.active) {
			const markerCoordinates = markerRef.current.getLngLat();
			map.current.flyTo({ center: markerCoordinates });
		}
	}, [map, point.active]);

	useEffect(() => {
		if (map && mapLoaded && point.active && markerElement.current) {
			const isPointZero = point.id === 0;
			const markerClass = isPointZero ? styles.pointMarker : styles.pointMarkerBlue;

			if (point.name?.length > 10) {
				markerElement.current.classList.remove(styles.pointMarkerGrey);
				markerElement.current.classList.add(markerClass);
			}
		}
	}, [map, mapLoaded, point]);

	return null;
};

export default CreateSearchMarkers;
