import {
  BUTTON_ACTIVE,
  BUTTON_DISABLED,
  GET_CARDS_SUCCESS,
  GET_CARDS_ERROR,
  GET_CARDS_LOADING,
} from './ccard.action'

const initialState = {
  userCardsList: [],
  isButtonActive: false,
  isLoading: false,
  addCardData: {},
}

const cardReducerNew = (state = initialState, action) => {
  switch (action.type) {
    case BUTTON_DISABLED: {
      return {
        ...state,
        isButtonActive: false,
        addCardData: {},
      }
    }
    case BUTTON_ACTIVE: {
      const languageName = localStorage.getItem('language_name')
      const currentLanguage = languageName || 'UK'
      const addCardData = action.payload.metadata || action.payload

      return {
        ...state,
        isButtonActive: true,
        addCardData: {
          ...addCardData,
          language: currentLanguage,
          gatewayType: action.payload.gatewayType || addCardData.gatewayType,
          cardId: action.payload.cardId,
        },
      }
    }

    case GET_CARDS_SUCCESS: {
      return {
        ...state,
        userCardsList: {
          ...action.payload.data,
          totalCards: action.payload.total,
        },
      }
    }
    case GET_CARDS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case GET_CARDS_ERROR: {
      return {
        ...state,
        userCardsList: [],
      }
    }

    default:
      return {
        ...state,
      }
  }
}

export default cardReducerNew
