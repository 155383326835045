export const ACTION_ERROR_TOKEN = 'ACTION_ERROR_TOKEN'

export const errorTokenFunction = data => {
  return dispatch => {
    dispatch({
      type: ACTION_ERROR_TOKEN,
      payload: data,
    })
  }
}
