import React, { memo } from 'react'
import { t } from 'i18next'
import { getValuteSymbol } from '../../CurrencyDefinition/getValuteSymbol'
import AddressStripHistory from '../../AddressStripHistory'
import { Button, ButtonSize, ButtonVariant } from '../../../shared/ui/Button/button'
import { classNames } from '../../../shared/lib/classNames/classNames'
import { CardIcon } from '../../../shared/assets/icons'
import cls from './historyItem.module.scss'

const HistoryItem = memo(({ trip, onClick }) => {
  const isFailedTrip = trip.statusId === 2 || trip.statusId === 32

  const mods = {
    [cls.failureTrip]: isFailedTrip,
  }

  return (
    <div className={classNames(cls.HistoryItem, mods, [])} onClick={onClick}>
      <div className={cls.header}>
        <CardIcon width={24} className={cls.icon} />
        <p className={cls.price}>
          {trip.totalQuote}
          <span className={cls.currency}>{getValuteSymbol(trip.currency)}</span>
        </p>
        <Button variant={ButtonVariant.success} className={cls.orderBtn} size={ButtonSize.M}>
          {isFailedTrip ? t('canceled') : t('done')}
        </Button>
      </div>
      <div className={cls.routeContainer}>
        <AddressStripHistory route={trip.destinationPoints} statusId={trip.statusId} />
      </div>
    </div>
  )
})

export default HistoryItem
