import React from 'react'
import { ChevronRightIcon } from '../../assets/icons'
import cls from './MenuItem.module.scss'
import { classNames } from '../../lib/classNames/classNames'
import { Button, ButtonVariant } from '../Button/button'

const MenuItem = (props) => {
  const { icon: Icon, title, subtitle, className, onClick, showRightIcon = true } = props

  return (
    <>
      <Button variant={ButtonVariant.clear} fullWidth className={classNames(cls.MenuItem, {}, [className])} onClick={onClick}>
        <div className={cls.icon}>{Icon}</div>
        <div className={cls.titleBlock}>
          <div className={cls.titleInfo}>
            <h3>{title}</h3>
            {subtitle && <p>{subtitle}</p>}
          </div>
          {showRightIcon && <ChevronRightIcon width={33} height={36} className={cls.rightIcon} />}
        </div>
      </Button>
    </>
  )
}

export default MenuItem
