import React, { memo } from 'react'
import cls from './apkRules.module.scss'
import { t } from 'i18next'
import Typography from '../../common/Typography'

const ApkRules = memo(() => {
  return (
    <div className={cls.ApkRulesWrapper}>
      <Typography>
        <p className={cls.title}>{t('main_text_paragraph')}</p>
        <p className={cls.title}>{t('main_text_paragraph_first')}</p>
        <p className={cls.title}>{t('main_text_paragraph_second')}</p>
        <p className={cls.title}>{t('main_text_paragraph_third')}</p>
        <p className={cls.title}>{t('main_text_paragraph_fourth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_fifth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_sixth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_seventh')}</p>
        <p className={cls.title}>{t('main_text_paragraph_eighth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_ninth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_tenth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_eleventh')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twelfth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirteenth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_fourteenth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_fifteenth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_sixteenth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_seventeenth')}</p>
        <p className={cls.title}>{t('exemption_from_liability')}</p>
        <p className={cls.title}>{t('main_text_paragraph_eighteenth')}</p>
        <p className={cls.title}>{t('main_text_breach_user_agreement_first')}</p>
        <p className={cls.title}>{t('main_text_breach_user_agreement_second')}</p>
        <p className={cls.title}>{t('main_text_breach_user_agreement_third')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentieth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentyFirst')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentySecond')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentyThird')}</p>
        <p className={cls.title}>
          {t('main_text_paragraph_user_can_provide_phone_number_any_way')}
        </p>
        <p className={cls.title}>{t('main_text_paragraph_twentyFour')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentyFifth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentySix')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentySeventh')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentyEight')}</p>
        <p className={cls.title}>{t('main_text_paragraph_twentyNinth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtieth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtyFirst')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtySecond')}</p>
        <p className={cls.title}>{t('confidentiality')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtyThird')}</p>
        <p className={cls.title}>{t('concluding_provisions')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtyFour')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtyFifth')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtySix')}</p>
        <p className={cls.title}>{t('main_text_paragraph_thirtySeventh')}</p>
      </Typography>
    </div>
  )
})

export default ApkRules
