import React from "react";
import { t } from "i18next";
import { classNames } from "src/shared/lib/classNames/classNames";
import styles from "src/components/MapJs/orders.module.scss";
import CarTimeDisplay from "../../../CarTimeDisplay/CarTimeDisplay";

const TariffsCarousel = (props) => {
	const { matchingCars, costOrders, imgesCar, carArrivalTimes, tariffCar, handleCarClick, symbol } =
		props;
	const isDarkTheme = localStorage.getItem("appTheme") === "dark";
	return (
		<div className={styles.tariffsCarousel}>
			{costOrders.map((tariff, index) => {
				const car = imgesCar(index);
				const active = tariffCar.rate === tariff.rate;
				const isLastItem = index === costOrders.length - 1;

				return (
					<>
						<button
							className={classNames(
								styles.tariffs,
								{
									[styles.activeTariff]: !isDarkTheme && active,
									[styles.activeTariffDark]: isDarkTheme && active,
									[styles.unavailableCar]: !matchingCars?.length > 0,
								},
								[],
							)}
							key={tariff.rate}
							onClick={() => handleCarClick({ ...car, ...tariff }, active)}
						>
							<div
								className={`${styles.tariffsItem} ${
									index === costOrders.length - 1 ? styles.last_item : ""
								}`}
							>
								<div className={`${styles.tariffsName} ${active ? styles.activeName : ""}`}>
									<div className={styles.tariffBlock}>
										<p className={styles.tariff}>{car.tariff}</p>
										<CarTimeDisplay
											carArrivalTimes={carArrivalTimes}
											tariffId={tariff.rate}
											className={styles.tariffTime}
										/>
									</div>
									<div className={styles.carItem}>
										<img src={car.img} alt={car.tariff} />
									</div>
								</div>
								<div className={`${styles.tariffsCost} ${active ? styles.activeCost : ""}`}>
									<p>{tariff.estimatedPrice || tariff.estimatedPriceBeforeVat || 0}</p>
									<p>{symbol}</p>
								</div>
							</div>
						</button>
						{!isLastItem && (
							<div className={`${styles.borderItem} ${isDarkTheme ? styles.borderItemDark : ""}`} />
						)}
					</>
				);
			})}
		</div>
	);
};

export default TariffsCarousel;
