import { SEND_MESSAGE_PENDING, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_ERROR, GET_ZENDESK_TOKEN, GET_ZENDESK_TOKEN_LOADING } from './feed.action'

const initialState = {
  feedLoaded: false,
  feedData: false,
  feedMessage: false,
	zendeskToken: '',
  zendeskTokenLoading: false,
}

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE_PENDING: {
      return {
        ...state,
        feedLoaded: true,
        feedData: false,
      }
    }
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        feedData: action.payload,
        feedLoaded: false,
      }
    }
    case SEND_MESSAGE_ERROR: {
      return {
        ...state,
        feedLoaded: false,
        feedMessage: true,
        feedData: false,
      }
    }
		case GET_ZENDESK_TOKEN:
      return { ...state, zendeskToken: action.payload }
    case GET_ZENDESK_TOKEN_LOADING:
      return { ...state, zendeskTokenLoading: action.payload }
    default:
      return {
        ...state,
      }
  }
}

export default feedReducer
