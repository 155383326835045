import { ACTIONS_ERROR, ACTIONS_CLOSE_ERROR } from './error.action'

const initialState = {
  error: false,
  errorDataSocket: '',
}

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_ERROR: {
      const errStr = JSON.stringify(action.payload)
      const errArr = errStr.split('http://mclient.devsplash.net')
      const finalErr = errArr.join('')
      return {
        ...state,
        error: true,
        errorDataSocket: finalErr,
      }
    }
    case ACTIONS_CLOSE_ERROR: {
      return {
        ...state,
        error: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default errorReducer
