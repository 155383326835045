import React, { memo, useCallback, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import { connect } from "react-redux";
import { fetchSuggestions } from "../../helpers/fetchSuggestions";
import { fetchLogSelectedAddress } from "../../helpers/fetchLogSelectedAddress";
import cls from "./styles.module.scss";
import { useMap } from "src/entities/MapWrapper/context/MapContext";
import { activeInputFocus } from "../OrderAddresses";

const AutocompletePoint = memo((props) => {
	const {
		value,
		placeholder,
		setPoint,
		suggestionPlaces,
		setSuggestionPlaces,
		passengerCoords,
		setActiveInput,
		setIsAutocompleteSelection,
		isActive,
		mapLoaded,
		setIsMapMove,
		registrationCountryInfo,
	} = props;

	const { setIsInputActive } = useMap();

	const inputRef = useRef(null);

	useEffect(() => {
		if (isActive && inputRef?.current) {
			inputRef.current.focus();
		}
	}, [isActive]);

	const debouncedGetSuggestions = useCallback(
		debounce(async (addressQuery) => {
			const params = {
				addressQuery,
				userLocation: {
					lat: passengerCoords[1] || null,
					lon: passengerCoords[0] || null,
				},
				autocomplete: true,
				registrationCountryInfo
			};

			const { data } = await fetchSuggestions(params);
			setSuggestionPlaces(data);
		}, 300),
		[],
	);

	const handleInputChange = useCallback(
		(e) => {
			const addressQuery = e.target.value;

			setIsMapMove(false);

			setPoint({
				name: addressQuery,
			});

			if (addressQuery === "") {
				setPoint({});
			}

			setIsInputActive(true);
			debouncedGetSuggestions(addressQuery);
		},
		[debouncedGetSuggestions, setIsInputActive, setIsMapMove, setPoint],
	);

	const handleSelectInput = (infoAddress) => {
		const { address, latitude, longitude, mapProvider, properties } = infoAddress;

		setPoint({
			lat: latitude,
			lon: longitude,
			name: address,
		});

		if (mapProvider && properties) {
			fetchLogSelectedAddress(infoAddress); // Логирируем выбранный пользователем адрес
		}

		setIsInputActive(false);
		setIsAutocompleteSelection(true);
		setSuggestionPlaces([]);
	};

	// show address list
	const renderSuggestions = () => {
		return suggestionPlaces?.map((suggestion, idx) => {
			const { address } = suggestion;

			return (
				<li
					key={idx}
					onClick={() => {
						handleSelectInput(suggestion);
					}}
				>
					<strong>{address}</strong>
				</li>
			);
		});
	};

	const handleActiveInput = () => {
		if (mapLoaded) {
			activeInputFocus();
			setActiveInput();
		}
	};

	return (
		<div className={cls.AutocompleteWrapper}>
			<input
				ref={inputRef}
				onChange={handleInputChange}
				value={value}
				placeholder={placeholder}
				disabled={!mapLoaded}
				className={cls.input}
				onClick={handleActiveInput}
			/>
		</div>
	);
});

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
});

export default connect(mapStateToProps)(AutocompletePoint);
