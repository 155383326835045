import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import AddressPoint from "../AddressPoint";
import { connect } from "react-redux";
import { showModalAlert } from "src/components/Modals/store/modal.action";
import Modal from "src/shared/ui/Modal/Modal";
import AlertModal from "src/components/Modals/ui/AlertModal/AlertModal";
import { hasDuplicateAddress } from "../../helpers/hasDuplicateAddress";
import { fetchSuggestions } from "../../helpers/fetchSuggestions";
import { useMap } from "src/entities/MapWrapper/context/MapContext";
import { HistoriesAddress } from "src/components/HistoriesAddress";
import { ReactComponent as PlaceOnMap } from "../../../../shared/assets/icons/PlaceOnMap.svg";
import styles from "./styles.module.scss";
import { fetchLogSelectedAddress } from "../../helpers/fetchLogSelectedAddress";
import { AutocompleteSuggestions } from "../AutocompleteSuggestions";
import { nameComponents } from "src/components/BottomMenu/bottom.navigate.action";
import { maxOrderPoints } from "../../constants";

const AddressPointsList = (props) => {
	const {
		setPoint,
		roadPoints,
		setRoadPoints,
		getPolyline,
		passengerCoords,
		getRoutesPrice,
		isOpenModalAlert,
		isCorrectDistance,
		isWorkCountry,
		showModalAlert,
		setIsAutocompleteSelection,
		isCurrentLocation,
		isInputHighlighted,
		setSuggestionPlaces,
		suggestionPlaces,
		checkStartRoutePoint,
		getCarArrivalTime,
		setIsMapMove,
		setCanCreateRoute,
		mapLoaded,
		getDiscount,
		registrationCountryInfo,
		lastPoints,
		openAnotherComponentBottomMenu,
		openMap,
	} = props;

	const [titleModal, setTitleModal] = useState("");
	const [subtitleModal, setSubtitleModal] = useState("");

	const { setIsLoading, setIsPriceCalculated } = useMap();

	useEffect(() => {
		if (!isCorrectDistance) {
			showModalAlert(true);
			setTitleModal(t("large_request_error"));
		}

		if (!isWorkCountry) {
			showModalAlert(true);
			setTitleModal(t("not_available"));
		}
	}, [isCorrectDistance, isWorkCountry, showModalAlert]);

	const sortPoint = useCallback((idxPoint, pointId) => {
		return idxPoint === 0 || pointId === 0 ? t("where_coming_from") : t("where_shall_we_go");
	}, []);

	const addInput = useCallback(() => {
		if (roadPoints.length < maxOrderPoints) {
			const prevPointId = roadPoints[roadPoints.length - 1].id;
			setRoadPoints((prevPoints) => [...prevPoints, { id: Number(prevPointId) + 1 }]);
		}
	}, [roadPoints, setRoadPoints]);

	const removePoint = (idPoint) => {
		setRoadPoints((prevPoints) =>
			prevPoints.map((point) => {
				if (point.id === idPoint && point?.name) {
					const { name, lat, lon, ...rest } = point;
					return rest;
				}
				return point;
			}),
		);
	};

	const removeInput = useCallback(
		(idPoint) => {
			if (roadPoints.length > 2 && idPoint !== 0) {
				const idxToRemove = roadPoints.findIndex((point) => point.id === idPoint);
				if (idxToRemove !== -1) {
					const updatedPoints = [...roadPoints];
					updatedPoints.splice(idxToRemove, 1);
					setRoadPoints(updatedPoints);
				}
			}
		},
		[roadPoints, setRoadPoints],
	);

	// On click el mark him active
	const setActiveInput = (id) => {
		const updatedPoints = roadPoints.map((point) => {
			if (point.id === id) {
				return { ...point, active: true };
			}
			return point;
		});

		setRoadPoints(updatedPoints);
	};

	// On click el mark him active
	const removeActiveInput = async (id) => {
		setRoadPoints((prevRoadPoints) => {
			const updatedPoints = prevRoadPoints.map((point) => {
				if (point.id === id) {
					return { ...point, active: false };
				}

				return point;
			});

			return updatedPoints;
		});

		// Check start point coords
		if (id === 0 && roadPoints[0].lat && roadPoints[0].lon) {
			const isCorrectPoint = await checkStartRoutePoint(
				roadPoints[0].lat,
				roadPoints[0].lon,
				false,
			);
			await getDiscount(roadPoints[0].lat, roadPoints[0].lon);

			if (!isCorrectPoint) {
				showModalAlert(true);
				setTitleModal(t("not_available"));
				removePoint(id);
			}
		}
	};

	// To select an address from previous trips ("lastPoints" from order history)
	const addHistory = (place) => {
		const index = roadPoints.findIndex((el) => el.lat === undefined || el.lon === undefined);
		if (index === -1) return;
		roadPoints[index] = { ...place, id: index };
		setRoadPoints([...roadPoints]);
	};

	const addPlace = () => {
		openMap();
		openAnotherComponentBottomMenu(nameComponents.PLACE_ON_MAP);
	};

	const setManualPoint = useCallback(
		async (addressQuery, pointId) => {
			try {
				setSuggestionPlaces([]);

				const params = {
					addressQuery,
					userLocation: {
						lat: passengerCoords[1] || null,
						lon: passengerCoords[0] || null,
					},
					autocomplete: false,
					registrationCountryInfo,
				};

				const { data } = await fetchSuggestions(params);

				if (!data?.length) {
					showModalAlert(true);
					setTitleModal(t("no_results_for_specified_address"));
				} else {
					const { latitude, longitude, address } = data[0];
					setPoint({ lat: latitude, lon: longitude, name: address }, pointId);
				}

				return Boolean(data?.length);
			} catch (error) {
				console.error("Error in setManualPoint:", error);
			}
		},
		[passengerCoords, registrationCountryInfo, setPoint, setSuggestionPlaces, showModalAlert],
	);

	const checkAndCalculateRoutePrice = useCallback(async () => {
		try {
			setIsLoading(true);
			const adressPoints = roadPoints.filter((p) => {
				p.active = false;
				if (p.lat && p.lon) return true;
			});
			setRoadPoints(adressPoints);

			if (hasDuplicateAddress(adressPoints)) {
				showModalAlert(true);
				setTitleModal(t("identity_adresses"));
				setSubtitleModal(t("identity_adresses_text"));
				return;
			}

			await Promise.all([getCarArrivalTime(), getRoutesPrice()]);
			setIsPriceCalculated(true);
			getPolyline();
		} catch (error) {
			console.error("Error in checkAndCalculateRoutePrice:", error);
		} finally {
			setIsLoading(false);
		}
	}, [
		getCarArrivalTime,
		getRoutesPrice,
		roadPoints,
		setIsLoading,
		setIsPriceCalculated,
		showModalAlert,
	]);

	// On click other place set input not active
	const renderAddressPoint = (point, idx) => {
		const placeholder = sortPoint(idx, point.id);
		return (
			<AddressPoint
				key={idx}
				registrationCountryInfo={registrationCountryInfo}
				placeholder={placeholder}
				setPoint={(pointData) => setPoint(pointData, point.id)}
				addInput={() => addInput()}
				removeInput={() => removeInput(point.id)}
				removePoint={() => removePoint(point.id)}
				roadPoints={roadPoints}
				pointId={point.id}
				isActive={point.active}
				name={point?.name || ""}
				getPolyline={getPolyline}
				passengerCoords={passengerCoords}
				getRoutesPrice={getRoutesPrice}
				setActiveInput={() => setActiveInput(point.id)}
				removeActiveInput={() => removeActiveInput(point.id)}
				checkAndCalculateRoutePrice={checkAndCalculateRoutePrice}
				setIsAutocompleteSelection={setIsAutocompleteSelection}
				isCurrentLocation={isCurrentLocation}
				isInputHighlighted={isInputHighlighted}
				setSuggestionPlaces={setSuggestionPlaces}
				suggestionPlaces={suggestionPlaces}
				getCarArrivalTime={getCarArrivalTime}
				setIsMapMove={setIsMapMove}
				setCanCreateRoute={setCanCreateRoute}
				mapLoaded={mapLoaded}
				setManualPoint={() => setManualPoint(point.name, point.id)}
				lastPoints={lastPoints}
				addHistory={addHistory}
			/>
		);
	};

	const currentEditPoint = roadPoints.find((item) => item.active);

	const RenderSuggestions = currentEditPoint && (
		<AutocompleteSuggestions
			registrationCountryInfo={registrationCountryInfo}
			setPoint={(pointData) => setPoint(pointData, currentEditPoint.id)}
			addInput={() => addInput()}
			removeInput={() => removeInput(currentEditPoint.id)}
			removePoint={() => removePoint(currentEditPoint.id)}
			roadPoints={roadPoints}
			pointId={currentEditPoint.id}
			isActive={currentEditPoint.active}
			name={currentEditPoint?.name || ""}
			getPolyline={getPolyline}
			setActiveInput={() => setActiveInput(currentEditPoint.id)}
			removeActiveInput={() => removeActiveInput(currentEditPoint.id)}
			checkAndCalculateRoutePrice={checkAndCalculateRoutePrice}
			setIsAutocompleteSelection={setIsAutocompleteSelection}
			isCurrentLocation={isCurrentLocation}
			isInputHighlighted={isInputHighlighted}
			setSuggestionPlaces={setSuggestionPlaces}
			suggestionPlaces={suggestionPlaces}
			getCarArrivalTime={getCarArrivalTime}
			setIsMapMove={setIsMapMove}
			setCanCreateRoute={setCanCreateRoute}
			mapLoaded={mapLoaded}
			setManualPoint={() => setManualPoint(currentEditPoint.name, currentEditPoint.id)}
		/>
	);

	return (
		<>
			<Modal isOpen={isOpenModalAlert} isCloseAction>
				<AlertModal title={titleModal} subtitle={subtitleModal} />
			</Modal>
			{roadPoints.map((point, idx) => renderAddressPoint(point, idx))}
			<div
				className={`${styles.scroll_suggestions} ${
					roadPoints.length > 2 ? styles.scroll_suggestions_big : ""
				}`}
			>
				{currentEditPoint ? (
					RenderSuggestions
				) : (
					<HistoriesAddress
						className={styles.greyIcons}
						click={addHistory}
						lastPoints={lastPoints}
						inputId={null}
					/>
				)}
				{currentEditPoint && (
					<div className={styles.placeOnMap} onClick={addPlace}>
						<PlaceOnMap />
						<div className={styles.text_block}>
							<p className={styles.main_text}>{t("place_on_map")}</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		isOpenModalAlert: state.modal.isOpenModalAlert,
		registrationCountryInfo: state.registration.registrationCountryInfo,
		lastPoints: state.history.lastPoints,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalAlert: (status) => dispatch(showModalAlert(status)),
	};
};

const ConnectedAddressPointsList = connect(mapStateToProps, mapDispatchToProps)(AddressPointsList);
export default memo(ConnectedAddressPointsList);
