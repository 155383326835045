/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import {
  CHANGE_MODAL_BOTTOM_NAVIGATE,
  OPEN_ANOTHER_COMPONENT_BOTTOM_NAVIGATE,
  SELECTED_MARKET_BOTTOM_NAVIGATE,
  START_CHANGE_COMPONENT_BOTTOM_NAVIGATE,
  nameComponents,
} from './bottom.navigate.action'

const initialState = {
  nameComponent: nameComponents.START_SEARCH_PLACE,
  nameModal: null,
  selectedMarketForMap: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MODAL_BOTTOM_NAVIGATE:
      return {
        ...state,
        nameModal: action.payload,
      }
    // case SELECTED_MARKET_BOTTOM_NAVIGATE : return {
    //   ...state,
    //   selectedMarketForMap : action.payload
    // }
    case START_CHANGE_COMPONENT_BOTTOM_NAVIGATE:
      return {
        ...state,
        nameComponent: action.payload,
      }
    case OPEN_ANOTHER_COMPONENT_BOTTOM_NAVIGATE:
      return {
        ...state,
        nameComponent: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export default reducer
