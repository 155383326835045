import {
  GET_CITIES_TARIFF_SUCCESS,
  GET_PREFERENCES_PENDING,
  GET_PREFERENCES_SUCCESS,
  GET_PREFERENCES_ERROR,
  GET_CLASSES_PENDING,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_ERROR,
  GET_CITIES_PENDING,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,
  GET_CURRENT_CITY_COORDINATE,
} from './dictionary.action'

const initialState = {
  currentCoordinates: [],
  currentGoogleMapCoordinates : null,
  preferences: [],
  preferencesLoaded: false,
  preferencesError: false,
  classes: [],
  classesLoaded: false,
  classesError: false,
  cities: [],
  citiesLoaded: false,
  citiesError: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREFERENCES_PENDING: {
      return {
        ...state,
        preferencesLoaded: true,
      }
    }
   
    case GET_CURRENT_CITY_COORDINATE: {
      return {
        ...state,
        currentCoordinates: action.payload,
      }
    }
    case GET_PREFERENCES_SUCCESS: {
      return {
        ...state,
        preferencesLoaded: false,
        preferences: action.payload,
      }
    }
    case GET_PREFERENCES_ERROR: {
      return {
        ...state,
        preferencesError: action.payload,
      }
    }
    case GET_CLASSES_PENDING: {
      return {
        ...state,
        classesLoaded: true,
      }
    }
    case GET_CLASSES_SUCCESS: {
      return {
        ...state,
        classesLoaded: false,
        classes: action.payload,
      }
    }
    case GET_CITIES_TARIFF_SUCCESS: {
      return {
        ...state,
        classesLoaded: false,
        classes: action.payload,
      }
    }
    case GET_CLASSES_ERROR: {
      return {
        ...state,
        classesError: action.payload,
      }
    }
    case GET_CITIES_PENDING: {
      return {
        ...state,
        citiesLoaded: true,
      }
    }
    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        citiesLoaded: false,
        cities: action.payload,
      }
    }
    case GET_CITIES_ERROR: {
      return {
        ...state,
        citiesError: action.payload,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
