import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { sortItemsByDate } from '../../shared/helpers/sortItemsByDate'
import LogoCarmaTaxi from '../../images/LogoCarmaTaxi.png'
import Loader from '../../shared/ui/Loader/Loader'
import cls from './chat.module.scss'
import SendIcon from '@material-ui/icons/Send'
import InputBase from '@mui/material/InputBase'
import Snackbar from '../../common/Snackbar'
import ChatMessage from './ChatMessage'

function formatTime(createdAt) {
  const formattedTime = moment(createdAt).format('HH:mm')
  return formattedTime
}

const ChatHistory = ({ userChats, loading, getUserChats, putChatSeen, sendMessage }) => {
  const [sortedChats, setSortedChats] = useState([])
  const [snackBar, setSnackBar] = useState(false)
  const [textMessage, setTextMessage] = useState('')
  const [contentLoaded, setContentLoaded] = useState(false)

  const scrollToRef = useRef(null)
  useEffect(() => {
    getUserChats()
  }, [getUserChats])

  useEffect(() => {
    if (userChats?.length) {
      const sortedTripsToDate = sortItemsByDate([...userChats], 'asc')
      setSortedChats(sortedTripsToDate)
      setContentLoaded(true)
    }
  }, [userChats, userChats?.length])

  useEffect(() => {
    if (scrollToRef.current && contentLoaded) {
      scrollToRef.current?.scrollIntoView({ behavior: 'auto' })
    }
  }, [contentLoaded])

  const handleChange = useCallback(({ target }) => {
    setTextMessage(target.value)
  }, [])

  const handleSendMessage = useCallback(() => {
    if (textMessage) {
      sendMessage('Обращение клиента', textMessage)
      setTextMessage('')
      setSnackBar(true)
    }
  }, [sendMessage, textMessage])

  const content = Object.keys(sortedChats).map((chatDate) => (
    <div className={cls.helpDateBlock} key={chatDate}>
      <h3>{chatDate}</h3>
      {sortedChats[chatDate].map((chat) => {
        return (
          <ChatMessage
            key={chat.id}
            id={chat.id}
            text={chat.content}
            isSupport={chat.isIncoming}
            postTime={formatTime(chat.createdAt)}
            avatar={LogoCarmaTaxi}
            putChatSeen={putChatSeen}
            isNew={chat.isNew}
          />
        )
      })}
    </div>
  ))

  const snackBarElement = (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={snackBar}
      autoHideDuration={3000}
      onClose={() => setSnackBar(false)}
      message={t('appeal_has_been_sent')}
    />
  )

  //!TODO
  if (loading) {
    return <Loader />
  }

  return (
    <div className={cls.ChatWrapper}>
      <div className={cls.contentBlock}>{content}</div>
      <div className={cls.sendMessageBlock}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            margin: '0 20px',
          }}
        >
          <InputBase
            className={cls.messageField}
            sx={{
              paddingRight: '50px',
              '& .MuiInputBase-input': { padding: '0 15px' },
            }}
            maxRows={4}
            value={textMessage}
            onChange={handleChange}
            fullWidth
            multiline
            placeholder={t('message_text')}
            inputProps={{ 'aria-label': 'message_text' }}
          />
          <SendIcon
            onClick={handleSendMessage}
            style={{ position: 'absolute', right: '10px', height: '19px' }}
          />
        </div>
      </div>
      {content.length === 0 ? <h1 className={cls.noHits}>{t('no_hits')}</h1> : null}
      <div id="bottom" ref={scrollToRef} />
      {snackBarElement}
    </div>
  )
}


export default memo(ChatHistory)
