import React, { useCallback } from 'react'
import { Portal } from '../Portal/Portal'
import { Button, ButtonSize, ButtonVariant } from '../Button/button'
import { classNames } from '../../lib/classNames/classNames'
import cls from './Modal.module.scss'
import { t } from 'i18next'
import { connect } from 'react-redux'
import { showModalAction, showModalAlert } from '../../../components/Modals/store/modal.action'

const Modal = ({
  className,
  children,
  isOpen,
  onClose,
  isCloseAction,
  isControlAction,
  onConfirm,
  showModalAlert,
  showModalAction,
	overlayClassname,
	contentStyles,
  ...otherProps
}) => {
  const closeModal = useCallback(() => {
    showModalAlert(false)
    showModalAction(false)
  }, [showModalAction, showModalAlert])

  const closeHandler = useCallback(() => {
    closeModal()
    if (onClose) {
      onClose()
    }
  }, [closeModal, onClose])

  const confirmHandler = useCallback(() => {
    if (onConfirm) {
      onConfirm()
      closeModal()
    }
  }, [closeModal, onConfirm])

  const onContentClick = useCallback((e) => {
    e.stopPropagation()
  }, [])

  const mods = {
    [cls.opened]: isOpen,
  }

  return (
    <Portal>
      <div
        className={classNames(cls.Modal, mods, [className])}
        onClick={closeHandler}
        {...otherProps}
      >
        <div className={classNames(cls.overlay, {}, [overlayClassname])} onClick={onContentClick}>
          <div className={classNames(cls.contentBlock, {}, [contentStyles])} >
            {children}
            {isCloseAction && (
              <Button
                className={cls.closeBtn}
                fullWidth
                variant={ButtonVariant.clear}
                size={ButtonSize.M}
                onClick={closeHandler}
              >
                {t('close')}
              </Button>
            )}
            {isControlAction && (
              <div className={cls.controlBtn}>
                <Button
                  className={cls.closeBtn}
                  fullWidth
                  variant={ButtonVariant.clear}
                  size={ButtonSize.M}
                  onClick={confirmHandler}
                >
                  {t('yes')}
                </Button>
                <Button
                  className={cls.closeBtn}
                  fullWidth
                  variant={ButtonVariant.clear}
                  size={ButtonSize.M}
                  onClick={closeHandler}
                >
                  {t('no')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Portal>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    showModalAction: (status) => dispatch(showModalAction(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
