import { axiosInstance } from "src/axios.config";
import { apiRPCSecond } from "src/config";

export const getPointsCountryData = async ({ coordsData, isAuthentication = false }) => {
	const response = await axiosInstance.post(`${apiRPCSecond}/get-points-country-data`, {
		point: coordsData,
		is_authentication: isAuthentication,
	});
	return response;
};
