import { axiosInstance } from "src/axios.config";
import { apiRPCSecond } from "../../../config";

export const fetchDiscount = async (point) => {
	try {
		const accessToken = localStorage.getItem("accessToken");
		const { data } = await axiosInstance.post(
			apiRPCSecond + "/get-point-discounts",
			{
				point,
			},
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			},
		);
		return data;
	} catch (error) {
		console.log("Error fetching discount:", error);
		return { success: false, data: null };
	}
};
