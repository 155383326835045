import React, { memo, useEffect, useState, useCallback } from 'react'
import { t } from 'i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserData, postImage, postUserData } from './profile.action'
import { showModalAlert } from '../Modals/store/modal.action'
import useThemeManager from '../../shared/hooks/useThemeManager'
import ProfileHeader from './ProfileHeader'
import Loader from '../../shared/ui/Loader/Loader'
import { List } from '../../common/List'
import Modal from '../../shared/ui/Modal/Modal'
import AlertModal from '../Modals/ui/AlertModal/AlertModal'
import MenuItemLink from '../Menu/MenuItemLink'
import FormBlockInfo from './FormBlockInfo'
import Select from '../../shared/ui/Select/Select'
import MenuItem from '../../shared/ui/MenuItem/MenuItem'
import {
	CheckCircleIcon,
	AstericsIcon,
	ExitIcon,
	CrownIcon,
	PasswordIcon,
	GearIcon,
} from '../../shared/assets/icons'
import { themes } from '../../mock/themes'
import cls from './profile.module.scss'
import { getUserCoords } from '../Registration/registration.action'

const Profile = memo((props) => {
	const {
		getUserData,
		userData,
		userDataLoaded,
		isOpenModalAlert,
		showModalAlert,
		registrationCountryInfo,
		rpcLoaded,
		getUserCoords,
	} = props

	const { theme, changeTheme, getCurrentTheme } = useThemeManager()

	const navigate = useNavigate()

	//TODO  select!
	const [isOpen, setIsOpen] = useState(false)
	const onClose = useCallback(() => {
		setIsOpen(false)
	}, [])

	useEffect(() => {
		const countryCode = localStorage.getItem('country_code')

		const getInfoClient = async () => {
			if (!userData.id) {
				await getUserData(countryCode)
			}
		}

		getInfoClient()
	}, [getUserData, userData.id])

	useEffect(() => {
		const getInfoCoords = async () => {
			await getUserCoords(true, false) //!TODO
			getCurrentTheme()
		}

		getInfoCoords()
	}, [getCurrentTheme, getUserCoords])

	const handleThemeChange = useCallback(
		(newTheme) => {
			changeTheme(newTheme)
		},
		[changeTheme],
	)

	const handleExit = useCallback(() => {
		showModalAlert(true)
	}, [showModalAlert])

	const confirmExit = useCallback(() => {
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
		localStorage.removeItem('userId')
		localStorage.removeItem('userPhone')
		navigate(`/?t=${Date.now()}`)
	}, [navigate])

	if (userDataLoaded || rpcLoaded) {
		return <Loader />
	}

	if (isOpen) {
		return (
			<Select
				title={t('interface_design')}
				onClose={onClose}
				isOpen={isOpen}
				options={themes}
				selectedOption={theme}
				handleOptionClick={handleThemeChange}
			/>
		)
	}

	return (
		<div className={cls.ProfileWrap}>
			<Modal isOpen={isOpenModalAlert} onConfirm={confirmExit} isControlAction>
				<AlertModal title={t('want_to_get_out')} />
			</Modal>
			<ProfileHeader userData={userData} />
			<div className={cls.blockInfo}>
				<FormBlockInfo  className={cls.checkIcon} icon={CheckCircleIcon} title={t('trips')} value={userData.numberOfOrders} />
				<FormBlockInfo  className={cls.estimateIcon} icon={AstericsIcon} title={t('estimate')} value={userData.rating} />
				<FormBlockInfo
				 className={cls.bonusIcon}
					icon={CrownIcon}
					title={t('bonusy')}
					value={
						userData.country === registrationCountryInfo?.country_code || !userData.country
							? userData.bonuses
							: 0
					} //!TODO HOTFIX
				/>
			</div>
			<div className={cls.mainMenu}>
				<List>
					<MenuItemLink
						className={cls.menuItemLink}
						icon={PasswordIcon}
						text={t('change_password')}
						path="/edit-password"
					/>
					<MenuItemLink
						className={cls.menuItemLink}
						icon={GearIcon}
						text={t('settings')}
						path="/settings"
					/>
					<MenuItem
						className={cls.menuItemBtn}
						showRightIcon={false}
						icon={<ExitIcon className={cls.icon} />}
						title={t('exit')}
						onClick={handleExit}
					/>
				</List>
			</div>
			{/* //!TODO */}
			{/* <div className={cls.secondaryMenu}>
        <h1>{t('other')}</h1>
        <MenuItem
          className={cls.menuItemBtn}
          icon={<ExitIcon className={cls.icon} />}
          title={t('exit')}
          onClick={handleExit}
        />
      </div> */}
		</div>
	)
})

const mapStateToProps = (state) => {
	return {
		userData: state.profile.userData,
		userImgId: state.profile.userImgId,
		userDataLoaded: state.profile.userDataLoaded,
		isOpenModalAlert: state.modal.isOpenModalAlert,
		registrationCountryInfo: state.registration.registrationCountryInfo,
		rpcLoaded: state.registration.rpcLoaded,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUserData: (data) => dispatch(getUserData(data)),
		postImage: (data, profileDta) => dispatch(postImage(data, profileDta)),
		postUserData: (data) => dispatch(postUserData(data)),
		showModalAlert: (status) => dispatch(showModalAlert(status)),
		getUserCoords: (requireCoordinates, isAuthentication) =>
			dispatch(getUserCoords(requireCoordinates, isAuthentication)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
