/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cls from "src/components/MapJs/orders.module.scss";
import { t } from "i18next";
import RatingOrder from "../../../RatingOrder/RatingOrder";
import { nameComponents, openAnotherComponentBottomMenu } from "../../bottom.navigate.action";
import { putRating } from "../../../MapJs/order.action";
import { clearLocalStoreOrder } from "../../helpers/clearLocalStoreOrder";

export const OrderReview = ({ openAnotherComponentBottomMenu, putRating }) => {
	const [rating, setRating] = useState(null);
	const [text, setText] = useState("");

	const isButtonDisabled = rating === null;

	useEffect(() => {
		localStorage.removeItem("startWaitingTime");
		localStorage.removeItem("isCountdown");
	}, []);

	const sendReview = async () => {
		const orderId = localStorage.getItem("orderId");

		if (!orderId) {
			return noSendReview();
		}

		await putRating({
			orderId: orderId,
			rating: rating === null ? 0 : rating,
			comment: text,
		});
		setText("");
		setRating(null);
		clearLocalStoreOrder();
		window.location.reload();
	};

	const noSendReview = async () => {
		clearLocalStoreOrder();
		window.location.reload();
	};

	return (
		<div className={cls.review_block}>
			<div className={cls.review_item}>
				<p className={cls.leave_review}>{t("leave_review")}</p>
				<div className={cls.stars_review}>
					<p className={cls.evaluate_trip}>{t("evaluate_trip")}</p>
					<RatingOrder rating={rating} setRating={setRating} />
					<p className={cls.anonim_review}>{t("anonim_review")}</p>
					<textarea
						placeholder={t("write_about_trip")}
						value={text}
						onChange={(e) => setText(e.target.value)}
						maxLength={195}
					/>
				</div>
				<button
					className={`${cls.confirm_review} ${isButtonDisabled ? cls.disabledButton : ""}`}
					onClick={sendReview}
					disabled={isButtonDisabled}
				>
					{t("send")}
				</button>
				<button className={cls.no_confirm_review} onClick={noSendReview}>
					{t("no_review")}
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};
export default connect(mapStateToProps, { openAnotherComponentBottomMenu, putRating })(OrderReview);
