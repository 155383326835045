import React from 'react'

export const NewsIcon = (props) => {
  const { width = 24, height = 24, fill = 'none', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#343330"
        d="M22.5 11.25a4.504 4.504 0 0 0-4.5-4.5h-3.731c-.273-.016-5.027-.35-9.554-4.148A1.5 1.5 0 0 0 2.25 3.75v15a1.5 1.5 0 0 0 2.465 1.148c3.54-2.97 7.218-3.821 8.785-4.06v2.975a1.501 1.501 0 0 0 .668 1.25l1.03.687a1.5 1.5 0 0 0 2.286-.875l1.104-4.159A4.507 4.507 0 0 0 22.5 11.25ZM3.75 18.743V3.75c4.013 3.366 8.122 4.219 9.75 4.429v6.138c-1.627.214-5.734 1.064-9.75 4.426Zm12.281.75v.01L15 18.817V15.75h2.025l-.994 3.743ZM18 14.25h-3v-6h3a3 3 0 0 1 0 6Z"
      />
    </svg>
  )
}
