export const CrownIcon = (props) => {
  const { width = 24, height = 24, fill = '#212121', stroke = '#212121', className } = props

  return (
    <svg width={width} height={height} fill={fill} {...props}>
      <path
        className={className}
        fill="#1056BB"
        d="M22.86 7.643a1.133 1.133 0 0 0-1.25-.16l-4.708 2.344-3.93-6.528a1.135 1.135 0 0 0-1.944 0L7.098 9.83 2.393 7.486a1.135 1.135 0 0 0-1.585 1.37l3.47 10.627a.75.75 0 0 0 1.094.412C5.396 19.881 7.792 18.5 12 18.5s6.605 1.381 6.627 1.394a.75.75 0 0 0 1.097-.411l3.47-10.625a1.13 1.13 0 0 0-.334-1.215Z"
      />
      <path
        className={className}
        fill="#fff"
        d="M16.488 15.203a.75.75 0 0 1-.87.608 21.391 21.391 0 0 0-7.239 0 .75.75 0 0 1-.26-1.477 22.912 22.912 0 0 1 7.76 0 .75.75 0 0 1 .61.866l-.001.003Z"
      />
    </svg>
  )
}
