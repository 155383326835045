import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import DialogError from '../../common/DialogError'
import { fetchIsServerErrorStatus } from './services/fetchIsServerErrorStatus';
import { ErrorTypes } from 'src/shared/variables/errors';
import { t } from 'i18next';

const ErrorComponent = ({ error, errorData, isServerError }) => {
  const [show, setShow] = useState(true);
	const [typeOfServerError, setTypeOfServerError] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')

  const toggleFunction = () => {
    setShow((prevShow) => !prevShow);
  };

  const handleAccept = () => {
    toggleFunction();
  };

  useEffect(() => {
    const checkServerError = async () => {
      if (isServerError) {
        try {
          const result = await fetchIsServerErrorStatus();

					if(result === ErrorTypes.MAINTENANCE_ERROR) {
						setErrorMessage(t('server_maintenance_message'))
						setTypeOfServerError(ErrorTypes.MAINTENANCE_ERROR)
					} else {
						setErrorMessage(t('generic_maintenance_message'))
						setTypeOfServerError(ErrorTypes.UNEXPECTED_ERROR)
					}
        } catch (error) {
					setErrorMessage("server_maintenance_message")
					setTypeOfServerError(ErrorTypes.MAINTENANCE_ERROR)
        }
      }
    };

    checkServerError();
  }, [isServerError]);


  return error ? (
    <DialogError
      open={show}
      close={handleAccept}
			typeServerError={typeOfServerError}
      content={errorMessage || errorData}
      onClick={handleAccept}
    />
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    error: state.error.error,
    errorData: state.error.errorData,
    isServerError: state.error.isServerError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorComponent)
