import { axiosInstance } from 'src/axios.config'
import { api } from '../../config'
import { getCities, getPreferences } from '../Dictionarys/dictionary.action'
import { getUserData } from '../ProfileForm/profile.action'

export const PUT_CODE_PENDING = 'PUT_CODE_PENDING'
export const PUT_CODE_SUCCESS = 'PUT_CODE_SUCCESS'
export const PUT_CODE_ERROR = 'PUT_CODE_ERROR'
export const RESET_CODE = 'RESET_CODE'

export const resetCode = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CODE,
    })
  }
}

export const sendCode = (loginInfo) => {
  const countryCode = localStorage.getItem('country_сode')

  return (dispatch) => {
    dispatch({
      type: PUT_CODE_PENDING,
    })
    axiosInstance
      .post(api + '/rider/confirm', {
        phone: loginInfo.phone,
        code: loginInfo.code,
				branchId: 2
      })
      .then((response) => {
        console.log('Action* - отправка кода УСПЕХ: ', response)
        if (response.status === 200 || response.status === 204) {
          setTimeout(() => {
            localStorage.setItem('accessToken', response.data.accessToken)
            localStorage.setItem('refreshToken', response.data.refreshToken)
            localStorage.setItem('userId', response.data.id)
            localStorage.setItem('userPhone', loginInfo.phone)
            localStorage.removeItem('city')

            dispatch(getPreferences(getPreferences(countryCode)), 50000)
            dispatch(getCities())
            setTimeout(() => dispatch(getUserData(countryCode)), 50000)

            dispatch({
              type: PUT_CODE_SUCCESS,
              payload: true,
            })
          }, 5000)
        } else {
          dispatch({
            type: PUT_CODE_ERROR,
            payload: false,
          })
        }
      })
      .catch((error) => {
        console.log('Action* - отправка кода ОШИБКА: ', error)
        // dispatch(errorFunction(error))
        dispatch({
          type: PUT_CODE_ERROR,
          payload: false,
        })
      })
  }
}
