import { axiosInstance } from 'src/axios.config'
import { apiData } from '../../config'
import { apiOrder } from '../../config'
import { errorFunction } from '../ErrorComponent/error.action'
import { errorTokenFunction } from '../ErrorToken/error.action'

export const GET_PREFERENCES_PENDING = 'GET_PREFERENCES_PENDING'
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS'
export const GET_PREFERENCES_ERROR = 'GET_PREFERENCES_ERROR'

export const getPreferences = (country) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_PREFERENCES_PENDING,
    })
    axiosInstance
      .get(apiOrder + '/catalog/preferences', {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { country },
      })
      .then((response) => {
        dispatch({
          type: GET_PREFERENCES_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_PREFERENCES_ERROR,
          payload: error,
        })
      })
  }
}

export const GET_CLASSES_PENDING = 'GET_CLASSES_PENDING'
export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS'
export const GET_CLASSES_ERROR = 'GET_CLASSES_ERROR'

export const getClassesCar = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_CLASSES_PENDING,
    })
    axiosInstance
      .get(apiData + '/catalog/classes/', { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        dispatch({
          type: GET_CLASSES_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_CLASSES_ERROR,
          payload: error,
        })
      })
  }
}

export const GET_CITIES_PENDING = 'GET_CITIES_PENDING'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR'
export const GET_CURRENT_CITY_COORDINATE = 'GET_CURRENT_CITY_COORDINATE'

export const getCities = () => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_CITIES_PENDING,
    })
    axiosInstance
      .get(apiOrder + '/catalog/cities', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.data.length !== 0) {
          if (localStorage.getItem('city')) {
            dispatch({
              type: GET_CURRENT_CITY_COORDINATE,
              payload: {
                lat: response.data.data[localStorage.getItem('city')].latitude,
                lon: response.data.data[localStorage.getItem('city')].longitude,
              },
            })
            // dispatch(getCitiesTariff(response.data.data[localStorage.getItem('city')].id))
          } else {
            dispatch({
              type: GET_CURRENT_CITY_COORDINATE,
              payload: {
                lat: response.data.data[0].latitude,
                lon: response.data.data[0].longitude,
              },
            })
            // dispatch(getCitiesTariff(response.data.data[0].id))
          }
        }
        dispatch({
          type: GET_CITIES_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        if (error.response?.statusText && error.response?.statusText === 'Unauthorized') {
          dispatch(errorTokenFunction(error))
        } else {
          dispatch(errorFunction(error.response))
        }
        dispatch({
          type: GET_CITIES_ERROR,
          payload: error,
        })
      })
  }
}

export const GET_CITIES_TARIFF_PENDING = 'GET_CITIES_TARIFF_PENDING'
export const GET_CITIES_TARIFF_SUCCESS = 'GET_CITIES_TARIFF_SUCCESS'
export const GET_CITIES_TARIFF_ERROR = 'GET_CITIES_TARIFF_ERROR'

export const getCitiesTariff = (cityId) => {
  const accessToken = localStorage.getItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: GET_CITIES_TARIFF_PENDING,
    })
    axiosInstance
      .get(apiOrder + `/catalog/cities/${cityId}/tariffs`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        dispatch({
          type: GET_CITIES_TARIFF_SUCCESS,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        dispatch(errorFunction(error.response))
        dispatch({
          type: GET_CITIES_TARIFF_ERROR,
          payload: error,
        })
      })
  }
}
