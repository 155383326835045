import { useEffect } from "react";
import createMarker from "../helpers/createMarker";

const useDrawClientMarker = (props) => {
	const { map, mapLoaded, clientMarkerRef, passengerCoords, disableClientMarker } = props;

	useEffect(() => {
		if (mapLoaded) {
			if (disableClientMarker) {
				if (clientMarkerRef.current) {
					clientMarkerRef.current.remove();
					clientMarkerRef.current = null;
				}

				return;
			}

			const markerElement = document.createElement("div");
			markerElement.className = "client-marker";

			let markerCoords = passengerCoords;

			if (!clientMarkerRef.current) {
				createMarker({
					map,
					markerElement,
					position: markerCoords,
					markerRef: clientMarkerRef,
				});
			} else {
				clientMarkerRef.current.setLngLat(markerCoords);
			}
		}
	}, [clientMarkerRef, disableClientMarker, map, mapLoaded, passengerCoords]);
};

export default useDrawClientMarker;
