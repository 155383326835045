import React, { memo, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Registration from '../../components/Registration'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'

const RegistrationPage = memo(() => {
  const [title, setTitle] = useState(t('register'))

  const accesToken = localStorage.getItem('accessToken')

  if (accesToken) {
    return <Navigate to="/order" />
  }

  const changeLanguageTitle = (lang) => {
    setTitle(lang)
  }

  return (
    <>
      <PageHeader title={title} />
      <PageLayout>
        <Registration changeLanguageTitle={changeLanguageTitle} />
      </PageLayout>
    </>
  )
})

export default RegistrationPage
