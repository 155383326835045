import { getNonEmptyPoints } from './getNonEmptyPoints'

export function hasDuplicateAddress(roadPoints = []) {
  const correctRoadPoints = getNonEmptyPoints(roadPoints)

  for (let i = 1; i < correctRoadPoints.length; i++) {
    if (correctRoadPoints[i]?.name === correctRoadPoints[i - 1]?.name) {
      return true
    }
  }

  return false
}
