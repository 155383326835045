import { axiosInstance } from "src/axios.config";
import { apiOrder } from "../../config";
import { errorFunction } from "../ErrorComponent/error.action";
import {
	changeModalBottomMenu,
	changeOrderStage,
	nameComponents,
	nameModals,
	openAnotherComponentBottomMenu,
} from "../BottomMenu/bottom.navigate.action";
import { clearOrderRoute } from "../OrderRoute/order.route.action";
import { clearLocalStoreOrder } from "../BottomMenu/helpers/clearLocalStoreOrder";

export const SET_DRIVER_LOCATION = "SET_DRIVER_LOCATION";
export const setDriverLocation = (data) => {
	return {
		type: SET_DRIVER_LOCATION,
		payload: { ...data },
	};
};

export const SET_ACCEPTED_DRIVER_COORDINATES = "SET_ACCEPTED_DRIVER_COORDINATES";
export const setAcceptedDriverCoordinates = (driverCoordinates) => {
	return {
		type: "SET_ACCEPTED_DRIVER_COORDINATES",
		payload: driverCoordinates,
	};
};

export const ORDER_STATUS = "ORDER_STATUS";
export const setOrderStatus = (data) => {
	localStorage.removeItem("activeOrder");
	return (dispatch) => {
		dispatch({
			type: ORDER_STATUS,
			payload: data,
		});
	};
};

export const ORDER_INFO = "ORDER_INFO";
export const orderInfoDriver = (data) => {
	localStorage.removeItem("activeOrder");
	return (dispatch) => {
		dispatch({
			type: ORDER_INFO,
			payload: data,
		});
	};
};

export const GET_CURRENT_ORDER_PENDING = "GET_CURRENT_ORDER_PENDING";
export const GET_CURRENT_ORDER_SUCCESS = "GET_CURRENT_ORDER_SUCCESS";
export const GET_CURRENT_ORDER_ERROR = "GET_CURRENT_ORDER_ERROR";

export const updateOrderStatus = (code) => {
	return (dispatch) => {
		const orderId = localStorage.getItem("orderId");
		const orderStage = Number(localStorage.getItem("orderStage"));
		const currentOrderStatus = code.data.status;
		const currentOrderId = code.data.orderId;

		if (orderId === currentOrderId) {
			if (currentOrderStatus === 32 || currentOrderStatus === 2) {
				dispatch(clearOrderRoute());
			}

			if (currentOrderStatus === 2) {
				return dispatch(changeModalBottomMenu(nameModals.REJECT_ORDER_INFO));
			}

			if (currentOrderStatus === 32) {
				return dispatch(changeModalBottomMenu(nameModals.CAR_NOT_FOUND));
			}

			// Если новый статус заказа больше предыдущего
			if (currentOrderStatus > orderStage) {
				dispatch(setOrderStatus(code.data));
				localStorage.setItem("orderStage", currentOrderStatus);

				if (currentOrderStatus >= 2) {
					localStorage.removeItem("destinationTime");
				}

				switch (currentOrderStatus) {
					case 4:
						dispatch(openAnotherComponentBottomMenu(nameComponents.ORDER_EXPECT));
						break;
					case 8:
						dispatch(openAnotherComponentBottomMenu(nameComponents.ORDER_PROGRESS));
						break;
					case 16:
						dispatch(openAnotherComponentBottomMenu(nameComponents.ORDER_COMPLETED));
						break;
					case 128:
						dispatch(openAnotherComponentBottomMenu(nameComponents.ORDER_REVIEW));
						break;
					default:
				}
			}
		}
	};
};

export const getCurrentOrder = (orderId) => {
	const accessToken = localStorage.getItem("accessToken");
	return async (dispatch, getState) => {
		const state = getState();

		dispatch({
			type: GET_CURRENT_ORDER_PENDING,
		});

		await axiosInstance
			.get(apiOrder + `/rider/${orderId}`, { headers: { Authorization: `Bearer ${accessToken}` } })
			.then((response) => {
				const orderRoute = JSON.parse(localStorage.getItem("odrerRoute"));
				const shouldClearOrderRoute = response.data.data.statusId === 2 && response.data.data.statusId === 32
				const shouldSetOrderRoute = !orderRoute && !shouldClearOrderRoute;

				if (shouldSetOrderRoute) {
					localStorage.setItem(
						"odrerRoute",
						JSON.stringify({ ...state.odrerRoute, routes: response.data.data.destinationPoints }),
					);
				}

				if (shouldClearOrderRoute) {
					localStorage.removeItem("odrerRoute");
				}

				const currentOrderStage = Number(localStorage.getItem("orderStage"));

				if (response.data.data.statusId >= 2) {
					localStorage.removeItem("destinationTime");
				}

				if (response.data.data.statusId > currentOrderStage) {
					localStorage.setItem("orderStage", response.data.data.statusId);
				}

				console.log("Action* - получить текущий заказ УСПЕХ: ", response);
				localStorage.removeItem("activeOrder");
				let kill = false;
				if (response.data.data.statusId === 32) {
					kill = response.data.data.id;
					dispatch(clearOrderRoute());
				}
				dispatch({
					type: GET_CURRENT_ORDER_SUCCESS,
					payload: response.data.data,
					killData: kill,
				});

				dispatch(changeOrderStage(response.data.data));
			})
			.catch((error) => {
				dispatch(errorFunction(error.response));
				dispatch({
					type: GET_CURRENT_ORDER_ERROR,
					payload: error,
				});
			});
	};
};

window.getCurrentOrder = (orderId) => {
	const accessToken = localStorage.getItem("accessToken");

	axiosInstance.get(apiOrder + `/rider/${orderId}`, {
		headers: { Authorization: `Bearer ${accessToken}` },
	});
};
