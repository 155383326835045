import React, { useCallback, useEffect } from "react";
import News from "../../components/News";
import PageHeader from "../../components/PageHeader";
import PageLayout from "../../components/PageLayout";
import { t } from "i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserNew, getUserNews, updNewsItem } from "./news.action";
import { getUserData } from "src/components/ProfileForm/profile.action";
import useDialog from "src/shared/hooks/useDialog";
import SimpleDialog from "src/common/Dialog";
import DetailNews from "src/components/News/DetailNews";
import Loader from "src/shared/ui/Loader/Loader";
import moment from "moment";

/**
 * Страница новостей
 *
 * @returns {*}
 * @constructor
 */

const NewsPage = () => {
	const newsState = useSelector((state) => state.news);
	const profileState = useSelector((state) => state.profile);
	const { userNews, loading } = newsState;
	const { registrationCountryInfo } = profileState;
	const { isOpen, dialogData, openDialog, closeDialog } = useDialog();
	const registrationDay = profileState.userData.registrationDate;
	const filteredNews = userNews.filter((oneNew) =>
		moment(oneNew.createdAt).isAfter(registrationDay),
	);

	const dispatch = useDispatch();

	const setDetailNews = useCallback(
		(newsData) => {
			openDialog(newsData);
			if (!newsData.isRead) {
				updNewsItem(newsData.id);
			}
		},
		[openDialog],
	);

	useEffect(() => {
		dispatch(getUserNews());
		const countryCode = localStorage.getItem("country_code");
		dispatch(getUserData(registrationCountryInfo?.country_code || countryCode));
	}, [dispatch, registrationCountryInfo?.country_code]);

	useEffect(() => {
		if (dialogData?.id) {
			dispatch(getUserNew(dialogData.id));
		}
	}, [dialogData?.id, dispatch]);

	if (loading && !isOpen) {
		return <Loader />;
	}

	return (
		<>
			<PageHeader title={t("news")} />
			<PageLayout>
				<News {...newsState} news={filteredNews} setDetailNews={setDetailNews} />
				{isOpen && (
					<SimpleDialog
						fullScreen
						open={isOpen}
						close={closeDialog}
						content={<DetailNews {...newsState} />}
						onClick={closeDialog}
					/>
				)}
			</PageLayout>
		</>
	);
};

export default NewsPage;
