import React, { useMemo } from 'react';
import { textFormator } from '../OrderRoute/AutocompleteOrderHandler';
import { ReactComponent as BackClock } from '../../images/BackClock.svg'
import styles from "./styles.module.scss"

export const HistoriesAddress = ({ lastPoints, click, inputId }) => {
    const uniqueLastPoints = useMemo(() => {
        const uniquePoints = {};
        lastPoints.forEach(place => {
            const key = `${place.lat},${place.lon}`;
            if (!uniquePoints[key]) {
                uniquePoints[key] = place;
            }
        });
        return Object.values(uniquePoints);
    }, [lastPoints]);

    const formattedLastPoints = useMemo(() => {
        return uniqueLastPoints.map(place => {
            const { mainText, secondaryText } = textFormator(place.name);
            return {
                ...place,
                mainText,
                secondaryText
            };
        });
    }, [uniqueLastPoints]);

    return (
        <div className={styles.addressesHistory}>
            {formattedLastPoints.map(place => (
                <div className={styles.singleAddress} key={`${place.lat},${place.lon}`} onClick={() => click(place, inputId)}>
                    <BackClock />
                    <div className={styles.textBlock}>
                        <p className={styles.mainText}>{place.mainText}</p>
                        <p className={styles.secondaryText}>{place.secondaryText}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};