import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Badge } from "@material-ui/core";
import { classNames } from "../../../shared/lib/classNames/classNames";
import { ChevronRightIcon } from "../../../shared/assets/icons";
import cls from "./menuItemLink.module.scss";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	badge: {
		marginRight: 16,

		"& .MuiBadge-badge": {
			height: 18,
		},
	},
}));

const MenuItemLink = (props) => {
	const {
		icon: Icon,
		text,
		path,
		showBadge,
		badgeContent,
		className,
		onClick,
	} = props;

	const classes = useStyles();

	return (
		<Link to={path} className={classNames(cls.menuLink, {}, [className])} onClick={onClick}>
			<Icon className={cls.menuIcon} />
			<div className={cls.menuText}>{text}</div>
			<div className={cls.menuAction}>
				{showBadge && (
					<Badge
						color="error"
						badgeContent={badgeContent}
						variant={badgeContent ? "standard" : "dot"}
						overlap="rectangular"
						className={classes.badge}
					/>
				)}
				<ChevronRightIcon className={cls.rightIcon} />
			</div>
		</Link>
	);
};

export default memo(MenuItemLink);
