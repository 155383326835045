export const ACTION_ERROR = 'ACTION_ERROR'

export const errorFunction = (data) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_ERROR,
      payload: data,
    })
  }
}
