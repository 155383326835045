import moment from 'moment'
import { t } from 'i18next'

const sortItemsByDate = (listItem, sortOrder = 'desc') => {
  const groupedItems = {}

  const compareFunction = (a, b) => {
    const aValue = moment(a.createdAt || a.date).valueOf()
    const bValue = moment(b.createdAt || b.date).valueOf()

    if (sortOrder === 'asc') {
      return aValue - bValue
    } else if (sortOrder === 'desc') {
      return bValue - aValue
    }
  }

  const sortedList = listItem.sort(compareFunction)

  sortedList.forEach((item) => {
    let dateLabel = ''
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'days').startOf('day')

    const itemDate = moment(item.createdAt || item.date).startOf('day')

    if (itemDate.isSame(today, 'd')) {
      dateLabel = t('today')
    } else if (itemDate.isSame(yesterday, 'd')) {
      dateLabel = t('yesterday')
    } else {
      dateLabel = itemDate.format('DD.MM.YYYY')
    }

    if (!groupedItems[dateLabel]) {
      groupedItems[dateLabel] = []
    }

    groupedItems[dateLabel].push(item)
  })

  return groupedItems
}

export { sortItemsByDate }
