import { useState, useEffect, useRef } from "react";
import styles from "../components/CreateSearchMarkers/styles.module.scss";

const timeouts = [
	{ duration: 7000, style: styles.pointMarkerPulse6km },
	{ duration: 14000, style: styles.pointMarkerPulse10km },
];

const useMarkerAnimation = (disableMarkerAnimation) => {
	const [pulseClass, setPulseClass] = useState(null);
	const timeoutRef = useRef([]);

	useEffect(() => {
		if (disableMarkerAnimation) {
			setPulseClass(null);
			return;
		}

		setPulseClass(styles.pointMarkerPulse);

		timeoutRef.current = timeouts.map((timeout) =>
			setTimeout(() => {
				setPulseClass(timeout.style);
			}, timeout.duration),
		);

		return () => {
			timeoutRef.current.forEach(clearTimeout);
			timeoutRef.current = [];
		};
	}, [disableMarkerAnimation]);

	return pulseClass;
};

export default useMarkerAnimation;
