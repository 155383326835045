import React, { memo } from 'react'
import ApkRules from '../../components/ApkRules'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
import { t } from 'i18next'

const ApkRulesPage = memo(() => {
  return (
    <>
      <PageHeader title={t('rules_of_use')} />
      <PageLayout>
        <ApkRules />
      </PageLayout>
    </>
  )
})

export default ApkRulesPage
