import React, { memo, useCallback, useEffect, useState } from 'react'
import cls from './setting.module.scss'
import MenuItem from '../../shared/ui/MenuItem/MenuItem'
import { GlobeIcon, LightModeIcon } from '../../shared/assets/icons'
import { t } from 'i18next'
import Select from '../../shared/ui/Select/Select'
import useLanguageManager from '../../shared/hooks/useLanguageManager'
import { languagesNames } from '../../mock/languages'
import { themes } from '../../mock/themes'
import { withTranslation } from 'react-i18next'
import useThemeManager from '../../shared/hooks/useThemeManager'
import { connect } from 'react-redux'
import { setTheme } from '../../store/reducers/theme-reducer/actions'
import { updateLanguage } from '../ProfileForm/profile.action'

const Setting = memo(({ changeLanguageTitle, setTheme, updateLanguage }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [nameSelect, setNameSelect] = useState('')
  const [optionsSelect, setOptionsSelect] = useState([])
  const [titleSelect, setTitleSelect] = useState('')
  const [selectedOption, setSelectedOption] = useState('')

  const { language, changeLanguage, getCurrentLanguage } = useLanguageManager()
  const { theme, changeTheme, getCurrentTheme } = useThemeManager()

  useEffect(() => {
    getCurrentLanguage()
    getCurrentTheme()
  }, [getCurrentLanguage, getCurrentTheme, language])

  const handleLanguageChange = useCallback(
    async (newLanguage) => {
      const userId = localStorage.getItem('userId')

      setSelectedOption(newLanguage)
      changeLanguage(newLanguage)
      changeLanguageTitle(t('settings'))
      await updateLanguage({ id: userId, language: newLanguage.locale })
    },
    [changeLanguage, changeLanguageTitle, updateLanguage]
  )

  const handleThemeChange = useCallback(
    (newTheme) => {
      setSelectedOption(newTheme)
      changeTheme(newTheme)
      setTheme(newTheme.name)
    },
    [changeTheme, setTheme]
  )

  const handlerSelect = useCallback(
    (item) => {
      if (nameSelect === 'languageName') {
        return handleLanguageChange(item)
      }
      if (nameSelect === 'themeName') {
        return handleThemeChange(item)
      }
    },
    [handleLanguageChange, handleThemeChange, nameSelect]
  )

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSelectLanguage = useCallback(() => {
    setOptionsSelect(languagesNames)
    setTitleSelect(t('language'))
    setNameSelect('languageName')
    setSelectedOption(language)
    setIsOpen(!isOpen)
  }, [isOpen, language])

  const handleSelectTheme = useCallback(() => {
    setOptionsSelect(themes)
    setTitleSelect(t('interface_design'))
    setNameSelect('themeName')
    setSelectedOption(theme)
    setIsOpen(!isOpen)
  }, [isOpen, theme])

  if (isOpen) {
    return (
      <Select
        title={nameSelect === 'languageName' ? t('language') : titleSelect}
        onClose={onClose}
        isOpen={isOpen}
        options={optionsSelect}
        selectedOption={selectedOption}
        handleOptionClick={handlerSelect}
      />
    )
  }

  return (
    <div className={cls.SettingWrapper}>
      <MenuItem
        icon={<GlobeIcon className={cls.globeIcon} />}
        title={t('language')}
        subtitle={language?.name}
        className={cls.menuItem}
        onClick={handleSelectLanguage}
      />
      <MenuItem
        icon={<LightModeIcon className={cls.icon} />}
        title={t('interface_design')}
        subtitle={t(theme?.name)}
        className={cls.menuItem}
        onClick={handleSelectTheme}
      />
    </div>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setTheme: (value) => dispatch(setTheme(value)),
    updateLanguage: (value) => dispatch(updateLanguage(value)),
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(Setting))
