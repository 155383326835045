import React from 'react'
import { t } from 'i18next'
import ProfileForm from '../../components/ProfileForm'
import PageHeader from '../../components/PageHeader'
import PageLayout from '../../components/PageLayout'
/**
 * Страница пользователя
 *
 * @returns {*}
 * @constructor
 */
export default function ProfilePage() {
  return (
    <>
      <PageHeader title={t('profile')} />
      <PageLayout>
        <ProfileForm />
      </PageLayout>
    </>
  )
}
